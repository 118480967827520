import React from "react";

import { OfflineStatusContext } from "./contexts/OfflineStatusContext";
import AppContainer from "./AppContainer";

import "@fortawesome/fontawesome-free/css/all.min.css";

import { createRoot } from "react-dom/client";
const container = document.getElementById("root");
const root = createRoot(container);
root.render(
    <OfflineStatusContext.Provider>
        <AppContainer />
    </OfflineStatusContext.Provider>
);
