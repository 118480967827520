import React from "react";
import { menu } from "../../../managers/pathManager";
import { LoginContext } from "../../../contexts/LoginContext";
import { Drawer as MuiDrawer, List, Grid, Avatar, IconButton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { Menu, MenuItem, Tooltip, Typography } from "@mui/material";
import { DrawerContext } from "../../../contexts/DrawerContext";
import {
    AllPasswordsEntry,
    BillingEntry,
    CloseEntry,
    DashboardEntry,
    DeviceLogger,
    EmployeePasswordsEntry,
    ExportEntry,
    GotoAdminConsoleEntry,
    GotoSuperAdminEntry,
    GroupManagementEntry,
    GroupPasswordsEntry,
    ImportEntry,
    InstallAppEntry,
    MandatorEntry,
    OfflinePasswordEntry,
    PwnedEntry,
    SettingEntry,
    UserManagementEntry,
} from "./PageDrawerListEntry";
import { useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import { useIsMobile } from "../../../hooks/mobileHook";
import { PageType, PageTypeList } from "../../../types/PageType";
import VaulteronLogoIcon from "../dataDisplay/VaulteronLogoIcon";
import { useDeviceInfos } from "../../../hooks/deviceInfosHook";
import { styled } from "@mui/system";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import { deepOrange } from "@mui/material/colors";
import { useContextMenu } from "../../../hooks/modalHook";

const openedMixin = (theme) => ({
    width: theme.sizes.drawerWidth,
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: "hidden",
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create("width", {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: "hidden",
    width: `calc(${theme.spacing(7)} + 1px)`,
    [theme.breakpoints.up("sm")]: {
        width: `calc(${theme.spacing(8)} + 1px)`,
    },
});

const DrawerHeader = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== "open" })(({ theme, open }) => ({
    width: theme.sizes.drawerWidth,
    flexShrink: 0,
    whiteSpace: "nowrap",
    boxSizing: "border-box",
    ...(open && {
        ...openedMixin(theme),
        "& .MuiDrawer-paper": openedMixin(theme),
    }),
    ...(!open && {
        ...closedMixin(theme),
        "& .MuiDrawer-paper": closedMixin(theme),
    }),
}));

const useStyles = makeStyles((theme) => ({
    profile: {
        padding: "0.7rem",
        width: theme.sizes.drawerWidth,
        flexWrap: "nowrap",
        maxHeight: 60,
        marginLeft: "7px",

        "& a": {
            textDecoration: "none",
        },
    },
    userBox: {
        flexGrow: 1,
        display: "flex",
        alignItems: "center",
        cursor: "pointer",

        "& .MuiGrid-root": {
            display: "flex",
            flexWrap: "wrap",
            "& a": {
                width: "100%",
            },
        },
    },
    avatar: {
        margin: "5px 10px 5px 0",
        width: "30px",
        height: "30px",
        fontSize: "1rem",
    },
    username: {
        color: theme.colors.drawerTextWhite,
        width: "100%",
        lineHeight: 0.8,
    },
    logo: {
        color: theme.palette.primary.dark,
        textAlign: "center",
        marginBottom: "5px",
    },
    logoImage: {
        marginTop: "55px",
        height: "60px",
    },
    menuBottom: {
        marginTop: "auto",
    },
    userMenuAvatarContainer: {
        marginLeft: theme.spacing(2),

        "& .MuiAvatar-root": {
            marginRight: 0,
        },
    },
    clickable: {
        cursor: "pointer",
    },
}));

const PageDrawer = ({ currentPageType }) => {
    const isMobile = useIsMobile();
    const { isMobileDevice } = useDeviceInfos();
    const classes = useStyles({ isMobile: isMobile, isMobileDevice: isMobileDevice });

    const { me } = useMyselfQuery();
    const { anchorEl: userMenuAnchor, open: openUserMenuAnchor, close: closeUserMenuAnchor } = useContextMenu();

    const handleUserMenuClick = (event) => {
        openUserMenuAnchor(event);
    };
    const handleUserMenuClose = () => {
        closeUserMenuAnchor();
    };

    const drawerContext = DrawerContext.useContainer();
    const loginContext = LoginContext.useContainer();

    const logout = async () => {
        try {
            await loginContext.logout();
        } catch (error) {
            console.error(error);
            window.location.href = menu.error.path;
        }
    };

    return (
        <>
            <Drawer variant="permanent" anchor="left" open={drawerContext.drawerOpen}>
                <DrawerHeader>
                    {drawerContext.drawerOpen && (
                        <IconButton onClick={drawerContext.closeDrawer}>
                            <ChevronLeftIcon />
                        </IconButton>
                    )}
                    {!drawerContext.drawerOpen && (
                        <IconButton style={{ marginRight: "5px" }} onClick={drawerContext.openDrawer}>
                            <MenuIcon />
                        </IconButton>
                    )}
                </DrawerHeader>
                <Grid container justifyContent="center">
                    <Grid item style={{ minHeight: isMobileDevice ? "0" : "170px", marginTop: isMobileDevice ? "0px" : "-50px" }}>
                        {drawerContext.drawerOpen && !isMobileDevice && (
                            <>
                                <VaulteronLogoIcon className={classes.logoImage} />
                                <Typography className={classes.logo} component="h1" variant="h5">
                                    Vaulteron
                                </Typography>
                                {currentPageType === PageTypeList.adminConsole && (
                                    <Typography className={classes.logo} component="h3" variant="h6">
                                        Admin
                                    </Typography>
                                )}
                                {currentPageType === PageTypeList.superAdminConsole && (
                                    <Typography className={classes.logo} component="h3" variant="h6">
                                        Support
                                    </Typography>
                                )}
                            </>
                        )}
                    </Grid>
                </Grid>

                <List className={classes.menu}>
                    <OfflinePasswordEntry currentPageType={currentPageType} />
                    <DashboardEntry currentPageType={currentPageType} />
                    <AllPasswordsEntry currentPageType={currentPageType} />
                    <EmployeePasswordsEntry currentPageType={currentPageType} />
                    <GroupPasswordsEntry currentPageType={currentPageType} />

                    <UserManagementEntry currentPageType={currentPageType} />

                    <ImportEntry currentPageType={currentPageType} />
                    <ExportEntry currentPageType={currentPageType} />
                    <InstallAppEntry currentPageType={currentPageType} />
                    <BillingEntry currentPageType={currentPageType} />
                    <DeviceLogger currentPageType={currentPageType} />
                    <MandatorEntry currentPageType={currentPageType} />
                </List>
                <List className={classes.menuBottom}>
                    <GroupManagementEntry currentPageType={currentPageType} />
                    <PwnedEntry currentPageType={currentPageType} />
                    <GotoAdminConsoleEntry currentPageType={currentPageType} />

                    <CloseEntry currentPageType={currentPageType} />

                    <SettingEntry currentPageType={currentPageType} />
                    <GotoSuperAdminEntry currentPageType={currentPageType} />
                </List>

                <Grid container className={classes.profile}>
                    <Grid item onClick={handleUserMenuClick} className={classes.clickable}>
                        <Tooltip title={`${me.firstname} ${me.lastname}`}>
                            <Avatar sx={{ bgcolor: deepOrange[500] }} className={classes.avatar}>
                                {me.firstname[0].toUpperCase() + me.lastname[0].toUpperCase()}
                            </Avatar>
                        </Tooltip>
                    </Grid>
                    {drawerContext.drawerOpen && (
                        <Grid item className={classes.userBox} onClick={handleUserMenuClick}>
                            <Tooltip title={`${me.firstname} ${me.lastname}`}>
                                <span className={classes.username}>
                                    {me.firstname} {me.lastname}
                                </span>
                            </Tooltip>
                        </Grid>
                    )}

                    <Menu anchorEl={userMenuAnchor} keepMounted open={Boolean(userMenuAnchor)} onClose={handleUserMenuClose}>
                        <MenuItem disabled>
                            <Grid container wrap="nowrap" justifyContent="space-between">
                                <Grid item container wrap="nowrap" direction="column">
                                    <Grid item>
                                        <strong>
                                            {me.firstname} {me.lastname}
                                        </strong>
                                    </Grid>
                                    <Grid item>
                                        <small>{me.email}</small>
                                    </Grid>
                                </Grid>
                                <Grid item className={classes.userMenuAvatarContainer}>
                                    <Avatar className={classes.avatar}>{me.firstname[0].toUpperCase() + me.lastname[0].toUpperCase()}</Avatar>
                                </Grid>
                            </Grid>
                        </MenuItem>
                        <MenuItem onClick={logout} selected={false}>
                            Logout
                        </MenuItem>
                    </Menu>
                </Grid>
            </Drawer>
        </>
    );
};

PageDrawer.propTypes = {
    currentPageType: PageType,
};

export default PageDrawer;
