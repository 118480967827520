import React from "react";
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, Chip, TableCell } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import { IconTypes } from "../../../types/IconType";
import IconButton from "../dataDisplay/IconButton";
import { KindOfPasswordTypes, PasswordType } from "../../../types/PasswordType";
import { useIsMobile } from "../../../hooks/mobileHook";
import classNames from "classnames";
import Visibility from "../dataDisplay/Visibility";
import Icon from "../dataDisplay/Icon";
import useTranslation from "../translation/translation";

const htmlColors = require("../../../htmlColor.json");

const useStyles = makeStyles((theme) => ({
    tableRow: {
        border: "1px solid rgb(224, 224, 224)",
        backgroundColor: "#fff",

        "&:last-child > *": {
            borderBottom: "unset",
        },
    },
    tableCell: {
        cursor: "pointer",
    },
    notSelectableNameCell: {
        padding: "9px",
        paddingLeft: "14px",
    },
    minWidthTableCell: {
        width: "1px",
    },
    nameTableCell: {
        maxWidth: (props) => (props.isMobile ? "10rem" : "auto"),
        overflowY: (props) => (props.isMobile ? "hidden" : "initial"),
        textOverflow: (props) => (props.isMobile ? "ellipsis" : "unset"),
        overflowWrap: (props) => (props.isMobile ? "break-word" : "normal"),
    },
    chips: {
        marginRight: "4px",
        position: "relative",
    },
    tagBackground: {
        opacity: 0.2,
        position: "absolute",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        borderRadius: "16px",
    },
    icon: {
        width: "1.3em",
    },
    typeChip: {
        marginRight: "4px",
        position: "relative",
        padding: "1.5em 1em",
        cursor: "pointer",
    },
}));

const PasswordTableRow = ({
    className,
    password,
    isViewer,
    skeleton,
    onRowClick,
    onContextMenu,
    showMenuOptions,
    selectedPasswords,
    setSelectedPasswords,
    showType,
}) => {
    const isMobile = useIsMobile();
    const classes = useStyles({ isMobile });
    const { i18n } = useTranslation();

    const { name, login, updatedAt, notes, type } = password;

    const handleRowClick = (e) => onRowClick(password);

    const generateTypeChip = (typeName, iconType) => (
        <Chip className={classes.typeChip} variant="outlined" icon={<Icon className={classes.icon} iconType={iconType} />} label={typeName} />
    );
    const handleSelectPassword = (password) => {
        let selectedCopy = [...selectedPasswords];
        let index = selectedCopy.findIndex((o) => o["id"] === password["id"]);
        if (index === -1) selectedCopy.push(password);
        else selectedCopy.splice(index, 1);
        setSelectedPasswords(selectedCopy);
    };

    const trimLongText = (text) => text.replace(/(^.{50}).*$/, "$1...");

    if (skeleton)
        return (
            <TableRow>
                <TableCell>***********</TableCell>
                <TableCell>***********</TableCell>
                <TableCell>***********</TableCell>
                <TableCell>***********</TableCell>
                <TableCell>***********</TableCell>
            </TableRow>
        );

    return (
        <TableRow className={classNames(classes.tableRow, className)} onContextMenu={onContextMenu ? onContextMenu : undefined} hover>
            {setSelectedPasswords && (
                <TableCell padding="checkbox">
                    <Checkbox
                        disabled={isViewer}
                        checked={selectedPasswords.some((selectedPW) => password.id === selectedPW.id)}
                        onClick={(_) => handleSelectPassword(password)}
                    />
                </TableCell>
            )}

            <TableCell
                padding="none"
                className={classNames(classes.tableCell, classes.nameTableCell, { [classes.notSelectableNameCell]: !Boolean(setSelectedPasswords) })}
                onClick={handleRowClick}
            >
                {trimLongText(name)}
            </TableCell>

            <Visibility hideOnMobile>
                <TableCell className={classes.tableCell} onClick={handleRowClick}>
                    {trimLongText(login)}
                </TableCell>
            </Visibility>

            <Visibility hideOnMobile hideOnTablet>
                <TableCell onClick={handleRowClick} className={classes.tableCell}>
                    {trimLongText(notes)}
                </TableCell>
            </Visibility>

            <Visibility hideOnMobile>
                {showType && type && (
                    <TableCell className={classes.tableCell} onClick={handleRowClick}>
                        {type === KindOfPasswordTypes.accountPasswords && generateTypeChip(i18n("password.chips.private"), IconTypes.secret)}
                        {type === KindOfPasswordTypes.companyPasswords && generateTypeChip(i18n("password.chips.company"), IconTypes.lock)}
                        {type === KindOfPasswordTypes.groupPasswords && generateTypeChip(i18n("password.chips.group"), IconTypes.sharedPassword)}
                    </TableCell>
                )}
                <TableCell padding="none" align="right" className={classes.tableCell} onClick={handleRowClick}>
                    {i18n("password.information.lastChange")} <br /> {new Date(updatedAt).toLocaleDateString("de")}
                </TableCell>
            </Visibility>

            {showMenuOptions && onContextMenu && (
                <TableCell align="right" className={classNames(classes.tableCell, classes.minWidthTableCell)}>
                    <IconButton iconType={IconTypes.moreOptions} onClick={onContextMenu} />
                </TableCell>
            )}
        </TableRow>
    );
};

PasswordTableRow.defaultProps = {
    className: "",
};

PasswordTableRow.propTypes = {
    className: PropTypes.string,
    password: PropTypes.object,
    skeleton: PropTypes.bool,
    onRowClick: PropTypes.func,
    onContextMenu: PropTypes.func,
    showMenuOptions: PropTypes.bool,
    selectedPasswords: PropTypes.arrayOf(PasswordType),
    setSelectedPasswords: PropTypes.func,
    isViewer: PropTypes.bool,
    showType: PropTypes.bool,
};

export default PasswordTableRow;
