import Page from "../common/layout/Page";
import PasswordSearchBar from "../common/dataEntry/PasswordSearchBar";
import { KindOfPasswordTypes } from "../../types/PasswordType";
import ContentContainer from "../common/layout/ContentContainer";
import PasswordsContainer from "../common/passwordComponents/PasswordsContainer";
import { Table, TableBody } from "@mui/material";
import PasswordTableRow from "../common/passwordComponents/PasswordTableRow";
import { useEffect, useState } from "react";
import { useFilterPassword } from "../../hooks/passwordHook";
import { useContextMenu, useModalStatus } from "../../hooks/modalHook";
import { useMyselfQuery, useMyselfRole } from "../../hooks/queries/userQueryHooks";
import { ClientFilter, defaultPasswordSearchTermFields } from "../../managers/filterManager";
import PasswordModal from "../common/passwordComponents/PasswordModal";
import ConfirmDeletePassword from "../common/passwordComponents/ConfirmDeletePassword";
import AccessLogModal from "../common/passwordComponents/AccessLogModal";
import PasswordHistoryModal from "../common/passwordComponents/history/PasswordHistoryModal";
import PasswordTableRowContextMenu from "../common/passwordComponents/PasswordTableRowContextMenu";
import { guid } from "../../managers/guidManager";
import { useGetAllPasswordQuery } from "../../graphql/generated";
import { useCompanyPasswordDeleteMutation } from "../../hooks/queries/companyPasswordHook";
import AddPasswordModal from "../common/passwordComponents/AddPasswordModal";
import { useAccountPasswordDeleteMutation } from "../../hooks/queries/accountPasswordHook";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";
import useTranslation from "../common/translation/translation";
import NatoAlphabetPasswordModal from "../common/passwordComponents/NatoAlphabetPasswordModal";

function AllPasswordsPage() {
    const { searchTerm, setSearchTerm, groups: groupsToFilter, setGroups: setGroupsToFilter, filterType, setFilterType } = useFilterPassword();

    const { i18n } = useTranslation();

    const [currentPassword, setCurrentPassword] = useState({});
    const { modalState: historyModalState, open: openHistoryModal, close: closeHistoryModal } = useModalStatus();
    const { modalState: accessLogModalState, open: openAccessLogModal, close: closeAccessLogModal } = useModalStatus();
    const {
        modalState: confirmDeletePasswordModalState,
        open: openConfirmDeletePasswordModal,
        close: closeConfirmDeletePasswordModal,
    } = useModalStatus();

    const { modalState: passwordModalState, open: openPasswordModal, close: closePasswordModal } = useModalStatus();
    const { modalState: addPasswordModalState, open: openAddPasswordModal, close: closeAddPasswordModal } = useModalStatus();
    const {
        modalState: natoAlphabetPasswordModalState,
        open: openNatoAlphabetPasswordModal,
        close: closeNatoAlphabetPasswordModal,
    } = useModalStatus();

    const { anchorEl, open: openContextMenu, close: closeContextMenu } = useContextMenu();
    const { deletePassword: deleteCompanyPassword } = useCompanyPasswordDeleteMutation();
    const { deletePassword: deleteAccountPassword } = useAccountPasswordDeleteMutation();

    const { me } = useMyselfQuery();

    const { data, loading } = useGetAllPasswordQuery({
        fetchPolicy: "cache-and-network",
    });

    const companyPasswords =
        data?.companyPasswords?.map((p) => {
            return { ...p, type: KindOfPasswordTypes.companyPasswords };
        }) || [];
    const sharedPasswords =
        data?.sharedPasswords?.map((p) => {
            return { ...p, type: KindOfPasswordTypes.groupPasswords };
        }) || [];
    const accountPasswords =
        data?.accountPasswords?.map((p) => {
            return { ...p, type: KindOfPasswordTypes.accountPasswords };
        }) || [];
    const allPasswords = [...companyPasswords, ...accountPasswords, ...sharedPasswords];

    const filteredPasswords = new ClientFilter(allPasswords)
        .byArchived(false)
        .bySearchTerm(defaultPasswordSearchTermFields, searchTerm)
        .byGroups(groupsToFilter)
        .sortBy("name")
        .byCustomFilter((pw) => {
            if (!pw.createdBy) return true;
            return pw.createdBy.id === me.id;
        })
        .byCustomFilter((pw) => {
            if (filterType === "none") return true;

            return filterType === pw.type;
        })
        .build();

    const emptyTitle = i18n("password.first.title");
    const emptyInformation = i18n("password.first.subTitle");
    const empty = Boolean(allPasswords.length === 0);
    const isInSearch = Boolean(searchTerm !== "" || groupsToFilter.length > 0 || filterType !== "none");

    const [groupId, setGroupId] = useState(guid.empty);
    const [isEditable, setIsEditable] = useState(true);
    const passwordType = currentPassword.type;

    const { isViewer } = useMyselfRole(groupId);

    useEffect(() => {
        if (passwordType === KindOfPasswordTypes.groupPasswords) {
            setIsEditable(!isViewer);
        } else {
            setIsEditable(true);
        }
    }, [isViewer, passwordType, groupId]);

    const onRowAction = (pw) => {
        setCurrentPassword(pw);

        switch (pw.type) {
            case KindOfPasswordTypes.accountPasswords:
            case KindOfPasswordTypes.companyPasswords:
                setIsEditable(true);
                break;
            case KindOfPasswordTypes.groupPasswords:
                setGroupId(pw.groupId);
                break;
        }
    };

    return (
        <>
            <Page
                loading={loading}
                empty={empty}
                emptyTitle={emptyTitle}
                emptyInformation={emptyInformation}
                onNoContentAction={openAddPasswordModal}
                inSearchOperation={isInSearch}
            >
                <PasswordSearchBar
                    onAddPassword={openAddPasswordModal}
                    passwordType={KindOfPasswordTypes.companyPasswords}
                    onSearchTerm={setSearchTerm}
                    groups={groupsToFilter}
                    onGroups={setGroupsToFilter}
                    type={filterType}
                    onType={setFilterType}
                />
                <ContentContainer>
                    <PageHeader>{i18n("navigation.allPasswords")}</PageHeader>
                    <PageSubTitle>{i18n("dashboard.vaults.allPasswordsDescription")}</PageSubTitle>
                    {filteredPasswords.length > 0 && (
                        <PasswordsContainer>
                            <Table size="small">
                                <TableBody>
                                    {filteredPasswords.map((pw, i) => (
                                        <PasswordTableRow
                                            key={i}
                                            showMenuOptions
                                            password={pw}
                                            onRowClick={(_) => {
                                                onRowAction(pw);
                                                openPasswordModal();
                                            }}
                                            onContextMenu={(e) => {
                                                onRowAction(pw);
                                                openContextMenu(e);
                                            }}
                                            showType
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </PasswordsContainer>
                    )}
                </ContentContainer>
            </Page>

            {passwordModalState && (
                <PasswordModal
                    onClose={closePasswordModal}
                    onOk={closePasswordModal}
                    password={currentPassword}
                    passwordType={passwordType}
                    disableTags
                    editable={isEditable}
                />
            )}

            {confirmDeletePasswordModalState && (
                <ConfirmDeletePassword
                    onOk={() => {
                        if (passwordType === KindOfPasswordTypes.accountPasswords) {
                            deleteAccountPassword({ variables: { id: currentPassword.id } }).then(closeConfirmDeletePasswordModal);
                        } else {
                            deleteCompanyPassword({ variables: { id: currentPassword.id } }).then(closeConfirmDeletePasswordModal);
                        }
                    }}
                    onClose={closeConfirmDeletePasswordModal}
                    passwords={[currentPassword]}
                />
            )}

            {accessLogModalState && <AccessLogModal logs={currentPassword.accessLog} onClose={closeAccessLogModal} />}
            {historyModalState && <PasswordHistoryModal password={currentPassword} onClose={closeHistoryModal} />}

            <PasswordTableRowContextMenu
                passwordType={passwordType || KindOfPasswordTypes.companyPasswords}
                isViewer={!isEditable}
                password={currentPassword}
                anchorEl={anchorEl}
                onClose={closeContextMenu}
                onPasswordModal={openPasswordModal}
                onConfirmDeleteModal={openConfirmDeletePasswordModal}
                onAccessModal={openAccessLogModal}
                onHistoryModal={me.admin ? openHistoryModal : undefined}
                onNatoAlphabetPassword={openNatoAlphabetPasswordModal}
            />
            {addPasswordModalState && (
                <AddPasswordModal
                    initialPasswordType={KindOfPasswordTypes.companyPasswords}
                    onClose={closeAddPasswordModal}
                    onOk={closeAddPasswordModal}
                />
            )}
            {natoAlphabetPasswordModalState ? (
                <NatoAlphabetPasswordModal password={currentPassword} passwordType={passwordType} onClose={closeNatoAlphabetPasswordModal} />
            ) : undefined}
        </>
    );
}

export default AllPasswordsPage;
