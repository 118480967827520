import React from "react";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { IconTypes } from "../../types/IconType";
import Icon from "../common/dataDisplay/Icon";

const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: "5px",
        marginBottom: "5px",
    },
    listIcon: {
        minWidth: "40px",
    },
}));

const UserTableRowContextMenu = ({ anchorEl, onClose, onEdit }) => {
    const classes = useStyles();

    return (
        <Menu keepMounted open={Boolean(anchorEl)} onClose={onClose} anchorReference="anchorPosition" anchorPosition={anchorEl}>
            <MenuItem
                onClick={() => {
                    onClose();
                    onEdit();
                }}
            >
                <ListItemIcon>
                    <Icon className={classes.listIcon} iconType={IconTypes.editUser} />
                </ListItemIcon>
                <Typography variant="inherit">Bearbeiten</Typography>
            </MenuItem>
        </Menu>
    );
};

UserTableRowContextMenu.propTypes = {
    anchorEl: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
};

export default UserTableRowContextMenu;
