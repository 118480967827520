import React, { useEffect, useState } from "react";
import { ApolloProvider } from "@apollo/client";
import { createApolloClient } from "./apolloClient";
import { localStorageManager } from "./managers/localStorageManager";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { ThemeProvider } from "@mui/material/styles";
import { CssBaseline } from "@mui/material";
import SplashScreen from "./components/splashScreen/SplashScreen";
import ErrorPage from "./components/systemPages/ErrorPage";
import { LoginContext } from "./contexts/LoginContext";
import { DrawerContext } from "./contexts/DrawerContext";
import { OfflinePasswordsContext } from "./contexts/OfflinePasswordsContext";
import { OfflineStatusContext } from "./contexts/OfflineStatusContext";
import { ExtensionInfosContext } from "./contexts/ExtensionContext";
import App from "./App";

import theme from "./styles/theme";

const AppContainer = () => {
    const offlineStatusContext = OfflineStatusContext.useContainer();
    let [unauthorised, setUnauthorised] = useState(false);

    // Apollo offline storage and detection
    const [client, setClient] = useState(null);
    const [loading, setLoading] = useState(true);
    const [errorOccured, setErrorOccured] = useState(false);
    useEffect(() => {
        const handleNetworkError = () => offlineStatusContext.setIsOffline(true);
        const handleUnauthorised = () => setUnauthorised(true);
        createApolloClient(handleNetworkError, handleUnauthorised)
            .then(({ client, persistor, offlineLink }) => {
                setClient(client);

                offlineStatusContext.setPersistor(persistor);
                offlineStatusContext.setOfflineLink(offlineLink);

                setLoading(false);
            })
            .catch((e) => {
                console.error(e);
                setErrorOccured(true);
            });
    }, []);

    // ServiceWorker updates
    const onServiceWorkerUpdateReady = (registration) => {
        offlineStatusContext.setNewVersionAvailable(true);
        localStorageManager.setServiceWorkerUpdateAvailable(true);
        offlineStatusContext.setWaitingNewWorker(registration.waiting);
    };
    const onServiceWorkerInstallationSuccess = (registration) => {
        // Ignored
    };
    const onSWRegistered = (registration) => {
        // Important information for troubleshooting: https://stackoverflow.com/questions/37573482/to-check-if-serviceworker-is-in-waiting-state

        if (registration.waiting && registration.active) {
            onServiceWorkerUpdateReady(registration);
        } else {
            registration.addEventListener("updatefound", () => {
                registration.installing.addEventListener("statechange", (e) => {
                    if (e.target.state === "installed" && registration.active) {
                        onServiceWorkerUpdateReady(registration);
                    }
                });
            });
        }
    };
    useEffect(() => {
        serviceWorkerRegistration.register({
            onUpdate: onServiceWorkerUpdateReady,
            onSuccess: onServiceWorkerInstallationSuccess,
            onRegistered: onSWRegistered,
        });
    }, []);

    // PWA install prompt
    useEffect(() => {
        window.addEventListener("beforeinstallprompt", (e) => {
            // Prevent the mini-infobar from appearing on mobile
            e.preventDefault();
            offlineStatusContext.setPwaInstallEvent(e);
        });
    }, []);

    // We can not go past this point when the persistor is not loaded yet
    // Because the 'client' and 'persistor' will be set as initialStates for the context providers
    if (loading) return <SplashScreen />;
    if (errorOccured) return <ErrorPage errorMessage="Ein Fehler ist aufgetreten. Bitte laden Sie die Seite neu." />;

    return (
        <ApolloProvider client={client}>
            <LoginContext.Provider>
                <DrawerContext.Provider>
                    <ExtensionInfosContext.Provider>
                        <OfflinePasswordsContext.Provider>
                            <ThemeProvider theme={theme}>
                                <CssBaseline />
                                <App unauthorised={unauthorised} />
                            </ThemeProvider>
                        </OfflinePasswordsContext.Provider>
                    </ExtensionInfosContext.Provider>
                </DrawerContext.Provider>
            </LoginContext.Provider>
        </ApolloProvider>
    );
};

export default AppContainer;
