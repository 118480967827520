import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import AdminAuthorizationNeededPage from "../../systemPages/AdminAuthorizationNeededPage";
import { useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import ErrorPage from "../../systemPages/ErrorPage";

const useStyles = makeStyles((theme) => ({
    spinner: {
        position: "fixed",
        left: "50%",
        top: "50%",
    },
}));
const LoadingContainer = ({ needsAdmin, children }) => {
    const classes = useStyles();
    const { me, loading, error } = useMyselfQuery();

    if (loading) return <CircularProgress className={classes.spinner} />;
    if (error) return <ErrorPage errorMessage="Benutzer konnte nicht geladen werden" />;

    if (!needsAdmin || me.admin) return children;
    return <AdminAuthorizationNeededPage />;
};

LoadingContainer.propTypes = {
    children: PropTypes.node.isRequired,
    needsAdmin: PropTypes.bool,
};

export default LoadingContainer;
