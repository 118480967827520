import React, { useEffect } from "react";
import PropTypes from "prop-types";
import SearchBar from "./SearchBar";
import PasswordFilterBar from "../passwordComponents/toolbar/PasswordFilterBar";
import { useDelayedMaterialUIInput } from "../../../hooks/inputHook";
import { KindOfPasswordType, passwordTypeToString } from "../../../types/PasswordType";
import { useIsMobile } from "../../../hooks/mobileHook";
import useTranslation from "../translation/translation";

const PasswordSearchBar = (props) => {
    const { passwordType, onSearchTerm, onAddPassword, tags, groups } = props;

    const { value: searchTerm, localValue: localSearchTerm, onChange: setLocalSearchTerm } = useDelayedMaterialUIInput("");
    const isMobile = useIsMobile();
    const { i18n } = useTranslation();

    useEffect(() => {
        onSearchTerm(searchTerm);
    }, [searchTerm]);

    const hasFilter = tags || groups;
    const filterBar = hasFilter ? <PasswordFilterBar {...props} /> : <div />;

    return (
        <SearchBar
            passwordType={passwordType}
            placeholder={isMobile ? i18n("search.searchPlaceholderMobile") : i18n("search.searchPlaceholder")}
            value={localSearchTerm}
            onChange={setLocalSearchTerm}
            onAdd={onAddPassword}
            subBar={filterBar}
        />
    );
};

PasswordSearchBar.propTypes = {
    passwordType: KindOfPasswordType,
    onAddPassword: PropTypes.func,
    onCardMode: PropTypes.func,
    onTableMode: PropTypes.func,
    onSearchTerm: PropTypes.func.isRequired,
    tags: PropTypes.array,
    onTags: PropTypes.func,
    onGroups: PropTypes.func,
    groups: PropTypes.array,
    onType: PropTypes.func,
    type: PropTypes.string,
};

export default PasswordSearchBar;
