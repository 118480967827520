import React from "react";
import PropTypes from "prop-types";
import { VaulteronPasswordColumns } from "./VaulteronPasswordColumns";
import CustomModal from "../../common/feedback/modals/CustomModal";
import { Grid, Typography } from "@mui/material";
import CustomDataGrid from "../../common/dataDisplay/CustomDataGrid";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({ tableContainer: { marginTop: theme.spacing(2) } }));

const FinishedImportModal = ({ skippedPasswords, onClose }) => {
    const classes = useStyles();

    const skippedColumnName = "Fehler";
    const rows = skippedPasswords.map((pw, index) => {
        const res = { id: index, ...pw };
        res[skippedColumnName] = pw.skippedReason;
        return res;
    });
    const columns = [...Object.values(VaulteronPasswordColumns), skippedColumnName].map((c, index) => ({
        field: c,
        headerName: c,
        sortable: false,
        minWidth: index === Object.keys(VaulteronPasswordColumns).length ? 500 : 100,
        flex: index === Object.keys(VaulteronPasswordColumns).length ? 5 : 1,
    }));

    const handleDownloadFile = () => {
        let csvString = "";
        const columnNames = columns.map((c) => c.headerName);
        csvString += columnNames.join(",") + "\n";
        csvString += rows.map((row) => columnNames.map((columnName) => row[columnName]).join(",") + "\n");

        let csvFile = new Blob([csvString], { type: "text/csv" });
        let downloadLink = document.createElement("a");
        downloadLink.download = "skipped_passwords.csv";
        downloadLink.href = window.URL.createObjectURL(csvFile);
        downloadLink.style.display = "none";

        document.body.appendChild(downloadLink);
        downloadLink.click();
        onClose();
    };
    return (
        <CustomModal
            loading={false}
            error={false}
            onClose={onClose}
            onOk={handleDownloadFile}
            saveText="Datei herunterladen"
            maxWidth="xl"
            title="Importieren der Passwörter war erfolgreich"
            closeableOnlyWithDialogActionButtons
        >
            <Grid container direction="column">
                <Grid item>
                    <Typography component="h2">
                        Beim Importieren wurden <strong>{skippedPasswords.length} Passwörter übersprungen</strong>.
                    </Typography>
                    <Typography component="p">
                        Die nachfolgende Tabelle zeigt Ihnen die übersprungenen Passwörter.
                        <br />
                        Um diese Passwörter zu importieren, laden sie diese Liste bitte als CSV-Datei herunter, bereinigen Sie die Fehler und
                        importieren sie diese korrigierten Passwörter danach erneut.
                    </Typography>
                </Grid>
                <Grid item className={classes.tableContainer}>
                    <CustomDataGrid rows={rows} columns={columns} pageSize={10} disableColumnMenu />
                </Grid>
            </Grid>
        </CustomModal>
    );
};

FinishedImportModal.propTypes = {
    skippedPasswords: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default FinishedImportModal;
