import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import makeStyles from "@mui/styles/makeStyles";
import { TextField, Typography } from "@mui/material";
import { LoginContext } from "../../contexts/LoginContext";
import Button from "@mui/material/Button";
import { useMaterialUIInput } from "../../hooks/inputHook";
import { menu } from "../../managers/pathManager";
import { CryptoManager } from "../../managers/cryptoManager";
import PasswordInputField from "../common/dataEntry/input/PasswordInputField";
import ConfirmationDialog from "../common/feedback/modals/ConfirmationDialog";
import { ConfirmDialogVariantList } from "../../types/ConfirmDialogVariant";
import WaitDialog from "../common/feedback/modals/WaitDialog";
import { useModalStatus } from "../../hooks/modalHook";
import { HtmlAutoCompleteTypeList } from "../../types/HtmlAutoCompleteType";
import VerticalContentPageContainer from "../common/layout/VerticalContentPageContainer";
import { api } from "../../managers/apiManager";
import { encryptPasswordsForUser } from "../../managers/passwordManager";
import VaulteronLogoIcon from "../common/dataDisplay/VaulteronLogoIcon";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "30rem",
        padding: "30px",
        border: "1px solid #C7C6C1",
        borderRadius: "5px",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    heading: {
        color: "#1D1C3E",
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(1),
    },
}));

const ActivateAccount = () => {
    const classes = useStyles();
    const location = useLocation();
    const { value: password, onChange: setPassword } = useMaterialUIInput("");
    const loginContext = LoginContext.useContainer();
    const [errorMessage, setErrorMessage] = useState("");
    const [tokenInvalid, setTokenInvalid] = useState(false);

    const [email, setEmail] = useState("");
    const [activationCode, setActivationCode] = useState("");
    const [tempLoginPassword, setTempLoginPassword] = useState("");
    const [userId, setUserId] = useState("");

    const { modalState: waitDialogOpen, close: closeWaitDialog, open: openWaitDialog } = useModalStatus();
    const { modalState: successDialogOpen, close: closeSuccessDialog, open: openSuccessDialog } = useModalStatus();

    useEffect(() => {
        // Log out if already logged-in
        if (loginContext.userPasswordHash) {
            loginContext.clearLocalData();
        }
    }, []);

    useEffect(() => {
        try {
            const params = queryString.parse(location.search);
            setEmail(decodeURIComponent(params.mail));
            setActivationCode(decodeURIComponent(params.activationCode));
            setTempLoginPassword(decodeURIComponent(params.loginPassword));
            setUserId(params.userId);
        } catch (e) {
            console.error("Unable to parse parameters");
            setTokenInvalid(true);
        }
    }, []);

    if (tokenInvalid) {
        return (
            <VerticalContentPageContainer hideDrawer>
                <div className={classes.paper}>
                    <VaulteronLogoIcon height="60px" />
                    <Typography className={classes.heading} component="h1" variant="h5">
                        Vaulteron
                    </Typography>
                    <Typography className={classes.title} component="h2" variant="h4">
                        Accountaktivierung
                    </Typography>
                    <Typography component="p" variant="body1">
                        Dieser Account-Aktivierungslink ist fehlerhalt oder abgelaufen. <br />
                        Aus Sicherheitsgründen sind Account-Aktivierungslinks nur für 24 Stunden gültig. <br />
                        <br />
                        Es wurde ein neuer gültiger Link an die angegeben Adresse gesendet. Bitte gehen Sie in Ihr E-Mail Postfach und verwenden Sie
                        diesen neuen Activierungslink.
                    </Typography>
                </div>
            </VerticalContentPageContainer>
        );
    }

    const onActivateAccount = async (e) => {
        e.preventDefault();
        openWaitDialog();

        try {
            const tempLoginPasswordHashed = await CryptoManager.createPasswordHash(tempLoginPassword);
            const newPasswordHashed = await CryptoManager.createPasswordHash(password);
            const keys = await CryptoManager.createAsymmetricalKeyPair();
            const encryptedPrivateKey = await CryptoManager.encryptUsingUserCredentials(keys.privateKey, newPasswordHashed);

            const result = await fetch(api.getAllSharedPasswordsOnAccountActivation, {
                method: "POST",
                headers: new Headers({ "Content-Type": "application/json" }),
                body: JSON.stringify({
                    userId: userId,
                    token: activationCode,
                    oldPasswordHashed: tempLoginPasswordHashed,
                }),
            });
            if (!result.ok) throw await result.json();
            const responseJson = await result.json();
            const oldEncryptedPrivateKey = responseJson.encryptedPrivateKey;
            const oldEncryptedPasswords = responseJson.encryptedSharedPasswords;
            const privateKey = await CryptoManager.decryptUsingUserCredentials(oldEncryptedPrivateKey, tempLoginPasswordHashed);
            const decryptedSharedPasswords = [];
            for (const oldEncryptedPassword of oldEncryptedPasswords) {
                const decryptedPasswordString = await CryptoManager.decryptWithPrivateKey(oldEncryptedPassword.encryptedPasswordString, privateKey);
                decryptedSharedPasswords.push({
                    id: oldEncryptedPassword.sharedPasswordId,
                    decryptedString: decryptedPasswordString,
                });
            }

            const encryptedPasswords = await encryptPasswordsForUser(decryptedSharedPasswords, keys.publicKey);
            const previousPasswordHashEncryptedWithPublicKey = await CryptoManager.encryptWithPublicKey(tempLoginPasswordHashed, keys.publicKey);

            await loginContext.setPasswordWithEmailToken(
                userId,
                activationCode,
                tempLoginPasswordHashed,
                newPasswordHashed,
                keys.publicKey,
                encryptedPrivateKey,
                encryptedPasswords,
                previousPasswordHashEncryptedWithPublicKey
            );

            closeWaitDialog();
            openSuccessDialog();
        } catch (e) {
            console.error(e);
            try {
                if (e.message.includes("Token or password invalid.")) {
                    setTokenInvalid(true);
                } else {
                    if (e.errorCode) setErrorMessage(`${e.message}: ${e.reasons.reduce((agg, e) => agg + e + "\n")}`);
                    else setErrorMessage(`Es ist ein Fehler aufgetreten: ${e.message}`);
                }
            } catch (e) {
                setErrorMessage(`Es ist ein Fehler aufgetreten: ${e.message}`);
            }
        }
    };

    const onCloseErrorDialog = () => {
        window.location.href = menu.root.path;
        closeWaitDialog();
    };

    const onCloseSuccessDialog = () => {
        window.location.href = menu.root.path;
        closeWaitDialog();
        closeSuccessDialog();
    };

    return (
        <VerticalContentPageContainer hideDrawer>
            <div className={classes.paper}>
                <VaulteronLogoIcon height="60px" />
                <Typography className={classes.heading} component="h1" variant="h5">
                    Vaulteron
                </Typography>
                <Typography className={classes.title} component="h2" variant="h4">
                    Accountaktivierung
                </Typography>
                <Typography component="p" variant="body1">
                    Ein Account wurde für Sie erstellt. Bitte geben Sie nun Ihr gewünschtes Passwort ein, um Ihren Account zu aktivieren.
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        id="email"
                        margin="normal"
                        label="Email Adresse"
                        name="email"
                        autoComplete={HtmlAutoCompleteTypeList.email}
                        autoFocus
                        value={email}
                        required
                        fullWidth
                        disabled
                    />
                    <PasswordInputField
                        required
                        fullWidth
                        autoFocus
                        value={password}
                        onChange={setPassword}
                        label="Login Passwort"
                        variant="outlined"
                        name="password"
                        margin="normal"
                        autoComplete={HtmlAutoCompleteTypeList.newPassword}
                    />

                    <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={onActivateAccount}>
                        Account aktivieren
                    </Button>
                </form>
            </div>

            <WaitDialog
                title="Verschlüsselung in Gange"
                open={waitDialogOpen && errorMessage === ""}
                description={<>Derzeit werden alle Passwörter auf die Sie Zugriff haben für Sie verschlüsselt. Bitte haben Sie einen Moment Geduld</>}
            />

            <ConfirmationDialog
                onOk={onCloseErrorDialog}
                onClose={onCloseErrorDialog}
                open={errorMessage !== ""}
                variant={ConfirmDialogVariantList.error}
                title="Es ist ein Fehler aufgetreten"
                description={
                    <>
                        Beim Verschlüsseln ist leider ein Fehler aufgetreten.
                        <br />
                        {errorMessage}
                    </>
                }
            />

            <ConfirmationDialog
                onOk={onCloseSuccessDialog}
                onClose={onCloseSuccessDialog}
                open={successDialogOpen}
                variant={ConfirmDialogVariantList.info}
                title="Verschlüsselung erfolgreich abgeschlossen"
                description={
                    <>
                        Die Verschlüsslung all Ihrer Passwörter wurde erfolgreiche abgeschlossen.
                        <br />
                        Wir wünschen Ihnen viel Erfolg mit Ihrem einfachen und sicheren Passwortmanager - <strong>Vaulteron</strong>
                    </>
                }
            />
        </VerticalContentPageContainer>
    );
};

export default ActivateAccount;
