import React from "react";
import PropTypes from "prop-types";
import Snackbar from "@mui/material/Snackbar";
import Slide from "@mui/material/Slide";
import IconButton from "../dataDisplay/IconButton";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import InfoIcon from "@mui/icons-material/Info";
import WarningIcon from "@mui/icons-material/Warning";
import CloseIcon from "@mui/icons-material/Close";
import makeStyles from "@mui/styles/makeStyles";
import classNames from "classnames";
import SnackbarContent from "@mui/material/SnackbarContent";
import Fade from "@mui/material/Fade";

const useStyles = makeStyles((theme) => ({
    success: {
        backgroundColor: "green",
    },
    error: {
        backgroundColor: theme.palette.error.dark,
    },
    info: {
        backgroundColor: theme.palette.primary.main,
    },
    warning: {
        backgroundColor: "red",
    },
    icon: {
        fontSize: 20,
    },
    iconVariant: {
        opacity: 0.9,
        marginRight: theme.spacing(1),
    },
    message: {
        display: "flex",
        alignItems: "center",
    },
}));

const CustomSnackbar = ({
    message,
    open,
    onClose,
    variant,
    withSlideTransition,
    withCloseButton,
    verticalPosition,
    horizontalPosition,
    className,
}) => {
    const classes = useStyles();
    const createTransition = (props) => {
        if (withSlideTransition) {
            const slideDirection = verticalPosition === "top" ? "down" : "up";
            return <Slide {...props} direction={slideDirection} />;
        } else {
            return <Fade {...props} />;
        }
    };

    let actions = [];
    if (withCloseButton)
        actions.push(
            <IconButton key="close" aria-label="close" color="inherit" onClick={onClose}>
                <CloseIcon className={classes.icon} />
            </IconButton>
        );

    const variantIcon = {
        success: CheckCircleIcon,
        warning: WarningIcon,
        error: ErrorIcon,
        info: InfoIcon,
    };
    const Icon = variantIcon[variant];

    const content = (
        <span id="message-id" className={classes.message}>
            <Icon className={classNames(classes.icon, classes.iconVariant)} />
            {message}
        </span>
    );

    return (
        <Snackbar
            open={open}
            className={className}
            onClose={onClose}
            autoHideDuration={2000}
            TransitionComponent={createTransition}
            anchorOrigin={{ vertical: verticalPosition, horizontal: horizontalPosition }}
            ContentProps={{
                "aria-describedby": "message-id",
            }}
        >
            <SnackbarContent action={actions} className={classes[variant]} message={content} />
        </Snackbar>
    );
};

CustomSnackbar.defaultProps = {
    open: true,
    variant: "info",
    withSlideTransition: false,
    withCloseButton: false,
    verticalPosition: "bottom",
    horizontalPosition: "left",
    className: "",
};

CustomSnackbar.propTypes = {
    message: PropTypes.string.isRequired,
    open: PropTypes.bool,
    onClose: PropTypes.func.isRequired,
    withSlideTransition: PropTypes.bool,
    withCloseButton: PropTypes.bool,
    variant: PropTypes.oneOf(["error", "info", "success", "warning"]).isRequired,
    verticalPosition: PropTypes.oneOf(["top", "bottom", "center"]),
    horizontalPosition: PropTypes.oneOf(["left", "right", "center"]),
    className: PropTypes.string,
};

export default CustomSnackbar;
