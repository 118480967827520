import { Button, Card, CardActions, CardContent, CardHeader, MobileStepper, Typography } from "@mui/material";
import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useIsMobile } from "../../hooks/mobileHook";
import PropTypes from "prop-types";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import useTranslation from "../common/translation/translation";

const useStyles = makeStyles((theme) => ({
    card: {
        marginTop: "2em",
    },
    cardHeader: {
        paddingBottom: 0,
    },
    cardAction: {
        justifyContent: "left",
        marginLeft: ".5em",
        marginBottom: "2rem",
    },
}));

function DashboardCard({ title, content, linkText, onClick, activeStep, handleBack, handleNext, maxSteps }) {
    const isMobile = useIsMobile();
    const classes = useStyles({ isMobile });
    const { i18n } = useTranslation();

    return (
        <Card className={classes.card}>
            <CardHeader title={title} className={classes.cardHeader} />
            <CardContent>
                <Typography variant="subtitle1">{content}</Typography>
            </CardContent>
            <CardActions className={classes.cardAction}>
                <Button onClick={onClick} color="primary" variant="contained" size="small">
                    {linkText}
                </Button>
            </CardActions>
            {maxSteps !== 1 && (
                <MobileStepper
                    steps={maxSteps}
                    position="static"
                    variant="dots"
                    activeStep={activeStep}
                    nextButton={
                        <Button size="small" onClick={handleNext} disabled={activeStep === maxSteps - 1}>
                            {i18n("general.actions.next")}
                            <KeyboardArrowRight />
                        </Button>
                    }
                    backButton={
                        <Button size="small" onClick={handleBack} disabled={activeStep === 0}>
                            <KeyboardArrowLeft />
                            {i18n("general.actions.back")}
                        </Button>
                    }
                />
            )}
        </Card>
    );
}

DashboardCard.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string.isRequired,
    linkText: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    maxSteps: PropTypes.number.isRequired,
    activeStep: PropTypes.number.isRequired,
    handleNext: PropTypes.func.isRequired,
    handleBack: PropTypes.func.isRequired,
};

export default DashboardCard;
