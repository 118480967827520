import React, { useState } from "react";
import PropTypes from "prop-types";
import { FormControl, Grid, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { KindOfPasswordTypes } from "../../types/PasswordType";
import GroupFilter from "../common/passwordComponents/toolbar/GroupFilter";
import { groupRole } from "../../managers/groupManager";
import { guid } from "../../managers/guidManager";
import { useMaterialUIInput } from "../../hooks/inputHook";
import useTranslation from "../common/translation/translation";

const StepImportData = ({ onStepFinished, interpretedData, onTargetKindOfPasswordChanged, onTargetGroupIdChanged }) => {
    const [targetGroupId, setTargetGroupId] = useState(guid.empty);
    const { value: targetKindOfPassword, onChange: setTargetKindOfPassword } = useMaterialUIInput("");
    const { i18n } = useTranslation();

    const handleInputChanged = () => {
        if (targetKindOfPassword !== KindOfPasswordTypes.groupPasswords || targetGroupId !== guid.empty) {
            onStepFinished(true);
        }
    };

    return (
        <Grid container>
            <Grid item xs={12}>
                <Typography component="h1" style={{ fontSize: "2rem" }}>
                    {i18n("import.finish.title")}
                </Typography>
                <Typography component="p"> {i18n("import.finish.subTitle")}</Typography>
                <br />
            </Grid>

            <Grid item xs={12} md={3}>
                <FormControl fullWidth>
                    <InputLabel id="passwordtype-select-label">{i18n("import.finish.location")}</InputLabel>
                    <Select
                        id="passwordtype-select-label"
                        label="Passworttyp"
                        value={targetKindOfPassword}
                        onChange={(event) => {
                            setTargetKindOfPassword(event);
                            onTargetKindOfPasswordChanged(event.target.value);
                            handleInputChanged();
                        }}
                    >
                        <MenuItem value={KindOfPasswordTypes.companyPasswords}>{i18n("password.information.saveToCompanyAccount")}</MenuItem>
                        <MenuItem value={KindOfPasswordTypes.groupPasswords}>{i18n("password.information.saveToGroupUnknown")} </MenuItem>
                        <MenuItem value={KindOfPasswordTypes.accountPasswords}>{i18n("password.information.saveToPrivateAccount")}</MenuItem>
                    </Select>
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                {targetKindOfPassword === KindOfPasswordTypes.groupPasswords && (
                    <GroupFilter
                        minimumGroupRole={groupRole.editor}
                        showNoDataFoundMessage
                        checked={[targetGroupId]}
                        setChecked={(groupsIds) => {
                            const newTargetGroupId = groupsIds.filter((id) => id !== targetGroupId)[0] || guid.empty;
                            setTargetGroupId(newTargetGroupId);
                            onTargetGroupIdChanged(newTargetGroupId);
                            handleInputChanged();
                        }}
                    />
                )}
            </Grid>
        </Grid>
    );
};

StepImportData.propTypes = {
    onStepFinished: PropTypes.func.isRequired,
    interpretedData: PropTypes.array.isRequired,
    onTargetKindOfPasswordChanged: PropTypes.func.isRequired,
    onTargetGroupIdChanged: PropTypes.func.isRequired,
};

export default StepImportData;
