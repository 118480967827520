import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@mui/styles";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Collapse from "@mui/material/Collapse";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import { ListItemButton, Menu } from "@mui/material";
import GroupFilter from "./toolbar/GroupFilter";
import { KindOfPasswordTypes } from "../../../types/PasswordType";
import UserSelect from "../dataEntry/select/UserSelect";
import { useMyselfQuery, useUsersQuery } from "../../../hooks/queries/userQueryHooks";
import { useAllGroupsQuery } from "../../../hooks/queries/groupQueryHooks";
import useTranslation from "../translation/translation";

const useStyles = makeStyles((theme) => ({
    nested: {
        paddingLeft: theme.spacing(4),
    },
}));

const SavePasswordToContextMenu = ({ anchorEl, onClose, setGroups, groups, setPasswordType, setUser, user }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();

    const [openGroupPassword, setOpenGroupPassword] = useState(false);
    const [openExternalPassword, setExternalPasswords] = useState(false);
    const { users } = useUsersQuery("", [], [], false);
    const { me } = useMyselfQuery();
    const { groups: allGroups } = useAllGroupsQuery();

    const toggleGroupPasswordCollapse = () => {
        setOpenGroupPassword(!openGroupPassword);
    };

    const toggleExternalPasswordCollapse = () => {
        setExternalPasswords(!openExternalPassword);
    };
    return (
        <Menu
            style={{ zIndex: 1500 }}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={onClose}
            anchorReference="anchorPosition"
            anchorPosition={anchorEl}
        >
            <List style={{ minWidth: "300px" }}>
                <ListItemButton
                    onClick={(event) => {
                        setPasswordType(KindOfPasswordTypes.companyPasswords);
                        setUser(event, undefined);
                        onClose();
                    }}
                >
                    <ListItemText primary={i18n("password.savePassword.company")} />
                </ListItemButton>
                {me.admin && users.length > 1 && (
                    <ListItemButton onClick={toggleExternalPasswordCollapse}>
                        <ListItemText primary={i18n("password.savePassword.employee")} />
                        {openExternalPassword ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                )}
                <Collapse in={openExternalPassword} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <UserSelect
                                value={user?.id}
                                onChange={(event, value) => {
                                    setPasswordType(KindOfPasswordTypes.companyPasswords);
                                    setUser(event, value);
                                    onClose();
                                }}
                                disableClearable
                            />
                        </ListItem>
                    </List>
                </Collapse>
                {allGroups.length >= 1 && (
                    <ListItemButton onClick={toggleGroupPasswordCollapse}>
                        <ListItemText primary={i18n("password.savePassword.group")} />
                        {openGroupPassword ? <ExpandLess /> : <ExpandMore />}
                    </ListItemButton>
                )}
                <Collapse in={openGroupPassword} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        <ListItem className={classes.nested}>
                            <GroupFilter
                                setChecked={(e) => {
                                    setPasswordType(KindOfPasswordTypes.groupPasswords);
                                    setGroups(e);
                                    onClose();
                                }}
                                checked={groups}
                            />
                        </ListItem>
                    </List>
                </Collapse>
                <ListItemButton
                    onClick={() => {
                        setPasswordType(KindOfPasswordTypes.accountPasswords);
                        onClose();
                    }}
                >
                    <ListItemText primary={i18n("password.savePassword.private")} />
                </ListItemButton>
            </List>
        </Menu>
    );
};

SavePasswordToContextMenu.propTypes = {
    anchorEl: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    groups: PropTypes.array.isRequired,
    setGroups: PropTypes.func.isRequired,
    setPasswordType: PropTypes.func.isRequired,
    setUser: PropTypes.func.isRequired,
    user: PropTypes.string.isRequired,
};

export default SavePasswordToContextMenu;
