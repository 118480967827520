import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { IconTypes } from "../../../types/IconType";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import IconButton from "../../common/dataDisplay/IconButton";

const useStyles = makeStyles((theme) => ({
    clickable: {
        cursor: "pointer",
    },
    archiveCell: {
        width: 1, // Using something way too small the table will use the minimum width based on the content
    },
}));

const GroupModalPasswordTable = ({ passwords, onPassword, onArchivePassword, canArchive }) => {
    const classes = useStyles();

    const handleArchivePassword = (event, password) => {
        event.stopPropagation();
        onArchivePassword(password);
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    {canArchive && <TableCell align="right">&nbsp;</TableCell>}
                </TableRow>
            </TableHead>
            <TableBody>
                {passwords.map((p, i) => (
                    <TableRow
                        className={classes.clickable}
                        hover
                        key={"passwordInGroup" + i}
                        onClick={(_) => {
                            onPassword(p);
                        }}
                    >
                        <TableCell>{p.name}</TableCell>
                        {canArchive && (
                            <TableCell className={classes.archiveCell}>
                                <IconButton
                                    size="small"
                                    color="secondary"
                                    iconType={IconTypes.fileArchive}
                                    onClick={(e) => {
                                        handleArchivePassword(e, p);
                                    }}
                                />
                            </TableCell>
                        )}
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
};

GroupModalPasswordTable.propTypes = {
    passwords: PropTypes.array.isRequired,
    onPassword: PropTypes.func.isRequired,
    onArchivePassword: PropTypes.func.isRequired,
    canArchive: PropTypes.bool.isRequired,
};

export default GroupModalPasswordTable;
