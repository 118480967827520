import React, { useCallback } from "react";
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";

import { Avatar, Box, Chip, TextField } from "@mui/material";
import { useAccountTagQuery, useSharedTagQuery } from "../../../../hooks/queries/tagQueryHooks";
import { useMyselfQuery } from "../../../../hooks/queries/userQueryHooks";
import { Autocomplete } from "@mui/lab";

import htmlColors from "../../../../htmlColor.json";

const useStyles = makeStyles((theme) => ({
    tagContainer: {
        display: "flex",
        flexWrap: "nowrap",
        alignItems: "center",
        justifyContent: "center",
        marginRight: "1rem",
    },
    tagBackground: {
        width: "10px",
        height: "10px",
        borderRadius: "16px",
        display: "inline-block",
        marginRight: "5px",
    },
    chip: {
        marginRight: "4px",
    },
}));

/**
 * @param {AccountTagType} value
 * @param {(event: *, value: AccountTagType) => void} onChange
 * @param {string} label
 * @param {string} className
 * @param {"small" | "medium"} size
 * @param {boolean} isAccountTags
 * @param {Guid} groupId
 * @param {boolean} disabled
 * @param {boolean} groupByGroups
 * @param {boolean} autofocus
 * @returns {JSX.Element}
 * @constructor
 */
const TagSelect = ({ value, onChange, label, className, size, isAccountTags, groupId, disabled, groupByGroups, autofocus }) => {
    const classes = useStyles();

    const inputElementRef = useCallback((inputElement) => {
        if (autofocus && inputElement !== null) {
            const input = inputElement.querySelector("input");
            if (input) input.focus();
        }
    }, []);

    const { loading: loadingQueryMyself, error: errorQueryMyself, me } = useMyselfQuery();

    const accountTagsQueryResult = useAccountTagQuery();
    const sharedTagsQueryResult = useSharedTagQuery("", groupId);
    const { loading: loadingTags, error: errorTags, accountTags, sharedTags } = isAccountTags ? accountTagsQueryResult : sharedTagsQueryResult;
    const tagOptions = isAccountTags ? accountTags : sharedTags;

    const handleRemoveTag = (tagId) => {
        const filteredOptions = value.filter((tag) => tag.id !== tagId);
        onChange(null, filteredOptions);
    };

    if (errorTags || errorQueryMyself)
        return (
            <TextField
                size={size}
                disabled={true}
                variant="outlined"
                multiline
                fullWidth
                value="Beim laden der Tags ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut."
            />
        );

    const noTagsAvailableMessage =
        "Derzeit sind in dieser Gruppe keine Tags vorhandend. " +
        (me.admin
            ? "Als Systemadministrator können Sie neue Tags auf in der Gruppenverwaltung anlegen."
            : "Um welche hinzuzufügen wenden Sie sich bitte an einen Gruppenadministrator.");

    if (!loadingQueryMyself && !loadingTags && tagOptions.length === 0)
        return <TextField size={size} disabled={true} variant="outlined" multiline fullWidth value={noTagsAvailableMessage} />;

    return (
        <Autocomplete
            className={className}
            size={size}
            disabled={disabled}
            multiple
            openOnFocus
            filterSelectedOptions
            disableCloseOnSelect
            value={value}
            onChange={onChange}
            noOptionsText="Keine Tags verfügbar"
            groupBy={groupByGroups ? (option) => option.group.name : undefined}
            options={tagOptions}
            getOptionLabel={(option) => option.name}
            getOptionSelected={(option, value) => option.id === value.id}
            renderInput={(params) => <TextField {...params} variant="outlined" label={label} ref={inputElementRef} />}
            renderOption={(option, state) => (
                <Box className={classes.tagContainer} key={option.id}>
                    <div>
                        <div style={{ backgroundColor: option.color || htmlColors.Orange }} className={classes.tagBackground} />
                    </div>
                    <Box>{option.name}</Box>
                </Box>
            )}
            renderTags={(options) => (
                <>
                    {options.map((option) => (
                        <Chip
                            className={classes.chip}
                            key={option.id}
                            variant="outlined"
                            size="small"
                            onDelete={() => handleRemoveTag(option.id)}
                            avatar={<Avatar style={{ backgroundColor: option.color || htmlColors.Kohle }}> </Avatar>}
                            label={option.name}
                        />
                    ))}
                </>
            )}
        />
    );
};

TagSelect.defaultProps = {
    disabled: false,
    size: "small",
    groupByGroups: false,
    autofocus: false,
    value: [],
};

TagSelect.propTypes = {
    label: PropTypes.string.isRequired,
    value: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
    className: PropTypes.string,
    size: PropTypes.oneOf(["small", "medium"]),
    disabled: PropTypes.bool,
    isAccountTags: PropTypes.bool.isRequired,
    groupId: PropTypes.string,
    groupByGroups: PropTypes.bool,
    autofocus: PropTypes.bool,
};

export default TagSelect;
