import React from "react";
import PropTypes from "prop-types";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    content: {
        display: "block",
        whiteSpace: "nowrap",
        overflow: "hidden",
        textOverflow: "ellipsis",
    },
}));

const ReorderableList = ({ columns, onChange }) => {
    const classes = useStyles();

    const reorder = (list, startIndex, endIndex) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const getItemStyle = (isDragging, draggableStyle) => ({
        // Some basic styles to make the items look a bit nicer
        userSelect: "none",
        padding: 8,
        margin: `0 0 4px 0`,
        height: 40,
        display: "flex",
        alignItems: "center",

        // Change background colour if dragging
        background: isDragging ? "lightgreen" : "grey",

        // Styles we need to apply on draggables
        ...draggableStyle,
    });

    const getListStyle = (isDraggingOver) => ({
        background: isDraggingOver ? "lightblue" : "lightgrey",
        padding: 4,
        paddingBottom: 0,
        width: 250,
    });

    const onDragEnd = (result) => {
        // Dropped outside the list
        if (!result.destination) {
            return;
        }

        const reorderedItems = reorder(columns, result.source.index, result.destination.index);
        onChange(reorderedItems);
    };

    return (
        <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable">
                {(provided, snapshot) => (
                    <div {...provided.droppableProps} ref={provided.innerRef} style={getListStyle(snapshot.isDraggingOver)}>
                        {columns.map((item, index) => (
                            <Draggable key={`${item.field}${index}`} draggableId={item.field} index={index}>
                                {(provided, snapshot) => (
                                    <div
                                        ref={provided.innerRef}
                                        {...provided.draggableProps}
                                        {...provided.dragHandleProps}
                                        style={getItemStyle(snapshot.isDragging, provided.draggableProps.style)}
                                    >
                                        <label className={classes.content}>{item.headerName}</label>
                                    </div>
                                )}
                            </Draggable>
                        ))}
                        {provided.placeholder}
                    </div>
                )}
            </Droppable>
        </DragDropContext>
    );
};

ReorderableList.propTypes = {
    columns: PropTypes.array.isRequired,
    onChange: PropTypes.func.isRequired,
};

export default ReorderableList;
