import { oneOf } from "prop-types";

export const IconColorTypeList = {
    inherit: "inherit",
    primary: "primary",
    secondary: "secondary",
    action: "action",
    error: "error",
    disabled: "disabled",
};
export const IconColorTypes = Object.freeze(IconColorTypeList);
export const IconColorType = oneOf(Object.values(IconColorTypeList));
