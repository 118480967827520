import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import TreeItem from "@mui/lab/TreeItem";

import GroupTreeItem from "./GroupTreeItem";
import { guid } from "../../../../managers/guidManager";
import { useGroupTreeStyles } from "./useGroupTreeStyles";
import Icon from "../../dataDisplay/Icon";
import { getGroupRoleIconType, groupRole, isGroupRoleHigherOrEqual } from "../../../../managers/groupManager";
import { useMyselfQuery } from "../../../../hooks/queries/userQueryHooks";

const GroupTreeBranch = ({ treeNodes, expanded, onClick, currentGroupId, withoutRoot, requiredRole }) => {
    const classes = useGroupTreeStyles();
    const { me } = useMyselfQuery();

    const getHigherRole = (role1, role2) => (isGroupRoleHigherOrEqual(role1, role2) ? role1 : role2);

    const generateTreeItems = (nodes, parentRole) => {
        if (!nodes || !Array.isArray(nodes) || nodes.length === 0) return <TreeItem nodeId="none" />;

        nodes.forEach((n) => {
            if (n.Children.length > 0) expanded.push(n.Id);
        });

        return (
            <>
                {nodes.map((node) => {
                    const currentRole = getHigherRole(parentRole, node.GroupRole);
                    return (
                        <GroupTreeItem
                            nodeId={node.Id}
                            key={node.Id}
                            bgColor="transparent"
                            color="rgba(0, 0, 0, 0.54)"
                            labelText={
                                <span className={classNames(classes.drawer, { [classes.groupItem]: currentGroupId === node.Id })}>{node.Name}</span>
                            }
                            onClick={() => onClick(node)}
                            icon={
                                currentRole ? (
                                    <Icon iconType={getGroupRoleIconType(currentRole, me?.admin)} className={classes.smallGroupIcon} />
                                ) : undefined
                            }
                            disabled={Boolean(requiredRole) ? !isGroupRoleHigherOrEqual(currentRole, requiredRole) : false}
                        >
                            {node.Children && node.Children.length > 0 ? generateTreeItems(node.Children, currentRole) : <div />}
                        </GroupTreeItem>
                    );
                })}
            </>
        );
    };

    if (withoutRoot) return generateTreeItems(treeNodes, me?.admin ? groupRole.admin : groupRole.viewer);

    const rootNode = {
        Id: guid.empty,
        Name: "Gruppenverwaltung",
        Children: treeNodes,
    };
    return generateTreeItems([rootNode], me?.admin ? groupRole.admin : groupRole.viewer);
};

GroupTreeBranch.propTypes = {
    treeNodes: PropTypes.array.isRequired,
    expanded: PropTypes.array.isRequired,
    onClick: PropTypes.func.isRequired,
    currentGroupId: PropTypes.string,
    withoutRoot: PropTypes.bool,
    requiredRole: PropTypes.string,
};

export default GroupTreeBranch;
