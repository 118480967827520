import React from "react";
import { IconTypes } from "../types/IconType";
import PropTypes from "prop-types";
import { guid } from "./guidManager";
import { translate } from "../components/common/translation/translation";

const groupRole = {
    admin: "GROUP_ADMIN",
    editor: "GROUP_EDITOR",
    viewer: "GROUP_VIEWER",
};

const groupRoleArray = [groupRole.admin, groupRole.editor, groupRole.viewer];
export const groupRoleType = PropTypes.oneOf(groupRoleArray);

const getGroupRoleText = (groupRoleStr) => {
    if (!groupRoleStr) return "";
    switch (groupRoleStr.toUpperCase()) {
        case groupRole.admin:
            return translate("general.groupRole.admin");
        case groupRole.editor:
            return translate("general.groupRole.editor");
        case groupRole.viewer:
            return translate("general.groupRole.viewer");
        default:
            return "";
    }
};
const getGroupRoleIconType = (groupRoleStr, isAdmin) => {
    if (!groupRoleStr) return "";
    if (isAdmin) return IconTypes.userShield;

    switch (groupRoleStr.toUpperCase()) {
        case groupRole.admin:
            return IconTypes.userShield;
        case groupRole.editor:
            return IconTypes.userEdit;
        case groupRole.viewer:
            return IconTypes.bookOpen;
        default:
            return "";
    }
};

export const isGroupRoleHigherOrEqual = (roleToCheck, requiredRole) => {
    if (!roleToCheck || !requiredRole) return false;

    switch (roleToCheck) {
        case groupRole.admin:
            return true;
        case groupRole.editor:
            return requiredRole === groupRole.editor || requiredRole === groupRole.viewer;
        case groupRole.viewer:
            return requiredRole === groupRole.viewer;
        default:
            return false;
    }
};

export { getGroupRoleText, getGroupRoleIconType, groupRole, groupRoleArray };

export const getGroupsFromParentGroups = (groupTreeNodes, parentGroupIds) => {
    const rootNode = {
        Id: guid.empty,
        Children: groupTreeNodes,
    };

    const findTargetParentGroupRecursive = (node, parentGroupId) => {
        if (node.Id === parentGroupId) return node;
        for (let child of node.Children) {
            const res = findTargetParentGroupRecursive(child, parentGroupId);
            if (res !== null) return res;
        }
        return null;
    };
    const getIdsOfSubGroupsRecursive = (node, set) => {
        set.add(node.Id);
        for (let child of node.Children) {
            getIdsOfSubGroupsRecursive(child, set);
        }
    };

    let groupIdsSet = new Set();
    for (let parentGroupId of parentGroupIds) {
        const targetParentGroupNode = findTargetParentGroupRecursive(rootNode, parentGroupId);
        if (targetParentGroupNode == null) throw new Error(`passed group not found in groupTree: ${parentGroupId}`);
        else getIdsOfSubGroupsRecursive(targetParentGroupNode, groupIdsSet);
    }
    return Array.from(groupIdsSet);
};
