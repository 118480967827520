import React, { useState } from "react";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import Icon from "../dataDisplay/Icon";
import Divider from "@mui/material/Divider";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { IconTypes } from "../../../types/IconType";
import { KindOfPasswordType, KindOfPasswordTypes, PasswordType } from "../../../types/PasswordType";
import { useRetrieveDecryptedPasswordString } from "../../../hooks/cryptoHook";
import { useModalStatus } from "../../../hooks/modalHook";
import CustomSnackbar from "../feedback/CustomSnackbar";
import { CryptoManager } from "../../../managers/cryptoManager";
import { LoginContext } from "../../../contexts/LoginContext";
import { useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import { OfflineStatusContext } from "../../../contexts/OfflineStatusContext";
import useTranslation from "../translation/translation";

const useStyles = makeStyles((theme) => ({
    divider: {
        marginTop: "5px",
        marginBottom: "5px",
    },
    icon: {
        minWidth: "40px",
    },
}));

export default function PasswordTableRowContextMenu({
    passwordType,
    password,
    anchorEl,
    onClose,
    onPasswordModal,
    onConfirmDeleteModal,
    onAccessModal,
    onMovePassword,
    onHistoryModal,
    isViewer,
    removeOperationText,
    onNatoAlphabetPassword,
}) {
    const classes = useStyles();
    const loginContext = LoginContext.useContainer();
    const offlineStatusContext = OfflineStatusContext.useContainer();

    const retrieveDecryptedPasswordString = useRetrieveDecryptedPasswordString();
    const { me } = useMyselfQuery();
    const { i18n } = useTranslation();

    const { modalState: successCopySnackbarOpen, open: openCopySuccessSnackbar, close: closeCopySuccessSnackbar } = useModalStatus();
    const { modalState: errorCopySnackbarOpen, open: openCopyErrorSnackbar, close: closeCopyErrorSnackbar } = useModalStatus();
    const [snackbarText, setSnackbarText] = useState("");

    const onRetrieveDecryptedPasswordAndCopy = async () => {
        try {
            let decryptedPasswordString = "";
            if (passwordType === KindOfPasswordTypes.offlinePasswords) {
                decryptedPasswordString = await CryptoManager.decryptEncryptedPassword(
                    me.keyPair.encryptedPrivateKey,
                    loginContext.userPasswordHash,
                    password.encryptedPassword
                );
            } else {
                decryptedPasswordString = await retrieveDecryptedPasswordString(passwordType, password.id);
            }
            await onCopyText(decryptedPasswordString);
        } catch (e) {
            setSnackbarText(i18n("password.actions.copyPasswordError"));
            openCopyErrorSnackbar();
        }
    };

    const onCopyText = async (text) => {
        try {
            await navigator.clipboard.writeText(text);
            openCopySuccessSnackbar();
        } catch (e) {
            console.error(e);
            setSnackbarText(i18n("password.actions.copyError"));
            openCopyErrorSnackbar();
        }
        onClose();
    };

    const isOnline = !offlineStatusContext.isOffline || passwordType === KindOfPasswordTypes.offlinePasswords;

    return (
        <>
            <Menu keepMounted open={Boolean(anchorEl)} onClose={onClose} anchorReference="anchorPosition" anchorPosition={anchorEl}>
                <MenuItem
                    onClick={() => {
                        onClose();
                        onPasswordModal();
                    }}
                >
                    <ListItemIcon>
                        <Icon className={classes.icon} iconType={IconTypes.editPasswordPen} />
                    </ListItemIcon>
                    <Typography variant="inherit">
                        {isViewer || offlineStatusContext.isOffline ? i18n("password.actions.show") : i18n("password.actions.edit")}
                    </Typography>
                </MenuItem>

                {password?.login && (
                    <MenuItem
                        onClick={() => {
                            setSnackbarText(i18n("password.actions.copyUsernameConfirmation"));
                            onCopyText(password.login);
                        }}
                    >
                        <ListItemIcon>
                            <Icon className={classes.icon} iconType={IconTypes.user} />
                        </ListItemIcon>
                        <Typography className={classes.contextMenuDescription} variant="inherit">
                            {i18n("password.actions.copyUsername")}
                        </Typography>
                    </MenuItem>
                )}

                {isOnline && onNatoAlphabetPassword ? (
                    <MenuItem
                        onClick={() => {
                            onClose();
                            onNatoAlphabetPassword();
                        }}
                    >
                        <ListItemIcon>
                            <Icon className={classes.icon} iconType={IconTypes.copyPassword} />
                        </ListItemIcon>
                        <Typography className={classes.contextMenuDescription} variant="inherit">
                            {i18n("password.actions.showPassword")}
                        </Typography>
                    </MenuItem>
                ) : undefined}

                {isOnline && (
                    <MenuItem
                        onClick={() => {
                            setSnackbarText(i18n("password.actions.copyPasswordConfirmation"));
                            onRetrieveDecryptedPasswordAndCopy();
                        }}
                    >
                        <ListItemIcon>
                            <Icon className={classes.icon} iconType={IconTypes.lock} />
                        </ListItemIcon>
                        <Typography className={classes.contextMenuDescription} variant="inherit">
                            {i18n("password.actions.copyPassword")}
                        </Typography>
                    </MenuItem>
                )}

                {!isViewer && onMovePassword && isOnline && (
                    <MenuItem
                        onClick={() => {
                            onClose();
                            onMovePassword();
                        }}
                    >
                        <ListItemIcon>
                            <Icon className={classes.icon} iconType={IconTypes.retweet} />
                        </ListItemIcon>
                        <Typography className={classes.contextMenuDescription} variant="inherit">
                            {i18n("password.actions.movePassword")}
                        </Typography>
                    </MenuItem>
                )}

                {onAccessModal && isOnline && (
                    <MenuItem
                        onClick={() => {
                            onClose();
                            onAccessModal();
                        }}
                    >
                        <ListItemIcon>
                            <Icon className={classes.icon} iconType={IconTypes.visibleEye} />
                        </ListItemIcon>
                        <Typography className={classes.contextMenuDescription} variant="inherit">
                            {i18n("password.actions.accesses")}
                        </Typography>
                    </MenuItem>
                )}

                {!isViewer && onHistoryModal && isOnline && (
                    <MenuItem
                        onClick={() => {
                            onClose();
                            onHistoryModal();
                        }}
                    >
                        <ListItemIcon>
                            <Icon className={classes.icon} iconType={IconTypes.history} />
                        </ListItemIcon>
                        <Typography className={classes.contextMenuDescription} variant="inherit">
                            {i18n("password.actions.changeHistory")}
                        </Typography>
                    </MenuItem>
                )}

                {!isViewer && isOnline && <Divider className={classes.divider} />}

                {!isViewer && isOnline && (
                    <MenuItem
                        onClick={() => {
                            onClose();
                            onConfirmDeleteModal();
                        }}
                    >
                        <ListItemIcon>
                            <Icon className={classes.icon} iconType={IconTypes.deletePassword} />
                        </ListItemIcon>
                        <Typography className={classes.contextMenuDescription} variant="inherit">
                            {removeOperationText || i18n("general.actions.archive")}
                        </Typography>
                    </MenuItem>
                )}
            </Menu>
            {successCopySnackbarOpen && (
                <CustomSnackbar
                    open
                    message={snackbarText}
                    onClose={closeCopySuccessSnackbar}
                    verticalPosition="top"
                    horizontalPosition="center"
                    variant="info"
                    withSlideTransition
                />
            )}
            {errorCopySnackbarOpen && (
                <CustomSnackbar
                    open
                    message={snackbarText}
                    onClose={closeCopyErrorSnackbar}
                    verticalPosition="top"
                    horizontalPosition="center"
                    variant="error"
                    withSlideTransition
                />
            )}
        </>
    );
}

PasswordTableRowContextMenu.defaultProps = {
    removeOperationText: undefined,
    password: {},
};

PasswordTableRowContextMenu.propTypes = {
    passwordType: KindOfPasswordType.isRequired,
    password: PasswordType,
    anchorEl: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onPasswordModal: PropTypes.func.isRequired,
    onConfirmDeleteModal: PropTypes.func.isRequired,
    onAccessModal: PropTypes.func,
    onMovePassword: PropTypes.func,
    onHistoryModal: PropTypes.func,
    onNatoAlphabetPassword: PropTypes.func,
    isViewer: PropTypes.bool,
    removeOperationText: PropTypes.string,
};
