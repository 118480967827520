import { Button, FormControlLabel, FormGroup, Grid, TextField } from "@mui/material";
import Checkbox from "@mui/material/Checkbox";
import { useEffect, useState } from "react";
import CopyToClipboardWithSnackbar from "../common/dataDisplay/CopyToClipboardWithSnackbar";
import IconButton from "../common/dataDisplay/IconButton";
import { IconTypesList } from "../../types/IconType";
import { IconColorTypeList } from "../../types/IconColorType";
import useTranslation from "../common/translation/translation";

function generatePasswordString(length, withNumbers, withSymbols, excludeSimilarCharacters) {
    let result = "";
    let characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";
    const numbers = "0123456789";
    const symbols = "!§$%&/()=?][{}+*#-";
    const similarCharacters = "IiLl0Oo1";

    if (withNumbers) {
        characters += numbers;
    }

    if (withSymbols) {
        characters += symbols;
    }

    if (excludeSimilarCharacters) {
        for (let i = 0; i < similarCharacters.length; i++) {
            characters = characters.split(similarCharacters[i]).join("");
        }
    }

    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
}

export function GeneratePassword({ onChange }) {
    const [value, setValue] = useState(generatePasswordString(20, true, true, false));
    const [count, setCount] = useState(20);
    const [includeNumber, setIncludeNumber] = useState(true);
    const [includeSymbols, setIncludeSymbols] = useState(true);
    const [excludeSimilar, setExcludeSimilar] = useState(false);
    const { i18n } = useTranslation();

    const handleGeneratePassword = () => {
        const newPasswort = generatePasswordString(count, includeNumber, includeSymbols, excludeSimilar);
        setValue(newPasswort);
        onChange?.(newPasswort);
    };

    useEffect(() => {
        handleGeneratePassword();
    }, [includeSymbols, includeNumber, excludeSimilar, count]);

    return (
        <Grid container spacing={1} style={{ fontSize: "1.2em" }}>
            <Grid item xs={11}>
                <TextField
                    value={value}
                    onChange={(e) => setValue(e.target.value)}
                    fullWidth
                    label={i18n("dashboard.support.generate.newPassword")}
                    variant="outlined"
                />
            </Grid>

            <Grid item xs={1}>
                <CopyToClipboardWithSnackbar
                    snackbarTextSuccessfulOnCopy={i18n("dashboard.support.generate.newPasswordCopied") + value}
                    snackbarTextFailedOnCopy={i18n("password.actions.copyError")}
                    toCopy={value}
                    disabled={!Boolean(value)}
                >
                    <IconButton
                        style={{ marginTop: "0.4em" }}
                        iconType={IconTypesList.copy}
                        colorType={IconColorTypeList.secondary}
                        disabled={!Boolean(value)}
                    />
                </CopyToClipboardWithSnackbar>
            </Grid>
            <Grid item xs={12}>
                <Button onClick={handleGeneratePassword} variant="contained" fullWidth>
                    {i18n("dashboard.support.generate.action")}
                </Button>
            </Grid>
            <Grid item xs={3}>
                <TextField value={count} onChange={(e) => setCount(e.target.value)} size="small" type="number" variant="outlined" />
            </Grid>
            <Grid item xs={9} style={{ marginTop: "0.6em" }}>
                {i18n("dashboard.support.generate.charCount")}
            </Grid>
            <Grid item xs={12}>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={includeNumber} onChange={(e) => setIncludeNumber(e.target.checked)} />}
                        label={i18n("dashboard.support.generate.numbers")}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={includeSymbols} onChange={(e) => setIncludeSymbols(e.target.checked)} />}
                        label={i18n("dashboard.support.generate.special")}
                    />
                    <FormControlLabel
                        control={<Checkbox checked={excludeSimilar} onChange={(e) => setExcludeSimilar(e.target.checked)} />}
                        label={i18n("dashboard.support.generate.similar")}
                    />
                </FormGroup>
            </Grid>
        </Grid>
    );
}
