import React from "react";
import { Box, CircularProgress } from "@mui/material";
import VaulteronLogoIcon from "../common/dataDisplay/VaulteronLogoIcon";

const SplashScreen = () => {
    return (
        <Box
            style={{
                height: "100vh",
                width: "100vw",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
            }}
        >
            <VaulteronLogoIcon width="50%" />
            <Box
                style={{
                    width: "25%",
                    marginTop: "5%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <CircularProgress />
            </Box>
        </Box>
    );
};

export default SplashScreen;
