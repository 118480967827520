import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import CustomModal from "../common/feedback/modals/CustomModal";
import QRCode from "qrcode.react";
import { useEnableTotpTwoFA } from "../../hooks/queries/userQueryHooks";
import { Grid, Typography } from "@mui/material";
import ReactCodeInput from "react-verification-code-input";
import useTranslation from "../common/translation/translation";

const useStyles = makeStyles((theme) => ({
    codeInput: {
        margin: "0 auto",
    },
    errorMessage: {
        display: "block",
        color: "red",
        textAlign: "center",
        fontSize: "1.5rem",
        marginTop: "2rem",
    },
}));

const TwoFactorTotpModal = ({ onClose, onCodeEntered, errorMessage, hideCancel }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const { getTotpAuthenticatorKey } = useEnableTotpTwoFA();
    const [AuthenticatorString, setAuthenticatorString] = useState("");
    const [codeInput, setCodeInput] = useState();

    useEffect(() => {
        getTotpAuthenticatorKey().then((e) => {
            setAuthenticatorString(e.data.user.twofactor.getTotpAuthenticatorKey.authenticatorString);
        });
    }, []);

    useEffect(() => {
        // TOPT length is always 6
        if (codeInput && codeInput.length && codeInput.length === 6) {
            onCodeEntered(codeInput);
            onClose();
        }
    }, [codeInput]);

    return (
        <CustomModal
            maxWidth="sm"
            onClose={onClose}
            onOk={() => {}}
            okButtonEnabled={false}
            saveText={i18n("settings.twoFactor.totp.confirmAction")}
            title={i18n("settings.twoFactor.totp.activateTitle")}
            withCancelButton={Boolean(!hideCancel)}
            cancelText={i18n("general.actions.cancel")}
            error={false}
            loading={false}
        >
            <Grid container spacing={1}>
                <Grid item xs={12} style={{ textAlign: "center" }}>
                    <QRCode value={AuthenticatorString} />
                </Grid>
                <Grid item xs={12}>
                    <Typography align="center">{i18n("settings.twoFactor.totp.description")}</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography align="center">
                        <ReactCodeInput required className={classes.codeInput} onChange={setCodeInput} />
                    </Typography>
                </Grid>
                <Grid item xs={12}>
                    <span className={classes.errorMessage}>{errorMessage}</span>
                </Grid>
            </Grid>
        </CustomModal>
    );
};

TwoFactorTotpModal.defaultProps = {
    errorMessage: "",
};

TwoFactorTotpModal.propTypes = {
    onClose: PropTypes.func,
    onCodeEntered: PropTypes.func,
    errorMessage: PropTypes.string,
};

export default TwoFactorTotpModal;
