import { useGetCustomerBillingPortalQuery, useGetCustomerCheckoutUrlQuery } from "../../graphql/generated";

export const useCustomerBillingPortalUrlQuery = () => {
    let { loading, error, data } = useGetCustomerBillingPortalQuery({
        fetchPolicy: "no-cache",
    });
    const url = data ? data.customerBillingPortalUrl : "";
    return { url, loading, error };
};

export const useCustomerCheckoutUrlQuery = () => {
    let { loading, error, data } = useGetCustomerCheckoutUrlQuery({
        fetchPolicy: "no-cache",
    });
    const url = data ? data.customerCheckoutUrl : "";
    return { url, loading, error };
};