import React from "react";
import { menu } from "../../managers/pathManager";
import ErrorInformation from "./ErrorInformation";
import { LoginContext } from "../../contexts/LoginContext";
import VerticalContentPageContainer from "../common/layout/VerticalContentPageContainer";

const PageNotFound = () => {
    const loginContext = LoginContext.useContainer();

    const heading = "Seite wurde nicht gefunden";
    const description = "Die Seite, die Sie suchen, existiert nicht. Es kann sein, dass die Seite verschoben oder gelöscht wurde";
    const isLoggedIn = loginContext.loggedIn;

    return (
        <VerticalContentPageContainer hideDrawer>
            <ErrorInformation
                heading={heading}
                description={description}
                actionButtonText={isLoggedIn ? "Zurück zum Dashboard" : "Zurück zum Login"}
                actionButtonHref={isLoggedIn ? menu.root.path : menu.login.path}
            />
        </VerticalContentPageContainer>
    );
};

export default PageNotFound;
