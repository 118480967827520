import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Grid } from "@mui/material";
import CustomModal from "../../common/feedback/modals/CustomModal";
import ConfirmationDialog from "../../common/feedback/modals/ConfirmationDialog";
import { ConfirmDialogVariantList } from "../../../types/ConfirmDialogVariant";
import { useModalStatus } from "../../../hooks/modalHook";
import { useExecuteAllUserWithAccessToGroupsQuery, useGroupMoveMutation } from "../../../hooks/queries/groupQueryHooks";
import GroupTreeByRole from "../../common/dataEntry/tree/GroupTreeByRole";
import { groupRole } from "../../../managers/groupManager";
import { useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import { extractApolloErrorMessage } from "../../../managers/errorManager";
import { useRetrieveDecryptedPasswordsInGroupsWithSubGroups } from "../../../hooks/cryptoHook";
import { reEncryptPasswordsForUsers } from "../../../managers/passwordManager";
import { guid } from "../../../managers/guidManager";
import useTranslation from "../../common/translation/translation";

const MoveGroupModal = ({ allGroups, onClose, group, treeNodes }) => {
    const [targetGroupId, setTargetGroupId] = useState(group.id);
    const [errorMessage, setErrorMessage] = useState("");
    const { i18n } = useTranslation();

    const { moveGroup } = useGroupMoveMutation();
    const { me } = useMyselfQuery();
    const { executeAllUserWithAccessToGroupsQuery } = useExecuteAllUserWithAccessToGroupsQuery();
    const retrieveDecryptedPasswordsInGroupWithSubGroups = useRetrieveDecryptedPasswordsInGroupsWithSubGroups();

    const { open: openMoveGroupConfirmDialog, close: closeMoveGroupConfirmDialog, modalState: moveGroupConfirmDialogState } = useModalStatus();
    const destinationGroup = useMemo(
        () => allGroups.find((t) => t.id === targetGroupId) || { name: i18n("groups.groupManagement") },
        [targetGroupId]
    );
    const canMoveGroup = targetGroupId !== group.id;

    const title = canMoveGroup
        ? i18n("groups.move.title", { key: "currentGroup", value: group.name }, { key: "newGroup", value: destinationGroup.name })
        : i18n("groups.move.titleEmpty", { key: "currentGroup", value: group.name });

    const getParentGroupTreeNodeByChildGroupId = async (childGroupId) => {
        const findTargetParentGroupRecursive = (parent, children) => {
            for (let child of children) {
                if (child.Id === childGroupId) return parent;

                const res = findTargetParentGroupRecursive(child, child.Children);
                if (res !== null) return res;
            }
            return null;
        };
        const rootNode = {
            Id: guid.empty,
            Children: treeNodes,
        };
        return findTargetParentGroupRecursive(rootNode, treeNodes);
    };

    const handleMoveGroup = async () => {
        try {
            if (targetGroupId === guid.empty) {
                await moveGroup({
                    variables: {
                        groupToMoveId: group.id,
                        newParentGroupId: guid.empty,
                        encryptedSharedPasswords: [],
                    },
                });
            } else {
                const targetGroups = await executeAllUserWithAccessToGroupsQuery([targetGroupId]);
                const usersWithAccessToTargetGroup = targetGroups[0].usersWithAccess;

                // The passwords are already encrypted for all admins and users in the parent group --> filter those
                // Filter all users (including admins) that already have access, which are all users with access to the parent group
                const currentParentGroupTreeNode = await getParentGroupTreeNodeByChildGroupId(group.id);
                let userWithAccessToParentGroup;
                if (currentParentGroupTreeNode.Id === guid.empty) {
                    // If the parent group is the root group we know that it only contains admins, which we have already loaded
                    userWithAccessToParentGroup = usersWithAccessToTargetGroup.filter((u) => u.admin);
                } else {
                    const parentGroups = await executeAllUserWithAccessToGroupsQuery([currentParentGroupTreeNode.Id]);
                    userWithAccessToParentGroup = parentGroups[0].usersWithAccess;
                }

                const userWithAccessToParentGroupIds = userWithAccessToParentGroup.map((u) => u.id);
                const targetUsers = usersWithAccessToTargetGroup.filter((u) => !userWithAccessToParentGroupIds.includes(u.id));

                const decryptedAccessiblePasswordsFromCurrentGroup = await retrieveDecryptedPasswordsInGroupWithSubGroups([group.id], null);

                const encryptionsForUsersWithAccessToTargetGroup = await reEncryptPasswordsForUsers(
                    decryptedAccessiblePasswordsFromCurrentGroup,
                    targetUsers
                );

                await moveGroup({
                    variables: {
                        groupToMoveId: group.id,
                        newParentGroupId: targetGroupId,
                        encryptedSharedPasswords: encryptionsForUsersWithAccessToTargetGroup,
                    },
                });
            }

            closeMoveGroupConfirmDialog();
            onClose();
        } catch (e) {
            console.error(e);
            closeMoveGroupConfirmDialog();
            setErrorMessage(extractApolloErrorMessage(e, i18n("groups.move.error")));
        }
    };

    return (
        <>
            <CustomModal
                title={title}
                loading={false}
                error={Boolean(errorMessage)}
                errorMessage={errorMessage}
                maxWidth="sm"
                onClose={onClose}
                saveText={i18n("general.actions.move")}
                onOk={openMoveGroupConfirmDialog}
                okButtonEnabled={canMoveGroup}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <GroupTreeByRole
                            withoutRoot={!me.admin}
                            role={groupRole.admin}
                            currentGroupId={targetGroupId}
                            onClick={(node) => setTargetGroupId(node.Id)}
                        />
                    </Grid>
                </Grid>
            </CustomModal>

            <ConfirmationDialog
                onOk={handleMoveGroup}
                onClose={() => {
                    closeMoveGroupConfirmDialog();
                    onClose();
                }}
                open={moveGroupConfirmDialogState}
                cancelText={i18n("general.actions.cancel")}
                okText={i18n("groups.move.moveGroup")}
                title={i18n(
                    "groups.move.title",
                    { key: "currentGroup", value: group.name },
                    {
                        key: "newGroup",
                        value: destinationGroup.name,
                    }
                )}
                description={i18n("groups.move.warning", { key: "group", value: destinationGroup.name })}
                variant={ConfirmDialogVariantList.warning}
            />
        </>
    );
};

MoveGroupModal.propTypes = {
    allGroups: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
    group: PropTypes.object,
    parentGroupId: PropTypes.string,
    treeNodes: PropTypes.array.isRequired,
};

export default MoveGroupModal;
