import { PaymentElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { Button } from "@mui/material";
import { apiBaseUrl } from "../../managers/apiManager";
import useTranslation from "../common/translation/translation";
import { useState } from "react";

export default function StripeContainer({ clientSecret }: { clientSecret: string }) {
    const stripe = useStripe();
    const elements = useElements();
    const { i18n } = useTranslation();
    const [loading, setLoading] = useState(false);

    const addPaymentMethod = async () => {
        if (!elements || !stripe) {
            return;
        }

        const { error: submitError } = await elements.submit();
        if (submitError) {
            console.log(submitError);
            return;
        }

        setLoading(true);

        await stripe.confirmSetup({
            elements,
            clientSecret,
            confirmParams: {
                return_url: apiBaseUrl,
            },
            redirect: "always",
        });
    };

    return (
        <>
            <PaymentElement />
            <Button style={{ marginTop: "2em" }} variant="contained" onClick={addPaymentMethod} disabled={!stripe || loading}>
                {i18n("billing.changePlan")}
            </Button>
        </>
    );
}
