// Inspired by https://github.com/jamiebuilds/unstated-next/blob/master/src/unstated-next.tsx
import React from "react";

export const createContainer = (useHook) => {
    let Context = React.createContext();
    return {
        Provider: (props) => <Context.Provider value={useHook(props.initialState)}>{props.children}</Context.Provider>,
        useContainer: () => React.useContext(Context),
    };
};
