import React from "react";
import PropTypes from "prop-types";
import { Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { DropzoneArea } from "mui-file-dropzone";
import ExportKeePassImage from "../../images/importer/exportKeePass.png";
import ExportLastPassImage from "../../images/importer/exportLastPass.png";
import ExportChromeStep1Image from "../../images/importer/chrome_step1.png";
import ExportChromeStep2Image from "../../images/importer/chrome_step2.png";
import ExportChromeStep3Image from "../../images/importer/chrome_step3.png";
import { ImportTypes, ImportTypesType } from "./helper/ImportTypes";
import useTranslation from "../common/translation/translation";

const useStyles = makeStyles((theme) => ({
    container: {
        flexDirection: "column",
        "& .MuiDropzonePreviewList-root": {
            width: "100%",
            margin: 0,
            padding: "2rem",

            "& .MuiDropzonePreviewList-imageContainer": {
                padding: 0,
                maxWidth: "auto",
                flexBasis: "unset",
            },
        },
    },
    instructionsContainer: {
        "& h2": {
            fontSize: "2rem",
        },
        "& p": {
            marginBottom: "2rem",
        },
        "& img": {
            width: "80%",
            maxWidth: "50rem",
            display: "block",
            margin: "1rem auto",
        },
    },
}));

const StepUploadFile = ({ onStepFinished, importType, onSetUploadedFile }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();

    const instructionsForCSV = (
        <>
            <Typography component="h2">{i18n("import.csv.instruction1")}</Typography>
            <Typography component="p">{i18n("import.csv.instruction2")} </Typography>
        </>
    );

    const instructionsForLastPass = (
        <>
            <Typography component="h2">{i18n("import.lastPass.instruction1")}</Typography>
            <Typography component="p">{i18n("import.lastPass.instruction2")}</Typography>
            <Typography component="p">{i18n("import.lastPass.instruction3")}</Typography>
            <img src={ExportLastPassImage} alt="Export image from LastPass" />
        </>
    );

    const instructionsForKeePass = (
        <>
            <Typography component="h2">{i18n("import.keepass.instruction1")}</Typography>
            <Typography component="p">{i18n("import.keepass.instruction2")}</Typography>
            <Typography component="p">{i18n("import.keepass.instruction3")}</Typography>
            <img src={ExportKeePassImage} alt="Exportvorgang von KeePass" />
        </>
    );

    const instructionsForChrome = (
        <>
            <Typography component="h2">{i18n("import.chrome.instruction1")}</Typography>
            <Typography component="p">{i18n("import.chrome.instruction2")}</Typography>
            <Typography component="p">{i18n("import.chrome.instruction3")}</Typography>
            <img src={ExportChromeStep1Image} alt="Export chrome step 1" style={{ maxWidth: "300px" }} />
            <img src={ExportChromeStep2Image} alt="Export chrome step 2" />
            <img src={ExportChromeStep3Image} alt="Export chrome step 3" />
        </>
    );

    let instructionsToShow;
    switch (importType) {
        case ImportTypes.csv:
            instructionsToShow = instructionsForCSV;
            break;
        case ImportTypes.lastpass:
            instructionsToShow = instructionsForLastPass;
            break;
        case ImportTypes.keepassCSV:
            instructionsToShow = instructionsForKeePass;
            break;
        case ImportTypes.chrome:
            instructionsToShow = instructionsForChrome;
            break;
    }

    return (
        <Grid container className={classes.container}>
            <Grid item className={classes.instructionsContainer}>
                {instructionsToShow}
            </Grid>
            <Grid item>
                <DropzoneArea
                    dropzoneText={i18n("import.dropzoneText")}
                    previewText="Vorschau:"
                    getFileAddedMessage={(fileName) => i18n("import.fileAddedMessage", { key: "name", value: fileName })}
                    getFileRemovedMessage={(fileName) => i18n("import.fileRemovedMessage", { key: "name", value: fileName })}
                    getDropRejectMessage={(fileName) => i18n("import.fileRejectMessage", { key: "name", value: fileName })}
                    filesLimit={1}
                    acceptedFiles={[
                        ".csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values",
                    ]}
                    showFileNamesInPreview
                    onChange={(files) => {
                        if (files.length > 0) {
                            onSetUploadedFile(files[0]);
                            onStepFinished(true);
                        }
                    }}
                    onDelete={() => {
                        onStepFinished(false);
                    }}
                />
            </Grid>
        </Grid>
    );
};

StepUploadFile.propTypes = {
    onStepFinished: PropTypes.func.isRequired,
    importType: ImportTypesType.isRequired,
    onSetUploadedFile: PropTypes.func.isRequired,
};

export default StepUploadFile;
