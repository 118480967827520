/*global chrome*/

/**
 * @typedef {Object} ErrorModel
 * @property {int} httpCode
 * @property {string} message
 */

/**
 * Enum for the state values
 * @readonly
 * @enum {string}
 */
export const CommunicationModelStates = Object.freeze({
    // From contentScript to background
    error: "error",
    requestPasswords: "requestPasswords",
    requestPasswordString: "requestPasswordString",
    saveUsernameAndPassword: "saveUsernameAndPassword",
    saveEnteredPassword: "saveEnteredPassword",
    getEnteredPassword: "getEnteredPassword",
    clearStoredUsernameAndPassword: "clearStoredUsernameAndPassword",

    // From popup to background
    clearWebWorkerStorage: "clearWebWorkerStorage",

    // From background/popup to contentScript
    response_emptySuccess: "response_emptySuccess",
    response_fetchedPasswords: "fetchedPasswords",
    response_fetchedPasswordString: "fetchedPasswordString",
    response_storedPassword: "storedPassword",
    fillPassword: "fillPassword",

    // From Vaulteron to background
    vaulteronLogin: "vaulteronLogin",
    getExtensionInfo: "getExtensionInfo",
});

/**
 * @typedef {Object} CommunicationModel
 * @property {CommunicationModelStates} state
 * @property {ErrorModel | null} error
 * @property {Object | string | null} payload
 */

/**
 * @typedef {Object} ExtensionInfos
 * @property {boolean} isUserLoggedIn
 * @property {string} userEmail
 * @property {string} userId
 * @property {string} extensionId
 */
