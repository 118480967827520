import React, { useState } from "react";
import { Checkbox, Grid, MenuItem, Select, Table, TableBody, TableCell, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import { useFilteredUsersQuery, useLazyUserQueries } from "../../../hooks/queries/userQueryHooks";
import { getGroupRoleText, groupRole } from "../../../managers/groupManager";
import CustomModal from "../../common/feedback/modals/CustomModal";
import { encryptPasswordsForUser } from "../../../managers/passwordManager";
import { useRetrieveDecryptedPasswordsInGroupsWithSubGroups } from "../../../hooks/cryptoHook";
import GroupModalTextField from "./GroupModalTextField";
import { useDelayedMaterialUIInput } from "../../../hooks/inputHook";
import { ClientFilter } from "../../../managers/filterManager";
import { useAddUserToGroupMutation } from "../../../hooks/queries/groupQueryHooks";
import { extractApolloErrorMessage } from "../../../managers/errorManager";
import useTranslation from "../../common/translation/translation";

const AddUserToGroupModal = ({ onClose, group, usersInGroup, parentGroupId }) => {
    const { value: searchTerm, localValue: localSearchTerm, onChange: setSearchTerm } = useDelayedMaterialUIInput("");
    const retrieveDecryptedPasswordsInGroupWithSubGroups = useRetrieveDecryptedPasswordsInGroupsWithSubGroups();
    const { addUserToGroup } = useAddUserToGroupMutation(parentGroupId, group.id);
    const { i18n } = useTranslation();

    const [errorMessage, setErrorMessage] = useState("");
    const [usersToAdd, setUsersToAdd] = useState([]);
    const [userGroupRoles, setUserGroupRoles] = useState([]);

    const defaultGroupRole = groupRole.editor;

    const { users, loading } = useFilteredUsersQuery({
        searchTerm: "",
        excludeUsersInGroupIds: [group.id],
        byArchived: false,
    });

    const filteredUsers = new ClientFilter(users)
        .bySearchTerm(["firstname", "lastname"], searchTerm)
        .excludeIds(usersInGroup.map((u) => u.id))
        .byCustomFilter((u) => !u.admin)
        .build();
    const lazyUsersQueries = useLazyUserQueries();
    const hasNoUsers = filteredUsers.length === 0 && searchTerm === "";

    const handleAddUsersToGroup = async () => {
        const userToAddList = [];

        for (let user of usersToAdd) {
            userToAddList.push(await getUserToAdd(user.id));
        }

        const promises = userToAddList.map((user) => addUserToGroup({ variables: { user } }));

        Promise.all(promises)
            .then((_) => {
                onClose();
            })
            .catch((e) => {
                setErrorMessage(extractApolloErrorMessage(e, "Es war leider nicht möglich den Benutzer zur Gruppe hinzuzufügen"));
            });
    };

    const getUserToAdd = async (targetUserId) => {
        const targetUser = await lazyUsersQueries.executeLoadUserById(targetUserId, true);
        const decryptedSharedPasswords = await retrieveDecryptedPasswordsInGroupWithSubGroups([group.id], null);
        const encryptedPasswords = await encryptPasswordsForUser(decryptedSharedPasswords, targetUser.publicKey.publicKeyString);
        const role = userGroupRoles.find((ug) => ug.id === targetUserId)?.groupRole || defaultGroupRole;

        return {
            userId: targetUser.id,
            groupId: group.id,
            groupRole: role,
            encryptedSharedPasswords: encryptedPasswords,
        };
    };

    const toggleCheckbox = (user) => {
        const index = usersToAdd.findIndex(({ id }) => id === user.id);
        const newUserList = index === -1 ? [...usersToAdd, user] : [...usersToAdd.slice(0, index), ...usersToAdd.slice(index + 1)];
        setUsersToAdd(newUserList);
    };
    const handleRoleChange = (user, groupRole) => {
        const userGroupRole = userGroupRoles.find((userGroupRole) => user.id === userGroupRole.id);

        let newUserGroupRoleList;
        if (userGroupRole) {
            newUserGroupRoleList = userGroupRoles.map((userGroupRole) =>
                userGroupRole.id === user.id ? { ...userGroupRole, groupRole } : userGroupRole
            );
        } else {
            newUserGroupRoleList = [...userGroupRoles, ...[{ id: user.id, groupRole }]];
        }

        setUserGroupRoles(newUserGroupRoleList);
    };

    return (
        <CustomModal
            maxWidth="sm"
            onClose={onClose}
            onOk={handleAddUsersToGroup}
            okButtonEnabled={!hasNoUsers}
            saveText={i18n("general.actions.add")}
            title={i18n("groups.addUser.title")}
            error={Boolean(errorMessage)}
            errorMessage={errorMessage}
            loading={loading && !searchTerm}
        >
            <Grid container spacing={2}>
                <Grid item xs={12} hidden={hasNoUsers}>
                    <GroupModalTextField onChange={setSearchTerm} value={localSearchTerm} label={i18n("groups.edit.searchUser")} />
                </Grid>
                <Grid item xs={12}>
                    <Table>
                        <TableBody>
                            {filteredUsers.map((u, i) => {
                                const { id, firstname, lastname } = u;
                                const userToAdd = usersToAdd.find((user) => user.id === id);
                                const currentGroupRole =
                                    userGroupRoles.find((userGroupRole) => userGroupRole.id === id)?.groupRole || defaultGroupRole;

                                return (
                                    <TableRow hover key={id} style={{ cursor: "pointer", height: "62px" }}>
                                        <TableCell padding="checkbox" onClick={(_) => toggleCheckbox(u)}>
                                            <Checkbox checked={Boolean(userToAdd)} />
                                        </TableCell>
                                        <TableCell scope="row" onClick={(_) => toggleCheckbox(u)}>
                                            {firstname + " " + lastname}
                                        </TableCell>

                                        <TableCell width={150}>
                                            {Boolean(userToAdd) && (
                                                <Select value={currentGroupRole} onChange={(e) => handleRoleChange(u, e.target.value)}>
                                                    <MenuItem value={groupRole.viewer}>{getGroupRoleText(groupRole.viewer)}</MenuItem>
                                                    <MenuItem value={groupRole.editor}>{getGroupRoleText(groupRole.editor)}</MenuItem>
                                                    <MenuItem value={groupRole.admin}>{getGroupRoleText(groupRole.admin)}</MenuItem>
                                                </Select>
                                            )}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                            {hasNoUsers && <p>{i18n("groups.addUser.noUser")}</p>}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </CustomModal>
    );
};

AddUserToGroupModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    group: PropTypes.object.isRequired,
    usersInGroup: PropTypes.array.isRequired,
    parentGroupId: PropTypes.string.isRequired,
};

export default AddUserToGroupModal;
