import React from "react";
import Page from "../common/layout/Page";
import ContentContainer from "../common/layout/ContentContainer";
import { Button, Grid } from "@mui/material";
import { useCustomerBillingPortalUrlQuery } from "../../hooks/queries/billingHook";
import { useMyselfQuery } from "../../hooks/queries/userQueryHooks";
import { OfflineStatusContext } from "../../contexts/OfflineStatusContext";
import { PageTypeList } from "../../types/PageType";
import { ClientFilter } from "../../managers/filterManager";
import { useGetBillingSecretQuery, useGetUsersQuery } from "../../graphql/generated";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";
import useTranslation from "../common/translation/translation";
import VaultCard from "../dashboard/VaultCard";
import { IconTypes } from "../../types/IconType";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import StripeContainer from "./StripeContainer";

export default function BillingPage() {
    const offlineStatusContext = OfflineStatusContext.useContainer();
    const { me, isTrial, trialFinishedLocalDate, isBusinessCustomer } = useMyselfQuery();
    const { url: billingUrl, loading: billingUrlLoading } = useCustomerBillingPortalUrlQuery();
    const { i18n } = useTranslation();

    const hasCustomerInformation = me.mandator.customerinformation !== null;

    const { data, loading: usersLoading } = useGetUsersQuery({
        fetchPolicy: "cache-and-network",
    });
    const users = data?.users || [];

    const { data: clientSecretData, loading: clientSecretLoading } = useGetBillingSecretQuery({
        fetchPolicy: "no-cache",
    });

    const loading = billingUrlLoading || usersLoading || clientSecretLoading;
    const nonArchivedUsers = new ClientFilter(users).byArchived(false).build();

    const stripePromise = loadStripe("pk_test_51J980ME2VD77zAJvkpcLoKf6oDk0gwnXGPWyc1KfawheCWciTMwUrE1oC7qMsk7QCiJhZmFGkl47qiz6GYzj6Xkk00B1fqNAQm");
    const options = {
        clientSecret: clientSecretData?.billingSecret,
    };

    const isIndividualPlan = isBusinessCustomer && !hasCustomerInformation;

    const getPlan = () => {
        if (!isBusinessCustomer) return "billing.privatePlan";
        if (hasCustomerInformation) return "billing.businessPlan";
        return "billing.individualPlan";
    };

    return (
        <Page loading={loading} currentPageType={PageTypeList.adminConsole}>
            <ContentContainer>
                <PageHeader>{i18n("billing.title")}</PageHeader>
                <PageSubTitle>{i18n("billing.subTitle")}</PageSubTitle>

                <Grid container>
                    <Grid item xl={4} md={6} sm={12}>
                        <VaultCard title={i18n("billing.plan")} subTitle={i18n(getPlan())} iconType={IconTypes.money} iconPosition="left" />
                    </Grid>
                    <Grid item xl={4} md={6} sm={12}>
                        <VaultCard title={i18n("billing.user")} subTitle={nonArchivedUsers.length} iconType={IconTypes.user} iconPosition="left" />
                    </Grid>

                    {isTrial && hasCustomerInformation ? (
                        <Grid item xl={4} md={6} sm={12}>
                            <VaultCard title={trialFinishedLocalDate} subTitle={i18n("billing.trial")} iconType={IconTypes.cog} iconPosition="left" />
                        </Grid>
                    ) : null}

                    {hasCustomerInformation ? (
                        <Grid item xl={6} md={6} sm={12}>
                            <VaultCard
                                title={i18n("billing.overviewTitle")}
                                subTitle={
                                    <Button
                                        target="_blank"
                                        variant="contained"
                                        color="secondary"
                                        href={billingUrl}
                                        disabled={offlineStatusContext.isOffline}
                                        style={{ marginTop: "2em" }}
                                    >
                                        {i18n("billing.overview")}
                                    </Button>
                                }
                                iconType={IconTypes.money}
                                iconPosition="top"
                            />
                        </Grid>
                    ) : null}
                </Grid>

                {!isIndividualPlan && !hasCustomerInformation && clientSecretData?.billingSecret ? (
                    <Grid item xl={4} md={6} sm={12}>
                        <Elements stripe={stripePromise} options={options}>
                            <VaultCard
                                title={<StripeContainer clientSecret={clientSecretData?.billingSecret} />}
                                iconType={IconTypes.money}
                                iconPosition="top"
                            />
                        </Elements>
                    </Grid>
                ) : null}
            </ContentContainer>
        </Page>
    );
}
