import translations from "./translation.json";

export default function useTranslation() {
    const i18n = (key, ...variables) => {
        return translate(key, ...variables);
    };

    return { i18n };
}

export function translate(key, ...variables) {
    const userLocale = navigator.languages && navigator.languages.length ? navigator.languages[0] : navigator.language;

    try {
        let translation = key.split(".").reduce((o, i) => (o ? o[i] : o), translations)[userLocale.includes("de") ? 1 : 0];

        variables.forEach((v) => {
            translation = translation.replace("${" + v.key + "}", v.value);
        });

        return translation;
    } catch (e) {
        return "--No translation found--";
    }
}
