import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import makeStyles from "@mui/styles/makeStyles";
import { Box, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import VerticalContentPageContainer from "../common/layout/VerticalContentPageContainer";
import VaulteronLogoIcon from "../common/dataDisplay/VaulteronLogoIcon";
import { useMaterialUIInput } from "../../hooks/inputHook";
import { CryptoManager } from "../../managers/cryptoManager";
import Separator from "../common/layout/Separator";
import { LoginContext } from "../../contexts/LoginContext";
import { useModalStatus } from "../../hooks/modalHook";
import config from "../../config.json";
import ChangeLoginPasswordModal from "../profile/ChangeLoginPasswordModal";
import CustomModal from "../common/feedback/modals/CustomModal";
import PasswordInputField from "../common/dataEntry/input/PasswordInputField";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "30rem",
        padding: "30px",
        border: "1px solid #C7C6C1",
        borderRadius: "5px",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    heading: {
        color: "#1D1C3E",
        marginBottom: theme.spacing(2),
    },
    title: {
        marginBottom: theme.spacing(1),
    },
}));

/**
 *
 * @param {string} question
 */
const replaceCharInUrl = (question) => decodeURIComponent(question).replaceAll("+", " ");

function ResetPasswordPage() {
    const classes = useStyles();
    const location = useLocation();
    const navigate = useNavigate();
    const loginContext = LoginContext.useContainer();

    const { value: answer1, onChange: setAnswer1 } = useMaterialUIInput("");
    const { value: answer2, onChange: setAnswer2 } = useMaterialUIInput("");
    const { value: answer3, onChange: setAnswer3 } = useMaterialUIInput("");

    const [oldDecryptedPassword, setOldDecryptedPassword] = useState("");
    const [error, setError] = useState("");

    const { modalState: resetPasswordModalState, open: openResetPasswordModal, close: closeResetPasswordModal } = useModalStatus();
    const { modalState: newPasswordModalState, open: openNewPasswordModal, close: closeNewPasswordModal } = useModalStatus();
    const { value: newPassword, onChange: setNewPassword } = useMaterialUIInput("");
    const { value: newPasswordRepeat, onChange: setNewPasswordRepeat } = useMaterialUIInput("");

    const isPasswordConfigured = Boolean(location.search);
    const params = queryString.parse(location.search);
    const question1 = replaceCharInUrl(params.q1);
    const question2 = replaceCharInUrl(params.q2);
    const question3 = replaceCharInUrl(params.q3);
    const userId = decodeURIComponent(params.userId);
    const passwordResetToken = decodeURIComponent(params.resetToken);
    const email = decodeURIComponent(params.email);

    const newPasswordTooLong = Boolean(newPassword.length > config.maxPasswordLength);
    const newPasswordTooShort = Boolean(newPassword.length < config.minLoginPasswordLength);
    const newPasswordSameAsOld = newPassword === oldDecryptedPassword;
    const newPasswordDoesNotEqualRepeated = newPassword !== newPasswordRepeat;
    const newPasswordValid = !newPasswordTooLong && !newPasswordTooShort && !newPasswordSameAsOld && !newPasswordDoesNotEqualRepeated;

    const showLoginPassword = async () => {
        try {
            const response = await loginContext.getEncryptedResetPasswordByToken(userId, passwordResetToken);
            const encryptedLoginPassword = response.encryptedResetPassword;

            const answerCryptoKey = answer1 + "" + answer2 + "" + answer3;
            const decryptionKey = await CryptoManager.createPasswordHash(answerCryptoKey);
            const decryptedLoginPassword = await CryptoManager.decryptUsingUserCredentials(encryptedLoginPassword, decryptionKey);

            setOldDecryptedPassword(decryptedLoginPassword);
            setError("");

            openNewPasswordModal();
        } catch (e) {
            setOldDecryptedPassword("");
            setError(
                `Ihr Loginpasswort konnte nicht entschlüsselt werden. Kontrollieren Sie Ihre Antworten und achten Sie auf die Groß- und Kleinschreibung.`
            );
        }
    };

    const handleResetPassword = async () => {
        const passwordHash = await CryptoManager.createPasswordHash(oldDecryptedPassword);
        loginContext.login(email, passwordHash, false);

        openResetPasswordModal();
    };

    if (!isPasswordConfigured)
        return (
            <VerticalContentPageContainer hideDrawer>
                <div className={classes.paper}>
                    <VaulteronLogoIcon height="60px" />
                    <Typography className={classes.heading} component="h1" variant="h5">
                        Vaulteron
                    </Typography>

                    <br />
                    <Typography className={classes.title} component="h2" variant="h5">
                        Für Ihren Account wurde die Funktion zum Passwort zurücksetzen nicht konfiguriert.
                    </Typography>

                    <br />
                    <br />
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={() => navigate("/login")}
                    >
                        Zurück zum Login
                    </Button>
                </div>
            </VerticalContentPageContainer>
        );

    return (
        <>
            <VerticalContentPageContainer hideDrawer>
                <div className={classes.paper}>
                    <VaulteronLogoIcon height="60px" />
                    <Typography className={classes.heading} component="h1" variant="h5">
                        Vaulteron
                    </Typography>

                    <Typography className={classes.title} component="h2" variant="h4">
                        Passwort zurücksetzten
                    </Typography>
                    <Typography style={{ marginBottom: "2em" }} component="p" variant="body1">
                        Beantworten Sie die Sicherheitsfragen, um ihr Loginpasswort anzuzeigen.
                    </Typography>

                    <div style={{ textAlign: "left" }}>
                        <Typography component="p" variant="body1">
                            {question1}
                        </Typography>
                        <TextField
                            onChange={setAnswer1}
                            value={answer1}
                            fullWidth
                            label="Antwort 1"
                            variant="outlined"
                            style={{ marginBottom: "1em" }}
                        />
                        <Typography component="p" variant="body1">
                            {question2}
                        </Typography>
                        <TextField
                            onChange={setAnswer2}
                            value={answer2}
                            fullWidth
                            label="Antwort 2"
                            variant="outlined"
                            style={{ marginBottom: "1em" }}
                        />
                        <Typography component="p" variant="body1">
                            {question3}
                        </Typography>
                        <TextField
                            onChange={setAnswer3}
                            value={answer3}
                            fullWidth
                            label="Antwort 3"
                            variant="outlined"
                            style={{ marginBottom: "1em" }}
                        />
                    </div>

                    <Button onClick={showLoginPassword} type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                        Loginpasswort zurücksetzen
                    </Button>

                    <br />
                    {Boolean(error) && <Typography style={{ color: "red" }}>{error}</Typography>}

                    <Separator title="oder" />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={() => navigate("/login")}
                    >
                        Zurück zum Login
                    </Button>
                </div>
            </VerticalContentPageContainer>

            {newPasswordModalState && (
                <CustomModal
                    open={true}
                    loading={false}
                    error={false}
                    onClose={closeNewPasswordModal}
                    saveText="Passwort zurücksetzen"
                    onOk={handleResetPassword}
                    title="Neues Passwort eingeben"
                    okButtonEnabled={newPasswordValid}
                    withCancelButton
                    cancelText="Abbrechen"
                    maxWidth="sm"
                    variant="light"
                >
                    <>
                        Ihr altes Passwort wurde erfolgreich entschlüsselt. Bitte wählen Sie nun ein neues Passwort.
                        <br />
                        <br />
                        {!newPasswordValid && (
                            <strong>
                                {newPasswordTooShort && <>Das Passwort muss mindestens {config.minLoginPasswordLength} Zeichen lang sein.</>}
                                {newPasswordTooLong && <>Das Passwort darf maximal {config.maxPasswordLength} Zeichen lang sein.</>}
                                {newPasswordSameAsOld && <>Das neue Password muss sich vom alten Passwort unterscheiden.</>}
                                {newPasswordDoesNotEqualRepeated && <>Die beiden eingegebenen Passwörter sind nicht gleich.</>}
                                <br />
                                <br />
                            </strong>
                        )}
                        <PasswordInputField
                            required
                            value={newPassword}
                            label="Neues Passwort"
                            variant="outlined"
                            onChange={setNewPassword}
                            size="small"
                            showInitially={false}
                            fullWidth
                        />
                        <Box style={{ marginBottom: "1rem" }} />
                        <PasswordInputField
                            required
                            value={newPasswordRepeat}
                            label="Neues Passwort wiederholen"
                            variant="outlined"
                            onChange={setNewPasswordRepeat}
                            size="small"
                            showInitially={false}
                            fullWidth
                        />
                    </>
                </CustomModal>
            )}

            {resetPasswordModalState && (
                <ChangeLoginPasswordModal
                    onClose={() => {
                        closeNewPasswordModal();
                        closeResetPasswordModal();
                    }}
                    newPassword={newPassword}
                    currentPassword={oldDecryptedPassword}
                />
            )}
        </>
    );
}

export default ResetPasswordPage;
