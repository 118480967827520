import React from "react";
import PropTypes from "prop-types";
import TreeView from "@mui/lab/TreeView";
import Icon from "../../dataDisplay/Icon";
import { IconTypes } from "../../../../types/IconType";
import { useGroupTreeByRoleQuery } from "../../../../hooks/queries/groupQueryHooks";
import { guid } from "../../../../managers/guidManager";
import GroupTreeBranch from "./GroupTreeBranch";
import { useGroupTreeStyles } from "./useGroupTreeStyles";
import { groupRoleType } from "../../../../managers/groupManager";

const GroupTreeByRole = ({ currentGroupId, onClick, withoutRoot, role }) => {
    const classes = useGroupTreeStyles();
    const { treeNodes } = useGroupTreeByRoleQuery(role);
    const ids = [guid.empty];

    return (
        <TreeView
            expanded={ids}
            defaultCollapseIcon={<Icon iconType={IconTypes.caretDown} />}
            defaultExpandIcon={<Icon iconType={IconTypes.users} className={classes.smallGroupIcon} />}
        >
            <GroupTreeBranch treeNodes={treeNodes} expanded={ids} onClick={onClick} currentGroupId={currentGroupId} withoutRoot={withoutRoot} />
        </TreeView>
    );
};

GroupTreeByRole.propTypes = {
    onClick: PropTypes.func.isRequired,
    currentGroupId: PropTypes.string,
    withoutRoot: PropTypes.bool,
    role: groupRoleType,
};

export default GroupTreeByRole;
