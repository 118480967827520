import React, { useState } from "react";
import Page from "../common/layout/Page";
import { PageType, PageTypeList } from "../../types/PageType";
import { useMyselfQuery } from "../../hooks/queries/userQueryHooks";
import { IconTypes } from "../../types/IconType";
import ConfirmationDialog from "../common/feedback/modals/ConfirmationDialog";
import { useModalStatus } from "../../hooks/modalHook";
import { KindOfPasswordTypes } from "../../types/PasswordType";
import { useGetAccountPasswordWithEncryptedStringLazyQuery, useGetSyncedPasswordWithEncryptedStringLazyQuery } from "../../graphql/generated";
import { CryptoManager } from "../../managers/cryptoManager";
import { LoginContext } from "../../contexts/LoginContext";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import { Grid, Typography } from "@mui/material";
import VaultCard from "../dashboard/VaultCard";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";
import useTranslation from "../common/translation/translation";

function escapeString(str) {
    const mustQuote = str.indexOf(",") !== -1 || str.indexOf('"') !== -1 || str.indexOf("\r") !== -1 || str.indexOf("\n") !== -1;
    if (mustQuote) {
        let sb = [];
        sb.push('"');
        for (let i = 0; i < str.length; i++) {
            let nextChar = str[i];
            sb.push(nextChar);
            if (nextChar === '"') {
                sb.push('"');
            }
        }
        sb.push('"');
        return sb.join("");
    }
    return str;
}

function getEncodedPassword(pw, passwordText) {
    return [escapeString(pw.name), escapeString(pw.websiteURL), escapeString(pw.login), escapeString(passwordText), escapeString(pw.notes)];
}

function download(name, data) {
    const blob = new Blob([data.join("\n")], { type: name });
    const url = URL.createObjectURL(blob);
    const pom = document.createElement("a");
    pom.href = url;
    pom.setAttribute("download", name + ".csv");
    document.body.appendChild(pom);
    pom.click();
    document.body.removeChild(pom);
}

const useStyles = makeStyles((theme) => ({
    backdrop: {
        display: "flex",
        flexDirection: "column",
        padding: "10%",
        zIndex: 10000,
        background: "#585858c2",
        textAlign: "center",
        fontSize: "1.5rem",
    },
    backdropInner: {
        backgroundColor: "white",
        padding: "2rem",
        borderRadius: 10,
    },
}));

/**
 *
 * @param {object} root0
 * @param {string} root0.currentPageType
 */
function ExportPage({ currentPageType }) {
    const classes = useStyles();
    const [passwordType, setPasswordType] = useState(KindOfPasswordTypes.companyPasswords);
    const { me } = useMyselfQuery();
    const loginContext = LoginContext.useContainer();
    const { i18n } = useTranslation();

    const { modalState: confirmationModalState, open: openConfirmationModalState, close: closeConfirmationModalState } = useModalStatus();
    const { modalState: encryptBackdropState, open: openEncryptBackdropState, close: closeEncryptBackdropState } = useModalStatus();

    const [loadAccountPassword] = useGetAccountPasswordWithEncryptedStringLazyQuery({
        onCompleted: async (someData) => {
            const data = [];
            data.push(getEncodedPassword({ name: "Name", websiteURL: "Webseite", login: "Login", notes: "Information" }, "Passwort"));

            for (let pw of someData.accountPasswords) {
                let passwordText = await CryptoManager.decryptEncryptedPassword(
                    me.keyPair.encryptedPrivateKey,
                    loginContext.userPasswordHash,
                    pw.encryptedString
                );
                data.push(getEncodedPassword(pw, passwordText));
            }

            download("vaulteron", data);
            closeEncryptBackdropState();
        },
    });

    const [loadSyncedPasswords] = useGetSyncedPasswordWithEncryptedStringLazyQuery({
        onCompleted: async (someData) => {
            const data = [];
            data.push(getEncodedPassword({ name: "Name", websiteURL: "Webseite", login: "Login", notes: "Information" }, "Passwort"));

            for (let pw of someData.companyPasswords) {
                let passwordText = await CryptoManager.decryptEncryptedPassword(
                    me.keyPair.encryptedPrivateKey,
                    loginContext.userPasswordHash,
                    pw.encryptedString
                );
                data.push(getEncodedPassword(pw, passwordText));
            }

            for (let pw of someData.sharedPasswords) {
                let passwordText = await CryptoManager.decryptEncryptedPassword(
                    me.keyPair.encryptedPrivateKey,
                    loginContext.userPasswordHash,
                    pw.encryptedString
                );
                data.push(getEncodedPassword(pw, passwordText));
            }

            download("vaulteron", data);
            closeEncryptBackdropState();
        },
    });

    const downloadAllNotSecretPasswords = () => {
        openEncryptBackdropState();
        if (passwordType === KindOfPasswordTypes.accountPasswords) loadAccountPassword();
        else loadSyncedPasswords();
        closeConfirmationModalState();
    };

    return (
        <Page currentPageType={currentPageType}>
            <PageHeader>{i18n("export.title")}</PageHeader>
            <PageSubTitle>{i18n("export.subTitle")}</PageSubTitle>

            <Grid container>
                <Grid item xl={4} md={8} sm={12}>
                    <VaultCard
                        title={i18n("export.companyPasswordTitle")}
                        subTitle={i18n("export.companyPasswordDescription")}
                        onClick={() => {
                            setPasswordType(KindOfPasswordTypes.companyPasswords);
                            openConfirmationModalState();
                        }}
                        iconType={IconTypes.export}
                        iconPosition="left"
                    />
                    <VaultCard
                        title={i18n("export.privatePasswordTitle")}
                        subTitle={i18n("export.privatePasswordDescription")}
                        onClick={() => {
                            setPasswordType(KindOfPasswordTypes.accountPasswords);
                            openConfirmationModalState();
                        }}
                        iconType={IconTypes.export}
                        iconPosition="left"
                    />
                </Grid>
            </Grid>

            {confirmationModalState && (
                <ConfirmationDialog
                    open
                    cancelText={i18n("general.actions.cancel")}
                    onClose={closeConfirmationModalState}
                    onOk={downloadAllNotSecretPasswords}
                    title={i18n("export.confirmTitle")}
                    description={i18n("export.confirmDescription")}
                />
            )}

            {encryptBackdropState && (
                <Backdrop open className={classes.backdrop}>
                    <div className={classes.backdropInner}>
                        <CircularProgress color="inherit" />
                        <h2>{i18n("export.inProgress")}</h2>
                    </div>
                </Backdrop>
            )}
        </Page>
    );
}

ExportPage.prototype = {
    currentPageType: PageType.isRequired,
};
export default ExportPage;
