import VerticalContentPageContainer from "../common/layout/VerticalContentPageContainer";
import queryString from "query-string";
import { useLocation } from "react-router-dom";
import { useEffect } from "react";
import { api } from "../../managers/apiManager";
import CircularProgress from "@mui/material/CircularProgress";

const addDeviceInformation = async (body) => {
    await fetch(api.deviceInformation, {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify(body),
    });
};

export default function DeviceLoggerLandingPage() {
    const VisitorAPI = require("visitorapi");
    const location = useLocation();
    const urlParams = queryString.parse(location.search);
    const userId = urlParams.userId;
    const email = urlParams.email;
    const redirect = urlParams.r;

    useEffect(() => {
        VisitorAPI("w8zsZnzOrHGiidCHjKp1", (data) => {
            addDeviceInformation({
                browser: data.browser,
                browserVersion: data.browserVersion,
                city: data.city,
                cityLatLong: data.cityLatLong,
                countryCode: data.countryCode,
                countryName: data.countryName,
                deviceBrand: data.deviceBrand,
                deviceFamily: data.deviceFamily,
                deviceModel: data.deviceModel,
                ipAddress: data.ipAddress,
                jSONInput: "",
                mail: email,
                os: data.os,
                osVersion: data.osVersion,
                clientId: userId,
            }).then(() => {
                window.location.replace("http://" + redirect);
            });
        });
    }, []);

    return (
        <VerticalContentPageContainer hideDrawer>
            <CircularProgress />
        </VerticalContentPageContainer>
    );
}
