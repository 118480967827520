import {styled} from "@mui/system";

export const PageHeader = styled("p")({
    fontSize: "2em",
    fontWeight: "600",
    "background-image": "linear-gradient(to left, #008cc0, #251743)",
    color: "transparent",
    backgroundClip: "text",
    "-webkit-background-clip": "text",
    position: "absolut",
    marginLeft: "0.7em",
});

export const PageSubTitle = styled("p")({
    fontSize: "1.4em",
    fontWeight: "500",
    color: "#6B6869",
    marginTop: "-30px",
    marginLeft: "1em",
});