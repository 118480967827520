import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { Table, TableBody, TableCell, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "../../common/dataDisplay/Icon";
import { IconTypes } from "../../../types/IconType";
import { VaulteronPasswordColumns } from "./VaulteronPasswordColumns";

const useStyles = makeStyles((theme) => ({
    table: {
        height: "fit-content",
        padding: 4,

        "& tr": {
            height: 44,

            "& td": {
                padding: "0 1rem",
            },
        },
    },
    dataColumn: {
        maxWidth: 150,
        textOverflow: "ellipsis",
        overflow: "hidden",
        whiteSpace: "nowrap",
    },
}));

const VaulteronColumnsPreview = ({ rows, columns, hasWebsiteColumn, hasAdditionalInformationColumn }) => {
    const classes = useStyles();

    const filteredVaulteronColumns = useMemo(() => {
        let columns = Object.values(VaulteronPasswordColumns);
        if (!hasWebsiteColumn) columns = columns.filter((c) => c !== VaulteronPasswordColumns.website);
        if (!hasAdditionalInformationColumn) columns = columns.filter((c) => c !== VaulteronPasswordColumns.notes);
        return columns;
    }, [hasWebsiteColumn, hasAdditionalInformationColumn]);

    const columnData = useMemo(() => {
        if (rows.length === 0) return [[], [], []];

        let data = [];
        for (let i = 0; i < Math.min(3, rows.length); i++) {
            let dataRow = [];
            const row = rows[i];
            for (let j = 0; j < filteredVaulteronColumns.length; j++) {
                dataRow.push(row[columns[j]?.field]);
            }
            data.push(dataRow);
        }
        return data;
    }, [rows, columns]);

    return (
        <Table className={classes.table}>
            <TableBody>
                {filteredVaulteronColumns.map((vaulteronColumn, index) => {
                    return (
                        <TableRow key={vaulteronColumn}>
                            <TableCell>
                                <div style={{ display: "flex", alignItems: "center" }}>
                                    <Icon iconType={IconTypes.arrowRight} />
                                    {vaulteronColumn}
                                </div>
                            </TableCell>
                            {columnData.map((columnData, j) => (
                                <TableCell key={`${columnData[index]}${index}${j}`} className={classes.dataColumn}>
                                    {columnData[index]}
                                </TableCell>
                            ))}
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

VaulteronColumnsPreview.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    hasWebsiteColumn: PropTypes.bool.isRequired,
    hasAdditionalInformationColumn: PropTypes.bool.isRequired,
};

export default VaulteronColumnsPreview;
