import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    progress: {
        width: "21.4px !important",
        height: "21.4px !important",
        marginRight: theme.spacing(1),
    },
}));

const LoadingButton = ({ loading, children, id, ...props }) => {
    const classes = useStyles();

    if (loading) {
        return (
            <Button {...props} disabled={true}>
                <CircularProgress className={classes.progress} />
                {children}
            </Button>
        );
    }

    return (
        <Button data-testid={id || "btn-ok"} {...props}>
            {children}
        </Button>
    );
};

LoadingButton.defaultProps = {};

LoadingButton.propTypes = {
    children: PropTypes.node.isRequired,
    loading: PropTypes.bool.isRequired,
};

export default LoadingButton;
