import React, { useState } from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import ConfirmationDialog from "../common/feedback/modals/ConfirmationDialog";
import { ConfirmDialogVariantList } from "../../types/ConfirmDialogVariant";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { CryptoManager } from "../../managers/cryptoManager";
import { useMyselfQuery, useUserMutation } from "../../hooks/queries/userQueryHooks";
import { LoginContext } from "../../contexts/LoginContext";
import { useModalStatus } from "../../hooks/modalHook";
import { menu } from "../../managers/pathManager";
import { extractApolloErrorMessage } from "../../managers/errorManager";
import { useRetrieveAllDecryptedSharedAndCompanyAndOfflinePasswords, useRetrieveDecryptedAccountPasswords } from "../../hooks/cryptoHook";
import { encryptPasswordsForUser } from "../../managers/passwordManager";
import { SecureStorageManager } from "../../managers/secureStorageManager";

const useStyles = makeStyles((theme) => ({
    backdrop: {
        display: "flex",
        flexDirection: "column",
        padding: "10%",
        zIndex: 10000,
        background: "#585858c2",
        textAlign: "center",
        fontSize: "1.5rem",
    },
    backdropInner: {
        backgroundColor: "white",
        padding: "2rem",
        borderRadius: 10,
    },
}));

const ChangeLoginPasswordModal = ({ onClose, currentPassword, newPassword }) => {
    const classes = useStyles();
    const loginContext = LoginContext.useContainer();

    const [errorMessage, setErrorMessage] = useState("");
    const [changingPassword, setChangingPassword] = useState(false);
    const { modalState: successDialogState, open: openSuccessDialog, close: closeSuccessDialog } = useModalStatus();

    const { loading: loadingMyself, error: errorMyself, me } = useMyselfQuery();

    const errorDialog = Boolean(errorMessage !== "" || errorMyself);

    const { changeUserPassword } = useUserMutation();
    const retrieveAllDecryptedSharedAndCompanyPasswords = useRetrieveAllDecryptedSharedAndCompanyAndOfflinePasswords();
    const retrieveAllDecryptedAccountPasswords = useRetrieveDecryptedAccountPasswords();

    const onChangePassword = async () => {
        setErrorMessage("");
        setChangingPassword(true);

        try {
            const currentPasswordHash = await CryptoManager.createPasswordHash(currentPassword);
            const keys = await CryptoManager.createAsymmetricalKeyPair();

            const decryptedSharedPasswords = await retrieveAllDecryptedSharedAndCompanyPasswords(null);
            const encryptedSharedPasswords = await encryptPasswordsForUser(decryptedSharedPasswords, keys.publicKey);

            const decryptedAccountPasswords = await retrieveAllDecryptedAccountPasswords(null);
            const encryptedAccountPasswords = await encryptPasswordsForUser(decryptedAccountPasswords, keys.publicKey, null, true);

            const newPublicKeyString = keys.publicKey;
            const newPasswordHashed = await CryptoManager.createPasswordHash(newPassword);
            const newEncryptedPrivateKey = await CryptoManager.encryptUsingUserCredentials(keys.privateKey, newPasswordHashed);

            const previousPasswordHashEncryptedWithPublicKey = await CryptoManager.encryptWithPublicKey(currentPasswordHash, newPublicKeyString);

            await changeUserPassword({
                variables: {
                    publicKeyString: newPublicKeyString,
                    encryptedPrivateKey: newEncryptedPrivateKey,
                    previousPasswordHashEncryptedWithPublicKey: previousPasswordHashEncryptedWithPublicKey,
                    currentPassword: currentPasswordHash,
                    newPassword: newPasswordHashed,
                    encryptedSharedPasswords: encryptedSharedPasswords,
                    encryptedAccountPasswords: encryptedAccountPasswords,
                },
            });

            // Updating local information
            await SecureStorageManager.setUserPasswordHash(newPasswordHashed);
            loginContext.setUserPasswordHash(newPasswordHashed);
        } catch (e) {
            if (e.networkError && e.networkError.result) {
                if (e.networkError.result.errorCode === 49) {
                    setErrorMessage("Das eingebene Loginpasswort entspricht nicht Ihrem derzeitigen Loginpasswort.");
                } else {
                    setErrorMessage(extractApolloErrorMessage(e, "Beim Setzen Ihres neuen Loginpassworts ist leider ein Fehler aufgetreten"));
                }
            } else {
                setErrorMessage("Beim Setzen Ihres neuen Loginpassworts ist leider ein Fehler aufgetreten: " + e.message);
            }
            return;
        }

        openSuccessDialog();
    };

    const onFinished = async () => {
        try {
            if (!errorDialog) {
                // Only auto-logout on success
                const success = await loginContext.logout();
                if (success) window.location.href = menu.login.path;
            }
        } catch (e) {
            // ignored
        }

        closeSuccessDialog();
        onClose();
    };

    return (
        <>
            {!loadingMyself && (
                <ConfirmationDialog
                    onOk={onChangePassword}
                    onClose={onClose}
                    open={!successDialogState && !changingPassword}
                    cancelText="Abbrechen"
                    okText="Ich möchte es ändern und werde dieses Fenster offen halten"
                    title="Wollen Sie wirklich Ihr Passwort ändern?"
                    description={
                        <>
                            Wenn Sie Ihr Login-Passwort ändern, müssen alle Passwörter auf die Sie Zugriff haben erneut verschlüsselt werden.
                            <br />
                            Dies kann einigen Minuten lang dauern.
                            <br />
                            <br />
                            Schließen Sie währenddessen bitte nicht dieses Fenster!
                            <br />
                            <br />
                            <strong>Sicherheitsfragen müssen neu eingerichtet werden!</strong>
                        </>
                    }
                    variant={ConfirmDialogVariantList.warning}
                />
            )}

            <ConfirmationDialog
                onOk={onFinished}
                onClose={onFinished}
                open={successDialogState}
                okText="Zum Login"
                title="Ändern Erfolgreich"
                description={
                    <>
                        Das Ändern Ihres Loginpassworts war erfolgreich und alle Ihre Passwörter wurden mit ihrem neuen Loginpasswort verschlüsselt.
                        <br />
                        Als letzten Schritt werden Sie auf die Login-Seite weitergeleitet, um sich mir Ihrem neuen Loginpasswort anmelden zu können.
                    </>
                }
            />

            <ConfirmationDialog
                onOk={onFinished}
                onClose={onFinished}
                open={errorDialog}
                title="Es ist ein Fehler aufgetreten"
                description={errorMessage}
                variant={ConfirmDialogVariantList.error}
            />

            <Backdrop open={changingPassword && !successDialogState && !errorDialog} className={classes.backdrop}>
                <div className={classes.backdropInner}>
                    <CircularProgress color="inherit" />
                    <h2>
                        Wir sind gerade dabei Ihre existierenden Passwörter neu zu verschlüsseln.
                        <br />
                        Bitte warten Sie einen Moment.
                    </h2>
                </div>
            </Backdrop>
        </>
    );
};

ChangeLoginPasswordModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    currentPassword: PropTypes.string.isRequired,
    newPassword: PropTypes.string.isRequired,
};

export default ChangeLoginPasswordModal;
