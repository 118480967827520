import LoginPage from "../components/login";
import RegisterPage from "../components/login/RegisterPage";
import UnauthorizedPage from "../components/systemPages/UnauthorizedPage";
import AdminAuthorizationNeededPage from "../components/systemPages/AdminAuthorizationNeededPage";
import ResetPasswordPage from "../components/landingpages/ResetPasswordPage";
import ErrorPage from "../components/systemPages/ErrorPage";
import ActivateAccount from "../components/landingpages/ActivateAccount";
import OfflinePasswordsPage from "../components/offlineData/OfflinePasswordsPage";
import DashboardPage from "../components/dashboard/DashboardPage";
import AllPasswordsPage from "../components/allPasswords/AllPasswordsPage";
import GroupPasswordsPage from "../components/groupPasswords";
import GroupsPage from "../components/groups";
import ProfilePage from "../components/profile/ProfilePage";
import ManageMandatorsPage from "../components/superAdmin/ManageMandatorsPage";
import UserPage from "../components/users/UserPage";
import ImportPage from "../components/import/ImportPage";
import ExportPage from "../components/export/ExportPage";
import BillingPage from "../components/billing/BillingPage";
import { SharedPasswords } from "../components/companyPasswords/PasswordPageContainer";
import { PageTypes } from "../types/PageType";
import DeviceLoggerPage from "../components/deviceLogger/DeviceLoggerPage";
import DeviceLoggerLandingPage from "../components/deviceLogger/DeviceLoggerLandingPage";
import PwnedPage from "../components/pwned/PwnedPage";

const menu = {
    login: {
        path: "/login",
        page: <LoginPage />,
        accessibleWithoutLogin: true,
    },
    register: {
        path: "/register",
        page: <RegisterPage />,
        accessibleWithoutLogin: true,
    },
    unauthorized: {
        path: "/unauthorized",
        page: <UnauthorizedPage />,
        accessibleWithoutLogin: true,
    },
    unauthorizedNeedsAdminAuth: {
        path: "/unauthorizedAdmin",
        page: <AdminAuthorizationNeededPage />,
    },
    resetPassword: {
        path: "/resetPassword",
        page: <ResetPasswordPage />,
        accessibleWithoutLogin: true,
    },
    error: {
        path: "/error",
        page: <ErrorPage />,
        accessibleWithoutLogin: true,
    },
    landingPageActivateAccount: {
        path: "/activate-account",
        page: <ActivateAccount />,
        accessibleWithoutLogin: true,
    },
    offlineData: {
        path: "/offline",
        page: <OfflinePasswordsPage />,
    },
    root: {
        path: "/",
        page: <DashboardPage />,
    },
    all: {
        path: "/all",
        page: <AllPasswordsPage />,
    },
    sharedPasswords: {
        path: "/shared",
        page: <SharedPasswords />,
    },
    groupPasswords: {
        path: "/group",
        page: <GroupPasswordsPage />,
    },
    groups: {
        path: "/groups",
        page: <GroupsPage />,
    },
    profile: {
        path: "/profile",
        page: <ProfilePage currentPageType={PageTypes.default} />,
    },
    pwned: {
        path: "/pwned",
        page: <PwnedPage currentPageType={PageTypes.default} />,
    },
    manageMandators: {
        path: "/superAdmin/",
        page: <ManageMandatorsPage />,
    },
    users: {
        path: "/a/users",
        page: <UserPage />,
    },
    aImport: {
        path: "/a/import",
        page: <ImportPage currentPageType={PageTypes.adminConsole} />,
    },
    aExport: {
        path: "/a/export",
        page: <ExportPage currentPageType={PageTypes.adminConsole} />,
    },
    billing: {
        path: "/a/billing",
        page: <BillingPage />,
    },
    deviceLogger: {
        path: "/a/device",
        page: <DeviceLoggerPage />,
    },
    deviceLoggerLanding: {
        path: "/log",
        page: <DeviceLoggerLandingPage />,
        accessibleWithoutLogin: true,
    },
};

Object.freeze(menu);

export { menu };
