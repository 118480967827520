import React from "react";
import Page from "../common/layout/Page";
import DashboardCard from "./DashboardCard";
import { Card, CardContent, Grid } from "@mui/material";
import { IconTypes } from "../../types/IconType";
import { menu } from "../../managers/pathManager";
import { useNavigate } from "react-router-dom";
import { useMyselfQuery, useUsersQuery } from "../../hooks/queries/userQueryHooks";
import VaultCard from "./VaultCard";
import { useGetCompanyPasswordQuery } from "../../graphql/generated";
import { PageTypes } from "../../types/PageType";
import useTranslation from "../common/translation/translation";
import { useModalStatus } from "../../hooks/modalHook";
import SetupPasswordResetModal from "../profile/SetupPasswordResetModal";
import { ExtensionInfosContext } from "../../contexts/ExtensionContext";
import { useDeviceInfos } from "../../hooks/deviceInfosHook";
import { styled } from "@mui/system";
import { GeneratePassword } from "./GeneratePassword";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";
import SupportCard from "./SupportCard";
import { useIsMobile } from "../../hooks/mobileHook";
import { isElectron } from "../../App";

const Container = styled("div")({
    margin: "1em 2em",
});

export const Header = styled("p")({
    fontSize: "1.6em",
    fontWeight: "600",
    color: "#322D31",
});
function DashboardPage() {
    const extensionContext = ExtensionInfosContext.useContainer();
    const { isChromium } = useDeviceInfos();
    const navigate = useNavigate();
    const [activeStep, setActiveStep] = React.useState(0);
    const { data } = useGetCompanyPasswordQuery({ fetchPolicy: "cache-first" });
    const { modalState: setupPasswordResetModalState, open: openSetupPasswordResetModal, close: closeSetupPasswordResetModal } = useModalStatus();

    const isMobile = useIsMobile();
    const { i18n } = useTranslation();
    const { users } = useUsersQuery("");
    const { me, isSystemAdmin, loading } = useMyselfQuery();

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const hasCustomerInformation = me.mandator.customerinformation !== null;

    const showInstallExtension = !extensionContext.isExtensionAvailable && isChromium && !isMobile && !isElectron();
    const showResetPassword = !me.passwordReset;
    const showTwoFactor = me.twoFATypesEnabled.length === 0;
    const showAddEmployee = Boolean(users.length <= 1 && isSystemAdmin);
    const showImportPassword = Boolean(data?.companyPasswords.length === 0 && isSystemAdmin);
    const showInvoiceProviderPaymentMethodeMissing = hasCustomerInformation && !Boolean(me.mandator.customerinformation?.defaultPaymentMethodId);

    const countSteps =
        (showInstallExtension ? 1 : 0) +
        (showResetPassword ? 1 : 0) +
        (showTwoFactor ? 1 : 0) +
        (showAddEmployee ? 1 : 0) +
        (showImportPassword ? 1 : 0) +
        (showInvoiceProviderPaymentMethodeMissing ? 1 : 0);

    const showTutorial = Boolean(!loading && countSteps && countSteps !== 0);

    const stepperProps = {
        activeStep,
        handleBack,
        handleNext,
        maxSteps: countSteps,
    };

    const getDashboardCard = (key, title, content, linkText, onClick) => {
        return <DashboardCard key={key} title={title} content={content} linkText={linkText} onClick={onClick} {...stepperProps} />;
    };

    const tutorialCards = [
        {
            getCard: (key) =>
                getDashboardCard(
                    key,
                    i18n("dashboard.tutorial.extension"),
                    i18n("dashboard.tutorial.extensionDescription"),
                    i18n("navigation.installExtension"),
                    () => extensionContext.openInstallTab()
                ),
            visible: showInstallExtension,
        },
        {
            getCard: (key) =>
                getDashboardCard(
                    key,
                    i18n("dashboard.tutorial.twoFactorAuthentication"),
                    i18n("dashboard.tutorial.twoFactorAuthenticationDescription"),
                    i18n("general.actions.setUp"),
                    () => navigate(menu.profile.path)
                ),
            visible: showTwoFactor,
        },
        {
            getCard: (key) =>
                getDashboardCard(
                    key,
                    i18n("dashboard.tutorial.forgotPasswordSetUp"),
                    i18n("dashboard.tutorial.forgotPasswordSetUpDescription"),
                    i18n("general.actions.setUp"),
                    openSetupPasswordResetModal
                ),
            visible: showResetPassword,
        },
        {
            getCard: (key) =>
                getDashboardCard(
                    key,
                    i18n("dashboard.tutorial.addEmployees"),
                    i18n("dashboard.tutorial.addEmployeesDescription"),
                    i18n("general.actions.add"),
                    () => navigate(menu.users.path)
                ),
            visible: showAddEmployee,
        },
        {
            getCard: (key) =>
                getDashboardCard(
                    key,
                    i18n("dashboard.tutorial.importPasswords"),
                    i18n("dashboard.tutorial.importPasswordsDescription"),
                    i18n("general.actions.import"),
                    () => navigate(menu.aImport.path)
                ),
            visible: showImportPassword,
        },
        {
            getCard: (key) =>
                getDashboardCard(
                    key,
                    i18n("dashboard.tutorial.managePayments"),
                    i18n("dashboard.tutorial.managePaymentsDescription"),
                    i18n("dashboard.tutorial.managePaymentsAction"),
                    () => navigate(menu.billing.path)
                ),
            visible: showInvoiceProviderPaymentMethodeMissing,
        },
    ];

    return (
        <Page currentPageType={PageTypes.default}>
            <Container>
                <PageHeader> Dashboard</PageHeader>
                <PageSubTitle>
                    {i18n("dashboard.welcome")} {me.firstname} {me.lastname}
                </PageSubTitle>
                <Grid container spacing={4}>
                    {showTutorial && (
                        <Grid item md={9} xs={12}>
                            <Card>
                                <CardContent>
                                    <Header>{i18n("dashboard.tutorial.title")}</Header>
                                    {tutorialCards
                                        .filter((c) => c.visible)
                                        .map((cardInformation, i) => {
                                            if (i === activeStep) return cardInformation.getCard(i);
                                        })}
                                </CardContent>
                            </Card>
                        </Grid>
                    )}

                    {showTutorial && <Grid item md={3} xs={12} />}

                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Header>{i18n("dashboard.vaults.title")}</Header>
                                <VaultCard
                                    title={i18n("navigation.allPasswords")}
                                    subTitle={i18n("dashboard.vaults.allPasswordsDescription")}
                                    onClick={(_) => navigate(menu.all.path)}
                                    iconType={IconTypes.allPasswords}
                                    iconPosition="left"
                                />
                                <VaultCard
                                    title={i18n("navigation.employeePasswords")}
                                    subTitle={i18n("dashboard.vaults.employeePasswordsDescription")}
                                    onClick={(_) => navigate(menu.sharedPasswords.path)}
                                    iconType={IconTypes.lock}
                                    iconPosition="left"
                                />
                                <VaultCard
                                    title={i18n("navigation.groupPasswords")}
                                    subTitle={i18n("dashboard.vaults.groupPasswordsDescription")}
                                    onClick={(_) => navigate(menu.groupPasswords.path)}
                                    iconType={IconTypes.sharedPassword}
                                    iconPosition="left"
                                />
                                <VaultCard
                                    title={i18n("navigation.groupManagement")}
                                    subTitle={i18n("dashboard.vaults.groupManagementDescription")}
                                    onClick={(_) => navigate(menu.groups.path)}
                                    iconType={IconTypes.groups}
                                    iconPosition="left"
                                />
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item md={3} xs={12}>
                        <Card style={{ marginBottom: "2em" }}>
                            <SupportCard
                                header={i18n("dashboard.support.number")}
                                iconType={IconTypes.support}
                                href="tel:0720270537"
                                linkText="+43 (0) 720270537"
                                showTimeInformation
                            />
                        </Card>
                        <Card>
                            <SupportCard
                                header={i18n("dashboard.support.mail")}
                                iconType={IconTypes.mail}
                                href="mailto:office@vaulteron.com"
                                linkText="office@vaulteron.com"
                            />
                        </Card>
                    </Grid>

                    <Grid item md={6} xs={12}>
                        <Card>
                            <CardContent>
                                <Header>{i18n("dashboard.support.generate.title")}</Header>
                                <GeneratePassword />
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>
            {setupPasswordResetModalState && <SetupPasswordResetModal onClose={closeSetupPasswordResetModal} />}
        </Page>
    );
}

export default DashboardPage;
