import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "../../dataDisplay/Icon";
import { IconTypes } from "../../../../types/IconType";
import { IconColorTypeList } from "../../../../types/IconColorType";
import { Dialog, DialogContent, DialogTitle } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";

const useStyles = makeStyles((theme) => ({
    titleIcon: {
        display: "flex",
        alignItems: "center",
        "& .icon": {
            width: "2rem",
            height: "2rem",
            marginRight: theme.spacing(1),
            display: "flex",
            alignItems: "center",
            "&:before": {
                fontSize: "2rem",
            },
        },
    },
    iconText: {
        fontSize: "1.2rem",
    },
    content: {
        fontSize: "1rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        padding: "0 !important",
    },
    backdrop: {
        display: "flex",
        flexDirection: "column",
        padding: "10%",
        zIndex: 10000,
        background: "#585858c2",
        textAlign: "center",
        fontSize: "1.5rem",
    },
    waitSpinner: {
        margin: theme.spacing(5),
    },
}));

/**
 *
 * @param {boolean} open
 * @param {string} title
 * @param {string | React.ReactNode} description
 * @returns {JSX.Element}
 * @constructor
 */
const WaitDialog = ({ open, title, description }) => {
    const classes = useStyles();

    return (
        <Dialog open={open} maxWidth="sm">
            <DialogTitle>
                <div className={classes.titleIcon}>
                    <Icon iconType={IconTypes.circleInfo} colorType={IconColorTypeList.secondary} />
                    <label className={classes.iconText}>{title}</label>
                </div>
            </DialogTitle>
            <DialogContent>
                <DialogContent className={classes.content}>
                    <p>{description}</p>
                    <CircularProgress color="inherit" className={classes.waitSpinner} />
                </DialogContent>
            </DialogContent>
        </Dialog>
    );
};

WaitDialog.propTypes = {
    open: PropTypes.bool.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
};

export default WaitDialog;
