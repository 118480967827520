import React, { useState } from "react";

import Page from "../common/layout/Page";
import PasswordSearchBar from "../common/dataEntry/PasswordSearchBar";
import { useFilterPassword, useSelectedPassword } from "../../hooks/passwordHook";
import { useContextMenu, useModalStatus } from "../../hooks/modalHook";
import AddPasswordModal from "../common/passwordComponents/AddPasswordModal";
import { KindOfPasswordTypes } from "../../types/PasswordType";
import PasswordsContainer from "../common/passwordComponents/PasswordsContainer";
import { Table, TableBody } from "@mui/material";
import PasswordTableRow from "../common/passwordComponents/PasswordTableRow";
import PasswordModal from "../common/passwordComponents/PasswordModal";
import PasswordTableRowContextMenu from "../common/passwordComponents/PasswordTableRowContextMenu";
import ConfirmDeletePassword from "../common/passwordComponents/ConfirmDeletePassword";
import TitleToolbar from "../common/layout/TitleToolbar";
import ConvertPasswordModal from "../common/convertPassword/ConvertPasswordModal";
import { ClientFilter, defaultPasswordSearchTermFields } from "../../managers/filterManager";
import PasswordHistoryModal from "../common/passwordComponents/history/PasswordHistoryModal";
import ContentContainer from "../common/layout/ContentContainer";
import AccessLogModal from "../common/passwordComponents/AccessLogModal";
import TableCaption from "../common/dataDisplay/TableCaption";
import { groupPasswordsByCreatorIdToMap } from "../../managers/passwordGroupingManager";
import { useCompanyPasswordDeleteMutation } from "../../hooks/queries/companyPasswordHook";
import { useMyselfQuery } from "../../hooks/queries/userQueryHooks";
import { arrayOf, bool, object, string } from "prop-types";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";
import useTranslation from "../common/translation/translation";
import NatoAlphabetPasswordModal from "../common/passwordComponents/NatoAlphabetPasswordModal";

const CompanyPasswordsPage = ({ rowPasswords, loading, title, subTitle }) => {
    const { i18n } = useTranslation();

    const [currentMultiSelectionKey, setCurrentMultiSelectionKey] = useState("");
    const { getSelectedPasswords, setSelectPasswords, selectAll, unselectAll } = useSelectedPassword();

    const { searchTerm, setSearchTerm } = useFilterPassword();

    const [clickedPassword, setClickedPassword] = useState();
    const [isBulkOperation, setIsBulkOperation] = useState(false);

    const { modalState: addPasswordModalState, open: openAddPasswordModal, close: closeAddPasswordModal } = useModalStatus();
    const { modalState: historyModalState, open: openHistoryModal, close: closeHistoryModal } = useModalStatus();
    const { modalState: accessLogsModalState, open: openAccessLogsModal, close: closeAccessLogsModal } = useModalStatus();
    const {
        modalState: confirmDeletePasswordModalState,
        open: openConfirmDeletePasswordModal,
        close: closeConfirmDeletePasswordModal,
    } = useModalStatus();
    const { modalState: passwordModalState, open: openPasswordModal, close: closePasswordModal } = useModalStatus();
    const { modalState: convertModalState, open: openConvertModal, close: closeConvertModal } = useModalStatus();

    const { anchorEl, open: openContextMenu, close: closeContextMenu } = useContextMenu();
    const { deletePassword } = useCompanyPasswordDeleteMutation();

    const { me, isSystemAdmin } = useMyselfQuery();
    const filteredPasswords = new ClientFilter(rowPasswords).bySearchTerm(defaultPasswordSearchTermFields, searchTerm).build();

    const emptyTitle = i18n("password.first.title");
    const emptyInformation = i18n("password.first.subTitle");
    const isInSearch = Boolean(searchTerm !== "");

    const groupedPasswordsMap = groupPasswordsByCreatorIdToMap(filteredPasswords);
    const groupedPasswordsArray = Array.from(groupedPasswordsMap.keys()).sort((s) => (s === me.id ? 1 : -1));
    let sortedGroupPasswordArray = groupedPasswordsArray;
    if (groupedPasswordsArray.includes(me.id)) {
        sortedGroupPasswordArray = groupedPasswordsArray.filter((id) => id !== me.id);
        sortedGroupPasswordArray.unshift(me.id);
    }
    const {
        modalState: natoAlphabetPasswordModalState,
        open: openNatoAlphabetPasswordModal,
        close: closeNatoAlphabetPasswordModal,
    } = useModalStatus();

    const targetPasswords = isBulkOperation ? getSelectedPasswords(currentMultiSelectionKey) : [clickedPassword];

    return (
        <>
            <Page
                loading={loading}
                empty={rowPasswords.length === 0}
                emptyTitle={emptyTitle}
                emptyInformation={emptyInformation}
                inSearchOperation={isInSearch}
                onNoContentAction={openAddPasswordModal}
            >
                <PasswordSearchBar
                    passwordType={KindOfPasswordTypes.companyPasswords}
                    onAddPassword={openAddPasswordModal}
                    onSearchTerm={setSearchTerm}
                />
                <ContentContainer>
                    <PageHeader>{title}</PageHeader>
                    <PageSubTitle>{subTitle}</PageSubTitle>
                    {sortedGroupPasswordArray.map((userId) => {
                        const passwords = groupedPasswordsMap.get(userId);
                        const createdBy = passwords[0].createdBy;
                        const fullname = createdBy["firstname"] + " " + createdBy["lastname"];

                        return (
                            <div key={userId}>
                                <TitleToolbar
                                    title={fullname}
                                    passwordCount={passwords.length}
                                    selected={getSelectedPasswords(userId)}
                                    setSelectAll={(_) => selectAll(userId, passwords)}
                                    setUnselectAll={(_) => unselectAll(userId)}
                                    onArchive={() => {
                                        setCurrentMultiSelectionKey(userId);
                                        setIsBulkOperation(true);
                                        openConfirmDeletePasswordModal();
                                    }}
                                    onConvert={() => {
                                        setCurrentMultiSelectionKey(userId);
                                        setIsBulkOperation(true);
                                        openConvertModal();
                                    }}
                                />
                                {passwords.length > 0 && (
                                    <PasswordsContainer>
                                        <Table size="small">
                                            <TableCaption
                                                visible={!isSystemAdmin}
                                                text="Diese Passwörter sind für Sie und für Systemadministratoren sichtbar"
                                            />
                                            <TableBody>
                                                {passwords.map((pw, i) => (
                                                    <PasswordTableRow
                                                        key={i}
                                                        showMenuOptions
                                                        password={pw}
                                                        selectedPasswords={getSelectedPasswords(userId)}
                                                        setSelectedPasswords={(passwords) => setSelectPasswords(userId, passwords)}
                                                        onRowClick={(_) => {
                                                            setCurrentMultiSelectionKey(userId);
                                                            setClickedPassword(pw);
                                                            setIsBulkOperation(false);
                                                            openPasswordModal();
                                                        }}
                                                        onContextMenu={(e) => {
                                                            setCurrentMultiSelectionKey(userId);
                                                            setClickedPassword(pw);
                                                            setIsBulkOperation(false);
                                                            openContextMenu(e);
                                                        }}
                                                        showType
                                                    />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </PasswordsContainer>
                                )}
                            </div>
                        );
                    })}
                </ContentContainer>
            </Page>

            {addPasswordModalState && (
                <AddPasswordModal
                    initialPasswordType={KindOfPasswordTypes.companyPasswords}
                    onClose={closeAddPasswordModal}
                    onOk={closeAddPasswordModal}
                />
            )}

            {passwordModalState && (
                <PasswordModal
                    passwordType={KindOfPasswordTypes.companyPasswords}
                    disableTags
                    editable
                    password={clickedPassword}
                    onClose={closePasswordModal}
                    onOk={closePasswordModal}
                />
            )}
            {confirmDeletePasswordModalState && (
                <ConfirmDeletePassword
                    onOk={() => {
                        for (const password of targetPasswords) {
                            deletePassword({ variables: { id: password.id } });
                        }
                        unselectAll(currentMultiSelectionKey);
                        closeConfirmDeletePasswordModal();
                    }}
                    onClose={closeConfirmDeletePasswordModal}
                    passwords={targetPasswords}
                />
            )}

            {historyModalState && <PasswordHistoryModal password={clickedPassword} onClose={closeHistoryModal} />}

            <PasswordTableRowContextMenu
                passwordType={KindOfPasswordTypes.companyPasswords}
                password={clickedPassword}
                anchorEl={anchorEl}
                onClose={closeContextMenu}
                onPasswordModal={openPasswordModal}
                onConfirmDeleteModal={openConfirmDeletePasswordModal}
                onMovePassword={openConvertModal}
                onHistoryModal={me.admin ? openHistoryModal : undefined}
                onAccessModal={openAccessLogsModal}
                onNatoAlphabetPassword={openNatoAlphabetPasswordModal}
            />

            {convertModalState && (
                <ConvertPasswordModal
                    preselectedPasswords={targetPasswords}
                    onClose={(_) => {
                        closeConvertModal();
                        unselectAll(currentMultiSelectionKey);
                    }}
                    fromType={KindOfPasswordTypes.companyPasswords}
                />
            )}

            {accessLogsModalState && <AccessLogModal logs={clickedPassword.accessLog} onClose={closeAccessLogsModal} />}
            {natoAlphabetPasswordModalState ? (
                <NatoAlphabetPasswordModal
                    onClose={closeNatoAlphabetPasswordModal}
                    passwordType={KindOfPasswordTypes.companyPasswords}
                    password={clickedPassword}
                />
            ) : undefined}
        </>
    );
};

CompanyPasswordsPage.propTypes = {
    rowPasswords: arrayOf(object),
    loading: bool,
    title: string,
    subTitle: string,
};

export default CompanyPasswordsPage;
