import makeStyles from "@mui/styles/makeStyles";
import { Checkbox, FormControlLabel, Grid, Tooltip } from "@mui/material";
import PropTypes from "prop-types";
import { IconTypes } from "../../../types/IconType";
import IconButton from "../dataDisplay/IconButton";
import { useIsMobile } from "../../../hooks/mobileHook";

const useStyles = makeStyles((theme) => ({
    container: {
        width: "calc(100% + 8px)",
        margin: "0 -8px",
        marginTop: (props) => (props.isMobile ? "10px" : "30px"),
        backgroundColor: "#fff",
    },
    innerContainer: {
        border: "1px solid rgb(224, 224, 224)",
        padding: "5px",
    },
    checkBox: {
        margin: 0,
        padding: 0,
        marginLeft: "3px",
        "& span:nth-child(2)": {
            fontWeight: "bold",
            marginLeft: "1px",
        },
    },
    headline: {
        margin: "0.26em",
    },
    alignItems: {
        marginTop: "7px",
        marginRight: "15px",
        float: "right",
    },
}));

const TitleToolbar = ({
    title,
    isViewer,
    selected,
    passwordCount,
    setUnselectAll,
    setSelectAll,
    onAdd,
    onConvert,
    onDelete,
    onArchive,
    countLabelSingular,
    countLabelPlural,
    hideTitleInformationInMultiSelect,
}) => {
    const isMobile = useIsMobile();
    const classes = useStyles({ isMobile: isMobile });
    const selectedCount = selected.length;
    const countPasswordLabel =
        selectedCount +
        " " +
        (selectedCount === 1 ? countLabelSingular : countLabelPlural) +
        (hideTitleInformationInMultiSelect ? "" : " von " + title);

    const hasNoneSelected = selectedCount === 0;
    const hasAllSelected = selectedCount === passwordCount;
    const hasSomeSelected = !(hasNoneSelected || hasAllSelected);

    return (
        <div className={classes.container}>
            <div className={classes.innerContainer}>
                <Grid container>
                    <Grid item xs={8}>
                        <FormControlLabel
                            className={classes.checkBox}
                            control={
                                <Checkbox
                                    disableRipple
                                    indeterminate={hasSomeSelected}
                                    checked={hasAllSelected && passwordCount > 0}
                                    disabled={passwordCount === 0 || isViewer}
                                    onChange={!hasAllSelected ? setSelectAll : setUnselectAll}
                                />
                            }
                            label={hasNoneSelected ? title : countPasswordLabel}
                        />
                    </Grid>
                    <Grid item xs={4}>
                        {!hasNoneSelected && (
                            <>
                                {onArchive && (
                                    <Tooltip title="Archivieren">
                                        <IconButton
                                            iconType={IconTypes.fileArchive}
                                            size="small"
                                            className={classes.alignItems}
                                            onClick={onArchive}
                                        />
                                    </Tooltip>
                                )}
                                {onConvert && (
                                    <Tooltip title="Verschieben">
                                        <IconButton iconType={IconTypes.retweet} size="small" className={classes.alignItems} onClick={onConvert} />
                                    </Tooltip>
                                )}
                                {onDelete && (
                                    <Tooltip title="Löschen">
                                        <IconButton
                                            iconType={IconTypes.deletePassword}
                                            size="small"
                                            className={classes.alignItems}
                                            onClick={onDelete}
                                        />
                                    </Tooltip>
                                )}
                            </>
                        )}
                        {hasNoneSelected && onAdd && !isViewer && (
                            <Tooltip title={"Passwort zu " + title + " hinzufügen"}>
                                <IconButton
                                    data-testid={"btn-add-" + title}
                                    iconType={IconTypes.plus}
                                    size="small"
                                    className={classes.alignItems}
                                    onClick={onAdd}
                                />
                            </Tooltip>
                        )}
                    </Grid>
                </Grid>
            </div>
        </div>
    );
};

TitleToolbar.defaultProps = {
    selected: [],
    isViewer: false,
    countLabelSingular: "Passwort",
    countLabelPlural: "Passwörter",
    hideTitleInformationInMultiSelect: false,
};

TitleToolbar.propTypes = {
    title: PropTypes.string.isRequired,
    isViewer: PropTypes.bool,
    selected: PropTypes.array,
    passwordCount: PropTypes.number,
    setUnselectAll: PropTypes.func,
    setSelectAll: PropTypes.func,
    onAdd: PropTypes.func,
    onConvert: PropTypes.func,
    onDelete: PropTypes.func,
    onArchive: PropTypes.func,
    countLabelSingular: PropTypes.string,
    countLabelPlural: PropTypes.string,
    hideTitleInformationInMultiSelect: PropTypes.bool,
};

export default TitleToolbar;
