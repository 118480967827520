import React from "react";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { guid, isGuidEmpty } from "../../managers/guidManager";
import { useGroupTreeQuery } from "../../hooks/queries/groupQueryHooks";
import Icon from "../common/dataDisplay/Icon";
import { IconTypes } from "../../types/IconType";
import useTranslation from "../common/translation/translation";

const useStyles = makeStyles((theme) => ({
    subbar: {
        padding: "0.3rem 1rem",
        backgroundColor: "white",
        justifyContent: "space-between",
        borderTop: "2px solid #a0a0a080",
    },
    breadcrumb: {
        display: "flex",
        alignItems: "center",
    },
    link: {
        fontSize: "1.2em",
        margin: "0px 10px",
        color: theme.colors.textLight,
    },
    linkContainer: {
        fontWeight: "500",
        padding: "6px 0px",
        "& li:last-child": {
            fontWeight: "600",
        },
    },
    icon: {
        padding: "4px",
    },
}));

function treeToArray(tree) {
    return tree.reduce(function (acc, o) {
        acc.push({ ...o });
        if (o.Children) acc = acc.concat(treeToArray(o.Children));
        return acc;
    }, []);
}

function findNodePathWithId(treeNodes, id) {
    if (treeNodes.length === 0) return [];
    if (isGuidEmpty(id)) return [];
    const nodePathArray = [];
    const arrayOfNodes = treeToArray(treeNodes);

    let currentId = id;
    nodePathArray.push(arrayOfNodes.find((a) => a.Id === id));

    while (!treeNodes.some((a) => a.Id === currentId)) {
        arrayOfNodes.forEach((node) => {
            if (node.Children && node.Children.some((c) => c.Id === currentId)) {
                nodePathArray.push(node);
                currentId = node.Id;
            }
        });
    }

    return nodePathArray;
}

const GroupBreadcrumb = ({ currentGroupId, onClick, onClickCurrentGroup }) => {
    const classes = useStyles();
    const { treeNodes } = useGroupTreeQuery();
    const { i18n } = useTranslation();

    return (
        <Grid container className={classes.subbar}>
            <Grid>
                <Grid container justifyContent="center">
                    <Grid className={classes.breadcrumb}>
                        <Breadcrumbs className={classes.linkContainer} aria-label="breadcrumb">
                            <Link className={classes.link} color="inherit" onClick={() => onClick(guid.empty)} href="#">
                                {i18n("groups.groupManagement")}
                            </Link>
                            {findNodePathWithId(treeNodes, currentGroupId)
                                .reverse()
                                .map((n) => (
                                    <Link
                                        className={classes.link}
                                        key={n.Name}
                                        color="inherit"
                                        onClick={(e) => (n.Id === currentGroupId ? onClickCurrentGroup(e) : onClick(n.Id))}
                                        href="#"
                                    >
                                        {n.Name}
                                        {n.Id === currentGroupId && !isGuidEmpty(currentGroupId) && (
                                            <Icon className={classes.icon} iconType={IconTypes.caretDown} />
                                        )}
                                    </Link>
                                ))}
                        </Breadcrumbs>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

GroupBreadcrumb.propTypes = {
    currentGroupId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    onClickCurrentGroup: PropTypes.func.isRequired,
};

export default GroupBreadcrumb;
