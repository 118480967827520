import { gql, useQuery } from "@apollo/client";
import { addEntry, deleteEntry } from "../../managers/cacheManager";
import {
    useAddGroupPasswordMutation,
    useDeleteGroupPasswordMutation,
    useGetGroupPasswordLazyQuery,
    SharedPasswordFieldsFragmentDoc,
} from "../../graphql/generated";

export const useSharedPasswordStringQuery = (passwordId, publicKeyId = null) => {
    const GET_ENCRYPTED_STRING_FOR_SHARED_PASSWORD = gql`
        query EncryptedCompanyPasswordString($passwordId: Guid!, $publicKeyId: Guid) {
            encryptedSharedPasswordString(passwordId: $passwordId, publicKeyId: $publicKeyId)
        }
    `;

    const { loading, error, data } = useQuery(GET_ENCRYPTED_STRING_FOR_SHARED_PASSWORD, {
        variables: { passwordId: passwordId, publicKeyId: publicKeyId },
    });

    let encryptedSharedPasswordString =
        data && data.encryptedSharedPasswordString && data.encryptedSharedPasswordString.length > 0 ? data.encryptedSharedPasswordString : "";
    return { encryptedSharedPasswordString, loading, error };
};

export const useLazySharedPasswordsByGroupIdsQuery = () => {
    const [getSharedPasswordsByGroupIds, { loading, error, data }] = useGetGroupPasswordLazyQuery({
        fetchPolicy: "cache-and-network",
    });
    const sharedPasswords = data?.sharedPasswords || [];
    return { getSharedPasswordsByGroupIds, loading, error, sharedPasswords };
};

export const useSharedPasswordAddMutation = () => {
    const [addSharedPassword] = useAddGroupPasswordMutation({
        update(
            cache,
            {
                data: {
                    sharedPassword: { add: newPassword },
                },
            }
        ) {
            cache.modify({
                fields: {
                    sharedPasswords(pasRefs) {
                        return addEntry(cache, pasRefs, SharedPasswordFieldsFragmentDoc, newPassword);
                    },
                },
                broadcast: false,
            });
        },
    });

    return { addSharedPassword };
};

export const useSharedPasswordDeleteMutation = () => {
    const [deletePassword] = useDeleteGroupPasswordMutation({
        update(
            cache,
            {
                data: {
                    sharedPassword: { delete: deletedPassword },
                },
            }
        ) {
            cache.modify({
                fields: {
                    sharedPasswords(refs, { readField }) {
                        return deleteEntry(refs, readField, deletedPassword.id);
                    },
                },
            });
        },
    });

    return { deletePassword };
};
