import React from "react";
import { Card, CardContent, Grid, ListItemText, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useIsMobile } from "../../hooks/mobileHook";
import PropTypes from "prop-types";
import Icon from "../common/dataDisplay/Icon";

const useStyles = makeStyles((theme) => ({
    vaultCard: {
        cursor: (props) => (props.clickable ? "pointer" : "default"),
        margin: "1.5em 1em",

        "&:hover": {
            boxShadow: (props) => (props.clickable ? theme.shadows[5] : ""),
        },
    },
    vaultCardContent: {
        display: "flex",
        flexDirection: (props) => (props.isMobile ? "column" : "row"),
        padding: "1rem !important",
    },
    vaultCardIconContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        textAlign: "center",
        padding: "1rem",
        marginTop: (props) => (props.isIconTop ? "1rem" : "0"),
        marginRight: (props) => (props.isIconTop ? "0" : "1rem"),
    },
    icon: {
        width: (props) => (props.isIconTop ? "100%" : "1.5em"),
        maxWidth: "12rem",
        fontSize: (props) => (props.isIconTop ? "3rem" : "1.5rem"),
    },
}));

function VaultCard({ children, title, subTitle, iconType, iconPosition, onClick, innerRef }) {
    const isMobile = useIsMobile();
    const isIconTop = iconPosition === "top";
    const classes = useStyles({ isMobile, clickable: Boolean(onClick), isIconTop });

    return (
        <Card ref={innerRef} className={classes.vaultCard} elevation={3} onClick={onClick}>
            <CardContent className={classes.vaultCardContent}>
                <Grid container wrap={isIconTop ? "wrap" : "nowrap"}>
                    {Boolean(iconType) && (
                        <Grid
                            item
                            xs={isIconTop ? 12 : 1}
                            container
                            alignItems="center"
                            justifyContent="center"
                            className={classes.vaultCardIconContainer}
                        >
                            <Icon colorType="action" iconType={iconType} className={classes.icon} />
                        </Grid>
                    )}
                    {!Boolean(children) && <ListItemText primary={title} secondary={subTitle} />}
                    {Boolean(children) && (
                        <Grid container item xs={isIconTop ? 12 : 11}>
                            <Grid item xs={12}>
                                <Typography variant="h4" component="h4" style={{ margin: "1em", marginTop: isIconTop ? ".5em" : "1.5em" }}>
                                    {title}
                                </Typography>
                                {subTitle && (
                                    <Typography variant="h5" component="h5" style={{ margin: "1em", fontSize: "1.2em" }}>
                                        {subTitle}
                                    </Typography>
                                )}
                            </Grid>
                            <Grid spacing={2} container item xs={12} style={{ margin: isMobile ? "0" : "1em" }}>
                                {children}
                            </Grid>
                        </Grid>
                    )}
                </Grid>
            </CardContent>
        </Card>
    );
}

VaultCard.defaultProps = {
    iconPosition: "top",
};

VaultCard.propTypes = {
    title: PropTypes.any,
    subTitle: PropTypes.any,
    children: PropTypes.node,
    iconType: PropTypes.string,
    iconPosition: PropTypes.oneOf(["top", "left"]),
    onClick: PropTypes.func,
};

export default VaultCard;
