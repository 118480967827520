import { Grid } from "@mui/material";
import { IconTypes } from "../../types/IconType";
import React from "react";
import PropTypes from "prop-types";
import { ImportTypes } from "./helper/ImportTypes";
import VaultCard from "../dashboard/VaultCard";
import useTranslation from "../common/translation/translation";

const StepChooseImportType = ({ handleNext, onSetSelected }) => {
    const { i18n } = useTranslation();

    const createSelection = (importType, iconType, heading, description) => {
        return (
            <Grid item xs={12} md={6} xl={3}>
                <VaultCard
                    onClick={() => {
                        onSetSelected(importType);
                        handleNext();
                    }}
                    iconType={iconType}
                    title={heading}
                    iconPosition="top"
                >
                    {description}
                </VaultCard>
            </Grid>
        );
    };

    return (
        <Grid container wrap="wrap" justifyContent="flex-start">
            {createSelection(ImportTypes.csv, IconTypes.csvFile, i18n("import.csv.title"), i18n("import.csv.subTitle"))}
            {createSelection(ImportTypes.lastpass, IconTypes.lastPass, i18n("import.lastPass.title"), i18n("import.lastPass.subTitle"))}
            {createSelection(ImportTypes.keepassCSV, IconTypes.keePass, i18n("import.keepass.title"), i18n("import.keepass.subTitle"))}
            {createSelection(ImportTypes.chrome, IconTypes.chrome, i18n("import.chrome.title"), i18n("import.chrome.subTitle"))}
        </Grid>
    );
};

StepChooseImportType.propTypes = {
    handleNext: PropTypes.func.isRequired,
    onSetSelected: PropTypes.func.isRequired,
};

export default StepChooseImportType;
