import React, { useMemo, useState } from "react";
import { Table, TableBody } from "@mui/material";
import Page from "../common/layout/Page";
import PasswordSearchBar from "../common/dataEntry/PasswordSearchBar";
import PasswordsContainer from "../common/passwordComponents/PasswordsContainer";
import AddPasswordModal from "../common/passwordComponents/AddPasswordModal";
import CustomSnackbar from "../common/feedback/CustomSnackbar";
import PasswordTableRow from "../common/passwordComponents/PasswordTableRow";
import SelectGroupModal from "./SelectGroupModal";
import { useFilterPassword, useSelectedPassword } from "../../hooks/passwordHook";
import { useContextMenu, useModalStatus } from "../../hooks/modalHook";
import PasswordModal from "../common/passwordComponents/PasswordModal";
import PasswordTableRowContextMenu from "../common/passwordComponents/PasswordTableRowContextMenu";
import ConfirmDeletePassword from "../common/passwordComponents/ConfirmDeletePassword";
import { useAllGroupsQuery } from "../../hooks/queries/groupQueryHooks";
import { KindOfPasswordTypes } from "../../types/PasswordType";
import { useSharedPasswordDeleteMutation } from "../../hooks/queries/sharedPasswordHook";
import ContentContainer from "../common/layout/ContentContainer";
import TitleToolbar from "../common/layout/TitleToolbar";
import { ClientFilter, defaultPasswordSearchTermFields } from "../../managers/filterManager";
import ConvertPasswordModal from "../common/convertPassword/ConvertPasswordModal";
import { useMyselfQuery } from "../../hooks/queries/userQueryHooks";
import { groupRole } from "../../managers/groupManager";
import PasswordHistoryModal from "../common/passwordComponents/history/PasswordHistoryModal";
import { OfflineStatusContext } from "../../contexts/OfflineStatusContext";
import AccessLogModal from "../common/passwordComponents/AccessLogModal";
import TableCaption from "../common/dataDisplay/TableCaption";
import { useGetGroupPasswordQuery } from "../../graphql/generated";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";
import useTranslation from "../common/translation/translation";
import NatoAlphabetPasswordModal from "../common/passwordComponents/NatoAlphabetPasswordModal";

function mergeGroupPasswordToObject(allGroups, passwordData, error) {
    const passwords = error || !passwordData ? [] : passwordData.sharedPasswords;
    const passwordMap = {};
    passwords.forEach((p) => {
        if (passwordMap[p.groupId]) {
            passwordMap[p.groupId].push(p);
        } else {
            passwordMap[p.groupId] = [p];
        }
    });

    return allGroups.map((g) => {
        return { ...g, passwords: passwordMap[g.id] || [] };
    });
}

function filterPasswordsInGroup(groups, searchTerm, tags) {
    const arr = groups.map((group) => {
        group.passwords = new ClientFilter(group.passwords)
            .byArchived(false)
            .bySearchTerm(defaultPasswordSearchTermFields, searchTerm)
            .byTags(tags)
            .build();
        return group;
    });
    arr.sort((g1, g2) => g1.name.localeCompare(g2.name));
    return arr;
}

const GroupPasswordsPage = () => {
    const offlineStatusContext = OfflineStatusContext.useContainer();
    const { i18n } = useTranslation();

    const [clickedPassword, setClickedPassword] = useState();
    const [isBulkOperation, setIsBulkOperation] = useState(false);

    const { searchTerm, setSearchTerm, tags, groups: groupsToFilter, setGroups: setGroupsToFilter } = useFilterPassword();
    const { getSelectedPasswords, setSelectPasswords, selectAll, unselectAll } = useSelectedPassword();
    const [currentMultiSelectionKey, setCurrentMultiSelectionKey] = useState("");

    const { error, data: allSharedPasswords } = useGetGroupPasswordQuery({
        variables: { groupIds: [] },
        fetchPolicy: "cache-and-network",
    });

    const { me, isSystemAdmin } = useMyselfQuery();
    const { groups: allGroups, loading } = useAllGroupsQuery({
        variables: { groupIds: [] },
        fetchPolicy: "cache-and-network",
    });

    const { modalState: addPasswordModalState, open: openAddPasswordModal, close: closeAddPasswordModal } = useModalStatus();
    const { modalState: chooseGroupModalState, open: openChooseGroupModal, close: closeChooseGroupModal } = useModalStatus();
    const { modalState: passwordModalState, open: openPasswordModal, close: closePasswordModal } = useModalStatus();
    const { modalState: accessLogModalState, open: openAccessLogModal, close: closeAccessLogModal } = useModalStatus();
    const { modalState: historyModalState, open: openHistoryModal, close: closeHistoryModal } = useModalStatus();

    const {
        modalState: confirmDeletePasswordModalState,
        open: openConfirmDeletePasswordModal,
        close: closeConfirmDeletePasswordModal,
    } = useModalStatus();

    const { modalState: convertModalState, open: openConvertModal, close: closeConvertModal } = useModalStatus();
    const {
        modalState: natoAlphabetPasswordModalState,
        open: openNatoAlphabetPasswordModal,
        close: closeNatoAlphabetPasswordModal,
    } = useModalStatus();

    const { anchorEl, open: openContextMenu, close: closeContextMenu } = useContextMenu();

    const { open: openSnackbar, close: closeSnackbar, modalOpen: isSnackbarOpen } = useModalStatus();
    const isGroupViewer = useMemo(() => {
        const currentUserGroup = me.usergroups.find((ug) => ug.groupId === currentMultiSelectionKey);
        return currentUserGroup?.groupRole === groupRole.viewer;
    }, [currentMultiSelectionKey]);

    const groupsFilteredByNames = new ClientFilter(allGroups).byIds(groupsToFilter).build();
    const groups = mergeGroupPasswordToObject(groupsFilteredByNames, allSharedPasswords, error);
    const filteredGroups = filterPasswordsInGroup(groups, searchTerm, tags);
    const { deletePassword } = useSharedPasswordDeleteMutation();

    const emptyTitle = me.admin ? i18n("password.first.title") : i18n("password.first.groupPassword");
    const emptyInformation = me.admin ? i18n("password.first.subTitle") : i18n("password.first.groupPasswordSubTitle");
    const empty = Boolean(groups.length === 0);
    const isInSearch = Boolean(searchTerm !== "");

    const targetPasswords = isBulkOperation ? getSelectedPasswords(currentMultiSelectionKey) : [clickedPassword];

    return (
        <>
            <Page
                emptyTitle={emptyTitle}
                emptyInformation={emptyInformation}
                empty={empty}
                inSearchOperation={isInSearch}
                onNoContentAction={me.admin ? openChooseGroupModal : undefined}
                loading={loading}
            >
                <PasswordSearchBar
                    passwordType={KindOfPasswordTypes.groupPasswords}
                    onAddPassword={offlineStatusContext.isOffline ? openAddPasswordModal : openChooseGroupModal}
                    onSearchTerm={setSearchTerm}
                    groups={groupsToFilter}
                    onGroups={setGroupsToFilter}
                />

                <ContentContainer>
                    <PageHeader>{i18n("navigation.groupPasswords")}</PageHeader>
                    <PageSubTitle>{i18n("dashboard.vaults.groupPasswordsDescription")}</PageSubTitle>

                    {filteredGroups.map((group) => {
                        const currentUserGroup = me.usergroups.find((ug) => ug.groupId === group.id);
                        if (!Boolean(currentUserGroup)) return <div key={group.id} />;
                        const isViewer = currentUserGroup.groupRole === groupRole.viewer;
                        const countOtherUserInGroup = group.countUsers;

                        return (
                            <div key={group.id}>
                                <TitleToolbar
                                    title={group.name}
                                    isViewer={isViewer}
                                    onAdd={() => {
                                        setCurrentMultiSelectionKey(group.id);
                                        openAddPasswordModal();
                                    }}
                                    passwordCount={group.passwords.length}
                                    selected={getSelectedPasswords(group.id)}
                                    setSelectAll={(_) => selectAll(group.id, group.passwords)}
                                    setUnselectAll={(_) => unselectAll(group.id)}
                                    onArchive={() => {
                                        setCurrentMultiSelectionKey(group.id);
                                        setIsBulkOperation(true);
                                        openConfirmDeletePasswordModal();
                                    }}
                                    onConvert={() => {
                                        setCurrentMultiSelectionKey(group.id);
                                        setIsBulkOperation(true);
                                        openConvertModal();
                                    }}
                                />
                                {group.passwords.length === 0 && (
                                    <PasswordsContainer>
                                        <Table size="small">
                                            <TableCaption
                                                text={
                                                    searchTerm !== ""
                                                        ? `In der Gruppe ${group.name} wurde kein passendes Passwort gefunden`
                                                        : `In der Gruppe ${group.name} befindet sich noch kein Passwort`
                                                }
                                            />
                                        </Table>
                                    </PasswordsContainer>
                                )}
                                {group.passwords.length > 0 && (
                                    <PasswordsContainer>
                                        <Table size="small">
                                            <TableCaption
                                                text={`Diese Passwörter sind für Sie, ${countOtherUserInGroup} Mitarbeiter und ${
                                                    isSystemAdmin ? "andere" : "alle"
                                                } Systemadministratoren sichtbar`}
                                            />
                                            <TableBody>
                                                {group.passwords.map((pw) => (
                                                    <PasswordTableRow
                                                        showMenuOptions
                                                        selectedPasswords={getSelectedPasswords(group.id)}
                                                        setSelectedPasswords={(passwords) => setSelectPasswords(group.id, passwords)}
                                                        onContextMenu={(e) => {
                                                            setCurrentMultiSelectionKey(group.id);
                                                            setClickedPassword(pw);
                                                            setIsBulkOperation(false);
                                                            openContextMenu(e);
                                                        }}
                                                        onRowClick={(_) => {
                                                            setCurrentMultiSelectionKey(group.id);
                                                            setClickedPassword(pw);
                                                            setIsBulkOperation(false);
                                                            openPasswordModal();
                                                        }}
                                                        password={pw}
                                                        key={pw.id}
                                                        isViewer={isViewer}
                                                    />
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </PasswordsContainer>
                                )}
                            </div>
                        );
                    })}
                </ContentContainer>
            </Page>

            {addPasswordModalState && (
                <AddPasswordModal
                    initialPasswordType={KindOfPasswordTypes.groupPasswords}
                    isOpen={addPasswordModalState}
                    onClose={() => {
                        closeAddPasswordModal();
                        closeChooseGroupModal();
                    }}
                    onOk={() => {
                        openSnackbar();
                        closeAddPasswordModal();
                        closeChooseGroupModal();
                    }}
                    groupId={currentMultiSelectionKey}
                />
            )}

            {passwordModalState && (
                <PasswordModal
                    onClose={closePasswordModal}
                    onOk={closePasswordModal}
                    password={clickedPassword}
                    passwordType={KindOfPasswordTypes.groupPasswords}
                    editable={!isGroupViewer}
                    disableTags
                />
            )}

            {chooseGroupModalState && (
                <SelectGroupModal
                    title="Neues Passwort hinzufügen"
                    isOpen
                    onClose={closeChooseGroupModal}
                    onSelected={(groupId) => {
                        setCurrentMultiSelectionKey(groupId);
                        openAddPasswordModal();
                    }}
                />
            )}

            {confirmDeletePasswordModalState && (
                <ConfirmDeletePassword
                    onOk={() => {
                        for (const password of targetPasswords) {
                            deletePassword({ variables: { id: password.id } });
                        }
                        unselectAll(currentMultiSelectionKey);
                        closeConfirmDeletePasswordModal();
                    }}
                    onClose={closeConfirmDeletePasswordModal}
                    passwords={targetPasswords}
                />
            )}

            {isSnackbarOpen && (
                <CustomSnackbar onClose={closeSnackbar} message="Passwort erfolgreich gespeichert" variant="success" withCloseButton />
            )}
            {accessLogModalState && <AccessLogModal logs={clickedPassword.accessLog} onClose={closeAccessLogModal} />}
            {historyModalState && <PasswordHistoryModal password={clickedPassword} onClose={closeHistoryModal} />}
            <PasswordTableRowContextMenu
                passwordType={KindOfPasswordTypes.groupPasswords}
                password={clickedPassword}
                anchorEl={anchorEl}
                onClose={closeContextMenu}
                onPasswordModal={openPasswordModal}
                onConfirmDeleteModal={openConfirmDeletePasswordModal}
                onAccessModal={openAccessLogModal}
                onMovePassword={openConvertModal}
                onHistoryModal={me.admin ? openHistoryModal : undefined}
                isViewer={isGroupViewer}
                onNatoAlphabetPassword={openNatoAlphabetPasswordModal}
            />
            {convertModalState && (
                <ConvertPasswordModal
                    preselectedPasswords={targetPasswords}
                    onClose={(_) => {
                        closeConvertModal();
                        unselectAll(currentMultiSelectionKey);
                    }}
                    fromGroupId={currentMultiSelectionKey}
                    fromType={KindOfPasswordTypes.groupPasswords}
                />
            )}
            {natoAlphabetPasswordModalState ? (
                <NatoAlphabetPasswordModal
                    onClose={closeNatoAlphabetPasswordModal}
                    passwordType={KindOfPasswordTypes.groupPasswords}
                    password={clickedPassword}
                />
            ) : undefined}
        </>
    );
};
export default GroupPasswordsPage;
