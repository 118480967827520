const localStorageKeys = Object.freeze({
    remember: "remember",

    serviceWorkerUpdateAvailable: "serviceWorkerUpdateAvailable",

    offlinePasswordsAsJson: "offlinePasswordsAsJson",

    apolloOfflineSchemaVersion: "apolloOfflineSchemaVersion",
});

/**
 *
 * @param {string} key
 * @param {any} value
 */
const set = (key, value) => localStorage.setItem(key, value);

/**
 *
 * @param {string} key
 */
const get = (key) => localStorage.getItem(key);

/**
 *
 * @param {string} key
 */
const remove = (key) => localStorage.removeItem(key);

/**
 *
 * @param {string} key
 * @param {boolean} state
 */
const setOrRemoveBoolean = (key, state) => {
    if (state) set(key, state);
    else remove(key);
};

/**
 *
 * @param {string} key
 */
const isSet = (key) => get(key) !== null;

export const localStorageManager = {
    clearAll: () => localStorage.clear(),

    isRemember: () => isSet(localStorageKeys.remember),
    setRemember: (shouldRemember) => setOrRemoveBoolean(localStorageKeys.remember, shouldRemember),

    isServiceWorkerUpdateAvailable: () => isSet(localStorageKeys.serviceWorkerUpdateAvailable),
    setServiceWorkerUpdateAvailable: (state) => setOrRemoveBoolean(localStorageKeys.serviceWorkerUpdateAvailable, state),

    saveOfflinePasswordsAsJson: (newPasswords) => set(localStorageKeys.offlinePasswordsAsJson, JSON.stringify(newPasswords)),
    loadOfflinePasswordsFromJson: () => {
        if (isSet(localStorageKeys.offlinePasswordsAsJson)) return JSON.parse(get(localStorageKeys.offlinePasswordsAsJson));
        else return [];
    },

    setApolloOfflineSchemaVersion: (value) => set(localStorageKeys.apolloOfflineSchemaVersion, value),
    getApolloOfflineSchemaVersion: () => get(localStorageKeys.apolloOfflineSchemaVersion),
};
