import React, { useEffect, useState } from "react";
import { useAutocompleteInput, useMaterialUIInput } from "./inputHook";
import { generatePassword } from "../managers/passwordGenerationManager";

export const useAddPassword = () => {
    const { value: image, onChange: setImage } = useMaterialUIInput("");
    const { value: user, onChange: setUser } = useAutocompleteInput("");
    const { value: name, onChange: setName } = useMaterialUIInput("");
    const { value: website, onChange: setWebsite } = useMaterialUIInput("");
    const { value: login, onChange: setLogin } = useMaterialUIInput("");
    const { value: password, onChange: setPassword, setValue: setPasswordValue } = useMaterialUIInput(generatePassword());
    const { value: tags, onChange: setTags } = useAutocompleteInput([]);
    const { value: description, onChange: setDescription } = useMaterialUIInput("");
    const { value: autoLogin, onChange: setAutoLogin } = useMaterialUIInput(false);
    const { value: triggerViolation, onChange: setTriggerViolation } = useMaterialUIInput(false);

    return {
        image,
        setImage,
        user,
        setUser,
        name,
        setName,
        website,
        setWebsite,
        login,
        setLogin,
        password,
        setPassword,
        setPasswordValue,
        tags,
        setTags,
        description,
        setDescription,
        autoLogin,
        setAutoLogin,
        triggerViolation,
        setTriggerViolation,
    };
};

export const useFilterPassword = () => {
    const [filter, setFilter] = useState({});
    const [filterActive, setFilterActive] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const { value: tags, onChange: setTags } = useAutocompleteInput([]);
    const [groups, setGroups] = useState([]);
    const [filterType, setFilterType] = useState("none");

    useEffect(() => {
        setFilter({ searchTerm, tags });
        if (searchTerm || tags.length > 0) setFilterActive(true);
        else setFilterActive(false);
    }, [searchTerm, tags]);

    return { filter, filterActive, searchTerm, setSearchTerm, groups, setGroups, tags, setTags, filterType, setFilterType };
};

export const useSelectedPassword = () => {
    const [selectedPasswords, setSelectedPasswords] = useState({});

    const unselectAll = (id) => {
        setSelectedPasswords({ ...selectedPasswords, [id]: [] });
    };

    const selectAll = (id, passwords) => {
        setSelectPasswords(id, passwords);
    };

    const getSelectedPasswords = (id) => {
        return selectedPasswords[id] || [];
    };

    const setSelectPasswords = (id, passwords) => {
        setSelectedPasswords({ ...selectedPasswords, [id]: passwords });
    };

    return { getSelectedPasswords, setSelectPasswords, unselectAll, selectAll };
};

export const useSelectElement = () => {
    const [selectedElements, setSelectedElements] = useState({});

    const unselectAll = (id) => {
        if (id) setSelectElements(id, []);
        else setSelectedElements({});
    };

    const selectAll = (id, elements) => {
        setSelectElements(id, elements);
    };

    const getSelectedElements = (id) => {
        return selectedElements[id] || [];
    };

    const setSelectElements = (id, elements) => {
        setSelectedElements({ ...selectedElements, [id]: elements });
    };

    const handleSelectElement = (id, element) => {
        let selectedCopy = [...getSelectedElements(id)];
        let index = selectedCopy.findIndex((o) => o["id"] === element["id"]);
        if (index === -1) selectedCopy.push(element);
        else selectedCopy.splice(index, 1);
        setSelectElements(id, selectedCopy);
    };

    return { getSelectedElements, setSelectElements, unselectAll, selectAll, handleSelectElement };
};
