import { gql, useApolloClient } from "@apollo/client";
import { fragments } from "../../managers/fragmentManager";
import { addEntry, deleteEntry } from "../../managers/cacheManager";
import {
    useAddOfflinePasswordMutation,
    useDeleteOfflinePasswordMutation,
    useGetOfflinePasswordLazyQuery,
    useGetOfflinePasswordQuery,
} from "../../graphql/generated";

export const useOfflinePasswordQuery = () => {
    const { loading, error, data } = useGetOfflinePasswordQuery({
        fetchPolicy: "cache-and-network",
    });

    let offlinePasswords = data?.offlinePasswords || [];
    return { offlinePasswords, loading, error };
};

export const useLazyOfflinePasswordQuery = () => {
    const [getOfflinePasswords, { loading, error, data }] = useGetOfflinePasswordLazyQuery({
        fetchPolicy: "network-only",
    });
    const offlinePasswords = data?.offlinePasswords || [];
    return { getOfflinePasswords, loading, error, offlinePasswords };
};

export const useLazyOfflinePasswordQueries = () => {
    const client = useApolloClient();

    const GET_OFFLINE_PASSWORD = gql`
        query OfflinePasswords($searchTerm: String!, $createdByIds: [Guid]) {
            offlinePasswords(searchTerm: $searchTerm, createdByIds: $createdByIds) {
                ...SelectSharedPassword
            }
        }
        ${fragments.query.SelectSharedPassword}
    `;

    const GET_OFFLINE_PASSWORD_WITH_ENCRYPTED_STRING = gql`
        query OfflinePasswords($searchTerm: String!, $createdByIds: [Guid]) {
            offlinePasswords(searchTerm: $searchTerm, createdByIds: $createdByIds) {
                encryptedString
                ...SelectSharedPassword
            }
        }
        ${fragments.query.SelectSharedPassword}
    `;

    /**
     *
     * @param {string} searchTerm
     * @param {Guid[]} createdByIds
     * @param {Boolean} includeEncryptedPasswordStrings
     * @returns {Promise<[SharedPasswordType|null|undefined]>}
     */
    const executeLoadOfflinePasswordQuery = async (searchTerm, createdByIds, includeEncryptedPasswordStrings = false) => {
        const { data } = await client.query({
            query: includeEncryptedPasswordStrings ? GET_OFFLINE_PASSWORD_WITH_ENCRYPTED_STRING : GET_OFFLINE_PASSWORD,
            variables: { searchTerm: searchTerm, createdByIds: createdByIds },
            fetchPolicy: includeEncryptedPasswordStrings ? "no-cache" : "cache-first",
        });
        return data.offlinePasswords;
    };

    /**
     *
     * @param {Boolean} includeEncryptedPasswordStrings
     * @returns {Promise<(SharedPasswordType|null|undefined)[]>}
     */
    const executeLoadAllOfflinePasswordsQuery = async (includeEncryptedPasswordStrings = false) =>
        await executeLoadOfflinePasswordQuery("", null, includeEncryptedPasswordStrings);

    return {
        executeLoadOfflinePasswordQuery,
        executeLoadAllOfflinePasswordsQuery,
    };
};

export const useOfflinePasswordAddMutation = () => {
    const [addOfflinePassword] = useAddOfflinePasswordMutation({
        update(
            cache,
            {
                data: {
                    sharedPassword: { offlineAdd: newPassword },
                },
            }
        ) {
            cache.modify({
                fields: {
                    offlinePasswords(pasRefs) {
                        return addEntry(cache, pasRefs, fragments.query.SelectSharedPassword, newPassword);
                    },
                },
                broadcast: false,
            });
        },
    });
    return { addOfflinePassword };
};

export const useOfflinePasswordDeleteMutation = () => {
    const [deleteOfflinePassword] = useDeleteOfflinePasswordMutation({
        update(
            cache,
            {
                data: {
                    sharedPassword: { delete: deletedPassword },
                },
            }
        ) {
            cache.modify({
                fields: {
                    offlinePasswords(refs, { readField }) {
                        return deleteEntry(refs, readField, deletedPassword.id);
                    },
                },
            });
        },
    });

    return { deleteOfflinePassword };
};
