import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Dialog, DialogActions, DialogContent, DialogTitle, Grid, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import CircularProgress from "@mui/material/CircularProgress";
import Icon from "../../dataDisplay/Icon";
import { IconTypes } from "../../../../types/IconType";
import IconButton from "../../dataDisplay/IconButton";
import { useIsMobile } from "../../../../hooks/mobileHook";
import LoadingButton from "../../dataDisplay/LoadingButton";
import useTranslation from "../../translation/translation";

const useStyles = makeStyles((theme) => ({
    dialog: {
        display: "flex",
        pointerEvents: (props) => (props.isHandlingOk ? "none" : "auto"),
        justifyContent: "center",
        alignItems: "center",
        paddingTop: ".5em !important",
    },
    lightVariantContentContainer: {
        color: theme.palette.text.secondary,
        fontSize: theme.typography.fontSize,
        marginBottom: theme.spacing(2),
    },
    titleBar: {
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "2px solid #c3c3c3",
        padding: (props) => (props.isMobile ? "8px 12px" : "16px 24px"),
    },
    titleIcon: {
        display: "flex",
        alignItems: "center",
        "& .icon": {
            width: "2rem",
            height: "2rem",
            marginRight: theme.spacing(1),
            display: "flex",
            alignItems: "center",
            "&:before": {
                fontSize: "2rem",
            },
        },
    },
    title: {
        display: "inline-flex",
    },
    bottomBar: {
        borderTop: "2px solid #c3c3c3",
        marginTop: "2rem",
        padding: (props) => (props.isMobile ? "8px 12px" : "16px 24px"),
    },
    exitButton: {
        float: "right",
        padding: (props) => (props.isMobile ? 0 : 12),
    },
    successButtonContainer: {
        position: "relative",
    },
    loadingContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flex: "1",
    },
    errorWrapper: {
        "& span": {
            width: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            fontSize: "3rem",
            margin: "1rem 0",
        },
    },
    errorMessage: {
        fontSize: "2rem",
        textAlign: "center",
    },
}));

const CustomModal = ({
    open,
    title,
    onOk,
    onClose,
    children,
    withCancelButton,
    okButtonEnabled,
    saveText,
    cancelText,
    maxWidth,
    loading,
    error,
    errorMessage,
    closeableOnlyWithDialogActionButtons,
    icon,
    variant,
}) => {
    const isLightVariant = variant === "light";
    const isMobile = useIsMobile();
    const [isHandlingOk, setIsHandlingOk] = useState(false);
    const classes = useStyles({ isMobile: isMobile, isHandlingOk: isHandlingOk, isLightVariant: isLightVariant });
    const { i18n } = useTranslation();

    useEffect(() => {
        if (error || Boolean(errorMessage)) {
            setIsHandlingOk(false);
        }
    }, [error, errorMessage]);

    const handleOnOk = () => {
        if (!onOk) return;

        setIsHandlingOk(true);
        onOk();
    };

    const localClosableOnlyWithDialogActionButtons = closeableOnlyWithDialogActionButtons || isHandlingOk || isLightVariant;

    return (
        <Dialog
            open={open}
            onClose={localClosableOnlyWithDialogActionButtons ? undefined : onClose}
            fullWidth
            maxWidth={maxWidth}
            fullScreen={isMobile}
        >
            {!(isLightVariant && !Boolean(title)) && (
                <DialogTitle disableTypography className={isLightVariant ? "" : classes.titleBar}>
                    <Typography variant="h6" className={classes.title}>
                        {icon && <div className={classes.titleIcon}>{icon}</div>}
                        {title}
                    </Typography>
                    {!localClosableOnlyWithDialogActionButtons && (
                        <IconButton onClick={onClose} iconType={IconTypes.times} className={classes.exitButton} />
                    )}
                </DialogTitle>
            )}
            <DialogContent className={classes.dialog}>
                <div className={classes.loadingContainer}>
                    {loading && !error && (
                        <div>
                            <CircularProgress />
                        </div>
                    )}
                    {error && (
                        <div className={classes.errorWrapper}>
                            <Icon iconType={IconTypes.exclamationCircle} />
                            <div className={classes.errorMessage}>{errorMessage || i18n("general.error.unexpected")}</div>
                        </div>
                    )}
                    {!loading && !error && !isLightVariant && children}
                    {!loading && !error && isLightVariant && <div className={classes.lightVariantContentContainer}>{children}</div>}
                </div>
            </DialogContent>
            <DialogActions className={isLightVariant ? "" : classes.bottomBar}>
                <Grid container spacing={2} justifyContent="end">
                    {error && (
                        <Grid item>
                            <Button onClick={onClose} color="secondary" variant="contained">
                                Schließen
                            </Button>
                        </Grid>
                    )}
                    {withCancelButton && !error && (
                        <Grid item>
                            <Button onClick={onClose} color="secondary" variant="contained" disabled={isHandlingOk || loading || error}>
                                {cancelText || i18n("general.actions.cancel")}
                            </Button>
                        </Grid>
                    )}
                    <Grid item>
                        <div className={classes.successButtonContainer}>
                            <LoadingButton
                                autoFocus
                                onClick={handleOnOk}
                                color="primary"
                                variant="contained"
                                disabled={loading || error || !okButtonEnabled}
                                loading={isHandlingOk && !error}
                            >
                                {saveText}
                            </LoadingButton>
                        </div>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    );
};

CustomModal.defaultProps = {
    open: true,
    withCancelButton: true,
    cancelText: undefined,
    okButtonEnabled: true,
    maxWidth: "md",
    errorMessage: undefined,
    closeableOnlyWithDialogActionButtons: false,
    variant: "normal",
};

CustomModal.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    onOk: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    withCancelButton: PropTypes.bool,
    saveText: PropTypes.string.isRequired,
    cancelText: PropTypes.string,
    okButtonEnabled: PropTypes.bool,
    maxWidth: PropTypes.oneOf(["lg", "md", "sm", "xl", "xs"]),
    loading: PropTypes.bool.isRequired,
    error: PropTypes.bool.isRequired,
    errorMessage: PropTypes.string,
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    closeableOnlyWithDialogActionButtons: PropTypes.bool,
    icon: PropTypes.node,
    variant: PropTypes.oneOf(["normal", "light"]),
};

export default CustomModal;
