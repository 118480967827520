import React from "react";
import { makeStyles } from "@mui/styles";
import TreeItem from "@mui/lab/TreeItem";
import Typography from "@mui/material/Typography";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    root: {
        color: theme.palette.text.secondary,
        "&:hover > $content": {
            backgroundColor: theme.palette.action.hover,
        },
        "&:focus > $content, &$selected > $content": {
            backgroundColor: theme.palette.grey[200],
            color: "var(--tree-view-color)",
        },
        "&:focus > $content $label, &:hover > $content $label, &$selected > $content $label": {
            backgroundColor: "transparent",
        },
        "& .MuiTreeItem-iconContainer": {
            marginLeft: "1.5rem",
        },
    },
    content: {
        color: theme.palette.text.secondary,
        borderTopRightRadius: theme.spacing(2),
        borderBottomRightRadius: theme.spacing(2),
        paddingRight: theme.spacing(1),
        fontWeight: theme.typography.fontWeightMedium,
        "$expanded > &": {
            fontWeight: theme.typography.fontWeightRegular,
        },
    },
    expanded: {},
    selected: {},
    disabledContent: {
        "&:hover > $content": {
            backgroundColor: "transparent !important",
        },
        "& .icon": {
            color: "#00000038",
        },
    },
    disabledSelected: {
        "& .MuiTreeItem-label": {
            backgroundColor: "transparent !important",
        },
    },
    label: {
        fontWeight: "inherit",
        color: "inherit",
    },
    labelRoot: {
        display: "flex",
        alignItems: "center",
        padding: theme.spacing(0.5, 0),
    },
    labelIcon: {
        marginRight: theme.spacing(1),
    },
    labelText: {
        fontWeight: "inherit",
        flexGrow: 1,
    },
}));

const GroupTreeItem = ({ labelText, labelInfo, color, bgColor, children, nodeId, onClick, icon, disabled }) => {
    const classes = useStyles();

    const handleClick = () => {
        if (!disabled) onClick(nodeId);
    };

    return (
        <TreeItem
            nodeId={nodeId}
            label={
                <div onClick={handleClick} className={classes.labelRoot}>
                    <Typography variant="caption" className={classes.labelText}>
                        {labelText}
                    </Typography>
                    <Typography variant="caption" color="inherit">
                        {labelInfo}
                    </Typography>
                </div>
            }
            style={{
                "--tree-view-color": color,
                "--tree-view-bg-color": bgColor,
            }}
            classes={{
                root: classes.root,
                content: disabled ? classes.disabledContent : classes.content,
                expanded: classes.expanded,
                selected: disabled ? classes.disabledSelected : classes.selected,
                group: classes.group,
                label: classes.label,
            }}
            icon={icon}
        >
            {children}
        </TreeItem>
    );
};

GroupTreeItem.defaultProps = {
    color: "#1a73e8",
    bgColor: "#e8f0fe",
    disabled: false,
};

GroupTreeItem.propTypes = {
    labelText: PropTypes.node.isRequired,
    nodeId: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    labelInfo: PropTypes.string,
    color: PropTypes.string,
    bgColor: PropTypes.string,
    icon: PropTypes.node,
    disabled: PropTypes.bool,
};

export default GroupTreeItem;
