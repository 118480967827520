import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomModal from "../common/feedback/modals/CustomModal";
import GroupTree from "../common/dataEntry/tree/GroupTree";
import { useGroupTreeByRoleQuery } from "../../hooks/queries/groupQueryHooks";
import GroupAddModal from "../groups/modals/AddGroupModal";
import { Button, Grid } from "@mui/material";
import { useModalStatus } from "../../hooks/modalHook";
import { useMyselfQuery } from "../../hooks/queries/userQueryHooks";
import { groupRole } from "../../managers/groupManager";

const SelectGroupModal = ({ onClose, onSelected, title }) => {
    const [groupId, setGroupId] = useState("");
    const { loading, error, me } = useMyselfQuery();
    const { modalState: groupAddModalState, open: openGroupAddModal, close: closeGroupAddModal } = useModalStatus();
    const { treeNodes, loading: loadingTreeNodes, error: treeNodesError } = useGroupTreeByRoleQuery(groupRole.editor);

    if (treeNodes.length === 0) {
        return (
            <>
                <CustomModal
                    maxWidth="sm"
                    onClose={onClose}
                    title="Keine Gruppen vorhanden"
                    saveText="Schließen"
                    withCancelButton={false}
                    okButtonEnabled
                    error={Boolean(treeNodesError) || Boolean(error)}
                    loading={Boolean(loadingTreeNodes) || Boolean(loading)}
                    onOk={onClose}
                >
                    <Grid container>
                        <Grid item xs={12}>
                            {me.admin ? (
                                <h2>Bitte erstellen Sie vorher Gruppen um fortzufahren.</h2>
                            ) : (
                                <>
                                    <h2>Derzeit existiert keine Gruppe in der ein neues Gruppenpasswort erstellt werden kann.</h2>
                                    <p>
                                        Leider besitzen Sie nicht die Rechte eine Gruppe zu erstellen. Bitte wenden Sie sich an einen Administrator
                                        der eine Gruppe für Sie zu erstellen kann.
                                    </p>
                                </>
                            )}
                        </Grid>
                        <Grid item xs={12} hidden={!me.admin}>
                            <Button variant="contained" onClick={openGroupAddModal}>
                                Erstellen Sie Ihre erste Gruppe
                            </Button>
                        </Grid>
                    </Grid>
                </CustomModal>
                {groupAddModalState && (
                    <GroupAddModal
                        onClose={() => {
                            closeGroupAddModal();
                            onClose();
                        }}
                    />
                )}
            </>
        );
    }

    return (
        <CustomModal
            maxWidth="sm"
            onClose={onClose}
            onOk={() => onSelected(groupId)}
            title={title}
            saveText="Auswählen"
            withCancelButton
            cancelText="Abbrechen"
            okButtonEnabled={groupId !== ""}
            error={treeNodesError || false}
            loading={loadingTreeNodes || false}
        >
            <GroupTree withoutRoot currentGroupId={groupId} onChange={(node) => setGroupId(node.Id)} requiredRole={groupRole.editor} />
        </CustomModal>
    );
};

SelectGroupModal.propTypes = {
    onClose: PropTypes.func,
    onSelected: PropTypes.func,
    title: PropTypes.string,
};

export default SelectGroupModal;
