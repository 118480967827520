import React, { useState } from "react";
import Page from "../common/layout/Page";
import { useDeleteMandator } from "../../hooks/queries/mandatorHook";
import MandatorSelect from "../common/dataEntry/select/MandatorSelect";
import { IconTypesList } from "../../types/IconType";
import { useModalStatus } from "../../hooks/modalHook";
import { extractApolloErrorMessage } from "../../managers/errorManager";
import CustomSnackbar from "../common/feedback/CustomSnackbar";
import ContentContainer from "../common/layout/ContentContainer";
import { Button, Grid } from "@mui/material";
import Icon from "../common/dataDisplay/Icon";
import ConfirmationDialog from "../common/feedback/modals/ConfirmationDialog";
import { ConfirmDialogVariantList } from "../../types/ConfirmDialogVariant";
import { PageTypeList } from "../../types/PageType";
import { useAutocompleteInput } from "../../hooks/inputHook";

const ManageMandatorsPage = () => {
    const [errorMessage, setErrorMessage] = useState("");
    const { value: selectedMandator, onChange: setSelectedMandator } = useAutocompleteInput();
    const {
        modalState: confirmDeleteMandatorModalState,
        open: openConfirmDeleteMandatorModal,
        close: closeConfirmDeleteMandatorModal,
    } = useModalStatus();

    const { deleteMandator } = useDeleteMandator();

    return (
        <Page currentPageType={PageTypeList.superAdminConsole}>
            <ContentContainer>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <h1>Löschen eines Mandators</h1>
                    </Grid>
                    <Grid item xs={12} md={6} lg={3}>
                        <MandatorSelect onChange={setSelectedMandator} value={selectedMandator} />
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            startIcon={<Icon iconType={IconTypesList.deletePassword} />}
                            onClick={openConfirmDeleteMandatorModal}
                            variant="contained"
                            disabled={!Boolean(selectedMandator)}
                        >
                            Mandator löschen
                        </Button>
                    </Grid>
                </Grid>
            </ContentContainer>

            {confirmDeleteMandatorModalState && (
                <ConfirmationDialog
                    onOk={() => {
                        deleteMandator({ variables: { id: selectedMandator.id } })
                            .then(closeConfirmDeleteMandatorModal)
                            .catch((e) => setErrorMessage(extractApolloErrorMessage(e)));
                    }}
                    onClose={closeConfirmDeleteMandatorModal}
                    name={selectedMandator.name}
                    okText="Löschen"
                    cancelText="Abbrechen"
                    open
                    title={`Löschen des Mandators ${selectedMandator.name}`}
                    variant={ConfirmDialogVariantList.error}
                    description="Sind Sie sicher, dass Sie diesen Mandator löschen möchten? Dabei werden alle Benutzer, Passwörter und andere dazugehörigen Daten gelöscht!"
                />
            )}
            <CustomSnackbar
                open={Boolean(errorMessage)}
                message={errorMessage}
                onClose={() => setErrorMessage("")}
                variant="error"
                withSlideTransition
            />
        </Page>
    );
};

export default ManageMandatorsPage;
