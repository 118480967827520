import PropTypes from "prop-types";

export const ImportTypes = Object.freeze({
    csv: "csv",
    lastpass: "lastpass",
    keepassCSV: "keepassCSV",
    chrome: "chrome",
});

export const ImportTypesType = PropTypes.oneOf(Object.keys(ImportTypes));
