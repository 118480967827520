import { oneOf } from "prop-types";

export const IconTypesList = {
    folder: "fas fa-folder",
    folderOutline: "far fa-folder",
    plus: "fa fa-plus",
    image: "fa fa-image",
    visibleEye: "fa fa-eye",
    invisibleClosedEye: "fa fa-eye-slash",
    chevronRight: "fa fa-chevron-right",
    chevronLeft: "fas fa-chevron-left",
    bars: "fas fa-bars",
    caretDown: "fa fa-caret-down",
    signIn: "fas fa-sign-in-alt",
    exclamationCircle: "fas fa-exclamation-circle",
    timesCircle: "fa fa-times-circle",
    times: "fas fa-times",
    lock: "fas fa-lock",
    sharedPassword: "fas fa-folder",
    userLock: "fas fa-user-lock",
    moreOptions: "fas fa-ellipsis-v",
    externalLink: "fas fa-external-link-alt",
    mail: "fas fa-envelope",
    user: "fas fa-user",
    copy: "far fa-copy",
    play: "fas fa-play-circle",
    signal: "fas fa-signal",
    support: "fas fa-headset",
    check: "fa fa-check",
    /* drawer */
    dashboard: "fas fa-home",
    users: "fas fa-users",
    tags: "fas fa-tags",
    userEdit: "fas fa-user-edit",
    chartLine: "fas fa-chart-line",
    fileArchive: "fas fa-archive",
    tools: "fas fa-tools",
    money: "fas fa-money-bill-wave",
    userSettings: "fa fa-users-cog",
    signOut: "fas fa-sign-out-alt",
    home: "fas fa-home",
    retweet: "fas fa-retweet",
    secret: "fas fa-user-secret",
    groups: "fas fa-network-wired",
    allPasswords: "fas fa-globe",
    /* Password */
    editPassword: "fa fa-edit",
    editPasswordPen: "fas fa-pen",
    copyPassword: "fas fa-key",
    deletePassword: "fas fa-trash",
    import: "fas fa-file-import",
    export: "fa fa-file-export",
    history: "fas fa-history",
    /* Password Overview */
    listView: "fas fa-th-list",
    columnView: "fas fa-th",
    /* group modal */
    removeUser: "fas fa-user-slash",
    addUser: "fas fa-user-plus",
    userShield: "fas fa-user-shield",
    editUser: "fas fa-user-edit",
    bookOpen: "fas fa-book-open",
    cog: "fas fa-cog",
    expandArrows: "fas fa-expand-arrows-alt",
    /* info, warning, error circles */
    circleInfo: "fas fa-info-circle",
    warning: "fas fa-exclamation",
    circleWarning: "fas fa-exclamation-circle",
    circleError: "fas fa-exclamation-circle",
    /* import */
    csvFile: "fas fa-file-csv",
    arrowRight: "fas fa-arrow-right",
    chrome: "fab fa-chrome",
    /* custom icons */
    unarchive: "custom_unarchive",
    lastPass: "custom_lastpass",
    keePass: "custom_keepass",
    muiPhoneInstall: "mui_phone_install",
    muiExtensionInstall: "mui_extension_install",
};
export const IconTypes = Object.freeze(IconTypesList);
export const IconType = oneOf(Object.values(IconTypesList));
