import CustomModal from "../common/feedback/modals/CustomModal";
import { Grid, TextField, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { LoginContext } from "../../contexts/LoginContext";
import { useMaterialUIInput } from "../../hooks/inputHook";
import ConfirmationDialog from "../common/feedback/modals/ConfirmationDialog";
import { useModalStatus } from "../../hooks/modalHook";
import { ConfirmDialogVariantList } from "../../types/ConfirmDialogVariant";

function ForgotPasswordModal({ onClose }) {
    const loginContext = LoginContext.useContainer();

    const { value: email, onChange: setValue } = useMaterialUIInput("");
    const { modalState: errorModalState, open: openErrorModal } = useModalStatus();
    const { modalState: successModalState, open: openSuccessModal } = useModalStatus();

    const sendPasswordForgotMail = () => {
        loginContext.passwordReset(email).then(openSuccessModal).catch(openErrorModal);
    };

    return (
        <>
            <CustomModal
                title=""
                error={false}
                loading={false}
                maxWidth="sm"
                variant="light"
                okButtonEnabled={Boolean(email)}
                onClose={onClose}
                saveText="Passwort zurücksetzten"
                onOk={sendPasswordForgotMail}
            >
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography component="h1" variant="h5">
                            Geben Sie Ihre E-Mail ein
                        </Typography>
                        <Typography>
                            Sie können Ihr Passwort nur zurücksetzten, wenn Sie die Funktion "Passwort zurücksetzten" eingerichtet haben. Wir schicken
                            Ihnen eine E-Mail, mit der Sie Ihr Passwort zurücksetzten können.
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TextField fullWidth label="E-Mail" variant="outlined" value={email} onChange={setValue} />
                    </Grid>
                </Grid>
            </CustomModal>

            {errorModalState && (
                <ConfirmationDialog
                    variant={ConfirmDialogVariantList.error}
                    onClose={onClose}
                    onOk={onClose}
                    title="Ein Fehler ist aufgetreten"
                    description="Es war nicht möglich die E-Mail zum Passwort zurücksetzen zu versenden. Bitte überprüfen Sie, ob die eingegebene E-Mail korrekt ist."
                />
            )}
            {successModalState && (
                <ConfirmationDialog
                    onClose={onClose}
                    onOk={onClose}
                    title="Erfolg"
                    description="Die E-Mail wurde erfolgreich versendet. Bitte klicken Sie auf den Link in der E-Mail, um Ihr Passwort zurückzusetzen."
                />
            )}
        </>
    );
}

ForgotPasswordModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default ForgotPasswordModal;
