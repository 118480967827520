import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    tableCaption: {
        border: "1px solid rgb(224, 224, 224)",
        marginTop: "-1px",
        backgroundColor: "#fff",
    },
}));

function TableCaption({ text, visible }) {
    const classes = useStyles();
    if (!visible) return null;

    return <caption className={classes.tableCaption}>{text}</caption>;
}

TableCaption.defaultProps = {
    visible: true,
};

TableCaption.propTypes = {
    visible: PropTypes.bool,
    text: PropTypes.string.isRequired,
};

export default TableCaption;
