import ConfirmationDialog from "../feedback/modals/ConfirmationDialog";
import React from "react";
import useTranslation from "../translation/translation";

interface ConfirmNotSavePasswordDialogProps {
    onCancel: () => void;
    onClose: () => void;
    open: boolean;
}

export default function ConfirmNotSavePasswordDialog({ onClose, onCancel, open }: ConfirmNotSavePasswordDialogProps) {
    const { i18n } = useTranslation();

    if (!open) return null;

    return (
        <ConfirmationDialog
            cancelText={i18n("password.notSaved.cancelText")}
            okText={i18n("password.notSaved.okText")}
            title={i18n("password.notSaved.title")}
            description=""
            onOk={onCancel}
            onClose={onClose}
        />
    );
}
