import React, { useEffect, useState } from "react";
import CheckboxTree from "react-checkbox-tree";
import { useGroupTreeByRoleQuery } from "../../../../hooks/queries/groupQueryHooks";
import PropTypes from "prop-types";
import { groupRole, groupRoleType } from "../../../../managers/groupManager";
import { Typography } from "@mui/material";
import useTranslation from "../../translation/translation";

const getDefaultExpand = (treeNodes) => {
    let defaultExpand = [];
    if (treeNodes && Array.isArray(treeNodes))
        for (let node of treeNodes) {
            defaultExpand.push(node.Id);
            if (node.Children && Array.isArray(node.Children) && node.Children.length) {
                defaultExpand.push({ ...mapIdToValue(node.Children) });
            }
        }
    return defaultExpand;
};

const mapIdToValue = (treeNodes) => {
    let newTree = [];
    if (treeNodes && Array.isArray(treeNodes))
        for (let node of treeNodes) {
            const values = { value: node.Id, label: node.Name };
            if (node.Children && Array.isArray(node.Children) && node.Children.length) {
                values.children = mapIdToValue(node.Children);
            }
            newTree.push(values);
        }

    return newTree;
};

const GroupFilter = ({ checked, setChecked, minimumGroupRole, showNoDataFoundMessage }) => {
    const { treeNodes, loading, error } = useGroupTreeByRoleQuery(minimumGroupRole);
    const [expanded, setExpanded] = useState();
    const { i18n } = useTranslation();

    useEffect(() => {
        if (treeNodes && treeNodes.length) setExpanded(getDefaultExpand(treeNodes));
    }, [JSON.stringify(treeNodes)]);

    if (!loading && !error && treeNodes.length === 0 && showNoDataFoundMessage) {
        return <Typography>{i18n("filter.groups.notFound")}</Typography>;
    }

    return (
        <CheckboxTree
            iconsClass="fa5"
            showNodeIcon={false}
            nodes={mapIdToValue(treeNodes)}
            checked={checked}
            expanded={expanded}
            onCheck={setChecked}
            onExpand={setExpanded}
            noCascade
            icons={{
                expandOpen: <span className="fas fa-caret-up" />,
                expandClose: <span className="fas fa-caret-down" />,
                parentClose: <span className="fas fa-folder" />,
                parentOpen: <span className="fas fa-folder-open" />,
                leaf: <span className="fas fa-chevron-right" />,
            }}
        />
    );
};

GroupFilter.defaultProps = {
    minimumGroupRole: groupRole.viewer,
};

GroupFilter.propTypes = {
    checked: PropTypes.array.isRequired,
    setChecked: PropTypes.func.isRequired,
    minimumGroupRole: groupRoleType,
    showNoDataFoundMessage: PropTypes.bool,
};

export default GroupFilter;
