import React from "react";
import { menu } from "../../managers/pathManager";
import { LoginContext } from "../../contexts/LoginContext";
import ErrorInformation from "./ErrorInformation";
import VerticalContentPageContainer from "../common/layout/VerticalContentPageContainer";

const UnauthorizedPage = () => {
    const loginContext = LoginContext.useContainer();
    loginContext.setLoggedIn(false);

    return (
        <VerticalContentPageContainer hideDrawer>
            <ErrorInformation
                heading="Ihre Sitzung ist abgelaufen"
                description="Bitte melden Sie sich erneut an"
                actionButtonText="Zurück zum Login"
                actionButtonHref={menu.login.path}
            />
        </VerticalContentPageContainer>
    );
};

export default UnauthorizedPage;
