import { useModalStatus } from "../hooks/modalHook";
import { createContainer } from "../hooks/ContextHook";
import { useIsMobile } from "../hooks/mobileHook";
import { useEffect } from "react";

function useDrawer() {
    const isMobile = useIsMobile();
    let { modalState: drawerOpenClicked, close: closeDrawerClicked, open: openDrawerClicked } = useModalStatus(!isMobile);

    useEffect(() => {
        if (isMobile) closeDrawerClicked();
        else openDrawerClicked();
    }, [closeDrawerClicked, isMobile, openDrawerClicked]);

    const toggleDrawerClicked = () => {
        if (drawerOpenClicked) closeDrawerClicked();
        else openDrawerClicked();
    };

    return {
        drawerOpen: drawerOpenClicked,
        closeDrawer: closeDrawerClicked,
        openDrawer: openDrawerClicked,
        toggleDrawer: toggleDrawerClicked,
    };
}

export const DrawerContext = createContainer(useDrawer);
