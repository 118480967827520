import React from "react";
import PropTypes from "prop-types";
import { TextField } from "@mui/material";
import { useMaterialUIInput } from "../../../hooks/inputHook";
import CustomModal from "../feedback/modals/CustomModal";
import { PasswordType } from "../../../types/PasswordType";
import useTranslation from "../translation/translation";

const ConfirmDeletePassword = ({ onOk, onClose, passwords, isDelete }) => {
    const { value: input, onChange: setInput } = useMaterialUIInput("");
    const { i18n } = useTranslation();
    let submitText = isDelete ? i18n("password.archive.delete") : i18n("password.archive.archive");
    let content, correctInput, labelText, titleText;
    if (passwords.length === 1) {
        titleText = isDelete
            ? i18n("password.archive.deletePassword", { key: "name", value: passwords[0].name })
            : i18n("password.archive.archivePassword", { key: "name", value: passwords[0].name });
        content = isDelete ? <>{i18n("password.archive.deleteInformation")}</> : <>{i18n("password.archive.archiveInformation")}</>;
        correctInput = passwords[0].name;
        labelText = i18n("password.information.name");
    } else {
        titleText = isDelete
            ? i18n("password.archive.bulkDeleteTitle", { key: "count", value: passwords.length })
            : i18n("password.archive.bulkArchiveTitle", { key: "count", value: passwords.length });
        correctInput = isDelete
            ? i18n("password.archive.bulkDeleteInput", { key: "count", value: passwords.length })
            : i18n("password.archive.bulkArchiveInput", { key: "count", value: passwords.length });

        content = isDelete ? (
            <>
                {i18n("password.archive.bulkDeleteInformation")}
                <br />
                <em>{correctInput}</em>
            </>
        ) : (
            <>
                {i18n("password.archive.bulkArchiveInformation")}
                <br />
                <em>{correctInput}</em>
            </>
        );
        labelText = i18n("password.archive.verificationLabel");
    }

    return (
        <CustomModal
            open={true}
            loading={false}
            error={false}
            onClose={onClose}
            saveText={submitText}
            onOk={onOk}
            title={titleText}
            okButtonEnabled={input === correctInput}
            maxWidth="sm"
            variant="light"
        >
            <div>
                {content}
                <TextField autoFocus onChange={setInput} value={input} margin="dense" label={labelText} fullWidth />
            </div>
        </CustomModal>
    );
};

ConfirmDeletePassword.defaultTypes = {
    isDelete: false,
};

ConfirmDeletePassword.propTypes = {
    onOk: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    passwords: PropTypes.arrayOf(PasswordType).isRequired,
    isDelete: PropTypes.bool,
};

export default ConfirmDeletePassword;
