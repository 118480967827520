import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import CustomModal from "../common/feedback/modals/CustomModal";
import { Box, Button, Grid, MenuItem, Select, Step, StepContent, StepLabel, Stepper, TextField, Typography } from "@mui/material";
import PasswordInputField from "../common/dataEntry/input/PasswordInputField";
import { useMaterialUIInput } from "../../hooks/inputHook";
import { useMyselfQuery, useUserMutation } from "../../hooks/queries/userQueryHooks";
import { CryptoManager } from "../../managers/cryptoManager";
import useTranslation from "../common/translation/translation";

const getQuestions = (i18n) => [
    i18n("settings.passwordReset.q1"),
    i18n("settings.passwordReset.q2"),
    i18n("settings.passwordReset.q3"),
    i18n("settings.passwordReset.q4"),
    i18n("settings.passwordReset.q5"),
    i18n("settings.passwordReset.q6"),
    i18n("settings.passwordReset.q7"),
    i18n("settings.passwordReset.q8"),
    i18n("settings.passwordReset.q9"),
    i18n("settings.passwordReset.q10"),
    i18n("settings.passwordReset.q11"),
];

const QuestionSelect = ({ value, onChange }) => {
    const { i18n } = useTranslation();
    const questions = getQuestions(i18n);
    return (
        <Select variant="outlined" style={{ width: "100%", marginTop: "2em" }} value={value} onChange={onChange} label="Sicherheitsfragen">
            {questions.map((q) => (
                <MenuItem value={q} key={q}>
                    {q}
                </MenuItem>
            ))}
        </Select>
    );
};

function SetupPasswordResetModal({ onClose }) {
    const { editUser } = useUserMutation();

    const [step, setStep] = useState(0);
    const [errorMessage, setErrorMessage] = useState("");

    const [isLoginCorrect, setIsLoginCorrect] = useState(false);
    const { value: password, onChange: setPassword } = useMaterialUIInput("");

    const { i18n } = useTranslation();
    const questions = getQuestions(i18n);

    const { value: question1, onChange: setQuestion1 } = useMaterialUIInput(questions[0]);
    const { value: question2, onChange: setQuestion2 } = useMaterialUIInput(questions[1]);
    const { value: question3, onChange: setQuestion3 } = useMaterialUIInput(questions[2]);

    const { value: answer1, onChange: setAnswer1 } = useMaterialUIInput("");
    const { value: answer2, onChange: setAnswer2 } = useMaterialUIInput("");
    const { value: answer3, onChange: setAnswer3 } = useMaterialUIInput("");

    const { me } = useMyselfQuery();

    useEffect(() => {
        checkLoginPassword();
    }, [password]);

    const checkLoginPassword = async () => {
        const passwordHash = await CryptoManager.createPasswordHash(password);
        try {
            await CryptoManager.decryptUsingUserCredentials(me.keyPair.encryptedPrivateKey, passwordHash);
            setIsLoginCorrect(true);
        } catch (e) {
            //could not decrypt
            setIsLoginCorrect(false);
        }
    };

    const nextStep = () => {
        setStep(step + 1);
    };
    const prevStep = () => {
        setStep(step - 1);
    };

    const stepNavigation = (disableNext) => (
        <Box sx={{ mb: 2 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button disabled={step === 0 || step === 1} onClick={prevStep} sx={{ mt: 1, mr: 1 }}>
                        {i18n("general.actions.back")}
                    </Button>
                    {step !== 3 && (
                        <Button disabled={disableNext} variant="contained" onClick={nextStep} sx={{ mt: 1, mr: 1 }}>
                            {i18n("general.actions.next")}
                        </Button>
                    )}
                </Grid>
            </Grid>
        </Box>
    );

    const securityQuestionInformation = (
        <Grid item xs={12}>
            <Typography variant={"body1"}>{i18n("settings.passwordReset.information")}</Typography>
        </Grid>
    );

    const handleOnOk = async () => {
        try {
            const cryptoKey = answer1 + "" + answer2 + "" + answer3;
            const newLoginPasswordHash = await CryptoManager.createPasswordHash(cryptoKey);
            const encryptedLoginPassword = await CryptoManager.encryptUsingUserCredentials(password, newLoginPasswordHash);

            await editUser({
                variables: {
                    user: {
                        securityQuestion1: question1,
                        securityQuestion2: question2,
                        securityQuestion3: question3,
                        encryptedLoginPassword,
                    },
                },
            });

            onClose();
        } catch (e) {
            setErrorMessage(i18n("settings.passwordReset.error") + e.message);
        }
    };

    return (
        <CustomModal
            title={i18n("settings.passwordReset.title")}
            onClose={onClose}
            okButtonEnabled={Boolean(answer1) && Boolean(answer3) && Boolean(answer3) && Boolean(password)}
            onOk={handleOnOk}
            loading={false}
            error={Boolean(errorMessage)}
            errorMessage={errorMessage}
            saveText={i18n("settings.passwordReset.setUp")}
        >
            <Stepper activeStep={step} orientation="vertical" style={{ width: "100%" }}>
                <Step>
                    <StepLabel>{i18n("settings.passwordReset.enterLoginPassword")}</StepLabel>
                    <StepContent>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <PasswordInputField
                                    onChange={setPassword}
                                    fullWidth
                                    label={i18n("settings.passwordReset.loginPassword")}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        {stepNavigation(!password || !isLoginCorrect)}
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>{i18n("settings.passwordReset.securityQuestion1")}</StepLabel>
                    <StepContent>
                        {securityQuestionInformation}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <QuestionSelect value={question1} onChange={setQuestion1} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    onChange={setAnswer1}
                                    value={answer1}
                                    fullWidth
                                    label={i18n("settings.passwordReset.answer")}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        {stepNavigation(!answer1)}
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>{i18n("settings.passwordReset.securityQuestion2")}</StepLabel>
                    <StepContent>
                        {securityQuestionInformation}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <QuestionSelect value={question2} onChange={setQuestion2} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    onChange={setAnswer2}
                                    value={answer2}
                                    fullWidth
                                    label={i18n("settings.passwordReset.answer")}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        {stepNavigation(!answer2)}
                    </StepContent>
                </Step>
                <Step>
                    <StepLabel>{i18n("settings.passwordReset.securityQuestion3")}</StepLabel>
                    <StepContent>
                        {securityQuestionInformation}
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <QuestionSelect value={question3} onChange={setQuestion3} />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    onChange={setAnswer3}
                                    value={answer3}
                                    fullWidth
                                    label={i18n("settings.passwordReset.answer")}
                                    variant="outlined"
                                />
                            </Grid>
                        </Grid>
                        {stepNavigation(!answer3)}
                    </StepContent>
                </Step>
            </Stepper>
        </CustomModal>
    );
}

SetupPasswordResetModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default SetupPasswordResetModal;
