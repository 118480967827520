import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import { useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import { Alert } from "@mui/lab";
import { DrawerContext } from "../../../contexts/DrawerContext";
import { Button } from "@mui/material";
import { useCustomerCheckoutUrlQuery } from "../../../hooks/queries/billingHook";
import useTranslation from "../translation/translation";

const useStyles = makeStyles((theme) => ({
    banner: {
        zIndex: 20,
        borderRadius: 0,
        width: "100%",
        "& .MuiAlert-icon": {
            alignItems: "center",
        },
        "& .MuiAlert-message": {
            width: "100%",
        },
    },
    fakeBackgroundHeight: {
        height: 0,
    },
}));

function TrialBanner() {
    const drawerContext = DrawerContext.useContainer();
    const classes = useStyles({ drawerOpen: drawerContext.drawerOpen });

    const { me, isBusinessCustomer, isTrial, isSystemAdmin, trialFinishedLocalDate } = useMyselfQuery();
    const { url } = useCustomerCheckoutUrlQuery();
    const { i18n } = useTranslation();

    const isPaymentMethodMissing = !Boolean(me.mandator.customerinformation?.defaultPaymentMethodId);

    if (!isBusinessCustomer || !isTrial || !isSystemAdmin || !isPaymentMethodMissing) return <div />;

    return (
        <>
            <div className={classes.fakeBackgroundHeight} />
            <Alert severity="warning" className={classes.banner}>
                <div style={{ display: "flex", alignItems: "center" }}>
                    <div>
                        <p>{i18n("banner.payment", { key: "date", value: trialFinishedLocalDate })}</p>
                    </div>
                    <div style={{ flex: 1 }} />
                    <Button variant="contained" color="primary" size="small" href={url}>
                        {i18n("banner.paymentLink")}
                    </Button>
                </div>
            </Alert>
        </>
    );
}

export default TrialBanner;
