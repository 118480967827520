import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { menu } from "../../managers/pathManager";
import { LoginContext } from "../../contexts/LoginContext";
import LoginInput from "./LoginInput";
import { Button, Snackbar, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import ReactCodeInput from "react-verification-code-input";
import { TwoFAManager } from "../../managers/twofactorManager";
import { HtmlAutoCompleteTypeList } from "../../types/HtmlAutoCompleteType";
import { CryptoManager } from "../../managers/cryptoManager";
import VerticalContentPageContainer from "../common/layout/VerticalContentPageContainer";
import Separator from "../common/layout/Separator";
import CircularProgress from "@mui/material/CircularProgress";
import VaulteronLogoIcon from "../common/dataDisplay/VaulteronLogoIcon";
import { useModalStatus } from "../../hooks/modalHook";
import ForgotPasswordModal from "./ForgotPasswordModal";
import useTranslation from "../common/translation/translation";
import { CommunicationModelStates } from "../../managers/extensionManager";
import queryString from "query-string";
import ConfirmationDialog from "../common/feedback/modals/ConfirmationDialog";
import { ExtensionInfosContext } from "../../contexts/ExtensionContext";
import LoginPageLayout from "./LoginPageLayout";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: "0 10px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "30rem",
        padding: "30px",
        border: "1px solid #C7C6C1",
        borderRadius: "5px",
        backgroundColor: "#fff",
    },
    form: {
        width: "100%", // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    link: {
        textDecoration: "none",
    },
    heading: {
        color: "#1D1C3E",
    },
    spinner: {
        position: "fixed",
        left: "50%",
        top: "50%",
    },
    codeInput: {
        width: "100% !important",
        "& input": {
            width: "16.66% !important",
        },
    },
}));

const LoginPage = (props) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const location = useLocation();
    const extensionContext = ExtensionInfosContext.useContainer();

    const refTotpInputField = useRef(null);
    const loginContext = LoginContext.useContainer();
    const [error, setError] = useState(undefined);
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const navigate = useNavigate();

    const [twoFactorAuth, setTwoFactorAuth] = useState(false);
    const [twoFactorReasons, setTwoFactorReasons] = useState([]);

    const isTOTPEnabled = twoFactorReasons.includes("TOTP");
    const isWebAuthnEnabled = twoFactorReasons.includes("WebAuthn");
    const isOnlyWebAuthEnabled = !isTOTPEnabled && isWebAuthnEnabled;
    const isLoginPathName = menu.login.path === window.location.pathname;

    const { modalState: forgotPasswordModalState, open: openForgotPasswordModal, close: closeForgotPasswordModal } = useModalStatus();
    const {
        modalState: webauthnExtensionLoginModalState,
        open: openWebauthnExtensionLoginModal,
        close: closeWebauthnExtensionLoginModal,
    } = useModalStatus();

    const urlParams = queryString.parse(location.search);
    const isLoginInitiatedByExtension = Boolean(urlParams.loginInitiatedByExtension) || (urlParams.ext && urlParams.ext === "1");

    useEffect(() => {
        if (!isLoginPathName) window.location.href = menu.login.path;
    }, [isLoginPathName]);

    useEffect(() => {
        loginContext.clearLocalData();
    }, []);

    useEffect(() => {
        if (isOnlyWebAuthEnabled) {
            onWebAuthnLogin();
        }
    }, [isOnlyWebAuthEnabled]);

    const onLogin = async (e) => {
        e.preventDefault();
        try {
            const passwordHash = await CryptoManager.createPasswordHash(password);
            await loginContext.login(username, passwordHash, true);

            if (extensionContext.isExtensionAvailable) {
                try {
                    extensionContext.sendRequestToWebWorker(CommunicationModelStates.vaulteronLogin, {
                        username: username,
                        passwordHash: passwordHash,
                        closeTabOnCompletion: isLoginInitiatedByExtension,
                    });
                } catch (e) {
                    console.error("Unable to login extension: ", e);
                }
            }
            navigate(menu.root.path);
        } catch (err) {
            try {
                const json = JSON.parse(err.message);
                if (json.errorCode === 33) {
                    // Two Factor
                    setTwoFactorAuth(true);
                    setTwoFactorReasons(json.reasons);
                } else if (json.errorCode === 400 || (json.errorCode === 30 && json.message === "Email or password incorrect")) {
                    setError(i18n("login.errorWrongCredentials"));
                } else {
                    setError(json.message);
                    console.error(json.message);
                }
            } catch (e) {
                setError(i18n("general.offline.error"));
            }
        }
    };

    const loginWithTOPT = async (codeInput) => {
        try {
            await loginContext.loginWithTOPT(codeInput);
            try {
                const passwordHash = await CryptoManager.createPasswordHash(password);

                if (extensionContext.isExtensionAvailable) {
                    // A normal login is necessary because the API expect a user that recently failed a 2FA login
                    try {
                        await loginContext.login(username, passwordHash, true);
                    } catch (e) {}
                    extensionContext.sendRequestToWebWorker(CommunicationModelStates.vaulteronLogin, {
                        username: username,
                        passwordHash: passwordHash,
                        totpCode: codeInput,
                        closeTabOnCompletion: isLoginInitiatedByExtension,
                    });
                }
            } catch (e) {
                console.error("Unable to login extension: ", e);
            }
            navigate(menu.root.path);
        } catch (err) {
            try {
                const json = JSON.parse(err.message);
                setError(json.message);
                refTotpInputField?.current?.__clearvalues__();
            } catch (e) {
                setError(i18n("general.offline.error"));
            }
        }
    };

    const onWebAuthnLogin = async () => {
        try {
            const passwordHash = await CryptoManager.createPasswordHash(password);

            const request = await loginContext.getWebAuthnChallenge();
            const resultJson = await request.json();
            const assertionResponse = await TwoFAManager.verifyFidoKey(resultJson);

            // Both logins are not possible with the same data. The user would have to be asked to do the webauthn login twice
            if (isLoginInitiatedByExtension) {
                try {
                    extensionContext.sendRequestToWebWorker(CommunicationModelStates.vaulteronLogin, {
                        username: username,
                        passwordHash: passwordHash,
                        webAuthnAssertionResponse: assertionResponse,
                        closeTabOnCompletion: true,
                    });
                } catch (e) {
                    console.error("Unable to login extension: ", e);
                    setError(i18n("login.extensionError"));
                }
            } else {
                await loginContext.loginWithWebAuthn(assertionResponse);

                if (extensionContext.isExtensionAvailable) {
                    openWebauthnExtensionLoginModal();
                } else {
                    navigate(menu.root.path);
                }
            }
        } catch (err) {
            console.error(err);
            setError(err.message || err);
        }
    };

    const onWebAuthnExtensionLogin = async () => {
        try {
            closeWebauthnExtensionLoginModal();

            const passwordHash = await CryptoManager.createPasswordHash(password);
            // A normal login is necessary because the API expect a user that recently failed a 2FA login
            try {
                await loginContext.login(username, passwordHash, true);
            } catch (e) {}

            const request = await loginContext.getWebAuthnChallenge();
            const resultJson = await request.json();
            const assertionResponse = await TwoFAManager.verifyFidoKey(resultJson);
            extensionContext.sendRequestToWebWorker(CommunicationModelStates.vaulteronLogin, {
                username: username,
                passwordHash: passwordHash,
                webAuthnAssertionResponse: assertionResponse,
                closeTabOnCompletion: false,
            });
            navigate(menu.root.path);
        } catch (e) {
            console.error("Unable to login extension: ", e);
            setError(i18n("login.extensionError"));
        }
    };

    if (!isLoginPathName) return <CircularProgress className={classes.spinner} />;

    return (
        <LoginPageLayout>
            <div className={classes.paper}>
                <VaulteronLogoIcon height="60px" />
                <Typography className={classes.heading} component="h1" variant="h5">
                    Vaulteron
                </Typography>

                <form className={classes.form} noValidate>
                    <LoginInput
                        id="email"
                        label={i18n("users.email")}
                        name="email"
                        autoComplete={HtmlAutoCompleteTypeList.email}
                        autoFocus
                        onChange={setUsername}
                        value={username}
                        disabled={twoFactorAuth}
                    />
                    <LoginInput
                        name="password"
                        label={i18n("general.keyWords.password")}
                        type="password"
                        id="password"
                        autoComplete={HtmlAutoCompleteTypeList.currentPassword}
                        onChange={setPassword}
                        value={password}
                        disabled={twoFactorAuth}
                    />

                    <Button onClick={openForgotPasswordModal} variant="text" style={{ float: "right" }}>
                        {i18n("login.forgotPassword")}
                    </Button>

                    {isTOTPEnabled && (
                        <>
                            <Separator title={i18n("login.loginWithAuthenticator")} />
                            <ReactCodeInput ref={refTotpInputField} required className={classes.codeInput} onComplete={loginWithTOPT} />
                        </>
                    )}

                    {isWebAuthnEnabled && (
                        <Button fullWidth variant="contained" color="primary" className={classes.submit} onClick={onWebAuthnLogin}>
                            {isOnlyWebAuthEnabled ? i18n("login.repeat") : i18n("login.loginSecurityKey")}
                        </Button>
                    )}

                    {!twoFactorAuth && (
                        <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={onLogin}>
                            {i18n("login.logIn")}
                        </Button>
                    )}

                    <Separator title={i18n("login.or")} />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="secondary"
                        className={classes.submit}
                        onClick={() => navigate("/register")}
                    >
                        {i18n("register.register")}
                    </Button>
                </form>
            </div>

            {forgotPasswordModalState && <ForgotPasswordModal onClose={closeForgotPasswordModal} />}

            <Snackbar message={error} open={!!error} onClose={() => setError(undefined)} />

            {webauthnExtensionLoginModalState && (
                <ConfirmationDialog
                    title={i18n("login.extensionConfirmation")}
                    description=""
                    cancelText={i18n("general.actions.no")}
                    okText={i18n("general.actions.yes")}
                    onClose={() => {
                        closeWebauthnExtensionLoginModal();
                        navigate(menu.root.path);
                    }}
                    onOk={onWebAuthnExtensionLogin}
                />
            )}
        </LoginPageLayout>
    );
};

export default LoginPage;
