import { addEntry, deleteEntries, deleteEntry } from "../../managers/cacheManager";
import {
    useAddCompanyPasswordMutation,
    useAddCompanyPasswordForUserMutation,
    useDeleteSharedPasswordMutation,
    useMoveCompanyPasswordToSharedPasswordMutation,
    SharedPasswordFieldsFragmentDoc,
} from "../../graphql/generated";

export const useCachedAddCompanyPasswordMutation = () => {
    const [addCompanyPassword] = useAddCompanyPasswordMutation({
        update(
            cache,
            {
                data: {
                    sharedPassword: { companyAdd: newPassword },
                },
            }
        ) {
            cache.modify({
                fields: {
                    companyPasswords(pasRefs) {
                        return addEntry(cache, pasRefs, SharedPasswordFieldsFragmentDoc, newPassword);
                    },
                },
                broadcast: false,
            });
        },
    });

    return { addCompanyPassword };
};

export const useCompanyPasswordDeleteMutation = () => {
    const [deletePassword] = useDeleteSharedPasswordMutation({
        update(
            cache,
            {
                data: {
                    sharedPassword: { delete: deletedPassword },
                },
            }
        ) {
            cache.modify({
                fields: {
                    companyPasswords(refs, { readField }) {
                        return deleteEntry(refs, readField, deletedPassword.id);
                    },
                },
            });
        },
    });

    return { deletePassword };
};

export const useMoveCompanyPasswordsToSharedPasswordsMutation = () => {
    const [movePasswords] = useMoveCompanyPasswordToSharedPasswordMutation({
        update(
            cache,
            {
                data: {
                    sharedPassword: { companyMoveToShared },
                },
            }
        ) {
            cache.modify({
                fields: {
                    companyPasswords(refs, { readField }) {
                        return deleteEntries(
                            refs,
                            readField,
                            companyMoveToShared.map((p) => p.id)
                        );
                    },
                },
            });
        },
    });
    return { moveCompanyPasswordsToSharedPasswords: movePasswords };
};

export const useCompanyPasswordAddForUserMutation = () => {
    const [addCompanyPasswordForUser] = useAddCompanyPasswordForUserMutation({
        update(
            cache,
            {
                data: {
                    sharedPassword: { companyAddForUser: newPassword },
                },
            }
        ) {
            cache.modify({
                fields: {
                    companyPasswords(pasRefs) {
                        return addEntry(cache, pasRefs, SharedPasswordFieldsFragmentDoc, newPassword);
                    },
                },
                broadcast: false,
            });
        },
    });

    return { addCompanyPasswordForUser };
};
