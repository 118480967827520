import React, { useState } from "react";
import makeStyles from "@mui/styles/makeStyles";
import { Table, TableBody } from "@mui/material";
import { KindOfPasswordTypes } from "../../types/PasswordType";
import TitleToolbar from "../common/layout/TitleToolbar";
import PasswordsContainer from "../common/passwordComponents/PasswordsContainer";
import PasswordTableRow from "../common/passwordComponents/PasswordTableRow";
import { ClientFilter, defaultPasswordSearchTermFields } from "../../managers/filterManager";
import { useContextMenu, useModalStatus } from "../../hooks/modalHook";
import { OfflinePasswordsContext } from "../../contexts/OfflinePasswordsContext";
import PasswordTableRowContextMenu from "../common/passwordComponents/PasswordTableRowContextMenu";
import PasswordModal from "../common/passwordComponents/PasswordModal";
import PropTypes from "prop-types";
import ConfirmDeletePassword from "../common/passwordComponents/ConfirmDeletePassword";

const useStyles = makeStyles((theme) => ({
    heading: {
        fontSize: "1.3rem",
    },
    playButton: {
        color: theme.colors.securityRating_verySecure,

        "& .MuiIcon-root": {
            fontSize: "2.5rem",
        },
    },
    playButtonOffline: {
        color: theme.colors.securityRating_dangerous,
    },
    textarea: {
        width: "100%",
    },
    passwordTableRow: {
        height: "58px",

        "& > td:last-child": {
            paddingRight: "16px",
        },
    },
    tableCaption: {
        border: "1px solid rgb(224, 224, 224)",
        marginTop: "-1px",
    },
}));

const LocalOfflinePasswordTable = ({ searchTerm }) => {
    const classes = useStyles();
    const offlinePasswordsContext = OfflinePasswordsContext.useContainer();

    const [selectedLocalPasswords, setSelectedLocalPasswords] = useState(/** @type SharedPasswordType[] */ []);
    const [clickedPassword, setClickedPassword] = useState(/** @type SharedPasswordType */ {});
    const [isBulkOperation, setIsBulkOperation] = useState(false);

    const { anchorEl, open: openContextMenu, close: closeContextMenu } = useContextMenu();

    const { modalState: passwordModalState, open: openPasswordModal, close: closePasswordModal } = useModalStatus();
    const {
        modalState: confirmDeletePasswordLocalOfflineModalState,
        open: openConfirmDeleteLocalOfflinePasswordModal,
        close: closeConfirmDeleteLocalOfflinePasswordModal,
    } = useModalStatus();

    /**
     *
     * @type {SharedPasswordType[]}
     */
    const filteredLocalPasswords = new ClientFilter(offlinePasswordsContext.locallySavedPasswords)
        .bySearchTerm(defaultPasswordSearchTermFields, searchTerm)
        .build();

    const targetPasswords = isBulkOperation ? selectedLocalPasswords : [clickedPassword];

    return (
        <>
            <TitleToolbar
                title="Offlinepasswörter auf diesem Gerät"
                passwordCount={offlinePasswordsContext.locallySavedPasswords.length}
                selected={selectedLocalPasswords}
                setSelectAll={(_) => setSelectedLocalPasswords(offlinePasswordsContext.locallySavedPasswords)}
                setUnselectAll={(_) => setSelectedLocalPasswords([])}
                onDelete={() => {
                    setIsBulkOperation(true);
                    openConfirmDeleteLocalOfflinePasswordModal();
                }}
            />
            <PasswordsContainer>
                <Table size="small">
                    <caption className={classes.tableCaption}>
                        Diese Passwörter wurden noch nicht in der Cloud gespeichert. Sobald Sie wieder mit dem Internet verbunden sind werden diese
                        Passwörter automatisch synchronisiert.
                    </caption>
                    <TableBody>
                        {filteredLocalPasswords.map((password, i) => (
                            <PasswordTableRow
                                key={i}
                                disableTags
                                showMenuOptions
                                className={classes.passwordTableRow}
                                password={password}
                                selectedPasswords={selectedLocalPasswords}
                                setSelectedPasswords={setSelectedLocalPasswords}
                                onRowClick={(_) => {
                                    setClickedPassword(password);
                                    setIsBulkOperation(false);
                                    openPasswordModal();
                                }}
                                onContextMenu={(e) => {
                                    setClickedPassword(password);
                                    setIsBulkOperation(false);
                                    openContextMenu(e);
                                }}
                            />
                        ))}
                    </TableBody>
                </Table>
            </PasswordsContainer>

            <PasswordTableRowContextMenu
                passwordType={KindOfPasswordTypes.offlinePasswords}
                password={clickedPassword}
                anchorEl={anchorEl}
                onClose={closeContextMenu}
                onPasswordModal={openPasswordModal}
                onConfirmDeleteModal={openConfirmDeleteLocalOfflinePasswordModal}
                removeOperationText="Löschen"
            />

            {confirmDeletePasswordLocalOfflineModalState && (
                <ConfirmDeletePassword
                    isDelete
                    onOk={() => {
                        offlinePasswordsContext.removeOfflinePasswords(targetPasswords.map((p) => p.offlineGuid));
                        setSelectedLocalPasswords([]);
                        closeConfirmDeleteLocalOfflinePasswordModal();
                    }}
                    onClose={closeConfirmDeleteLocalOfflinePasswordModal}
                    passwords={targetPasswords}
                />
            )}

            {passwordModalState && (
                <PasswordModal
                    passwordType={
                        Boolean(clickedPassword.encryptedPassword) ? KindOfPasswordTypes.offlinePasswords : KindOfPasswordTypes.companyPasswords
                    }
                    disableTags
                    editable
                    password={clickedPassword}
                    onClose={closePasswordModal}
                    onOk={closePasswordModal}
                />
            )}
        </>
    );
};

LocalOfflinePasswordTable.propTypes = {
    searchTerm: PropTypes.string.isRequired,
};

export default LocalOfflinePasswordTable;
