import React from "react";
import { gql, useApolloClient } from "@apollo/client";
import { fragments } from "../../managers/fragmentManager";
import { EDIT_SHARED_PASSWORD } from "../../queries/passwordQuery";

const GET_SHARED_PASSWORD = gql`
    query SharedPasswordQuery($searchTerm: String!, $groupIds: [Guid]!, $byArchived: Boolean = false) {
        sharedPasswords(searchTerm: $searchTerm, groupIds: $groupIds, byArchived: $byArchived) {
            ...SelectSharedPassword
        }
    }
    ${fragments.query.SelectSharedPassword}
`;
const GET_SHARED_PASSWORD_WITH_ENCRYPTED_STRING = gql`
    query SharedPasswordQuery($searchTerm: String!, $groupIds: [Guid]!, $byArchived: Boolean = false) {
        sharedPasswords(searchTerm: $searchTerm, groupIds: $groupIds, byArchived: $byArchived) {
            encryptedString
            ...SelectSharedPassword
        }
    }
    ${fragments.query.SelectSharedPassword}
`;

const GET_COMPANY_PASSWORD = gql`
    query CompanyPasswords($searchTerm: String!, $createdByIds: [Guid]!, $byArchived: Boolean = false) {
        companyPasswords(searchTerm: $searchTerm, createdByIds: $createdByIds, byArchived: $byArchived) {
            ...SelectSharedPassword
        }
    }
    ${fragments.query.SelectSharedPassword}
`;
const GET_COMPANY_PASSWORD_WITH_ENCRYPTED_STRING = gql`
    query CompanyPasswords($searchTerm: String!, $createdByIds: [Guid]!, $byArchived: Boolean = false) {
        companyPasswords(searchTerm: $searchTerm, createdByIds: $createdByIds, byArchived: $byArchived) {
            encryptedString
            ...SelectSharedPassword
        }
    }
    ${fragments.query.SelectSharedPassword}
`;

const GET_ENCRYPTED_STRING_FOR_SHARED_PASSWORD = gql`
    query EncryptedSharedPasswordString($passwordId: Guid!) {
        encryptedSharedPasswordString(passwordId: $passwordId)
    }
`;

export const useLazySharedPasswordQueries = () => {
    const client = useApolloClient();

    /**
     *
     * @param {string} searchTerm
     * @param {[Guid]} groupIds
     * @param {Boolean | null} byArchived
     * @param {Boolean} includeEncryptedPasswordStrings
     * @returns {Promise<[SharedPasswordType|null|undefined]>}
     */
    const executeLoadSharedPasswordsQuery = async (searchTerm, groupIds, byArchived = false, includeEncryptedPasswordStrings = false) => {
        const { data } = await client.query({
            query: includeEncryptedPasswordStrings ? GET_SHARED_PASSWORD_WITH_ENCRYPTED_STRING : GET_SHARED_PASSWORD,
            variables: { searchTerm: searchTerm, groupIds: groupIds, byArchived: byArchived },
            fetchPolicy: includeEncryptedPasswordStrings ? "no-cache" : "cache-first",
        });
        return data.sharedPasswords;
    };

    /**
     *
     * @param {Boolean | null} byArchived
     * @param {Boolean} includeEncryptedPasswordStrings
     * @returns {Promise<(SharedPasswordType|null|undefined)[]>}
     */
    const executeLoadAllSharedPasswordsQuery = async (byArchived = false, includeEncryptedPasswordStrings = false) =>
        await executeLoadSharedPasswordsQuery("", [], byArchived, includeEncryptedPasswordStrings);

    /**
     *
     * @param {Guid} passwordId
     * @returns {Promise<string>}
     */
    const executeLoadEncryptedSharedPasswordStringQuery = async (passwordId) => {
        const { data } = await client.query({
            query: GET_ENCRYPTED_STRING_FOR_SHARED_PASSWORD,
            variables: { passwordId: passwordId },
            fetchPolicy: "network-only",
        });
        return data.encryptedSharedPasswordString;
    };

    /**
     *
     * @param {string} searchTerm
     * @param {[Guid]} createdByIds
     * @param {Boolean | null} byArchived
     * @param {Boolean} includeEncryptedPasswordStrings
     * @returns {Promise<[SharedPasswordType|null|undefined]>}
     */
    const executeLoadCompanyPasswordQuery = async (searchTerm, createdByIds, byArchived = false, includeEncryptedPasswordStrings = false) => {
        const { data } = await client.query({
            query: includeEncryptedPasswordStrings ? GET_COMPANY_PASSWORD_WITH_ENCRYPTED_STRING : GET_COMPANY_PASSWORD,
            variables: { searchTerm: searchTerm, createdByIds: createdByIds, byArchived: byArchived },
            fetchPolicy: includeEncryptedPasswordStrings ? "no-cache" : "cache-first",
        });
        return data.companyPasswords;
    };

    /**
     *
     * @param {Boolean | null} byArchived
     * @param {Boolean} includeEncryptedPasswordStrings
     * @returns {Promise<(SharedPasswordType|null|undefined)[]>}
     */
    const executeLoadAllCompanyPasswordsQuery = async (byArchived = false, includeEncryptedPasswordStrings = false) =>
        await executeLoadCompanyPasswordQuery("", [], byArchived, includeEncryptedPasswordStrings);

    return {
        executeLoadSharedPasswordsQuery,
        executeLoadAllSharedPasswordsQuery,
        executeLoadCompanyPasswordQuery,
        executeLoadAllCompanyPasswordsQuery,
        executeLoadEncryptedSharedPasswordStringQuery,
    };
};

export const useLazySharedPasswordMutations = () => {
    const client = useApolloClient();

    const useExecuteEditSharedPassword = async (password) => {
        const { data } = await client.mutate({
            mutation: EDIT_SHARED_PASSWORD,
            variables: { sharedPassword: password },
        });
        return data.sharedPassword;
    };
    return { useExecuteEditSharedPassword };
};
