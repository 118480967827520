import { Grid, Box } from "@mui/material";
import { styled } from "@mui/system";
import { ReactElement } from "react";

const Container = styled("div")({
    width: "100%",
    marginTop: "2em",
    minHeight: "500px",
    maxHeight: "60vh",
});

interface GroupModalTabPanelProps {
    children: ReactElement;
    value: number;
    index: number;
}

export default function GroupModalTabPanel(props: GroupModalTabPanelProps) {
    const { children, value, index } = props;

    return (
        <Container role="tabpanel" hidden={value !== index} id={`groupTabPanel-${index}`} aria-labelledby={`simple-tab-${index}`}>
            {value === index && (
                <Box p={3}>
                    <Grid container spacing={2}>
                        {children}
                    </Grid>
                </Box>
            )}
        </Container>
    );
}
