import { TextField } from "@mui/material";
import PropTypes from "prop-types";

const GroupModalTextField = ({ onChange, value, label, className }) => {
    return <TextField margin="dense" fullWidth label={label} variant="outlined" value={value} onChange={onChange} className={className} />;
};

GroupModalTextField.propTypes = {
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    className: PropTypes.object,
};

export default GroupModalTextField;
