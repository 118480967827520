import React, { useMemo, useState } from "react";
import CustomModal from "../common/feedback/modals/CustomModal";
import PropTypes from "prop-types";
import { FormControlLabel, Grid, TextField } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { useMaterialUICheckedInput, useMaterialUIInput } from "../../hooks/inputHook";
import { useUserMutation } from "../../hooks/queries/userQueryHooks";
import Checkbox from "@mui/material/Checkbox";
import GroupFilter from "../common/passwordComponents/toolbar/GroupFilter";
import { CryptoManager } from "../../managers/cryptoManager";
import { useExecuteGroupTreeQuery, useGroupTreeQuery } from "../../hooks/queries/groupQueryHooks";
import { extractApolloErrorMessage } from "../../managers/errorManager";
import { useRetrieveAllDecryptedSharedAndCompanyAndOfflinePasswords, useRetrieveDecryptedPasswordsInGroups } from "../../hooks/cryptoHook";
import { encryptPasswordsForUser } from "../../managers/passwordManager";
import { generatePassword } from "../../managers/passwordGenerationManager";
import { getGroupsFromParentGroups } from "../../managers/groupManager";
import useTranslation from "../common/translation/translation";

const useStyles = makeStyles((theme) => ({
    input: {
        width: "100%",
    },
    formControl: {
        minWidth: 120,
        width: "100%",
    },
}));

const UserAddModal = ({ onClose }) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState("");
    const { i18n } = useTranslation();

    const retrieveDecryptedPasswordsInGroups = useRetrieveDecryptedPasswordsInGroups();
    const retrieveAllDecryptedPasswords = useRetrieveAllDecryptedSharedAndCompanyAndOfflinePasswords();

    const { addUser, error: errorUserMutation } = useUserMutation();
    const { treeNodes, loading, error: treeNodesError } = useGroupTreeQuery();
    const { executeGroupTreeQuery } = useExecuteGroupTreeQuery();

    const error = Boolean(errorUserMutation || treeNodesError);

    const { value: email, onChange: setEmail } = useMaterialUIInput("");
    const { value: firstName, onChange: setFirstName, setValue: setFirstNameValue } = useMaterialUIInput("");
    const { value: lastName, onChange: setLastName, setValue: setLastNameValue } = useMaterialUIInput("");
    const { value: admin, onChange: setAdmin } = useMaterialUICheckedInput(false);
    const [groupIds, setGroupIdsIds] = useState([]);

    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    const emailValid = useMemo(() => Boolean(email !== "" && re.test(String(email).toLowerCase())), [email]);
    const firstnameValid = firstName !== "" && firstName.length >= 1;
    const lastnameValid = lastName !== "" && lastName.length >= 1;
    const isFormValid = Boolean(emailValid && firstnameValid && lastnameValid);

    const handleEmailBlur = () => {
        if (firstName === "" && lastName === "") {
            const regex = /(.*)\.(.*)@.*/;
            const matches = email.match(regex);
            if (matches?.length === 3) {
                setFirstNameValue(matches[1]);
                setLastNameValue(matches[2]);
            }
        }
    };

    const handleAdd = async () => {
        try {
            const keys = await CryptoManager.createAsymmetricalKeyPair();
            const tmpLoginPassword = generatePassword();
            const tmpLoginPasswordHashed = await CryptoManager.createPasswordHash(tmpLoginPassword);
            const encryptedPrivateKey = await CryptoManager.encryptUsingUserCredentials(keys.privateKey, tmpLoginPasswordHashed);

            let encryptedPasswords = [];
            if (admin || groupIds.length > 0) {
                let decryptedSharedPasswords;
                if (admin) {
                    decryptedSharedPasswords = await retrieveAllDecryptedPasswords(null);
                } else {
                    const groupTreeNodes = await executeGroupTreeQuery(null);
                    const groupsWithSubGroupsIds = getGroupsFromParentGroups(groupTreeNodes, groupIds);
                    decryptedSharedPasswords = await retrieveDecryptedPasswordsInGroups(groupsWithSubGroupsIds, null);
                }
                encryptedPasswords = await encryptPasswordsForUser(decryptedSharedPasswords, keys.publicKey);
            }

            const user = {
                firstname: firstName,
                lastname: lastName,
                email: email,
                admin: admin,
                sex: "Unset",
                newTemporaryLoginPassword: tmpLoginPassword,
                newTemporaryLoginPasswordHashed: tmpLoginPasswordHashed,
                publicKey: keys.publicKey,
                encryptedPrivateKey: encryptedPrivateKey,
                groupsToBeAddedTo: groupIds,
                encryptedSharedPasswords: encryptedPasswords,
            };

            await addUser({ variables: { user: user } });
            onClose();
        } catch (e) {
            console.error(e);
            setErrorMessage(extractApolloErrorMessage(e));
        }
    };

    return (
        <CustomModal
            onClose={onClose}
            saveText={i18n("general.actions.add")}
            okButtonEnabled={isFormValid}
            onOk={handleAdd}
            title={i18n("users.addUser")}
            error={Boolean(error) || errorMessage !== ""}
            loading={loading}
            errorMessage={errorMessage}
        >
            <Grid container spacing={2} style={{ marginTop: "2px" }}>
                <Grid item xs={12}>
                    <TextField
                        variant="outlined"
                        required
                        autoFocus
                        value={email}
                        onChange={setEmail}
                        onBlur={handleEmailBlur}
                        label={i18n("users.emailLong")}
                        className={classes.input}
                        error={!emailValid && email}
                        helperText={!emailValid && email ? i18n("users.invalidMail") : ""}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        required
                        value={firstName}
                        onChange={setFirstName}
                        label={i18n("users.firstName")}
                        className={classes.input}
                        error={!firstnameValid && firstName}
                        helperText={!firstnameValid && firstName ? i18n("users.invalidFirstname") : ""}
                    />
                </Grid>
                <Grid item xs={12} md={6}>
                    <TextField
                        variant="outlined"
                        required
                        value={lastName}
                        onChange={setLastName}
                        label={i18n("users.lastName")}
                        className={classes.input}
                        error={!lastnameValid && lastName}
                        helperText={!lastnameValid && lastName ? i18n("users.invalidLastname") : ""}
                    />
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox variant="outlined" value={admin} onChange={setAdmin} />}
                        label={i18n("users.isSystemAdmin")}
                        labelPlacement="start"
                    />
                </Grid>
                <Grid item xs={12}>
                    {!admin && treeNodes.length !== 0 && (
                        <FormControlLabel
                            control={<GroupFilter setChecked={setGroupIdsIds} checked={groupIds} />}
                            label={i18n("users.addGroups")}
                            labelPlacement="top"
                        />
                    )}
                </Grid>
            </Grid>
        </CustomModal>
    );
};

UserAddModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default UserAddModal;
