import { useState } from "react";
import { createContainer } from "../hooks/ContextHook";
import { localStorageManager } from "../managers/localStorageManager";

function useOfflinePasswordsContext() {
    const [locallySavedPasswords, setLocallySavedPasswords] = useState(localStorageManager.loadOfflinePasswordsFromJson());
    const [isSavingLocalPasswordsToServer, setIsSavingLocalPasswordsToServer] = useState(false);
    const [hasSyncedPasswords, setHasSyncedPasswords] = useState(false);

    const addOfflinePassword = (password) => {
        const newPasswords = [...locallySavedPasswords, password];
        localStorageManager.saveOfflinePasswordsAsJson(newPasswords);
        setLocallySavedPasswords(newPasswords);
    };

    /**
     *
     * @param {Guid[]} passwordGuids
     */
    const removeOfflinePasswords = (passwordGuids) => {
        const newPasswords = locallySavedPasswords.filter((pw) => !passwordGuids.includes(pw.offlineGuid));
        localStorageManager.saveOfflinePasswordsAsJson(newPasswords);
        setLocallySavedPasswords(newPasswords);
    };

    const updatePassword = (password) => {
        const foundPassword = locallySavedPasswords.find((pw) => pw.offlineGuid === password.offlineGuid);
        if (!foundPassword) return;

        foundPassword.name = password.name;
        foundPassword.login = password.login;
        foundPassword.websiteURL = password.websiteURL;
        foundPassword.notes = password.notes;
        foundPassword.tags = [];

        foundPassword.encryptedPassword = password.encryptedPassword;
    };

    return {
        locallySavedPasswords,
        addOfflinePassword,
        removeOfflinePasswords,
        updatePassword,
        isSavingLocalPasswordsToServer,
        setIsSavingLocalPasswordsToServer,
        setHasSyncedPasswords,

        hasLocallySavedPasswords: Boolean(locallySavedPasswords.length) && locallySavedPasswords.length > 0,
        hasSyncedPasswords,
    };
}

export const OfflinePasswordsContext = createContainer(useOfflinePasswordsContext);
