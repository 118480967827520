import React, { useState } from "react";
import { Button, Step, StepContent, StepLabel, Stepper } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Page from "../common/layout/Page";
import StepChooseImportType from "./StepChooseImportType";
import StepUploadFile from "./StepUploadFile";
import StepAssignColumns from "./StepAssignColumns";
import StepImportData from "./StepImportData";
import { ImportTypes } from "./helper/ImportTypes";
import WaitDialog from "../common/feedback/modals/WaitDialog";
import { useModalStatus } from "../../hooks/modalHook";
import { KindOfPasswordTypes } from "../../types/PasswordType";
import { createNewPasswordMutationObject, usePasswordMutation } from "../../hooks/queries/passwordMutationHook";
import { VaulteronPasswordColumns } from "./helper/VaulteronPasswordColumns";
import { useMyselfQuery } from "../../hooks/queries/userQueryHooks";
import ConfirmationDialog from "../common/feedback/modals/ConfirmationDialog";
import { extractApolloErrorMessage } from "../../managers/errorManager";
import FinishedImportModal from "./helper/FinishedImportModal";
import { OfflineStatusContext } from "../../contexts/OfflineStatusContext";
import { PageType, PageTypeList } from "../../types/PageType";
import { useIsMobile } from "../../hooks/mobileHook";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";
import useTranslation from "../common/translation/translation";

const useStyles = makeStyles((theme) => ({
    container: {
        display: "flex",
        flexDirection: "column",
    },
    stepContentContainer: {
        padding: "1rem",
        overflowY: "auto",
    },
    stepper: {
        flex: 1,
        padding: "1rem 0",
    },
    steppingButtonsContainer: {
        display: "flex",
        justifyContent: "space-between",
    },
    steppingButton: {
        margin: "1rem",
    },
}));

/**
 *
 * @param {object} root0
 * @param {string} root0.currentPageType
 */
const ImportPage = ({ currentPageType }) => {
    const isMobile = useIsMobile();
    const classes = useStyles({ isMobile });
    const offlineStatusContext = OfflineStatusContext.useContainer();

    const { me, isBusinessCustomer } = useMyselfQuery();
    const { createAccountPassword, createCompanyPassword, createGroupPassword } = usePasswordMutation();
    const { i18n } = useTranslation();

    const steps = isBusinessCustomer
        ? { chooseExportMethodeStep: 0, uploadFileStep: 1, mapValuesStep: 2, finishStep: 3 }
        : { chooseExportMethodeStep: 0, uploadFileStep: 1, mapValuesStep: 2 };

    const stepsLabel = isBusinessCustomer
        ? [i18n("import.step1"), i18n("import.step2"), i18n("import.step3"), i18n("import.step4")]
        : [i18n("import.step1"), i18n("import.step2"), i18n("import.step3")];

    const [activeStep, setActiveStep] = React.useState(steps.chooseExportMethodeStep);
    const [nextStepEnabled, setNextStepEnabled] = useState(false);

    const [selectedImportType, setSelectedImportType] = useState(ImportTypes.csv);
    const [uploadedFile, setUploadedFile] = useState({});
    const [interpretedData, setInterpretedData] = useState({});
    const { modalState: importWaitDialogState, open: openImportWaitDialog, close: closeImportWaitDialog } = useModalStatus();
    const { modalState: successDialogState, open: openSuccessDialog, close: closeSuccessDialog } = useModalStatus();
    const { modalState: offlineErrorDialogState, open: openOfflineErrorDialog, close: closeOfflineErrorDialog } = useModalStatus();
    const [countImportedPasswords, setCountImportedPasswords] = useState(0);
    const [targetKindOfPassword, setTargetKindOfPassword] = useState(KindOfPasswordTypes.accountPasswords);
    const [targetGroupId, setTargetGroupId] = useState(undefined);
    const [skippedPasswords, setSkippedPasswords] = useState([]);

    const handleNext = () => {
        if (offlineStatusContext.isOffline) {
            openOfflineErrorDialog();
            return;
        }

        if (activeStep === steps.finishStep || (activeStep === steps.mapValuesStep && !isBusinessCustomer)) {
            handleImport();
        } else {
            setNextStepEnabled(false);
            setActiveStep((prevActiveStep) => prevActiveStep + 1);
        }
    };

    const handleBack = () => {
        if (activeStep >= steps.uploadFileStep) {
            setNextStepEnabled(false);
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const resetImport = () => {
        setActiveStep(steps.chooseExportMethodeStep);
        setNextStepEnabled(false);
        setSelectedImportType(ImportTypes.csv);
        setUploadedFile({});
        setInterpretedData({});
        setCountImportedPasswords(0);
        setTargetKindOfPassword(undefined);
        setTargetGroupId(undefined);
        closeImportWaitDialog();
    };
    const handleFinishedSuccessfully = () => {
        resetImport();
        openSuccessDialog();
    };

    let currentStepContent;
    switch (activeStep) {
        case steps.chooseExportMethodeStep:
            currentStepContent = <StepChooseImportType handleNext={handleNext} onSetSelected={setSelectedImportType} />;
            break;
        case steps.uploadFileStep:
            currentStepContent = (
                <StepUploadFile onStepFinished={setNextStepEnabled} importType={selectedImportType} onSetUploadedFile={setUploadedFile} />
            );
            break;
        case steps.mapValuesStep:
            currentStepContent = (
                <StepAssignColumns
                    onStepFinished={setNextStepEnabled}
                    importType={selectedImportType}
                    uploadedFile={uploadedFile}
                    onInterpretedDataChanged={setInterpretedData}
                />
            );
            break;
        case steps.finishStep:
            currentStepContent = (
                <StepImportData
                    onStepFinished={setNextStepEnabled}
                    interpretedData={interpretedData}
                    onTargetKindOfPasswordChanged={setTargetKindOfPassword}
                    onTargetGroupIdChanged={setTargetGroupId}
                />
            );
            break;
    }

    const handleImport = async () => {
        let count = 0;
        let skippedPasswords = [];

        openImportWaitDialog();
        for (const password of interpretedData) {
            password.skippedReason = "";
            const passwordString = password[VaulteronPasswordColumns.password];
            const name = password[VaulteronPasswordColumns.passwortname];
            const username = password[VaulteronPasswordColumns.username];
            const website = password[VaulteronPasswordColumns.website];
            const notes = password[VaulteronPasswordColumns.notes];

            let currentTargetGroupId = targetKindOfPassword === KindOfPasswordTypes.groupPasswords ? targetGroupId : undefined;
            const includePublicKeyId = targetKindOfPassword === KindOfPasswordTypes.accountPasswords;
            let passwordMutationObject = createNewPasswordMutationObject(
                name,
                username,
                website,
                notes,
                includePublicKeyId ? me.keyPair.id : null,
                [],
                currentTargetGroupId
            );

            if (!Boolean(passwordString)) {
                password.skippedReason = "Dieser Eintrag besitzt keinen Text für das Passwort.";
                skippedPasswords.push(password);
            } else if (!Boolean(name)) {
                password.skippedReason = "Dieser Eintrag besitzt keinen Passwortnamen.";
                skippedPasswords.push(password);
            } else {
                try {
                    switch (targetKindOfPassword) {
                        case KindOfPasswordTypes.accountPasswords:
                            await createAccountPassword(me, passwordString, passwordMutationObject);
                            break;
                        case KindOfPasswordTypes.companyPasswords:
                            await createCompanyPassword(me, passwordString, passwordMutationObject);
                            break;
                        case KindOfPasswordTypes.groupPasswords:
                            await createGroupPassword(me, passwordString, passwordMutationObject, targetGroupId);
                            break;
                    }
                } catch (e) {
                    password.skippedReason = extractApolloErrorMessage(e);
                    skippedPasswords.push(password);
                }
            }

            setCountImportedPasswords(count++);
        }

        setSkippedPasswords(skippedPasswords);
        handleFinishedSuccessfully();
    };

    return (
        <Page currentPageType={currentPageType}>
            <PageHeader>{i18n("import.title")}</PageHeader>
            <PageSubTitle>{i18n("import.subTitle")}</PageSubTitle>
            <div className={classes.stepContentContainer}>
                <Button
                    variant="contained"
                    className={classes.steppingButton}
                    color="secondary"
                    onClick={handleBack}
                    disabled={activeStep === steps.chooseExportMethodeStep}
                >
                    {activeStep === 1 ? i18n("general.actions.first") : i18n("general.actions.back")}
                </Button>
                <Stepper className={classes.stepper} activeStep={activeStep} orientation="vertical">
                    {stepsLabel.map((label, index) => (
                        <Step key={label}>
                            <StepLabel>{label}</StepLabel>
                            <StepContent>{currentStepContent}</StepContent>
                        </Step>
                    ))}
                </Stepper>
                <Button variant="contained" className={classes.steppingButton} color="primary" onClick={handleNext} disabled={!nextStepEnabled}>
                    {activeStep === steps.finishStep ? i18n("import.finish.startImporting") : i18n("general.actions.next")}
                </Button>
            </div>
            {importWaitDialogState && (
                <WaitDialog
                    title="Passwörter werden importiert"
                    open
                    description={`${countImportedPasswords} von ${interpretedData.length} Passwörtern wurden erfolgreich importiert`}
                />
            )}
            {successDialogState && skippedPasswords.length > 0 && (
                <FinishedImportModal skippedPasswords={skippedPasswords} onClose={closeSuccessDialog} />
            )}
            {successDialogState && skippedPasswords.length === 0 && (
                <ConfirmationDialog
                    open
                    onOk={closeSuccessDialog}
                    onClose={closeSuccessDialog}
                    title="Importieren der Passwörter war erfolgreich"
                    description=""
                />
            )}
            {offlineErrorDialogState && (
                <ConfirmationDialog
                    open
                    onOk={closeOfflineErrorDialog}
                    onClose={closeOfflineErrorDialog}
                    title="Importieren nicht möglich"
                    description="Solange sie offline sind können keine Passwörter importiert werden."
                />
            )}
        </Page>
    );
};

ImportPage.prototype = {
    currentPageType: PageType.isRequired,
};

export default ImportPage;
