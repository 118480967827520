/*global chrome*/

import { createContainer } from "../hooks/ContextHook";
import { useEffect, useState } from "react";
import { CommunicationModelStates } from "../managers/extensionManager";

import config from "../config.json";

function useExtensionInfos() {
    const [extensionInfos, setExtensionInfos] = useState(/** @type ExtensionInfos */ null);

    /**
     *
     * @param {CommunicationModelStates} requestState
     * @param {Object | string | null} payload
     * @returns {any | SharedPasswordType[] | string | ErrorModel}
     */
    const sendRequestToWebWorker = (requestState, payload) => {
        const content = createRequestObject(requestState, payload);
        window.postMessage({
            vaulteron_requestFromWebPage: true,
            content: content,
        });
    };

    useEffect(() => {
        window.addEventListener(
            "message",
            (event) => {
                // We only accept messages from ourselves
                if (event.source !== window) return;

                if (event.data.vaulteron_responseFromExtension && event.data.vaulteron_responseFromExtension === true) {
                    /**
                     * @type ExtensionInfos
                     */
                    const infos = event.data.content;
                    setExtensionInfos(infos);
                }
            },
            false
        );
        window.addEventListener("load", () => {
            sendRequestToWebWorker(CommunicationModelStates.getExtensionInfo, {});
            setTimeout(() => sendRequestToWebWorker(CommunicationModelStates.getExtensionInfo, {}), 500);
            setTimeout(() => sendRequestToWebWorker(CommunicationModelStates.getExtensionInfo, {}), 1000);
            setTimeout(() => sendRequestToWebWorker(CommunicationModelStates.getExtensionInfo, {}), 10000);
        });
    }, []);

    const openInstallTab = () => window.open(config.chromeExtensionWebStoreUrl, "_blank").focus();

    const refetchExtensionInfos = async () => await sendRequestToWebWorker(CommunicationModelStates.getExtensionInfo, {});

    /**
     *
     * @param {CommunicationModelStates} requestState
     * @param {Object} payload
     * @returns {CommunicationModel}
     */
    const createRequestObject = (requestState, payload) => ({
        state: requestState,
        payload: payload,
        error: null,
    });

    return {
        isExtensionAvailable: Boolean(extensionInfos),
        extensionInfos: extensionInfos || {},
        refetchExtensionInfos,
        openInstallTab,
        sendRequestToWebWorker,
    };
}

export const ExtensionInfosContext = createContainer(useExtensionInfos);
