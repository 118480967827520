import { oneOf } from "prop-types";

export const HtmlAutoCompleteTypeList = {
    name: "name",
    honorificPrefix: "honorific-prefix",
    givenName: "given-name",
    additionalName: "additional-name",
    familyName: "family-name",
    honorificSuffix: "honorific-suffix",
    nickname: "nickname",
    username: "username",
    newPassword: "new-password",
    currentPassword: "current-password",
    oneTimeCode: "one-time-code",
    organizationTitle: "organization-title",
    organization: "organization",
    streetAddress: "street-address",
    addressLine1: "address-line1",
    addressLine2: "address-line2",
    addressLine3: "address-line3",
    addressLevel4: "address-level4",
    addressLevel3: "address-level3",
    addressLevel2: "address-level2",
    addressLevel1: "address-level1",
    country: "country",
    countryName: "country-name",
    postalCode: "postal-code",
    ccName: "cc-name",
    ccGivenName: "cc-given-name",
    ccAdditionalName: "cc-additional-name",
    ccFamilyName: "cc-family-name",
    ccNumber: "cc-number",
    ccExp: "cc-exp",
    ccExpMonth: "cc-exp-month",
    ccExpYear: "cc-exp-year",
    ccCsc: "cc-csc",
    ccType: "cc-type",
    transactionCurrency: "transaction-currency",
    transactionAmount: "transaction-amount",
    language: "language",
    bday: "bday",
    bdayDay: "bday-day",
    bdayMonth: "bday-month",
    bdayYear: "bday-year",
    sex: "sex",
    url: "url",
    photo: "photo",
    email: "email",
    tel: "tel",
};
export const HtmlAutoCompleteTypes = Object.freeze(HtmlAutoCompleteTypeList);
export const HtmlAutoCompleteType = oneOf(Object.values(HtmlAutoCompleteTypeList));
