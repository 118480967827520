import React from "react";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    strike: {
        display: "block",
        textAlign: "center",
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "100%",

        "& > span": {
            position: "relative",
            display: "inline-block",

            "&:before, &:after": {
                content: '""',
                position: "absolute",
                top: "50%",
                width: "9999px",
                height: "1px",
                background: "#C7C6C1",
            },
            "&:before": {
                right: "100%",
                marginRight: "15px",
            },
            "&:after": {
                left: "100%",
                marginLeft: "15px",
            },
        },
    },
}));

const Separator = ({ title }) => {
    const classes = useStyles();

    return (
        <div className={classes.strike}>
            <span>{title}</span>
        </div>
    );
};

export default Separator;
