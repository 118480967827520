import { useCallback, useState } from "react";

export const useModalStatus = () => {
    const [modalState, setModalState] = useState(false);

    const close = useCallback(() => setModalState(false), []);
    const open = useCallback(() => setModalState(true), []);

    return { modalState, open, close };
};

export const useContextMenu = () => {
    const [anchorEl, setAnchorEl] = useState(null);

    const open = (event) => {
        event.preventDefault(); // Do not open browser context menu
        setAnchorEl({ top: event.clientY - 4, left: event.clientX - 2 });
    };

    const close = () => setAnchorEl(null);
    return { anchorEl, open, close };
};
