import makeStyles from "@mui/styles/makeStyles";
import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import { OfflineStatusContext } from "../../../contexts/OfflineStatusContext";
import { useIsMobile } from "../../../hooks/mobileHook";
import CircularProgress from "@mui/material/CircularProgress";
import useTranslation from "../translation/translation";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: (props) => (props.isMobile ? "10vh 0.5em" : "10vh auto"),
        maxWidth: (props) => (props.isMobile ? "100%" : "30rem"),

        display: "flex",
        flexDirection: "column",
        padding: "30px",
        border: "1px solid #C7C6C1",
        borderRadius: "5px",
        backgroundColor: "#fff",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    heading: {
        color: "#1D1C3E",
        margin: theme.spacing(1, 0, 1),
    },
    paragraph: {
        margin: theme.spacing(1, 0, 1),
    },
    spinner: {
        position: "fixed",
        left: "50%",
        top: "50%",
    },
}));

const EmptyPageContainer = ({
    children,
    loading,
    empty,
    onNoContentAction,
    noContentActionText,
    emptyTitle,
    emptyInformation,
    inSearchOperation,
}) => {
    const isMobile = useIsMobile();
    const classes = useStyles({ isMobile });
    const offlineStatusContext = OfflineStatusContext.useContainer();
    const { i18n } = useTranslation();

    const emptyInformationComponent = (
        <div className={classes.paper}>
            <Typography className={classes.heading} component="h1" variant="h5">
                {emptyTitle}
            </Typography>
            <Typography className={classes.paragraph} component="p" variant="body2">
                {emptyInformation}
            </Typography>

            {!offlineStatusContext.isOffline && onNoContentAction && (
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} onClick={onNoContentAction}>
                    {noContentActionText || i18n("general.actions.add")}
                </Button>
            )}
        </div>
    );

    if (empty && loading) return <CircularProgress className={classes.spinner} />;
    if (!inSearchOperation && empty && !loading) return emptyInformationComponent;

    return (
        <>
            {children}
            {inSearchOperation && empty && (
                <div className={classes.paper}>
                    <Typography className={classes.heading} component="h1" variant="h5">
                        Es wurde kein Suchergebnis gefunden
                    </Typography>
                </div>
            )}
        </>
    );
};

EmptyPageContainer.defaultProps = {
    loading: false,
    empty: false,
    emptyTitle: "",
    emptyInformation: "",
};

EmptyPageContainer.propTypes = {
    children: PropTypes.node.isRequired,
    empty: PropTypes.bool,
    loading: PropTypes.bool,
    onNoContentAction: PropTypes.func,
    emptyTitle: PropTypes.string,
    emptyInformation: PropTypes.string,
    inSearchOperation: PropTypes.bool,
};

export default EmptyPageContainer;
