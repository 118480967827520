import { isElectron } from "../App";

export const checkIsDevelopmentEnvironment = () => window.location.href.includes("localhost") || window.location.href.includes("127.0.0.1");

export const apiBaseUrl = isElectron()
    ? "https://app.vaulteron.com"
    : checkIsDevelopmentEnvironment()
    ? "https://localhost:3000"
    : window.location.origin;

export const api = Object.freeze({
    login: `${apiBaseUrl}/api/login`,
    twoFactorTotpLogin: `${apiBaseUrl}/api/twofactortotplogin`,
    twoFactorWebAuthnChallenge: `${apiBaseUrl}/api/twofactorwebauthnchallenge`,
    twoFactorWebAuthnLogin: `${apiBaseUrl}/api/twofactorwebauthnlogin`,
    logout: `${apiBaseUrl}/api/logout`,
    register: `${apiBaseUrl}/api/register`,
    setPasswordForOwnAccountByEmailToken: `${apiBaseUrl}/api/setPasswordFromEmailToken`,
    graphql: `${apiBaseUrl}/api/graph`,
    requestEmailConfirmation: `${apiBaseUrl}/api/requestEmailConfirmation`,
    getAllSharedPasswordsOnAccountActivation: `${apiBaseUrl}/api/getPublicKeyAndSharedPasswordsFromEmailToken`,
    resetPassword: `${apiBaseUrl}/api/resetPassword`,
    encryptedResetPasswordByToken: `${apiBaseUrl}/api/encryptedResetPasswordByToken`,
    deviceInformation: `${apiBaseUrl}/addDeviceInformation`,
});
