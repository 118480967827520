import React, { useState } from "react";
import Page from "../common/layout/Page";
import UserSearchBar from "./UserSearchBar";
import ContentContainer from "../common/layout/ContentContainer";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useContextMenu, useModalStatus } from "../../hooks/modalHook";
import { useMyselfQuery } from "../../hooks/queries/userQueryHooks";
import EditUserModal from "./EditUserModal";
import UserTableRowContextMenu from "./UserTableRowContextMenu";
import UserTableRow from "./UserTableRow";
import { ClientFilter } from "../../managers/filterManager";
import UserAddModal from "./AddUserModal";
import Visibility from "../common/dataDisplay/Visibility";
import LicenceErrorModal from "./LicenceErrorModal";
import { OfflineStatusContext } from "../../contexts/OfflineStatusContext";
import { PageTypeList } from "../../types/PageType";
import LicenseInvalidModal from "../common/feedback/modals/LicenseInvalidModal";

import { useGetUsersQuery } from "../../graphql/generated";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";
import useTranslation from "../common/translation/translation";

const UserPage = () => {
    const offlineStatusContext = OfflineStatusContext.useContainer();
    const { i18n } = useTranslation();

    const { modalState: userAddModalState, open: openUserAddModal, close: closeUserAddModal } = useModalStatus();
    const { modalState: userEditModalState, open: openEditAddModal, close: closeUserEditModal } = useModalStatus();
    const { modalState: licenceErrorModalState, open: openLicenceErrorModal, close: closeLicenceErrorModal } = useModalStatus();
    const { modalState: licenseInvalidModalState, open: openLicenseInvalidModal, close: closeLicenseInvalidModal } = useModalStatus();

    const [selectedUserId, setSelectedUserId] = useState("");
    const [filterArchived, setFilterArchived] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const [contextMenuSelectedUser, setContextMenuSelectedUser] = useState({});
    const { anchorEl, open: openContextMenu, close: closeContextMenu } = useContextMenu();

    const { me, isLicenseValid } = useMyselfQuery();

    const { data, loading } = useGetUsersQuery({
        fetchPolicy: "cache-and-network",
    });
    const users = data?.users || [];

    const handleEditUserClicked = (clickedUser) => {
        setSelectedUserId(clickedUser.id);
        openEditAddModal();
    };

    const filteredUsers = new ClientFilter(users).bySearchTerm(["firstname", "lastname", "email"], searchTerm).byArchived(filterArchived).build();
    const nonArchivedUsers = new ClientFilter(users).byArchived(false).build();
    const canAddNewUser = me.mandator.subscriptionUserLimit > nonArchivedUsers.length || me.mandator.customerinformation !== null;

    return (
        <Page currentPageType={PageTypeList.adminConsole} loading={Boolean(loading && users.length === 0)}>
            <UserSearchBar
                onAdd={isLicenseValid ? (canAddNewUser ? openUserAddModal : openLicenceErrorModal) : openLicenseInvalidModal}
                onSearchTerm={setSearchTerm}
                filterArchived={filterArchived}
                onChangeFilterArchived={setFilterArchived}
                addButtonDisabledMessage={offlineStatusContext.isOffline ? "Offline können keine Benutzer angelegt werden" : ""}
            />
            <ContentContainer>
                <PageHeader>{i18n("users.title")}</PageHeader>
                <PageSubTitle>{i18n("users.subTitle")}</PageSubTitle>
                <Table style={{ backgroundColor: "white", border: "1px solid rgb(224, 224, 224)" }}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <Visibility hideOnMobile>
                                <TableCell>E-Mail Address</TableCell>
                            </Visibility>
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredUsers.map((user) => (
                            <UserTableRow
                                key={user.id}
                                user={user}
                                onRowClick={handleEditUserClicked}
                                onContextMenu={(e, u) => {
                                    setContextMenuSelectedUser(u);
                                    openContextMenu(e);
                                }}
                            />
                        ))}
                    </TableBody>
                </Table>
            </ContentContainer>
            <UserTableRowContextMenu anchorEl={anchorEl} onClose={closeContextMenu} onEdit={() => handleEditUserClicked(contextMenuSelectedUser)} />
            {userAddModalState && <UserAddModal onClose={closeUserAddModal} />}
            {userEditModalState && <EditUserModal onClose={closeUserEditModal} userId={selectedUserId} />}
            {licenceErrorModalState && <LicenceErrorModal onClose={closeLicenceErrorModal} />}
            {licenseInvalidModalState && <LicenseInvalidModal onClose={closeLicenseInvalidModal} />}
        </Page>
    );
};

export default UserPage;
