import React, { useEffect, useState } from "react";
import { menu } from "../../managers/pathManager";
import { LoginContext } from "../../contexts/LoginContext";
import LoginInput from "./LoginInput";
import { Button, Checkbox, CircularProgress, FormControlLabel, FormGroup, Grid, Link, Snackbar, Typography } from "@mui/material";
import { CryptoManager } from "../../managers/cryptoManager";
import { HtmlAutoCompleteTypeList } from "../../types/HtmlAutoCompleteType";
import VerticalContentPageContainer from "../common/layout/VerticalContentPageContainer";
import config from "../../config.json";
import { useMaterialUICheckedInput } from "../../hooks/inputHook";
import VaulteronLogoIcon from "../common/dataDisplay/VaulteronLogoIcon";
import { useNavigate } from "react-router-dom";
import useTranslation from "../common/translation/translation";
import LoginPageLayout from "./LoginPageLayout";
import { styled } from "@mui/system";

const Heading = styled(Typography)({
    color: "#1D1C3E",
    marginTop: "5px",
    marginBottom: "15px",
});

const Spacer = styled("div")({
    flexGrow: 1,
});

const Paper = styled("div")({
    margin: "0 auto",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: "30rem",
    padding: "30px",
    border: "1px solid #C7C6C1",
    borderRadius: "5px",
    paddingBottom: "50px",
    backgroundColor: "#fff",
    minHeight: "45em",
    minWidth: "430px",
});

function validateEmail(email) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

function RegisterNavigationButton({ hideBack, prevStep, nextStep, disabled, nextText }) {
    const { i18n } = useTranslation();

    return (
        <>
            <Spacer />
            <Grid container spacing={2}>
                <Grid item xs={6}>
                    {hideBack ? null : (
                        <Button type="submit" fullWidth variant="contained" color="primary" onClick={prevStep}>
                            {i18n("register.previous")}
                        </Button>
                    )}
                </Grid>
                <Grid item xs={6}>
                    <Button disabled={disabled} type="submit" fullWidth variant="contained" color="primary" onClick={nextStep}>
                        {nextText ? nextText : i18n("register.next")}
                    </Button>
                </Grid>
            </Grid>
        </>
    );
}

export default function RegisterPage() {
    const { i18n } = useTranslation();

    useEffect(() => {
        // Redirect if already logged in
        if (loginContext.userPasswordHash) {
            window.location.href = menu.companyPasswords.path;
        } else {
            loginContext.clearLocalData();
        }
    }, []);

    const loginContext = LoginContext.useContainer();

    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [repeatPassword, setRepeatPassword] = useState("");
    const [firstname, setFirstname] = useState("");
    const [lastname, setLastname] = useState("");
    const [companyName, setCompanyName] = useState("");

    const { value: dataProtection, onChange: setDataProtection } = useMaterialUICheckedInput(false);
    const { value: termsConditions, onChange: setTermsConditions } = useMaterialUICheckedInput(false);
    const navigate = useNavigate();

    const [step, setStep] = useState(0);

    const nextStep = () => {
        setStep(step + 1);
    };
    const prevStep = () => {
        setStep(step - 1);
    };

    useEffect(() => {
        let params = new URL(document.location).searchParams;
        let mail = params.get("mail");
        if (validateEmail(mail)) {
            setStep(2);
            setUsername(mail);
        }
    }, []);

    const onRegister = async (e) => {
        e.preventDefault();
        setError("");
        setLoading(true);
        const passwordHash = await CryptoManager.createPasswordHash(password);
        const mandatorName = companyName ? companyName : username;

        try {
            await loginContext.register(username, passwordHash, firstname, lastname, mandatorName);
            navigate(menu.root.path);
        } catch (err) {
            setLoading(false);
            const msg = JSON.parse(err.message);
            setError(msg.message);
        }
    };

    const isRegisterPasswordValid = password && repeatPassword && password.length >= config.minLoginPasswordLength && password === repeatPassword;

    if (loading)
        return (
            <VerticalContentPageContainer hideDrawer>
                <Paper>
                    <VaulteronLogoIcon height="60px" />
                    <Heading component="h1" variant="h5">
                        {i18n("register.created")}
                    </Heading>
                    <CircularProgress />
                </Paper>
            </VerticalContentPageContainer>
        );

    return (
        <LoginPageLayout>
            <Paper>
                <VaulteronLogoIcon height="60px" />
                {step === 0 && (
                    <>
                        <Heading component="h1" variant="h5">
                            {i18n("register.account")}
                        </Heading>
                        <Typography>{i18n("register.enterMail")}</Typography>
                        <LoginInput
                            id="username"
                            name="email"
                            label={i18n("users.emailLong")}
                            autoComplete={HtmlAutoCompleteTypeList.email}
                            autoFocus
                            onChange={setUsername}
                            value={username}
                            error={Boolean(username && !validateEmail(username))}
                            helperText={username && !validateEmail(username) ? i18n("register.validMail") : null}
                        />

                        <RegisterNavigationButton nextStep={nextStep} hideBack disabled={!validateEmail(username)} />
                    </>
                )}
                {step === 1 && (
                    <>
                        <Heading component="h1" variant="h5">
                            {username}
                        </Heading>
                        <Typography>{i18n("register.passwordSubTitle")}</Typography>
                        <LoginInput
                            id="password"
                            name="password"
                            label={i18n("register.password")}
                            type="password"
                            autoComplete={HtmlAutoCompleteTypeList.currentPassword}
                            onChange={setPassword}
                            value={password}
                            error={Boolean(password && password.length < config.minLoginPasswordLength)}
                            helperText={
                                password && password.length < config.minLoginPasswordLength
                                    ? i18n("register.passwordMinLength", { key: "count", value: config.minLoginPasswordLength })
                                    : null
                            }
                        />
                        <LoginInput
                            id="password-repeat"
                            name="password-repeat"
                            label={i18n("register.repeatPassword")}
                            type="password"
                            autoComplete={HtmlAutoCompleteTypeList.currentPassword}
                            onChange={setRepeatPassword}
                            value={repeatPassword}
                            error={Boolean(password && repeatPassword && password !== repeatPassword)}
                            helperText={Boolean(password && repeatPassword && password !== repeatPassword) ? i18n("register.passwordNoMatch") : null}
                        />

                        <RegisterNavigationButton prevStep={prevStep} nextStep={nextStep} disabled={!isRegisterPasswordValid} />
                    </>
                )}

                {step === 2 && (
                    <>
                        <Heading component="h1" variant="h5">
                            {i18n("register.information")}
                        </Heading>
                        <Typography>{i18n("register.informationSubTitle")}</Typography>
                        <h3>{i18n("register.contact")}</h3>
                        <LoginInput name="firstname" label={i18n("users.firstName")} onChange={setFirstname} value={firstname} />
                        <LoginInput name="lastname" label={i18n("users.lastName")} onChange={setLastname} value={lastname} />

                        <h3>{i18n("register.companyName")}</h3>
                        <LoginInput
                            name="companyname"
                            label={i18n("register.companyName")}
                            required={false}
                            onChange={setCompanyName}
                            value={companyName}
                        />

                        <RegisterNavigationButton prevStep={prevStep} nextStep={nextStep} disabled={!firstname || !lastname} />
                    </>
                )}

                {step === 3 && (
                    <>
                        <Heading component="h1" variant="h5">
                            {i18n("register.approvals")}
                        </Heading>
                        <Typography style={{ marginBottom: "20px" }}>{i18n("register.approvalsSubTitle")}</Typography>
                        <FormGroup>
                            <FormControlLabel
                                control={<Checkbox checked={dataProtection} onChange={setDataProtection} />}
                                label={
                                    <>
                                        {i18n("register.privacyPolicy")}
                                        <Link target="_blank" href="https://vaulteron.com/datenschutzerklarung/" variant="body1">
                                            {i18n("register.read")}
                                        </Link>
                                    </>
                                }
                            />
                            <FormControlLabel
                                control={<Checkbox checked={termsConditions} onChange={setTermsConditions} />}
                                label={
                                    <>
                                        {i18n("register.termsAndConditions")}
                                        <Link target="_blank" href="https://vaulteron.com/vaulteron-agbs/" variant="body1">
                                            {i18n("register.read")}
                                        </Link>
                                    </>
                                }
                            />
                        </FormGroup>

                        <RegisterNavigationButton
                            prevStep={prevStep}
                            nextStep={onRegister}
                            disabled={!dataProtection || !termsConditions}
                            nextText={i18n("register.register")}
                        />
                    </>
                )}
            </Paper>

            <Snackbar message={error} open={error !== ""} onClose={() => setError(undefined)} />
        </LoginPageLayout>
    );
}
