import React from "react";
import makeStyles from "@mui/styles/makeStyles";
import classnames from "classnames";
import PropTypes from "prop-types";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        padding: "0 20px",
    },
}));

const ContentContainer = ({ children, className }) => {
    const classes = useStyles();

    return <div className={classnames(className, classes.contentContainer)}>{children}</div>;
};

ContentContainer.defaultProps = {
    className: "",
};

ContentContainer.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};

export default ContentContainer;
