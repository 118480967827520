import { useMemo } from "react";
import { useGroupByParentIdQuery } from "../../../hooks/queries/groupQueryHooks";
import { ClientFilter, defaultPasswordSearchTermFields } from "../../../managers/filterManager";
import { useGetGroupPasswordLazyQuery, useGetUsersInGroupLazyQuery } from "../../../graphql/generated";

export const useGroupLoading = ({ groupId, userInGroupSearchTerm, passwordInGroupSearchTerm, subGroupInGroupSearchTerm }) => {
    const [getUsersInGroup, { loading: userGroupLoading, error: userGroupError, data: userGroupData }] = useGetUsersInGroupLazyQuery({
        variables: { groupIds: groupId },
        fetchPolicy: "cache-and-network",
    });

    const [getSharedPassword, { loading: passwordGroupLoading, error: passwordGroupError, data: passwordGroupData }] = useGetGroupPasswordLazyQuery({
        variables: { groupIds: [groupId] },
        fetchPolicy: "cache-and-network",
    });

    const { groups: subGroups, error: subGroupError, loading: subGroupLoading } = useGroupByParentIdQuery(groupId, subGroupInGroupSearchTerm);

    const usersInGroup = useMemo(() => {
        if (!userGroupData) return [];

        return new ClientFilter(userGroupData.users).byArchived(false).bySearchTerm(["firstname", "lastname"], userInGroupSearchTerm).build();
    }, [userGroupData, userInGroupSearchTerm]);

    const passwords = useMemo(() => {
        if (!passwordGroupData) return [];

        return new ClientFilter(passwordGroupData.sharedPasswords)
            .byArchived(false)
            .bySearchTerm(defaultPasswordSearchTermFields, passwordInGroupSearchTerm)
            .byGroups(groupId)
            .build();
    }, [passwordGroupData, passwordInGroupSearchTerm]);

    const loadAll = () => {
        getUsersInGroup();
        getSharedPassword();
    };

    const error = { userInGroup: userGroupError };

    const hasError = useMemo(() => userGroupError || passwordGroupError || subGroupError, [userGroupError, passwordGroupError, subGroupError]);
    const loading = userGroupLoading || passwordGroupLoading || subGroupLoading;

    return {
        loadAll,
        usersInGroup,
        unfilteredUsersInGroup: userGroupData?.users || [],
        unfilteredPasswords: passwordGroupData?.sharedPasswords || [],
        passwords,
        subGroups,
        hasError,
        error,
        loading,
    };
};
