import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { useMyselfQuery, useUsersQuery } from "../../../../hooks/queries/userQueryHooks";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/lab/Autocomplete";
import { ClientFilter } from "../../../../managers/filterManager";

const UserSelect = ({ value, onChange, disableClearable }) => {
    const { users } = useUsersQuery("", [], [], false);
    const { me } = useMyselfQuery();

    const getOption = (user) => ({
        label: user.firstname + " " + user.lastname,
        id: user.id,
    });

    const options = useMemo(() => {
        const activeUsers = new ClientFilter(users).byArchived(false).build();

        return activeUsers.map(getOption).sort((user1, user2) => {
            return user1.label.toUpperCase().localeCompare(user2.label.toUpperCase());
        });
    }, [users]);

    const defaultValue = getOption(me);

    const currentOption = useMemo(() => options.find((option) => option.id === value), [value, options]);

    return (
        <Autocomplete
            value={currentOption}
            defaultValue={defaultValue}
            onChange={onChange}
            options={options}
            getOptionLabel={(option) => option.label}
            fullWidth
            disableClearable={disableClearable}
            noOptionsText="Kein Nutzer gefunden"
            clearText="Löschen"
            size="small"
            renderInput={(params) => <TextField {...params} label="Besitzer" variant="outlined" />}
        />
    );
};

UserSelect.defaultProps = {};

UserSelect.propTypes = {
    value: PropTypes.array,
    onChange: PropTypes.func.isRequired,
    disableClearable: PropTypes.bool,
};

export default UserSelect;
