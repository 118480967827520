import React, { useState } from "react";
import * as PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { Badge, ClickAwayListener, Fade, Grid, Paper, Popper, Select } from "@mui/material";
import FilterButton from "../../dataDisplay/FilterButton";
import { IconTypes } from "../../../../types/IconType";
import GroupFilter from "./GroupFilter";
import useTranslation from "../../translation/translation";
import PasswordTypeSelect from "./PasswordTypeSelect";
import { styled } from "@mui/system";

const useStyles = makeStyles((theme) => ({
    subbar: {
        padding: "0.3rem 1rem",
        backgroundColor: "white",
        justifyContent: "space-between",
        borderTop: "2px solid #a0a0a080",
    },
    filters: {
        display: "flex",
        alignItems: "center",
    },
    filterTitle: {
        fontSize: ".9rem",
        marginRight: "20px",
        color: theme.colors.textLight,
    },
    popperPaper: {
        minWidth: "300px",
        maxWidth: "50vw",
        margin: "3px 3px",
    },
}));

const filterOptions = {
    group: "group",
    type: "type",
};

export const StyledContainer = styled("div")({
    "& > div": {
        "margin-top": ".3em",
        "font-size": " 1.1em",
        color: "rgb(114, 114, 114)",
    },
});

function PasswordFilterBar({ groups, onGroups, type, onType }) {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const [anchorEl, setAnchorEl] = useState(null);
    const [currentOpenFilterPopper, setCurrentOpenFilterPopper] = useState(undefined);

    const handleGroupFilter = (e) => {
        setCurrentOpenFilterPopper(filterOptions.group);
        setAnchor(e);
    };

    const setAnchor = (e) => {
        setAnchorEl(e.currentTarget);
    };

    const handleCloseAnchorEl = () => {
        setAnchorEl(null);
    };

    const getButtonWithBadge = (button, number = 0) => {
        return (
            <Badge
                overlap="rectangular"
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                }}
                badgeContent={number}
                color="primary"
            >
                {button}
            </Badge>
        );
    };

    return (
        <>
            <Grid container className={classes.subbar}>
                <Grid>
                    <Grid container justifyContent="center">
                        <Grid className={classes.filters}>
                            <span className={classes.filterTitle}>{i18n("filter.title")}</span>
                            {onType &&
                                getButtonWithBadge(
                                    <FilterButton
                                        activated={false}
                                        iconType={IconTypes.allPasswords}
                                        text={
                                            <StyledContainer>
                                                <PasswordTypeSelect isFilter={true} value={type} onChange={onType} />
                                            </StyledContainer>
                                        }
                                    />,
                                    type === "none" ? 0 : 1
                                )}
                            {onGroups &&
                                getButtonWithBadge(
                                    <FilterButton
                                        activated={false}
                                        onClick={handleGroupFilter}
                                        iconType={IconTypes.users}
                                        text={i18n("filter.groups.name")}
                                    />,
                                    groups.length
                                )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <Popper open={Boolean(anchorEl)} anchorEl={anchorEl} placement="bottom-start" transition disablePortal>
                {({ TransitionProps }) => (
                    <ClickAwayListener onClickAway={handleCloseAnchorEl}>
                        <Fade {...TransitionProps} timeout={350}>
                            <Paper className={classes.popperPaper}>
                                {currentOpenFilterPopper === filterOptions.group && <GroupFilter checked={groups} setChecked={onGroups} />}
                            </Paper>
                        </Fade>
                    </ClickAwayListener>
                )}
            </Popper>
        </>
    );
}

PasswordFilterBar.propTypes = {
    onGroups: PropTypes.func,
    groups: PropTypes.array,
    onType: PropTypes.func,
    type: PropTypes.string,
};

export default PasswordFilterBar;
