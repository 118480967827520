/**
 * 
 * @param {SharedPasswordType[]} passwords
 * @returns {Map<Guid, SharedPasswordType[]>}
 */
export const groupPasswordsByCreatorIdToMap = (passwords) => {
    const map = new Map();
    for (const password of passwords) {
        const creatorGuid = password.createdBy.id;
        if (!map.has(creatorGuid)) map.set(creatorGuid, []);
        map.get(creatorGuid).push(password);
    }
    return map;
}


/**
 *
 * @param {SharedPasswordType[]} passwords
 * @returns {Map<Guid, SharedPasswordType[]>}
 */
export const groupPasswordsByGroupsToMap = (passwords) => {
    const map = new Map();
    for (const password of passwords) {
        const creatorGuid = password.groupId;
        if (!map.has(creatorGuid)) map.set(creatorGuid, []);
        map.get(creatorGuid).push(password);
    }
    return map;
}