import React, { useState } from "react";
import PropTypes from "prop-types";
import CustomModal from "../../common/feedback/modals/CustomModal";
import { FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup } from "@mui/material";
import { groupRole } from "../../../managers/groupManager";
import { useEditUserInGroupAwaitRefetchMutation } from "../../../hooks/queries/userQueryHooks";

const GroupRoleModal = ({ onClose, groupId, userId, currentRole }) => {
    const [role, setRole] = useState(currentRole);
    const { editUserInGroup, loading, error } = useEditUserInGroupAwaitRefetchMutation(groupId);

    const [errorMessage, setErrorMessage] = useState("");

    const handleOk = async () => {
        try {
            const user = { userId, groupId, groupRole: role };
            await editUserInGroup({ variables: { user: user } });
            onClose();
        } catch (e) {
            console.error(e);
            setErrorMessage(`Es war leider nicht möglich die Rolle zu ändern. Bitte laden Sie die Seite neu und versuchen es erneut`);
        }
    };

    return (
        <CustomModal
            maxWidth="sm"
            onClose={onClose}
            saveText="Aktualisieren"
            onOk={handleOk}
            title="Gruppenrolle ändern"
            loading={loading}
            error={error || errorMessage !== ""}
            errorMessage={errorMessage}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <FormControl component="fieldset" error={false}>
                        <FormLabel component="legend">Ändern Sie die Rolle dieser Person</FormLabel>
                        <RadioGroup value={role} onChange={(e) => setRole(e.target.value)}>
                            <FormControlLabel value={groupRole.admin} control={<Radio />} label="Administrator" />
                            <FormControlLabel value={groupRole.editor} control={<Radio />} label="Editor" />
                            <FormControlLabel value={groupRole.viewer} control={<Radio />} label="Beobachter" />
                        </RadioGroup>
                    </FormControl>
                </Grid>
            </Grid>
        </CustomModal>
    );
};
GroupRoleModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    groupId: PropTypes.string.isRequired,
    userId: PropTypes.string.isRequired,
    currentRole: PropTypes.string,
};

export default GroupRoleModal;
