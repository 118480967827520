import React from "react";
import PropTypes from "prop-types";
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const localeTexts = {
    // Root
    noRowsLabel: "Keine Daten verfügbar",
    noResultsOverlayLabel: "Keine Daten verfügbar.",
    errorOverlayDefaultLabel: "Ein Fehler ist aufgetreten.",

    // Density selector toolbar button text
    toolbarDensity: "Dichte",
    toolbarDensityLabel: "Dichte",
    toolbarDensityCompact: "Kompakt",
    toolbarDensityStandard: "Standard",
    toolbarDensityComfortable: "Komfortabel",

    // Columns selector toolbar button text
    toolbarColumns: "Spalten",
    toolbarColumnsLabel: "Spalten auswählen",

    // Filters toolbar button text
    toolbarFilters: "Filter",
    toolbarFiltersLabel: "Filter anzeigen",
    toolbarFiltersTooltipHide: "Filter ausblenden",
    toolbarFiltersTooltipShow: "Filter einblenden",
    toolbarFiltersTooltipActive: (count) => (count !== 1 ? `${count} aktive Filter` : `${count} aktive Filter`),

    // Export selector toolbar button text
    toolbarExport: "Exportieren",
    toolbarExportLabel: "Exportieren",
    toolbarExportCSV: "Als CSV-Datei herunterladen",

    // Columns panel text
    columnsPanelTextFieldLabel: "Spalte finden",
    columnsPanelTextFieldPlaceholder: "Spaltentitel",
    columnsPanelDragIconLabel: "Spalten umsortieren",
    columnsPanelShowAllButton: "Alle anzeigen",
    columnsPanelHideAllButton: "Alle ausblenden",

    // Filter panel text
    filterPanelAddFilter: "Filter hinzufügen",
    filterPanelDeleteIconLabel: "Löschen",
    filterPanelOperators: "Operatoren",
    filterPanelOperatorAnd: "Und",
    filterPanelOperatorOr: "Oder",
    filterPanelColumns: "Spalten",
    filterPanelInputLabel: "Wert",
    filterPanelInputPlaceholder: "Wert filtern",

    // Filter operators text
    filterOperatorContains: "beinhaltet",
    filterOperatorEquals: "entspricht",
    filterOperatorStartsWith: "beginnt mit",
    filterOperatorEndsWith: "endet mit",
    filterOperatorIs: "ist",
    filterOperatorNot: "ist nicht",
    filterOperatorAfter: "kommt dannach",
    filterOperatorOnOrAfter: "kommt gleich oder danach",
    filterOperatorBefore: "kommt davor",
    filterOperatorOnOrBefore: "kommt gleich oder davor",
    filterOperatorIsEmpty: "ist leer",
    filterOperatorIsNotEmpty: "ist nicht leer",

    // Filter values text
    filterValueAny: "any",
    filterValueTrue: "true",
    filterValueFalse: "false",

    // Column menu text
    columnMenuLabel: "Menü",
    columnMenuShowColumns: "Spalten anzeigen",
    columnMenuFilter: "Filtern",
    columnMenuHideColumn: "Ausblenden",
    columnMenuUnsort: "Umsortieren",
    columnMenuSortAsc: "Aussteigend sortieren",
    columnMenuSortDesc: "Absteigend sortieren",

    // Column header text
    columnHeaderFiltersTooltipActive: (count) => (count !== 1 ? `${count} aktive Filter` : `${count} aktive Filter`),
    columnHeaderFiltersLabel: "Filter anzeigen",
    columnHeaderSortIconLabel: "Sortieren",

    // Rows selected footer text
    footerRowSelected: (count) => (count !== 1 ? `${count.toLocaleString()} Zeilen ausgewählt` : `${count.toLocaleString()} Zeile ausgewählt`),

    // Total rows footer text
    footerTotalRows: "Gesamtanzahl an Spalten:",

    // Total visible rows footer text
    footerTotalVisibleRows: (visibleCount, totalCount) => `${visibleCount.toLocaleString()} von ${totalCount.toLocaleString()}`,

    // Checkbox selection text
    checkboxSelectionHeaderName: "Checkbox-Auswahl",

    // Boolean cell text
    booleanCellTrueLabel: "true",
    booleanCellFalseLabel: "false",

    // Actions cell more text
    actionsCellMore: "Mehr",

    // Used core components translation keys
    MuiTablePagination: {},
};

const CustomDataGrid = ({ rows, columns, pageSize, exportable, disableColumnMenu }) => {
    return (
        <Box sx={{ height: 400, width: "100%" }}>
            <DataGrid
                localeText={localeTexts}
                rows={rows || []}
                columns={columns || []}
                initialState={{
                    pagination: {
                        paginationModel: {
                            pageSize: pageSize,
                        },
                    },
                }}
                pageSizeOptions={[5]}
                checkboxSelection={false}
                disableRowSelectionOnClick
                disableColumnMenu={disableColumnMenu}
                exportable={exportable}
            />
        </Box>
    );
};
CustomDataGrid.defaultProps = {
    pageSize: 30,
    exportable: false,
    disableColumnMenu: false,
};
CustomDataGrid.propTypes = {
    rows: PropTypes.array.isRequired,
    columns: PropTypes.array.isRequired,
    pageSize: PropTypes.number,
    exportable: PropTypes.bool,
    disableColumnMenu: PropTypes.bool,
};

export default CustomDataGrid;
