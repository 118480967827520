import { Avatar, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText } from "@mui/material";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import DeleteIcon from "@mui/icons-material/Delete";
import AddIcon from "@mui/icons-material/Add";
import React, { useState } from "react";
import useTranslation from "../common/translation/translation";
import { useMyselfQuery } from "../../hooks/queries/userQueryHooks";
import { styled } from "@mui/system";
import {
    GetMyselfDocument,
    useAddWebautnMutation,
    useGetWebAuthnChallengeMutation,
    useRemoveWebauthnMutation,
    WebauthnKeyType,
} from "../../graphql/generated";
import { TwoFAManager } from "../../managers/twofactorManager";
import { useModalStatus } from "../../hooks/modalHook";
import TextField from "@mui/material/TextField";
import CustomModal from "../common/feedback/modals/CustomModal";
import ConfirmationDialog from "../common/feedback/modals/ConfirmationDialog";

const StyledListButton = styled(ListItemButton)({
    paddingLeft: 0,
});

export default function WebAuthnSection() {
    const { i18n } = useTranslation();
    const { me } = useMyselfQuery();
    const { registeredWebAuthnKeys } = me;

    const [addWebautn] = useAddWebautnMutation({ refetchQueries: [GetMyselfDocument] });
    const [removeWebautn] = useRemoveWebauthnMutation({ refetchQueries: [GetMyselfDocument] });

    const [getWebauthnChallenge] = useGetWebAuthnChallengeMutation();
    const { modalState: webAuthnNameModalState, open: openWebAuthnNameModal, close: closeWebAuthnNameModal } = useModalStatus();
    const { modalState: confirmDeleteModalState, open: openConfirmDeleteModal, close: closeConfirmDeleteModal } = useModalStatus();

    const [currentAttestationResponse, setCurrentAttestationResponse] = useState<string>("");
    const [currentName, setCurrentName] = useState<string>("");
    const [currentWebAuthnId, setCurrentWebAuthnId] = useState<string>("");

    return (
        <>
            {registeredWebAuthnKeys?.length > 0
                ? registeredWebAuthnKeys.map((authKey: WebauthnKeyType) => {
                      return (
                          <StyledListButton
                              onClick={() => {
                                  setCurrentWebAuthnId(authKey.id);
                                  openConfirmDeleteModal();
                              }}
                          >
                              <ListItemAvatar>
                                  <Avatar>
                                      <VpnKeyIcon />
                                  </Avatar>
                              </ListItemAvatar>
                              <ListItemText
                                  primary={authKey.description || "-"}
                                  secondary={i18n("settings.twoFactor.webAuthn.deactivateDescription")}
                              />
                              <ListItemSecondaryAction>
                                  <DeleteIcon />
                              </ListItemSecondaryAction>
                          </StyledListButton>
                      );
                  })
                : null}

            <StyledListButton
                onClick={() => {
                    getWebauthnChallenge().then((e) => {
                        if (e.data?.user?.twofactor?.getWebauthnChallenge?.optionsJson) {
                            TwoFAManager.registerFidoKey(e.data.user.twofactor.getWebauthnChallenge.optionsJson).then((attestationResponse) => {
                                setCurrentAttestationResponse(attestationResponse);
                                openWebAuthnNameModal();
                            });
                        }
                    });
                }}
            >
                <ListItemAvatar>
                    <Avatar>
                        <VpnKeyIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText
                    primary={i18n("settings.twoFactor.webAuthn.activateTitle")}
                    secondary={i18n("settings.twoFactor.webAuthn.activateDescription")}
                />
                <ListItemSecondaryAction>
                    <AddIcon />
                </ListItemSecondaryAction>
            </StyledListButton>

            {confirmDeleteModalState ? (
                <ConfirmationDialog
                    okText={i18n("general.actions.ok")}
                    cancelText={i18n("general.actions.cancel")}
                    title={i18n("settings.twoFactor.webAuthn.deactivateDescription")}
                    description=""
                    onOk={() => {
                        removeWebautn({ variables: { webauthnKeyId: currentWebAuthnId } }).then(closeConfirmDeleteModal);
                    }}
                    onClose={closeConfirmDeleteModal}
                />
            ) : null}
            {webAuthnNameModalState ? (
                <CustomModal
                    title={i18n("settings.twoFactor.webAuthn.nameModalTitle")}
                    onClose={closeWebAuthnNameModal}
                    onOk={() => {
                        addWebautn({ variables: { description: currentName, attestationResponse: currentAttestationResponse } }).then(
                            closeWebAuthnNameModal
                        );
                    }}
                    error={false}
                    loading={false}
                    saveText={i18n("general.actions.ok")}
                    withCancelButton={false}
                    okButtonEnabled={Boolean(currentName)}
                >
                    <TextField autoFocus label="Name of the key" fullWidth onChange={(e) => setCurrentName(e.target.value)} value={currentName} />
                </CustomModal>
            ) : null}
        </>
    );
}
