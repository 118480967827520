import { useCallback, useState } from "react";
import { createContainer } from "../hooks/ContextHook";
import { localStorageManager } from "../managers/localStorageManager";

/**
 *
 * @param {CachePersistor<NormalizedCacheObject>} initialState
 * @returns {{clearCache: ((function(): void)|*), setPersistor: (value: unknown) => void, persistor: unknown, waitingNewWorker: Object, newVersionAvailable: boolean, setNewVersionAvailable: (value: (((prevState: boolean) => boolean) | boolean)) => void, operationsQueue, isAppInstalled: (*|boolean), isOffline: boolean, setWaitingNewWorker: (value: unknown) => void}}
 */
function useOfflineStatusContext() {
    const [persistor, setPersistor] = useState();
    const [offlineLink, setOfflineLink] = useState();

    const [newVersionAvailable, setNewVersionAvailable] = useState(localStorageManager.isServiceWorkerUpdateAvailable());
    const [waitingNewWorker, setWaitingNewWorker] = useState(null);
    const [pwaInstallEvent, setPwaInstallEvent] = useState(null);

    const [isOffline, setIsOffline] = useState(!Boolean(window.navigator?.onLine) || false);

    const clearCache = useCallback(async () => {
        if (!persistor) {
            return async () => {};
        }
        return persistor.purge;
    }, [persistor]);

    const handleOffline = () => {
        setIsOffline(true);
        offlineLink?.close();
    };
    const handleOnline = () => {
        setIsOffline(false);
        offlineLink?.open();
    };

    window.addEventListener("offline", handleOffline);
    window.addEventListener("online", handleOnline);

    const isAppInstalled = navigator.standalone || window.matchMedia("(display-mode: standalone)").matches;

    const showInstallPwaPrompt = async () => {
        if (!pwaInstallEvent) return;

        pwaInstallEvent.prompt();
        const { outcome } = await pwaInstallEvent.userChoice;
        setPwaInstallEvent(null);
    };

    return {
        persistor,
        setPersistor,
        clearCache,

        newVersionAvailable,
        setNewVersionAvailable,

        waitingNewWorker,
        setWaitingNewWorker,

        setOfflineLink,
        isOffline,
        setIsOffline: (offline) => {
            if (offline) handleOffline();
            else handleOnline();
        },

        isAppInstalled,
        setPwaInstallEvent,
        isAppInstallerReady: Boolean(pwaInstallEvent),
        showInstallPwaPrompt,
    };
}

export const OfflineStatusContext = createContainer(useOfflineStatusContext);
