import React, { useCallback } from "react";
import PropTypes from "prop-types";
import CustomModal from "../../feedback/modals/CustomModal";
import { Grid } from "@mui/material";
import { PasswordType } from "../../../../types/PasswordType";
import CustomDataGrid from "../../dataDisplay/CustomDataGrid";
import { LoggedColumnRenderCell } from "./LoggedColumnRenderCell";
import { useMyselfQuery } from "../../../../hooks/queries/userQueryHooks";
import { LoginContext } from "../../../../contexts/LoginContext";
import { useGenerateHistoryRow } from "./useGenerateHistoryRow";
import useTranslation from "../../translation/translation";

const PasswordHistoryModal = ({ onClose, password }) => {
    const { me } = useMyselfQuery();
    const { i18n } = useTranslation();

    const loginContext = LoginContext.useContainer();
    const { data, loading, changeLogs, error } = useGenerateHistoryRow(password, me, loginContext);

    const getMinLength = useCallback(
        (propertyName) => {
            const filteredChangeLogs = changeLogs.filter((logs) => logs.propertyName?.toLowerCase() === propertyName.toLowerCase());
            if (!filteredChangeLogs.length) return 0;

            const names = filteredChangeLogs.map((log) => (log.newValue?.name || "") + " " + (log.oldValue?.name || ""));
            return measureTextOnCanvas(getLongestText(names));
        },
        [changeLogs]
    );

    const getMinLenghtOfPassword = useCallback(() => {
        const names = data.map((entry) => (entry.encryptedPasswordString?.oldValue || "") + " " + (entry.encryptedPasswordString?.newValue || ""));
        return measureTextOnCanvas(getLongestText(names));
    }, [data]);

    const measureTextOnCanvas = (text) => {
        if (!text) return 0;

        const canvas = document.createElement("canvas");
        const ctx = canvas.getContext("2d");
        ctx.font = "16px Roboto";
        return ctx.measureText(text).width;
    };

    const getLongestText = (textArray) => {
        return textArray.reduce((a, b) => (a.length > b.length ? a : b), 0);
    };

    const columns = [
        {
            field: "formattedCreatedAt",
            headerName: i18n("password.history.date"),
            minWidth: 50,
            flex: 1,
            renderCell: (cellParams) => <LoggedColumnRenderCell cellParams={cellParams} />,
        },
        {
            field: "changedBy",
            headerName: i18n("password.history.changedBy"),
            minWidth: 200,
            flex: 1,
            renderCell: (cellParams) => <LoggedColumnRenderCell cellParams={cellParams} />,
        },
        {
            field: "name",
            headerName: i18n("password.information.name"),
            minWidth: getMinLength("name"),
            flex: 1,
            renderCell: (cellParams) => <LoggedColumnRenderCell cellParams={cellParams} />,
        },
        {
            field: "encryptedPasswordString",
            headerName: i18n("password.information.password"),
            minWidth: getMinLenghtOfPassword(),
            flex: 1,
            renderCell: (cellParams) => <LoggedColumnRenderCell cellParams={cellParams} />,
        },
        {
            field: "websiteURL",
            headerName: i18n("password.information.website"),
            minWidth: getMinLength("websiteURL"),
            flex: 1,
            renderCell: (cellParams) => <LoggedColumnRenderCell cellParams={cellParams} />,
        },
        {
            field: "login",
            headerName: i18n("password.information.username"),
            minWidth: getMinLength("login"),
            flex: 1,
            renderCell: (cellParams) => <LoggedColumnRenderCell cellParams={cellParams} />,
        },
        {
            field: "notes",
            headerName: i18n("password.information.description"),
            minWidth: getMinLength("notes"),
            flex: 1,
            renderCell: (cellParams) => <LoggedColumnRenderCell cellParams={cellParams} />,
        },
    ];

    return (
        <CustomModal
            onClose={onClose}
            maxWidth={data.length <= 1 ? "sm" : "xl"}
            saveText="OK"
            withCancelButton={false}
            onOk={onClose}
            title={i18n("password.history.title")}
            loading={loading}
            error={Boolean(error)}
            errorMessage={i18n("general.error.unexpected")}
        >
            <Grid container>
                <Grid item xs={12}>
                    {data.length <= 1 ? <p>{i18n("password.history.notChanged")}</p> : <CustomDataGrid columns={columns} rows={data} />}
                </Grid>
            </Grid>
        </CustomModal>
    );
};

PasswordHistoryModal.propTypes = {
    password: PasswordType.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default PasswordHistoryModal;
