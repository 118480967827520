import React, { useMemo } from "react";
import PropTypes from "prop-types";
import { TextField, Autocomplete } from "@mui/material";
import { useMandatorsQuery } from "../../../../hooks/queries/mandatorHook";

const MandatorSelect = ({ value, onChange }) => {
    const { mandators } = useMandatorsQuery();

    const currentOption = useMemo(() => mandators.find((option) => option.id === value), [value, mandators]);

    return (
        <Autocomplete
            value={currentOption}
            onChange={onChange}
            options={mandators}
            getOptionLabel={(option) => option.name}
            fullWidth
            noOptionsText="Kein Mandator gefunden"
            clearText="Löschen"
            size="small"
            renderInput={(params) => <TextField {...params} label="Mandator" variant="outlined" />}
        />
    );
};

MandatorSelect.propTypes = {
    value: PropTypes.object,
    onChange: PropTypes.func.isRequired,
};

export default MandatorSelect;
