export const defaultPasswordSearchTermFields = ["name", "login", "websiteURL", "notes"];

export class ClientFilter {
    /**
     * Constructor
     *
     * @param {Object[]} arrayToFilter
     */
    constructor(arrayToFilter) {
        this.arrayToFilter = arrayToFilter;
    }

    /**
     * Filter array by checking if any param include the searchTerm (case insensitive).
     * Ignore the filter methode if params or searchTerm is not set.
     *
     * @param {string[]} params
     * @param {string} searchTerm
     * @returns {ClientFilter}
     */
    bySearchTerm(params, searchTerm) {
        if (!searchTerm || searchTerm === "" || !params || params.length === 0) return this;

        this.arrayToFilter = this.arrayToFilter.filter((a) => {
            return params.some((p) => a[p]?.toLowerCase().includes(searchTerm.toLowerCase()));
        });
        return this;
    }

    /**
     * Filter array by checking if tag is included
     * Ignore the filter methode if tags are not set
     *
     * @param tags
     * @returns {ClientFilter}
     */
    byTags(tags) {
        if (!tags || tags.length === 0) return this;

        const tagIds = tags.map((t) => t.id);
        this.arrayToFilter = this.arrayToFilter.filter((a) => a.tags && a.tags.map((t) => t.id).some((tagId) => tagIds.includes(tagId)));
        return this;
    }

    /**
     * Filter array by checking if groups is included
     * Ignore the filter methode if groups are not set
     *
     * @param groupIds
     * @returns {ClientFilter}
     */
    byGroups(groupIds) {
        if (!groupIds || groupIds.length === 0) return this;

        this.arrayToFilter = this.arrayToFilter.filter((a) => groupIds.includes(a.groupId));
        return this;
    }

    /**
     * Filter array by excluding given ids
     *
     * @param {string[]} ids
     * @returns {ClientFilter}
     */
    excludeIds(ids) {
        if (!ids || ids.length === 0) return this;

        this.arrayToFilter = this.arrayToFilter.filter((a) => !ids.some((id) => id === a.id));
        return this;
    }

    /**
     * Filter array by the given ids
     * @param {Guid[]} ids
     * @returns {ClientFilter}
     */
    byIds(ids) {
        if (!ids || ids.length === 0) return this;

        this.arrayToFilter = this.arrayToFilter.filter((a) => ids.includes(a.id));
        return this;
    }

    /**
     * Filter array by checking if archivedAt is set
     *
     * @returns {ClientFilter}
     */
    byUnArchived() {
        this.arrayToFilter = this.arrayToFilter.filter((a) => !a.archivedAt);
        return this;
    }

    /**
     * Filter array by checking if archivedAt is set
     *
     * @param {boolean | null} byArchived
     * @returns {ClientFilter}
     */
    byArchived(byArchived) {
        if (byArchived === null || byArchived === undefined) return this;
        this.arrayToFilter = this.arrayToFilter.filter((a) => Boolean(a.archivedAt) === byArchived);
        return this;
    }

    /**
     * Sort array by given entity
     *
     * @param {string} entity
     * @returns {ClientFilter}
     */
    sortBy(entity) {
        this.arrayToFilter = this.arrayToFilter.sort((g1, g2) => g1[entity].localeCompare(g2[entity]));
        return this;
    }

    /**
     * Filter array by a custom filter callback
     *
     * @param filterCB
     * @returns {ClientFilter}
     */
    byCustomFilter(filterCB) {
        if (!filterCB instanceof Function) return this;

        this.arrayToFilter = this.arrayToFilter.filter(filterCB);
        return this;
    }

    /**
     * Returns the filtered array
     *
     * @returns {Object[]}
     */
    build() {
        return this.arrayToFilter;
    }
}
