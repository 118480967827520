import React, { useEffect } from "react";
import SearchBar from "./SearchBar";
import GroupBreadcrumb from "../../groups/GroupBreadcrumb";
import PropTypes from "prop-types";
import { useDelayedMaterialUIInput } from "../../../hooks/inputHook";
import { useIsMobile } from "../../../hooks/mobileHook";
import useTranslation from "../translation/translation";

const GroupSearchBar = ({ currentGroupId, onBreadCrumbClick, onAdd, onSearchTerm, addButtonDisabledMessage, onBreadCrumbCurrentGroupClick }) => {
    const { value: searchTerm, localValue: localSearchTerm, onChange: setLocalSearchTerm } = useDelayedMaterialUIInput("");
    const isMobile = useIsMobile();
    const { i18n } = useTranslation();

    useEffect(() => {
        onSearchTerm(searchTerm);
    }, [searchTerm]);

    return (
        <SearchBar
            placeholder={isMobile ? i18n("search.groupSearchPlaceholderMobile") : i18n("search.groupSearchPlaceholder")}
            value={localSearchTerm}
            onChange={setLocalSearchTerm}
            onAdd={onAdd}
            addButtonDisabledMessage={addButtonDisabledMessage}
            subBar={
                <GroupBreadcrumb onClickCurrentGroup={onBreadCrumbCurrentGroupClick} onClick={onBreadCrumbClick} currentGroupId={currentGroupId} />
            }
        />
    );
};

GroupSearchBar.defaultProps = {
    addButtonDisabledMessage: undefined,
};

GroupSearchBar.propTypes = {
    currentGroupId: PropTypes.string.isRequired,
    onBreadCrumbClick: PropTypes.func.isRequired,
    onBreadCrumbCurrentGroupClick: PropTypes.func.isRequired,
    onAdd: PropTypes.func,
    onSearchTerm: PropTypes.func.isRequired,
    addButtonDisabledMessage: PropTypes.string,
};

export default GroupSearchBar;
