import { useEffect, useMemo, useState } from "react";
import Page from "../common/layout/Page";
import GroupSearchBar from "../common/dataEntry/GroupSearchBar";
import makeStyles from "@mui/styles/makeStyles";
import GroupModal from "./modals/GroupModal";
import { useContextMenu, useModalStatus } from "../../hooks/modalHook";
import GroupAddModal from "./modals/AddGroupModal";
import PasswordModal from "../common/passwordComponents/PasswordModal";
import GroupRoleModal from "./modals/GroupRoleModal";
import { guid, isGuidEmpty } from "../../managers/guidManager";
import { useAllGroupsQuery, useGroupMutation, useGroupTreeQuery } from "../../hooks/queries/groupQueryHooks";
import { useMyselfQuery, useMyselfRole } from "../../hooks/queries/userQueryHooks";
import { KindOfPasswordTypes } from "../../types/PasswordType";
import ContentContainer from "../common/layout/ContentContainer";
import { groupRole, isGroupRoleHigherOrEqual } from "../../managers/groupManager";
import { OfflineStatusContext } from "../../contexts/OfflineStatusContext";
import ConfirmationDialog from "../common/feedback/modals/ConfirmationDialog";
import TitleToolbar from "../common/layout/TitleToolbar";
import PasswordsContainer from "../common/passwordComponents/PasswordsContainer";
import { Checkbox, Table, TableBody, TableCell, TableRow } from "@mui/material";
import PasswordTableRow from "../common/passwordComponents/PasswordTableRow";
import { useSelectElement } from "../../hooks/passwordHook";
import classNames from "classnames";
import IconButton from "../common/dataDisplay/IconButton";
import { IconTypes } from "../../types/IconType";
import PasswordTableRowContextMenu from "../common/passwordComponents/PasswordTableRowContextMenu";
import ConvertPasswordModal from "../common/convertPassword/ConvertPasswordModal";
import AccessLogModal from "../common/passwordComponents/AccessLogModal";
import PasswordHistoryModal from "../common/passwordComponents/history/PasswordHistoryModal";
import { useCompanyPasswordDeleteMutation } from "../../hooks/queries/companyPasswordHook";
import { useLazySharedPasswordsByGroupIdsQuery } from "../../hooks/queries/sharedPasswordHook";
import GroupTableRowContextMenu from "./GroupTableRowContextMenu";
import ConfirmDeletePassword from "../common/passwordComponents/ConfirmDeletePassword";
import { ConfirmDialogVariantList } from "../../types/ConfirmDialogVariant";
import AddPasswordModal from "../common/passwordComponents/AddPasswordModal";
import GroupBreadcrumbContextMenu from "./GroupBreadcrumbContextMenu";
import { ClientFilter } from "../../managers/filterManager";
import MoveGroupModal from "./modals/MoveGroupModal";
import useTranslation from "../common/translation/translation";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";

const useStyles = makeStyles(() => ({
    tableRow: {
        border: "1px solid rgb(224, 224, 224)",
        backgroundColor: "#fff",

        "&:last-child > *": {
            borderBottom: "unset",
        },
    },
    tableCell: {
        cursor: "pointer",
    },
    nameTableCell: {
        maxWidth: (props) => (props.isMobile ? "10rem" : "auto"),
        overflowY: (props) => (props.isMobile ? "hidden" : "initial"),
        textOverflow: (props) => (props.isMobile ? "ellipsis" : "unset"),
        overflowWrap: (props) => (props.isMobile ? "break-word" : "normal"),
    },
    minWidthTableCell: {
        width: "1px",
    },
}));

const subGroupKey = "subgroups";
const passwordKey = "passwords";

/**
 *
 * @param {GroupType[]} allGroups
 * @param {boolean} isInSearch
 * @param {string} parentGroupId
 */
function getGroupsToDisplay(allGroups, isInSearch, parentGroupId) {
    if (isInSearch) return allGroups;
    return allGroups.filter((g) => (g.parentGroupId || guid.empty) === parentGroupId);
}

function GroupsPage() {
    const classes = useStyles();
    const offlineStatusContext = OfflineStatusContext.useContainer();
    const { i18n } = useTranslation();

    const { deletePassword } = useCompanyPasswordDeleteMutation();

    const { modalState: groupRoleModalState, open: openRoleModal, close: closeRoleModal } = useModalStatus();
    const { modalState: groupModalState, open: handleOpenGroupModal, close: closeGroupModal } = useModalStatus();
    const { modalState: groupAddModalState, open: openGroupAddModal, close: closeGroupAddModal } = useModalStatus();
    const { modalState: passwordModalState, open: openPasswordModal, close: closePasswordModal } = useModalStatus();
    const { modalState: offlineErrorDialogState, open: openOfflineErrorDialog, close: closeOfflineErrorDialog } = useModalStatus();
    const { modalState: addPasswordModalState, open: openAddPasswordModal, close: closeAddPasswordModal } = useModalStatus();
    const { modalState: historyModalState, open: openHistoryModal, close: closeHistoryModal } = useModalStatus();
    const { modalState: accessLogsModalState, open: openAccessLogsModal, close: closeAccessLogsModal } = useModalStatus();
    const { modalState: archiveConfirmDialogState, open: openArchiveConfirmDialog, close: closeArchiveConfirmDialog } = useModalStatus();
    const { modalState: convertModalState, open: openConvertModal, close: closeConvertModal } = useModalStatus();
    const { modalState: moveGroupModalState, open: openMoveGroupModal, close: closeMoveGroupModal } = useModalStatus();

    const {
        modalState: confirmDeletePasswordModalState,
        open: openConfirmDeletePasswordModal,
        close: closeConfirmDeletePasswordModal,
    } = useModalStatus();

    const openGroupModal = () => {
        if (offlineStatusContext.isOffline) openOfflineErrorDialog();
        else handleOpenGroupModal();
    };

    const [clickedPassword, setClickedPassword] = useState();
    const [isBulkOperation, setIsBulkOperation] = useState(false);
    const [searchTerm, setSearchTerm] = useState("");
    const isInSearch = searchTerm !== "";

    const [currentRole, setCurrentRole] = useState();
    const [currentUserId, setCurrentUserId] = useState();
    const [currentGroupId, setCurrentGroupId] = useState(guid.empty);
    const [clickedGroupId, setClickedGroupId] = useState(guid.empty);

    const { getSharedPasswordsByGroupIds, sharedPasswords } = useLazySharedPasswordsByGroupIdsQuery();
    const passwords = new ClientFilter(sharedPasswords).byArchived(false).byGroups(currentGroupId).build();

    const { groups: allGroups } = useAllGroupsQuery();
    const groups = useMemo(() => getGroupsToDisplay(allGroups, isInSearch, currentGroupId), [allGroups, isInSearch, currentGroupId]);

    const { me, isSystemAdmin } = useMyselfQuery();
    const { isViewer } = useMyselfRole(currentGroupId);

    const { loading: treeNodesLoading, treeNodes, flattenedTreeNodes } = useGroupTreeQuery();

    const nextTreeNode = useMemo(() => flattenedTreeNodes.find((n) => n.Id === clickedGroupId), [flattenedTreeNodes, clickedGroupId]) || {
        Id: guid.empty,
        Name: "",
    };

    const groupMutation = useGroupMutation(undefined, currentGroupId);

    const { setSelectElements, getSelectedElements, selectAll, unselectAll, handleSelectElement } = useSelectElement();
    const { anchorEl: passwordAnchorEl, open: openPasswordContextMenu, close: closePasswordContextMenu } = useContextMenu();
    const { anchorEl: groupAnchorEl, open: openGroupContextMenu, close: closeGroupContextMenu } = useContextMenu();
    const { anchorEl: groupBreadcrumbAnchorEl, open: openGroupBreadcrumbContextMenu, close: closeGroupBreadcrumbContextMenu } = useContextMenu();

    const handleOpenPasswordModal = (password) => {
        setClickedPassword(password);
        openPasswordModal();
    };

    useEffect(() => {
        if (!isGuidEmpty(currentGroupId)) getSharedPasswordsByGroupIds({ variables: { groupIds: [currentGroupId] } });
    }, [currentGroupId]);

    useEffect(() => {
        // If currentGroupId changes reset bulk for subgroups and passwords
        unselectAll();
        setIsBulkOperation(false);
    }, [currentGroupId]);

    const filteredGroups = new ClientFilter(groups).bySearchTerm(["name"], searchTerm).byArchived(false).build();

    const groupsToEdit = useMemo(() => {
        if (isSystemAdmin) return filteredGroups;
        let groups = [];

        filteredGroups.forEach((g) => {
            let treeNode = flattenedTreeNodes.find((n) => n.Id === g.id);
            if (treeNode?.GroupRole !== groupRole.viewer) {
                groups.push(g);
            }
        });
        return groups;
    }, [filteredGroups, isSystemAdmin, flattenedTreeNodes]);

    const isAdminInAnySubGroup = useMemo(() => {
        if (isSystemAdmin) return true;

        groups.forEach((g) => {
            let treeNode = flattenedTreeNodes.find((n) => n.Id === g.id);
            if (treeNode?.GroupRole === groupRole.admin) {
                return true;
            }
        });

        return false;
    }, [groups, flattenedTreeNodes]);

    const hasGroupAdminPermissionsOfAtLeastOneGroup = useMemo(() => {
        if (me.admin) return true;

        const rootNode = {
            Id: guid.empty,
            GroupRole: groupRole.viewer,
            Children: treeNodes,
            Name: "",
        };
        const hasNodeGroupAdminPermissions = (node) => {
            if (isGroupRoleHigherOrEqual(node.GroupRole, groupRole.admin)) return true;
            for (const child of node.Children) {
                if (hasNodeGroupAdminPermissions(child)) return true;
            }
            return false;
        };
        return hasNodeGroupAdminPermissions(rootNode);
    }, [me, treeNodes]);

    const handleArchiveGroups = async () => {
        if (isBulkOperation) {
            for (const p of getSelectedElements(subGroupKey)) {
                await groupMutation.archiveGroup({ variables: { groupId: p.id } });
            }
        } else {
            await groupMutation.archiveGroup({ variables: { groupId: clickedGroupId } });
            setCurrentGroupId(currentGroupId === clickedGroupId ? guid.empty : currentGroupId);
        }

        closeArchiveConfirmDialog();
    };

    const emptyTitle = me.admin ? i18n("groups.addFirst") : i18n("groups.noGroupsAvailable");
    const emptyInformation = me.admin ? i18n("groups.addInformationAdmin") : i18n("groups.addInformation");
    const empty = allGroups.length === 0;

    const targetPasswords = isBulkOperation ? getSelectedElements(passwordKey) : [clickedPassword];

    const getGroupSearchAddDisabledMessage = () => {
        if (offlineStatusContext.isOffline) return i18n("groups.offlineInformation");
        if (!hasGroupAdminPermissionsOfAtLeastOneGroup) return i18n("groups.addPermissionInformation");
        return "";
    };

    const getOnAdd = () => {
        if (!hasGroupAdminPermissionsOfAtLeastOneGroup) return undefined;
        return openGroupAddModal;
    };

    return (
        <>
            <Page
                emptyTitle={emptyTitle}
                emptyInformation={emptyInformation}
                empty={empty}
                loading={treeNodesLoading}
                onNoContentAction={me.admin ? openGroupAddModal : undefined}
                noContentActionText={i18n("general.actions.add")}
            >
                <GroupSearchBar
                    onAdd={getOnAdd()}
                    addButtonDisabledMessage={getGroupSearchAddDisabledMessage()}
                    onSearchTerm={setSearchTerm}
                    onBreadCrumbClick={setCurrentGroupId}
                    currentGroupId={currentGroupId}
                    onBreadCrumbCurrentGroupClick={(e) => {
                        setClickedGroupId(currentGroupId);
                        openGroupBreadcrumbContextMenu(e);
                    }}
                />
                <ContentContainer>
                    <PageHeader>{i18n("navigation.groupManagement")}</PageHeader>
                    <PageSubTitle>{i18n("dashboard.vaults.groupManagementDescription")}</PageSubTitle>
                    {groups.length > 0 && (
                        <>
                            <TitleToolbar
                                title={searchTerm ? i18n("groups.searchGroupTitle") : i18n("groups.subGroups")}
                                countLabelSingular={i18n("groups.subGroup")}
                                countLabelPlural={i18n("groups.subGroups")}
                                setSelectAll={(_) => selectAll(subGroupKey, groupsToEdit)}
                                setUnselectAll={(_) => unselectAll(subGroupKey)}
                                selected={getSelectedElements(subGroupKey)}
                                passwordCount={groupsToEdit.length}
                                hideTitleInformationInMultiSelect
                                onArchive={() => {
                                    setIsBulkOperation(true);
                                    openArchiveConfirmDialog();
                                }}
                                isViewer={!isAdminInAnySubGroup}
                            />
                            <PasswordsContainer>
                                <Table size="small">
                                    <TableBody>
                                        {filteredGroups.map((g) => {
                                            const treeNode = flattenedTreeNodes.find((n) => n.Id === g.id) || {
                                                Id: guid.empty,
                                                Name: "",
                                            };

                                            return (
                                                <TableRow
                                                    key={g.id}
                                                    onContextMenu={(e) => {
                                                        setClickedGroupId(g.id);
                                                        setIsBulkOperation(false);
                                                        openGroupContextMenu(e);
                                                    }}
                                                    className={classes.tableRow}
                                                    hover
                                                >
                                                    <TableCell padding="checkbox">
                                                        <Checkbox
                                                            disabled={treeNode.GroupRole !== groupRole.admin}
                                                            checked={getSelectedElements(subGroupKey).some(
                                                                (selectedGroup) => g.id === selectedGroup.id
                                                            )}
                                                            onClick={(_) => handleSelectElement(subGroupKey, g)}
                                                        />
                                                    </TableCell>
                                                    <TableCell
                                                        padding="none"
                                                        onClick={(_) => {
                                                            setCurrentGroupId(g.id);
                                                        }}
                                                        className={classNames(classes.tableCell, classes.nameTableCell)}
                                                    >
                                                        {g.name}
                                                    </TableCell>
                                                    <TableCell align="right" className={classNames(classes.tableCell, classes.minWidthTableCell)}>
                                                        <IconButton
                                                            iconType={IconTypes.moreOptions}
                                                            onClick={(e) => {
                                                                setClickedGroupId(g.id);
                                                                setIsBulkOperation(false);
                                                                openGroupContextMenu(e);
                                                            }}
                                                        />
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </PasswordsContainer>
                        </>
                    )}

                    {currentGroupId !== guid.empty && !searchTerm && (
                        <>
                            <TitleToolbar
                                title={i18n("general.keyWords.passwords")}
                                selected={getSelectedElements(passwordKey)}
                                setSelectAll={(_) => selectAll(passwordKey, passwords)}
                                setUnselectAll={(_) => unselectAll(passwordKey)}
                                hideTitleInformationInMultiSelect
                                isViewer={isViewer}
                                passwordCount={passwords.length}
                                onArchive={() => {
                                    setIsBulkOperation(true);
                                    openConfirmDeletePasswordModal();
                                }}
                                onAdd={openAddPasswordModal}
                                onConvert={() => {
                                    isBulkOperation(true);
                                    openConvertModal();
                                }}
                            />
                            <PasswordsContainer>
                                <Table size="small">
                                    <TableBody>
                                        {passwords.map((pw) => {
                                            return (
                                                <PasswordTableRow
                                                    showMenuOptions
                                                    isViewer={isViewer}
                                                    selectedPasswords={getSelectedElements(passwordKey)}
                                                    setSelectedPasswords={(passwords) => setSelectElements(passwordKey, passwords)}
                                                    onContextMenu={(e) => {
                                                        setClickedPassword(pw);
                                                        setIsBulkOperation(false);
                                                        openPasswordContextMenu(e);
                                                    }}
                                                    onRowClick={(_) => {
                                                        setClickedPassword(pw);
                                                        setIsBulkOperation(false);
                                                        openPasswordModal();
                                                    }}
                                                    password={pw}
                                                    key={pw.id}
                                                />
                                            );
                                        })}
                                    </TableBody>
                                </Table>
                            </PasswordsContainer>
                        </>
                    )}
                </ContentContainer>
            </Page>

            {groupModalState && (
                <GroupModal
                    onClose={closeGroupModal}
                    onOk={closeGroupModal}
                    group={{ id: nextTreeNode.Id, name: nextTreeNode.Name }}
                    parentGroupId={currentGroupId}
                    onRoleChange={(userId, role) => {
                        setCurrentUserId(userId);
                        setCurrentRole(role);
                        openRoleModal();
                    }}
                    onArchive={() => {
                        setCurrentGroupId(currentGroupId === clickedGroupId ? guid.empty : currentGroupId);
                    }}
                    onPassword={handleOpenPasswordModal}
                />
            )}

            {groupAddModalState && <GroupAddModal groupId={currentGroupId} onClose={closeGroupAddModal} />}
            {passwordModalState && (
                <PasswordModal
                    editable={!isViewer}
                    disableTags
                    onClose={closePasswordModal}
                    onOk={closePasswordModal}
                    password={clickedPassword}
                    passwordType={KindOfPasswordTypes.groupPasswords}
                />
            )}
            {groupRoleModalState && (
                <GroupRoleModal groupId={clickedGroupId} userId={currentUserId} currentRole={currentRole} onClose={closeRoleModal} />
            )}

            <PasswordTableRowContextMenu
                passwordType={KindOfPasswordTypes.companyPasswords}
                password={clickedPassword}
                anchorEl={passwordAnchorEl}
                onClose={closePasswordContextMenu}
                onPasswordModal={openPasswordModal}
                onConfirmDeleteModal={openConfirmDeletePasswordModal}
                onMovePassword={openConvertModal}
                onHistoryModal={me.admin ? openHistoryModal : undefined}
                onAccessModal={openAccessLogsModal}
                isViewer={isViewer}
            />

            <GroupTableRowContextMenu
                onOpen={(_) => setCurrentGroupId(clickedGroupId)}
                onEdit={openGroupModal}
                anchorEl={groupAnchorEl}
                onClose={closeGroupContextMenu}
                groupId={clickedGroupId}
                onArchive={() => {
                    setIsBulkOperation(false);
                    openArchiveConfirmDialog();
                }}
                onMove={openMoveGroupModal}
            />
            <GroupBreadcrumbContextMenu
                isViewer={isViewer}
                anchorEl={groupBreadcrumbAnchorEl}
                onClose={closeGroupBreadcrumbContextMenu}
                onEdit={handleOpenGroupModal}
                onMove={openMoveGroupModal}
                onArchive={openArchiveConfirmDialog}
                groupId={currentGroupId}
            />

            {offlineErrorDialogState && (
                <ConfirmationDialog
                    open
                    onOk={closeOfflineErrorDialog}
                    onClose={closeOfflineErrorDialog}
                    title={i18n("general.offline.title")}
                    description={i18n("groups.offlineInformation")}
                />
            )}

            {convertModalState && (
                <ConvertPasswordModal
                    preselectedPasswords={targetPasswords}
                    onClose={(_) => {
                        closeConvertModal();
                        unselectAll(passwordKey);
                    }}
                    fromType={KindOfPasswordTypes.companyPasswords}
                />
            )}
            {accessLogsModalState && <AccessLogModal logs={clickedPassword.accessLog} onClose={closeAccessLogsModal} />}
            {historyModalState && <PasswordHistoryModal password={clickedPassword} onClose={closeHistoryModal} />}
            {confirmDeletePasswordModalState && (
                <ConfirmDeletePassword
                    onOk={() => {
                        for (const password of targetPasswords) {
                            deletePassword({ variables: { id: password.id } });
                        }
                        unselectAll(passwordKey);
                        closeConfirmDeletePasswordModal();
                    }}
                    onClose={closeConfirmDeletePasswordModal}
                    passwords={targetPasswords}
                />
            )}
            {archiveConfirmDialogState && (
                <ConfirmationDialog
                    onOk={handleArchiveGroups}
                    onClose={closeArchiveConfirmDialog}
                    cancelText={i18n("general.actions.cancel")}
                    okText={i18n("groups.archive.confirm")}
                    title={i18n("groups.archive.title")}
                    description={i18n("groups.archive.description")}
                    variant={ConfirmDialogVariantList.warning}
                />
            )}

            {addPasswordModalState && (
                <AddPasswordModal
                    groupId={currentGroupId}
                    initialPasswordType={KindOfPasswordTypes.groupPasswords}
                    onClose={closeAddPasswordModal}
                    onOk={(_) => {
                        if (!isGuidEmpty(currentGroupId)) getSharedPasswordsByGroupIds({ variables: { searchTerm: "", groupIds: [currentGroupId] } });
                        closeAddPasswordModal();
                    }}
                />
            )}
            {moveGroupModalState && (
                <MoveGroupModal
                    allGroups={allGroups}
                    group={{ id: nextTreeNode.Id, name: nextTreeNode.Name }}
                    onClose={closeMoveGroupModal}
                    treeNodes={treeNodes}
                />
            )}
        </>
    );
}

export default GroupsPage;
