import React, { useState } from "react";
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import { IconButton, InputAdornment, TextField } from "@mui/material";
import Icon from "../../dataDisplay/Icon";
import { IconTypes } from "../../../../types/IconType";
import { HtmlAutoCompleteTypeList } from "../../../../types/HtmlAutoCompleteType";

const useStyles = makeStyles((theme) => ({
    icon: {
        width: "25px",
    },
}));

const PasswordInputField = ({
    label,
    onChange,
    value,
    required,
    className,
    variant,
    size,
    disabled,
    showInitially,
    error,
    errorText,
    ...otherProps
}) => {
    const classes = useStyles();
    const [showPassword, setShowPassword] = useState(showInitially);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleFocus = (event) => event.target.select();

    return (
        <TextField
            type={showPassword ? "text" : "password"}
            autoComplete={HtmlAutoCompleteTypeList.newPassword}
            label={label}
            className={className}
            onChange={onChange}
            value={value}
            variant={variant}
            required={required}
            size={size}
            error={error}
            helperText={Boolean(error) ? errorText : ""}
            disabled={disabled}
            onFocus={showPassword ? handleFocus : () => {}}
            InputProps={{
                endAdornment: (
                    <InputAdornment position="end">
                        <IconButton aria-label="toggle password visibility" onClick={handleClickShowPassword}>
                            {showPassword ? (
                                <Icon className={classes.icon} iconType={IconTypes.visibleEye} />
                            ) : (
                                <Icon className={classes.icon} iconType={IconTypes.invisibleClosedEye} />
                            )}
                        </IconButton>
                    </InputAdornment>
                ),
            }}
            data-testid="pw-field"
            {...otherProps}
        />
    );
};

PasswordInputField.defaultProps = {
    showInitially: false,
    error: false,
    errorText: "",
};

PasswordInputField.propTypes = {
    className: PropTypes.string,
    value: PropTypes.string,
    required: PropTypes.bool,
    onChange: PropTypes.func.isRequired,
    variant: PropTypes.oneOf(["filled", "outlined", "standard"]),
    size: PropTypes.string,
    disabled: PropTypes.bool,
    showInitially: PropTypes.bool,
    error: PropTypes.bool,
    errorText: PropTypes.string,
};

export default PasswordInputField;
