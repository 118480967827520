import React, { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { Button, Grid, TextField, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "../common/dataDisplay/Icon";
import ConfirmationDialog from "../common/feedback/modals/ConfirmationDialog";
import CustomModal from "../common/feedback/modals/CustomModal";
import { IconTypes } from "../../types/IconType";
import { useMaterialUIInput } from "../../hooks/inputHook";
import { useLazyUserQueries, useLoadUserById, useMyselfQuery, useUserMutation, useUsersQuery } from "../../hooks/queries/userQueryHooks";
import { useModalStatus } from "../../hooks/modalHook";
import { ConfirmDialogVariantList } from "../../types/ConfirmDialogVariant";
import { CryptoManager } from "../../managers/cryptoManager";
import { extractApolloErrorMessage } from "../../managers/errorManager";
import { encryptPasswordsForUser } from "../../managers/passwordManager";
import {
    useRetrieveAllDecryptedSharedAndCompanyAndOfflinePasswords,
    useRetrieveDecryptedCompanyPasswordsByCreatorIds,
    useRetrieveDecryptedOfflinePasswordsByCreatorIds,
    useRetrieveDecryptedPasswordsInGroups,
} from "../../hooks/cryptoHook";
import FormControl from "@mui/material/FormControl";
import WaitDialog from "../common/feedback/modals/WaitDialog";
import { ClientFilter } from "../../managers/filterManager";
import LicenseInvalidAlert from "../common/feedback/modals/LicenseInvalidAlert";
import { generatePassword } from "../../managers/passwordGenerationManager";
import useTranslation from "../common/translation/translation";

const useStyles = makeStyles((theme) => ({
    input: {
        width: "100%",
    },
    formControl: {
        minWidth: 120,
        width: "100%",
    },
    actionButtonIcon: {
        width: "1.7rem",
    },
}));

/**
 *
 * @param {() => void} onClose
 * @param {Guid} userId
 * @returns {JSX.Element}
 * @constructor
 */
const EditUserModal = ({ onClose, userId }) => {
    const classes = useStyles();
    const [errorMessage, setErrorMessage] = useState("");
    const { i18n } = useTranslation();

    const { user: targetUser, loading: loadingUser, error: errorLoadingUser } = useLoadUserById(userId, null);

    const retrieveAllDecryptedPasswords = useRetrieveAllDecryptedSharedAndCompanyAndOfflinePasswords();

    const {
        editUser,
        grantAdminPermission,
        revokeAdminPermission,
        loading: loadingUserMutation,
        error: errorUserMutation,
        archiveOrReactivateUser,
        resendAccountActivationEmail,
    } = useUserMutation();
    const retrieveAllDecryptedSharedAndCompanyPasswords = useRetrieveAllDecryptedSharedAndCompanyAndOfflinePasswords();
    const lazyOtherUsersQueries = useLazyUserQueries();
    const retrieveDecryptedPasswordsInGroups = useRetrieveDecryptedPasswordsInGroups();
    const retrieveDecryptedCompanyPasswordsByCreatorIds = useRetrieveDecryptedCompanyPasswordsByCreatorIds();
    const retrieveDecryptedOfflinePasswordsByCreatorIds = useRetrieveDecryptedOfflinePasswordsByCreatorIds();

    const { isLicenseValid } = useMyselfQuery();
    const { users } = useUsersQuery("");

    const loading = loadingUserMutation || loadingUser;
    const error = Boolean(errorUserMutation || errorLoadingUser);

    const { value: email, onChange: setEmail, setValue: setEmailValue } = useMaterialUIInput(targetUser.email || "");
    const { value: firstName, onChange: setFirstName, setValue: setFirstnameValue } = useMaterialUIInput(targetUser.firstname || "");
    const { value: lastName, onChange: setLastName, setValue: setLastnameValue } = useMaterialUIInput(targetUser.lastname || "");
    const [admin, setAdmin] = useState(targetUser.admin);
    const [archived, setArchived] = useState(Boolean(targetUser.archivedAt));
    const { modalState: emailChangedSuccessfullyDialogState, open: openEmailChangedSuccessfullyDialog } = useModalStatus();

    const [isArchivingLoading, setIsArchivingLoading] = useState(false);
    const [targetArchivedState, setTargetArchivedState] = useState(Boolean(targetUser.archivedAt));
    const { modalState: confirmArchiveUserModalState, open: openConfirmArchiveUserModal, close: closeConfirmArchiveUserModal } = useModalStatus();
    const { modalState: confirmArchiveUserSuccessModalState, open: openConfirmArchiveUserSuccessModal } = useModalStatus();

    const [isChangingAdminStateLoading, setIsChangingAdminStateLoading] = useState(false);
    const [targetAdminState, setTargetAdminState] = useState(targetUser.admin);
    const {
        modalState: confirmChangeAdminStateModalState,
        open: openConfirmChangeAdminStateModal,
        close: closeConfirmChangeAdminStateModal,
    } = useModalStatus();
    const { modalState: confirmChangeAdminStateSuccessModalState, open: openConfirmChangeAdminStateSuccessModal } = useModalStatus();

    const {
        modalState: confirmResendActivationMailModalState,
        open: openConfirmResendActivationMailModal,
        close: closeConfirmResendActivationMailModal,
    } = useModalStatus();
    const { modalState: confirmResendActivationMailSuccessModalState, open: openConfirmResendActivationMailSuccessModal } = useModalStatus();

    const isOnlyAdmin = useMemo(() => {
        const admins = new ClientFilter(users)
            .byArchived(false)
            .byCustomFilter((u) => u.admin)
            .build();
        return admins.length === 1 && admins[0].id === targetUser.id && targetUser.admin;
    }, [users, targetUser]);

    const hasUserEdited = useMemo(
        () => email !== targetUser.email || firstName !== targetUser.firstname || lastName !== targetUser.lastname,
        [email, firstName, lastName, targetUser]
    );

    useEffect(() => {
        if (!loading) {
            setEmailValue(targetUser.email);
            setFirstnameValue(targetUser.firstname);
            setLastnameValue(targetUser.lastname);
            setAdmin(targetUser.admin);
            setTargetAdminState(targetUser.admin);
            setArchived(Boolean(targetUser.archivedAt));
            setTargetArchivedState(Boolean(targetUser.archivedAt));
        }
    }, [loading, targetUser]);

    const handleArchiveOrReactivateUser = async () => {
        closeConfirmArchiveUserModal();
        setIsArchivingLoading(true);

        try {
            if (targetArchivedState) {
                await archiveOrReactivateUser({ variables: { targetUserId: targetUser.id, reactivate: false } });
            } else {
                await reactivateUser();
            }
            setArchived(targetArchivedState);
            setIsArchivingLoading(false);
            openConfirmArchiveUserSuccessModal();
        } catch (e) {
            setIsArchivingLoading(false);
            setErrorMessage(extractApolloErrorMessage(e, "Beim Archivieren-Vorgang ist ein Fehler aufgetreten"));
        }
    };

    const reactivateUser = async () => {
        const targetUserWithPublicKey = await lazyOtherUsersQueries.executeLoadUserById(targetUser.id, true, true);

        let newEncryptions = [];
        if (admin) {
            const allDecryptedPasswords = await retrieveAllDecryptedSharedAndCompanyPasswords(null);
            newEncryptions = await encryptPasswordsForUser(allDecryptedPasswords, targetUserWithPublicKey.publicKey.publicKeyString);
        } else {
            const groupsTargetUserHasAccessTo = await lazyOtherUsersQueries.executeLoadGroupsOfUsers([targetUser.id], true, true);
            const groupIds = groupsTargetUserHasAccessTo.map((g) => g.id);

            const decryptedGroupPasswords = groupIds.length === 0 ? [] : await retrieveDecryptedPasswordsInGroups(groupIds, false);
            const decryptedCompanyPasswords = await retrieveDecryptedCompanyPasswordsByCreatorIds([targetUser.id], false);
            const decryptedOfflinePasswords = await retrieveDecryptedOfflinePasswordsByCreatorIds([targetUser.id]);
            const decryptedSharedPasswords = [...decryptedGroupPasswords, ...decryptedCompanyPasswords, ...decryptedOfflinePasswords];
            newEncryptions = await encryptPasswordsForUser(decryptedSharedPasswords, targetUserWithPublicKey.publicKey.publicKeyString);
        }

        await archiveOrReactivateUser({
            variables: {
                targetUserId: targetUser.id,
                reactivate: true,
                encryptedPasswords: newEncryptions,
            },
        });
    };

    const handleChangeAdminState = async () => {
        closeConfirmChangeAdminStateModal();
        setIsChangingAdminStateLoading(true);
        try {
            const targetUserWithPublicKey = await lazyOtherUsersQueries.executeLoadUserById(targetUser.id, true, null);

            if (targetAdminState) {
                const decryptedSharedPasswords = await retrieveAllDecryptedSharedAndCompanyPasswords(null);
                const allEncryptedPasswords = await encryptPasswordsForUser(
                    decryptedSharedPasswords,
                    targetUserWithPublicKey.publicKey.publicKeyString
                );
                await grantAdminPermission({
                    variables: {
                        targetUserId: targetUserWithPublicKey.id,
                        encryptedPasswords: allEncryptedPasswords,
                    },
                });
            } else {
                await revokeAdminPermission({ variables: { targetUserId: targetUserWithPublicKey.id } });
            }
            setAdmin(targetAdminState);
            setIsChangingAdminStateLoading(false);
            openConfirmChangeAdminStateSuccessModal();
        } catch (e) {
            setIsChangingAdminStateLoading(false);
            setErrorMessage(extractApolloErrorMessage(e, i18n("users.adminError")));
        }
    };

    const handleEditOccured = async () => {
        try {
            // First change normal meta-data
            const firstnameWasChanged = firstName !== targetUser.firstname;
            const lastnameWasChanged = lastName !== targetUser.lastname;
            const emailWasChanged = email !== targetUser.email;
            if (firstnameWasChanged || lastnameWasChanged || emailWasChanged) {
                await editUser({
                    variables: {
                        user: {
                            id: targetUser.id,
                            firstname: firstnameWasChanged ? firstName : null,
                            lastname: lastnameWasChanged ? lastName : null,
                            email: emailWasChanged ? email : null,
                        },
                    },
                });
                if (emailWasChanged) {
                    openEmailChangedSuccessfullyDialog();
                } else {
                    onClose();
                }
            }
        } catch (e) {
            console.error(e);
            setErrorMessage(extractApolloErrorMessage(e));
        }
    };

    const handleResendAccountActivationMail = async () => {
        try {
            const keys = await CryptoManager.createAsymmetricalKeyPair();
            const tmpLoginPassword = generatePassword();
            const tmpLoginPasswordHash = await CryptoManager.createPasswordHash(tmpLoginPassword);
            const encryptedPrivateKey = await CryptoManager.encryptUsingUserCredentials(keys.privateKey, tmpLoginPasswordHash);

            let encryptedPasswords = [];
            if (admin) {
                const decryptedSharedPasswords = await retrieveAllDecryptedPasswords(null);
                encryptedPasswords = await encryptPasswordsForUser(decryptedSharedPasswords, keys.publicKey);
            } else {
                const groupsTargetUserHasAccessTo = await lazyOtherUsersQueries.executeLoadGroupsOfUsers([targetUser.id], true, false);
                const groupIds = groupsTargetUserHasAccessTo.map((g) => g.id);

                if (groupIds.length > 0) {
                    const decryptedGroupPasswords = await retrieveDecryptedPasswordsInGroups(groupIds, false);
                    const decryptedCompanyPasswords = await retrieveDecryptedCompanyPasswordsByCreatorIds([targetUser.id], false);
                    const decryptedSharedPasswords = [...decryptedGroupPasswords, ...decryptedCompanyPasswords];
                    encryptedPasswords = await encryptPasswordsForUser(decryptedSharedPasswords, keys.publicKey);
                }
            }

            await resendAccountActivationEmail({
                variables: {
                    targetUserId: targetUser.id,
                    publicKey: keys.publicKey,
                    encryptedPrivateKey: encryptedPrivateKey,
                    newTempLoginPassword: tmpLoginPassword,
                    newTempLoginPasswordHashed: tmpLoginPasswordHash,
                    encryptedSharedPasswords: encryptedPasswords,
                },
            });

            openConfirmResendActivationMailSuccessModal();
            closeConfirmResendActivationMailModal();
        } catch (e) {
            closeConfirmResendActivationMailModal();
            setErrorMessage(e.message);
        }
    };

    return (
        <>
            <CustomModal
                onClose={onClose}
                saveText={hasUserEdited ? i18n("general.actions.edit") : i18n("general.actions.close")}
                okButtonEnabled={!archived}
                onOk={hasUserEdited ? handleEditOccured : onClose}
                withCancelButton={hasUserEdited}
                cancelText="Abbrechen"
                title={loading ? "" : `${targetUser.firstname} ${targetUser.lastname}`}
                error={error || errorMessage !== ""}
                loading={loading}
                errorMessage={errorMessage}
            >
                <Grid container spacing={2} style={{ marginTop: "2px" }}>
                    {!isLicenseValid && (
                        <Grid item xs={12}>
                            <LicenseInvalidAlert />
                        </Grid>
                    )}
                    <Grid item xs={12}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                variant="outlined"
                                autoFocus
                                value={email}
                                onChange={setEmail}
                                label={i18n("users.emailLong")}
                                className={classes.input}
                                disabled={archived || !isLicenseValid}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                variant="outlined"
                                value={firstName}
                                onChange={setFirstName}
                                label={i18n("users.firstName")}
                                disabled={archived || !isLicenseValid}
                                className={classes.input}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <FormControl variant="outlined" className={classes.formControl}>
                            <TextField
                                variant="outlined"
                                value={lastName}
                                onChange={setLastName}
                                label={i18n("users.lastName")}
                                disabled={archived || !isLicenseValid}
                                className={classes.input}
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography component="h6">
                            {isOnlyAdmin && i18n("users.isOnlyAdminInfo")}
                            {!isOnlyAdmin && admin && i18n("users.isAdminInfo")}
                            {!isOnlyAdmin && !admin && i18n("users.isNoAdminInfo")}
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setTargetAdminState(!admin);
                                openConfirmChangeAdminStateModal();
                            }}
                            disabled={archived || isOnlyAdmin || !isLicenseValid}
                            startIcon={<Icon className={classes.actionButtonIcon} iconType={admin ? IconTypes.removeUser : IconTypes.userShield} />}
                        >
                            {admin ? i18n("users.removeAdminRights") : i18n("users.addAdminRights")}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            onClick={() => {
                                setTargetArchivedState(!archived);
                                openConfirmArchiveUserModal();
                            }}
                            disabled={(!archived && admin && isOnlyAdmin) || !isLicenseValid}
                            startIcon={<Icon className={classes.actionButtonIcon} iconType={IconTypes.fileArchive} />}
                        >
                            {archived && i18n("users.activateUser")}
                            {!archived && isOnlyAdmin && i18n("users.archiveOnlyAdmin")}
                            {!archived && !isOnlyAdmin && i18n("general.actions.archive")}
                        </Button>
                    </Grid>
                    <Grid item xs={12}>
                        <Button
                            variant="outlined"
                            onClick={openConfirmResendActivationMailModal}
                            disabled={targetUser.emailConfirmed || archived || !isLicenseValid}
                            startIcon={<Icon className={classes.actionButtonIcon} iconType={IconTypes.mail} />}
                        >
                            {targetUser.emailConfirmed ? i18n("users.emailConfirmed") : i18n("users.noEmailConfirmed")}
                        </Button>
                    </Grid>
                </Grid>
            </CustomModal>
            {emailChangedSuccessfullyDialogState && (
                <ConfirmationDialog
                    onOk={onClose}
                    onClose={onClose}
                    open
                    title={i18n("users.verificationMailTitle")}
                    description={i18n("users.verificationMailDescription", { key: "email", value: email })}
                />
            )}

            {confirmChangeAdminStateModalState && (
                <ConfirmationDialog
                    title={targetAdminState ? i18n("users.addAdminRights") : i18n("users.removeAdminRights")}
                    okText={targetAdminState ? i18n("users.addAdminRights") : i18n("users.removeAdminRights")}
                    description={i18n("users.userChangeConfirmDescription")}
                    cancelText={i18n("general.actions.cancel")}
                    variant={ConfirmDialogVariantList.warning}
                    onOk={handleChangeAdminState}
                    onClose={closeConfirmChangeAdminStateModal}
                />
            )}
            {isChangingAdminStateLoading && (
                <WaitDialog open title="Bitte warten" description={`Administratorrechte werden ${targetAdminState ? "vergeben" : "entzogen"}.`} />
            )}
            {confirmChangeAdminStateSuccessModalState && (
                <ConfirmationDialog
                    title={targetAdminState ? i18n("users.addAdminRights") : i18n("users.removeAdminRights")}
                    variant={ConfirmDialogVariantList.info}
                    onClose={onClose}
                    onOk={onClose}
                    description={i18n("users.userChangeConfirmDescription")}
                />
            )}

            {confirmArchiveUserModalState && (
                <ConfirmationDialog
                    onClose={closeConfirmArchiveUserModal}
                    okText={`Benutzer ${targetArchivedState ? "archivieren" : "wieder aktivieren"}`}
                    cancelText={i18n("general.actions.cancel")}
                    variant={ConfirmDialogVariantList.warning}
                    title={`Benutzer ${targetArchivedState ? "archivieren" : "wieder aktivieren"}`}
                    onOk={handleArchiveOrReactivateUser}
                    description={`Sind Sie sicher, dass Sie diesen Benutzer ${targetArchivedState ? "archivieren" : "wieder aktivieren"} möchten?`}
                />
            )}
            {isArchivingLoading && (
                <WaitDialog open title="Bitte warten" description={`Benutzer wird ${targetArchivedState ? "archiviert" : "wieder aktiviert"}`} />
            )}
            {confirmArchiveUserSuccessModalState && (
                <ConfirmationDialog
                    title={`Benutzer ${archived ? "archiviert" : "wieder aktiviert"}`}
                    variant={ConfirmDialogVariantList.info}
                    onClose={onClose}
                    onOk={onClose}
                    description={`Der Benutzer wurde erfolgreich ${archived ? "archiviert" : "wieder aktiviert"}`}
                />
            )}

            {confirmResendActivationMailModalState && (
                <ConfirmationDialog
                    onClose={closeConfirmResendActivationMailModal}
                    okText="Aktivierungsmail versenden"
                    cancelText={i18n("general.actions.cancel")}
                    variant={ConfirmDialogVariantList.warning}
                    title="Aktivierungsmail erneut versenden"
                    onOk={handleResendAccountActivationMail}
                    description="Sind Sie sicher, dass Sie jetzt die Account-Aktivierungsmail für diesen Benutzer erneut versenden möchten?"
                />
            )}
            {confirmResendActivationMailSuccessModalState && (
                <ConfirmationDialog
                    title="Aktivierungsmail erneut versenden"
                    variant={ConfirmDialogVariantList.info}
                    onClose={onClose}
                    onOk={onClose}
                    description="Die Email wurde erfolgreich versendet"
                />
            )}
        </>
    );
};

EditUserModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    userId: PropTypes.string.isRequired,
};

export default EditUserModal;
