import React, { useEffect, useState } from "react";
import { localStorageManager } from "../managers/localStorageManager";
import { api } from "../managers/apiManager";
import { menu } from "../managers/pathManager";
import { CryptoManager } from "../managers/cryptoManager";
import { createContainer } from "../hooks/ContextHook";
import { useApolloClient } from "@apollo/client";
import { OfflineStatusContext } from "./OfflineStatusContext";
import config from "../config.json";
import { SecureStorageManager } from "../managers/secureStorageManager";

function useLogin() {
    const client = useApolloClient();
    const apolloOfflineCacheContext = OfflineStatusContext.useContainer();

    let [remember, setRemember] = useState(localStorageManager.isRemember());
    let [loggedIn, setLoggedIn] = useState(undefined);
    let [userPasswordHash, setUserPasswordHash] = useState(null);
    let [isBusinessCustomer, setIsBusinessCustomer] = useState();

    useEffect(() => {
        const fn = async () => {
            try {
                const userPasswordHash = await SecureStorageManager.getUserPasswordHash();
                setUserPasswordHash(userPasswordHash);
                setLoggedIn(true);
            } catch (e) {
                setLoggedIn(false);
                setUserPasswordHash(null);
            }
        };
        fn();
    }, []);

    const passwordReset = async (email) => {
        let response = await fetch(api.resetPassword, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({ email }),
        });
        if (response.ok) {
            return true;
        } else {
            throw new Error("Ein Fehler ist aufgetreten!");
        }
    };

    const getEncryptedResetPasswordByToken = async (userId, token) => {
        let response = await fetch(api.encryptedResetPasswordByToken, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({ userId, token }),
        });
        if (response.ok) {
            return await response.json();
        } else {
            throw new Error(
                "Ein Fehler ist aufgetreten: Es war nicht möglich das verschlüsselte Loginpasswort vom Server anzufragen. Bitte versuchen Sie es später erneut."
            );
        }
    };

    const resendActivationMail = async () => {
        let response = await fetch(api.requestEmailConfirmation, { method: "POST" });
        if (response.ok) {
            return true;
        } else {
            throw new Error("something went wrong");
        }
    };

    const storeLoginMetaData = async (passwordHash, remember) => {
        await SecureStorageManager.setUserPasswordHash(passwordHash);
        localStorageManager.setRemember(remember);
        setUserPasswordHash(passwordHash);
        setRemember(remember);

        localStorageManager.setApolloOfflineSchemaVersion(config.apolloOfflineSchemaVersion);
    };

    const register = async (email, passwordHash, firstname, lastname, companyName, companyCountry, companyCity, companyPostCode, companyStreet) => {
        const keys = await CryptoManager.createAsymmetricalKeyPair();
        const publicKey = keys.publicKey;
        const encryptedPrivateKey = await CryptoManager.encryptUsingUserCredentials(keys.privateKey, passwordHash);

        let response = await fetch(api.register, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({
                email,
                password: passwordHash,
                firstname,
                lastname,
                companyName,
                companyCountry,
                companyCity,
                companyPostCode,
                companyStreet,
                publicKey,
                encryptedPrivateKey,
            }),
        });

        let jsonResponse;
        try {
            jsonResponse = await response.json(); // Read the response, which will complete the request
        } catch (e) {
            throw new Error("Unknown error occured. No body in response.");
        }

        if (!response.ok) {
            throw new Error(JSON.stringify(jsonResponse));
        } else {
            setIsBusinessCustomer(isBusinessCustomer);
            setLoggedIn(true);
            await storeLoginMetaData(passwordHash, false);
            return true;
        }
    };

    const login = async (email, passwordHash, remember) => {
        let response = await fetch(api.login, {
            method: "POST",
            credentials: "include",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({
                email,
                password: passwordHash,
            }),
        });

        if (response.ok) {
            await storeLoginMetaData(passwordHash, remember);
            setLoggedIn(true);
        } else {
            const err = await response.json();
            if (err.errorCode === 33) {
                // ErrorCode 33: 2FA is required to log in. Values must be set.
                await storeLoginMetaData(passwordHash, remember);
            }
            throw Error(JSON.stringify(err));
        }
    };

    const loginWithTOPT = async (totp) => {
        let response = await fetch(api.twoFactorTotpLogin, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({
                code: totp,
                isPersistent: true,
                rememberClient: false,
            }),
        });
        if (!response.ok) {
            const err = await response.json();
            throw Error(JSON.stringify(err));
        }

        setLoggedIn(true);
        return true;
    };

    const getWebAuthnChallenge = async (totp) => {
        let response = await fetch(api.twoFactorWebAuthnChallenge, {
            method: "GET",
        });
        if (!response.ok) {
            const err = await response.json();
            throw Error(JSON.stringify(err));
        }
        return response;
    };

    const loginWithWebAuthn = async (assertionResponse) => {
        let response = await fetch(api.twoFactorWebAuthnLogin, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({
                assertionResponse: assertionResponse,
                isPersistent: true,
            }),
        });
        if (!response.ok) {
            const err = await response.json();
            throw Error(JSON.stringify(err));
        }
        setLoggedIn(true);
        return true;
    };

    const logout = async (redirectOnError = true) => {
        setLoggedIn(false);

        let response = await fetch(api.logout, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({}),
        });

        if (!response.ok) {
            if (redirectOnError) window.location.href = menu.error.path;
            else return false;
        } else {
            return true;
        }
    };

    const clearLocalData = async () => {
        await apolloOfflineCacheContext.clearCache();
        await client.clearStore();
        localStorageManager.clearAll();
        await SecureStorageManager.clearSecureLocalData();
        // Clear all cookies
        document.cookie.split(";").forEach((c) => {
            document.cookie = c.replace(/^ +/, "").replace(/=.*/, "=;expires=" + new Date().toUTCString() + ";path=/");
        });
    };

    const setPasswordWithEmailToken = async (
        userId,
        activationToken,
        oldTempPasswordHashed,
        newPasswordHashed,
        publicKey,
        encryptedPrivateKey,
        encryptedSharedPasswords,
        previousPasswordHashEncryptedWithPublicKey
    ) => {
        let response = await fetch(api.setPasswordForOwnAccountByEmailToken, {
            method: "POST",
            headers: new Headers({ "Content-Type": "application/json" }),
            body: JSON.stringify({
                userId: userId,
                token: activationToken,
                oldPassword: oldTempPasswordHashed,
                newPassword: newPasswordHashed,
                publicKey: publicKey,
                encryptedPrivateKey: encryptedPrivateKey,
                encryptedSharedPasswords: encryptedSharedPasswords,
                previousPasswordHashEncryptedWithPublicKey: previousPasswordHashEncryptedWithPublicKey,
            }),
        });
        if (!response.ok) {
            throw await response.json();
        } else {
            await storeLoginMetaData(newPasswordHashed, false);
            return true;
        }
    };

    return {
        isBusinessCustomer,
        setIsBusinessCustomer,
        loggedIn,
        setLoggedIn,
        remember,
        userPasswordHash,
        setUserPasswordHash,
        login,
        loginWithTOPT,
        logout,
        clearLocalData,
        register,
        resendActivationMail,
        setPasswordWithEmailToken,
        getWebAuthnChallenge,
        loginWithWebAuthn,
        passwordReset,
        getEncryptedResetPasswordByToken,
    };
}

export const LoginContext = createContainer(useLogin);
