import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import PropTypes from "prop-types";
import { getGroupRoleIconType, getGroupRoleText } from "../../../managers/groupManager";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "../../common/dataDisplay/IconButton";
import { IconTypesList } from "../../../types/IconType";
import { useMyselfQuery, useMyselfRole } from "../../../hooks/queries/userQueryHooks";
import useTranslation from "../../common/translation/translation";
import Icon from "../../common/dataDisplay/Icon";

const GroupModalUserTable = ({ groupUsers, onRemove, onRoleChange, groupId }) => {
    const { isAdmin } = useMyselfRole(groupId);
    const { me } = useMyselfQuery();
    const { i18n } = useTranslation();

    const handleRoleChange = (id, groupRole, admin) => {
        if (!isAdmin || id === me.id || admin) return;
        onRoleChange(id, groupRole);
    };

    return (
        <Table>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell align="center">{i18n("groups.edit.role")}</TableCell>
                    <TableCell align="center">{i18n("groups.edit.admin")}</TableCell>

                    <TableCell align="right">&nbsp;</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {groupUsers.map((u, i) => {
                    const { id, firstname, lastname, admin, usergroups } = u;
                    const userGroup = usergroups.find((u) => u.group.id === groupId);
                    const { groupRole } = userGroup;
                    const iconType = getGroupRoleIconType(groupRole);

                    return (
                        <TableRow style={{ cursor: admin ? "default" : "pointer" }} hover key={"userInGroup" + i}>
                            <TableCell onClick={() => handleRoleChange(id, groupRole, admin)} scope="row">
                                {firstname + " " + lastname}
                            </TableCell>
                            <TableCell align="center">
                                {groupRole && (
                                    <Tooltip title={getGroupRoleText(groupRole)}>
                                        <IconButton onClick={() => handleRoleChange(id, groupRole, admin)} size="small" iconType={iconType} />
                                    </Tooltip>
                                )}
                            </TableCell>
                            <TableCell align="center">{admin && <Icon iconType={IconTypesList.check} />}</TableCell>
                            <TableCell align="right">
                                {isAdmin && !admin && (
                                    <IconButton onClick={() => onRemove(id)} size="small" color="secondary" iconType={IconTypesList.removeUser} />
                                )}
                            </TableCell>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
};

GroupModalUserTable.propTypes = {
    groupUsers: PropTypes.array.isRequired,
    onRoleChange: PropTypes.func,
    onRemove: PropTypes.func,
    groupId: PropTypes.string,
};

export default GroupModalUserTable;
