import React from "react";
import PropTypes from "prop-types";
import { Icon as MuiIcon } from "@mui/material";
import { IconType, IconTypes } from "../../../types/IconType";
import { IconColorType } from "../../../types/IconColorType";
import SystemUpdateIcon from "@mui/icons-material/SystemUpdate";
import SystemUpdateAltIcon from "@mui/icons-material/SystemUpdateAlt";

function LastPassIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" x="0" y="0" enableBackground="new 0 0 352 52" version="1.1" viewBox="0 0 352 100" xmlSpace="preserve">
            <g>
                <g transform="translate(-74 -124)">
                    <g transform="translate(74 124)">
                        <g>
                            <path d="M.4.3C.7.1 1.1 0 1.7 0h5.7c.7 0 1.2.1 1.4.3.3.2.4.6.4 1.3v35.3c0 2 .5 3.4 1.5 4.2 1 .8 2.3 1.2 4 1.2H28c.6 0 1.2 0 1.6.4.4.3.3.5.5 1.3.3.8.8 4.8.8 4.8 0 .4 0 .7-.3 1s-.8.5-1.2.6-1.3.2-2.5.2H13.3c-2.6 0-4.8-.4-6.5-1.1-1.7-.8-3.1-1.8-4.1-3.1C1.7 45.1 1 43.7.6 42c-.4-1.6-.6-3.3-.6-5.1V1.6C0 .9.1.5.4.3z" />
                            <path
                                d="M190.7 46.4c0 1.1-.4 1.9-1.3 2.3-1 .5-2.1.9-3.3 1.2-1.2.3-2.4.6-3.7.8s-2.5.3-3.8.4c-1.2.1-2.4.1-3.5.1-3.1 0-5.7-.3-7.7-1-2-.6-3.6-1.5-4.8-2.5-1.2-1.1-2-2.3-2.5-3.7s-.7-3-.7-4.6c0-1.6.3-3.2.8-4.8.5-1.5 1.4-2.9 2.6-4 1.2-1.2 2.8-2.1 4.7-2.8 1.9-.7 4.3-1.1 7.1-1.1h7.9v-1.2c0-.9-.1-1.7-.3-2.5s-.5-1.4-1.1-2c-.5-.6-1.3-1-2.2-1.3s-2.2-.5-3.6-.5c-1.6 0-3.7.4-4.9.8-.5.1-.9.2-1.1.1s-.4-.4-.5-.8l-1-4c-.2-.8 0-1.2.6-1.5 2-.8 6.1-1.4 8.2-1.4 4.9 0 8.4 1.1 10.7 3.3 2.3 2.2 3.4 5.3 3.4 9.5v21.2zM182.4 33h-6.7c-.8 0-1.6 0-2.6.1-.9.1-1.8.4-2.6.8s-1.5 1-2 1.8-.8 1.9-.8 3.3c0 2 .7 3.4 2 4.2 1.4.8 3.2 1.2 5.4 1.2h1.4c.6 0 1.2-.1 1.9-.2s1.3-.2 2.1-.4c.7-.1 1.4-.3 2-.6V33z"
                                fill="#D32D27"
                            />
                            <path d="M95.8 39.3c0 1.9-.3 3.5-1 4.9-.7 1.4-1.6 2.6-2.9 3.5-1.2.9-2.7 1.6-4.4 2.1-1.7.5-3.5.7-5.5.8-1.1 0-2.3 0-3.6-.1-1.3-.1-2.5-.2-3.8-.5-1.3-.2-2.5-.5-3.7-.9-1.2-.4-2.3-.8-3.3-1.4-.7-.4-.9-.9-.5-1.7l1.6-3.9c.2-.4.5-.7.7-.8.2-.1.6-.1 1.1.2 1.7.8 3.6 1.3 5.6 1.7 2.1.4 3.9.6 5.5.6 2 0 3.4-.4 4.4-1.2 1-.8 1.4-1.8 1.4-3 0-1.3-.7-2.4-2-3.2-1.3-.8-3.2-1.6-5.7-2.5-1.9-.7-3.6-1.3-5.1-2-1.4-.7-2.6-1.4-3.6-2.3-1-.8-1.7-1.8-2.2-2.8-.5-1.1-.8-2.3-.8-3.7 0-1.5.2-2.9.8-4.2.5-1.3 1.4-2.4 2.5-3.3 1.1-.9 2.5-1.7 4.1-2.3 1.6-.6 3.5-.9 5.7-.9 2 0 3.9.1 5.9.4 2 .3 3.9.8 5.7 1.5.4.2.6.4.7.7s.1.6 0 1l-1.5 4c-.1.4-.3.6-.5.6-.2.1-.5 0-.9-.1-1.8-.6-3.4-1-4.8-1.2-1.2-.2-2.7-.3-4.3-.3-1.7 0-3 .4-3.8 1.1-.8.7-1.2 1.5-1.2 2.4 0 .6.1 1.2.4 1.7.3.5.7.9 1.3 1.3.6.4 1.3.8 2.2 1.2.9.4 2 .8 3.4 1.3 1.5.5 3 1.1 4.4 1.7 1.4.6 2.7 1.3 3.8 2.2 1.1.9 2 1.9 2.7 3.1.8 1.1 1.2 2.6 1.2 4.3" />
                            <path d="M122 49.6c-1.6.5-3.2.8-4.8.9-1.6.1-2.8.1-3.7.1-3.8 0-6.6-.8-8.1-2.5-1.6-1.7-2.4-4.2-2.4-7.6l.2-20.1H98c-.5 0-.7-.1-.9-.3-.2-.2-.3-.4-.2-.8.1-.3 1.4-3.7 1.4-3.7.1-.1.4-.9.5-1 .1-.2.3-.3.9-.3h3.5V7.5c0-.9.4-1.1.6-1.3.2-.2.6-.4 1.1-.6l5-1.4c.4-.1.7-.1.9.1.2.2.4.5.4 1v9h7.1c.5 0 .8.1.9.3.1.2.2.5.2.8v3.9c0 .4-.1.7-.2.9-.1.2-.4.3-.9.3h-7.1v19c0 1.5.4 2.5 1.1 3.1.7.6 1.8.8 3.2.8h1.1c.5 0 .9-.1 1.4-.1.5 0 1-.1 1.5-.2s.9-.1 1.2-.2c.5-.1.8-.1.9 0 .1.1.2.3.3.7l.8 4.5c0 .3.1.5 0 .7-.1.4-.3.6-.7.8z" />
                            <path
                                d="M138.2 49.6c0 .7-.1 1.1-.3 1.3-.2.2-1.1.3-1.8.3h-4.6c-.5 0-1-.1-1.3-.3-.3-.2-.4-.6-.4-1.3V5.2c0-.6.1-1.5.2-2 .1-.7.1-.9.5-1.3.4-.5 1-.8 1.7-.9.4-.1.7 0 1.3 0h14.1c2.5 0 4.7.4 6.8 1.2 2 .8 3.8 1.9 5.3 3.3s2.6 3.1 3.4 5.1c.8 2 1.2 4.1 1.2 6.5 0 1.3-.2 3-.6 4.8-.4 1.9-1.3 3.7-2.5 5.4-1.3 1.7-3 3.2-5.3 4.4-2.3 1.2-5.3 1.8-9 1.8h-8.5v16.1zm18-34.8c-.3-1-.7-1.9-1.4-2.7-.7-.8-1.6-1.5-2.8-2.1-1.2-.5-2.7-.8-4.5-.8h-8.8v16.6h9c3.1 0 5.4-.8 6.8-2.3 1.4-1.5 2.1-3.5 2.1-5.9.1-.9-.1-1.8-.4-2.8z"
                                fill="#D32D27"
                            />
                            <path
                                d="M221.8 44.7c-.7 1.4-1.6 2.6-2.9 3.5-1.2.9-2.7 1.6-4.4 2.1-1.7.5-3.5.7-5.5.8-1.1 0-2.3 0-3.6-.1-1.3-.1-2.5-.2-3.8-.5-1.3-.2-2.5-.5-3.7-.9-1.2-.4-2.3-.8-3.3-1.4-.7-.4-.9-.9-.5-1.7l1.6-3.9c.2-.4.5-.7.7-.8.2-.1.6-.1 1.1.2 1.7.8 3.6 1.3 5.6 1.7 2.1.4 3.9.6 5.5.6 2 0 3.4-.4 4.4-1.2 1-.8 1.4-1.8 1.4-3 0-1.3-.7-2.4-2-3.2-1.3-.8-3.2-1.6-5.7-2.5-1.9-.7-3.6-1.3-5.1-2-1.4-.7-2.6-1.4-3.6-2.3-1-.8-1.7-1.8-2.2-2.8-.5-1.1-.8-2.3-.8-3.7 0-1.5.2-2.9.8-4.2s1.4-2.4 2.5-3.3c1.1-.9 2.5-1.7 4.1-2.3 1.6-.6 3.5-.9 5.7-.9 2 0 3.9.1 5.9.4 2 .3 3.9.8 5.7 1.5.4.2.6.4.7.7.1.3.1.6 0 1l-1.5 4c-.1.4-.3.6-.5.6-.2.1-.5 0-.9-.1-1.8-.6-3.4-1-4.8-1.2-1.4-.2-2.9-.3-4.5-.3-1.7 0-3 .4-3.8 1.1-.8.7-1.2 1.5-1.2 2.4 0 .6.1 1.2.4 1.7.3.5.7.9 1.3 1.3.6.4 1.3.8 2.2 1.2.9.4 2 .8 3.4 1.3 1.5.5 3 1.1 4.4 1.7 1.4.6 2.7 1.3 3.8 2.2 1.1.9 2 1.9 2.7 3.1.7 1.2 1.1 2.6 1.1 4.4.3 1.7 0 3.3-.7 4.8z"
                                fill="#D32D27"
                            />
                            <path
                                d="M254.2 39.8c0 1.9-.3 3.5-1 4.9-.7 1.4-1.6 2.6-2.9 3.5-1.2.9-2.7 1.6-4.4 2.1-1.7.5-3.5.7-5.5.8-1.1 0-2.3 0-3.6-.1-1.3-.1-2.5-.2-3.8-.5-1.3-.2-2.5-.5-3.7-.9-1.2-.4-2.3-.8-3.3-1.4-.7-.4-.9-.9-.5-1.7l1.6-3.9c.2-.4.5-.7.7-.8.2-.1.6-.1 1.1.2 1.7.8 3.6 1.3 5.6 1.7 2.1.4 3.9.6 5.5.6 2 0 3.4-.4 4.4-1.2 1-.8 1.4-1.8 1.4-3 0-1.3-.7-2.4-2-3.2-1.3-.8-3.2-1.6-5.7-2.5-1.9-.7-3.6-1.3-5.1-2-1.4-.7-2.6-1.4-3.6-2.3-1-.8-1.7-1.8-2.2-2.8-.5-1.1-.8-2.3-.8-3.7 0-1.5.2-2.9.8-4.2.5-1.3 1.4-2.4 2.5-3.3 1.1-.9 2.5-1.7 4.1-2.3 1.6-.6 3.5-.9 5.7-.9 2 0 3.9.1 5.9.4 2 .3 3.9.8 5.7 1.5.4.2.6.4.7.7.1.3.1.6 0 1l-1.5 4c-.1.4-.3.6-.5.6-.2.1-.5 0-.9-.1-1.8-.6-3.4-1-4.8-1.2-1.4-.2-2.9-.3-4.5-.3-1.7 0-3 .4-3.8 1.1-.8.7-1.2 1.5-1.2 2.4 0 .6.1 1.2.4 1.7.3.5.7.9 1.3 1.3.6.4 1.3.8 2.2 1.2.9.4 2 .8 3.4 1.3 1.5.5 3 1.1 4.4 1.7 1.4.6 2.7 1.3 3.8 2.2 1.1.9 2 1.9 2.7 3.1 1.1 1.1 1.4 2.6 1.4 4.3"
                                fill="#D32D27"
                            />
                            <path d="M63.8 45.9c0 1.1-.4 1.9-1.3 2.3-1 .5-2.1.9-3.3 1.2-1.2.3-2.4.6-3.7.8-1.3.2-2.5.3-3.8.4-1.2.1-2.4.1-3.5.1-3.1 0-5.7-.3-7.7-1-2-.6-3.6-1.5-4.8-2.5-1.2-1.1-2-2.3-2.5-3.8-.5-1.4-.7-3-.7-4.6 0-1.7.3-3.2.8-4.8.5-1.5 1.4-2.9 2.6-4 1.2-1.2 2.8-2.1 4.7-2.8 1.9-.7 4.3-1.1 7.1-1.1h7.9v-1.2c0-.9-.1-1.7-.3-2.5-.2-.8-.6-1.4-1.1-2-.5-.6-1.3-1-2.2-1.3-.9-.3-2.2-.5-3.6-.5-1.6 0-3.3.1-5.2.4-1.9.3-3.4.6-4.6 1-.5.1-.9.2-1.1.1-.2-.1-.4-.4-.5-.8l-1-4c-.2-.8 0-1.2.6-1.5 2-.8 4.1-1.3 6.5-1.6 2.3-.3 4.5-.5 6.6-.5 4.9 0 8.4 1.1 10.7 3.3 2.3 2.2 3.4 5.3 3.4 9.5v21.4zm-8.3-13.3h-6.7c-.8 0-1.6 0-2.6.1-.9.1-1.8.4-2.6.8s-1.5 1-2 1.8-.8 1.9-.8 3.3c0 2 .7 3.4 2 4.2 1.4.8 3.2 1.2 5.4 1.2h1.4c.6 0 1.2-.1 1.9-.2s1.3-.2 2.1-.4c.7-.1 1.4-.3 2-.6V32.6h-.1z" />
                        </g>
                        <path
                            d="M347.4 12.9c0-1.3 1-2.3 2.3-2.3 1.3 0 2.3 1 2.3 2.3v36.8c0 1.3-1 2.3-2.3 2.3-1.3 0-2.3-1-2.3-2.3V12.9z"
                            fill="#D32D27"
                        />
                        <g transform="translate(266.625 24.39)">
                            <ellipse cx="7.8" cy="7.8" fill="#D32D27" rx="7.8" ry="7.8" />
                            <ellipse cx="33.8" cy="7.8" fill="#D32D27" rx="7.8" ry="7.8" />
                            <ellipse cx="59.8" cy="7.8" fill="#D32D27" rx="7.8" ry="7.8" />
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    );
}

function KeePassIcon() {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width="100%"
            height="100%"
            viewBox="0 0 256 300"
            version="1.1"
        >
            <defs>
                <linearGradient id="linearGradient3904">
                    <stop offset="0" stopColor="#fff" stopOpacity="1" />
                    <stop offset="1" stopColor="#b8caff" stopOpacity="1" />
                </linearGradient>
                <linearGradient id="linearGradient3870">
                    <stop offset="0" stopColor="#fff" stopOpacity="1" />
                    <stop offset="1" stopColor="#2a53c6" stopOpacity="1" />
                </linearGradient>
                <linearGradient
                    id="linearGradient3876"
                    x1="371.429"
                    x2="500.714"
                    y1="580.934"
                    y2="710.219"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient3870"
                />
                <linearGradient
                    id="linearGradient3910"
                    x1="279.991"
                    x2="548.581"
                    y1="526.638"
                    y2="795.229"
                    gradientUnits="userSpaceOnUse"
                    xlinkHref="#linearGradient3904"
                />
            </defs>
            <g fillOpacity="1" transform="translate(-140.286 -560.076)">
                <path
                    fill="url(#linearGradient3910)"
                    stroke="#000"
                    strokeDasharray="none"
                    strokeMiterlimit="4"
                    strokeOpacity="1"
                    strokeWidth="12.59"
                    d="M542.286 660.934a128 128 0 11-256 0 128 128 0 11256 0z"
                    transform="matrix(.95313 0 0 .95313 -126.58 58.124)"
                />
                <path
                    fill="url(#linearGradient3876)"
                    stroke="none"
                    d="M500.714 645.576a64.643 64.643 0 11-129.285 0 64.643 64.643 0 11129.285 0z"
                    transform="matrix(1.57017 0 0 1.57017 -416.419 -325.586)"
                />
                <g fill="#000" stroke="none" transform="translate(-145.998 29.128)">
                    <path d="M351.786 709.148H476.786V725.148H351.786z" />
                    <path d="M351.781 689.156v16h53v-16h-53zm72 0v16h53v-16h-53z" />
                    <path d="M351.781 669.156v16h53v-16h-53zm72 0v16h53v-16h-53z" />
                    <path d="M351.786 649.148H476.786V665.148H351.786z" />
                    <path
                        d="M414.281 572.75c-11.705 0-21.611 5.49-28.687 12.688-7.076 7.197-11.872 16.058-15.438 24.625-7.132 17.133-9.281 33.843-9.281 33.843l-.156 1.25h19.156c.467-2.986 2.59-15.267 7.813-27.812 2.977-7.155 6.952-14 11.468-18.594 4.516-4.594 9.006-7 15.125-7 6.12 0 10.64 2.406 15.156 7 4.517 4.594 8.46 11.44 11.438 18.594 5.222 12.545 7.346 24.826 7.813 27.812h19.156l-.156-1.25s-2.119-16.71-9.25-33.843c-3.566-8.567-8.393-17.428-15.47-24.625-7.075-7.198-16.981-12.688-28.687-12.688z"
                        style={{
                            WebkitTextIndent: "0",
                            textIndent: "0",
                            WebkitTextAlign: "start",
                            textAlign: "start",
                            lineHeight: "normal",
                            WebkitTextTransform: "none",
                            textTransform: "none",
                            blockProgression: "tb",
                            marker: "none",
                            InkscapeFontSpecification: "Sans",
                        }}
                        strokeWidth="19"
                        baselineShift="baseline"
                        color="#000"
                        direction="ltr"
                        display="inline"
                        enableBackground="accumulate"
                        fontFamily="Sans"
                        fontSize="medium"
                        fontStretch="normal"
                        fontStyle="normal"
                        fontVariant="normal"
                        fontWeight="normal"
                        letterSpacing="normal"
                        overflow="visible"
                        textAnchor="start"
                        textDecoration="none"
                        visibility="visible"
                        wordSpacing="normal"
                        writingMode="lr-tb"
                    />
                </g>
            </g>
        </svg>
    );
}

const unarchive = (
    <svg xmlns="http://www.w3.org/2000/svg" width="40" height="30" viewBox="0 0 60 40">
        <path
            color="#2c2f40"
            d="M4039.124,432.273l-28.549-28.548a.813.813,0,0,1,1.15-1.151l28.549,28.549a.813.813,0,0,1-1.15,1.15Zm-5.886-3.912h-17.189a1.565,1.565,0,0,1-1.562-1.563V412.737h3.13l15.622,15.624h0Zm3.121-3.122-7.985-7.985a.581.581,0,0,0,.174-.416v-.39a.586.586,0,0,0-.585-.586h-.982l-3.125-3.125h12.5v12.5Zm-14.065-14.065-4.688-4.688h18.755a1.565,1.565,0,0,1,1.563,1.563v2.345a.782.782,0,0,1-.781.781Zm-6.242,0h-2.347a.782.782,0,0,1-.781-.781v-2.345l3.127,3.123,0,0Z"
            transform="translate(-4010.337 -402.337)"
        />
    </svg>
);

const Icon = ({ className, colorType, iconType }) => {
    switch (iconType) {
        case IconTypes.unarchive:
            return (
                <MuiIcon color={colorType} className={`${className} icon`}>
                    {unarchive}
                </MuiIcon>
            );
        case IconTypes.lastPass:
            return (
                <MuiIcon color={colorType} className={`${className} icon`}>
                    <LastPassIcon />
                </MuiIcon>
            );
        case IconTypes.keePass:
            return (
                <MuiIcon color={colorType} className={`${className} icon`} style={{ width: "100%", height: "5rem" }}>
                    <KeePassIcon />
                </MuiIcon>
            );
        case IconTypes.muiPhoneInstall:
            return <SystemUpdateIcon className={`${className} icon`} style={{ fontSize: "1.5rem" }} />;
        case IconTypes.muiExtensionInstall:
            return <SystemUpdateAltIcon className={`${className} icon`} style={{ fontSize: "1.5rem" }} />;
        default:
            return <MuiIcon color={colorType} className={className + " icon " + iconType} />;
    }
};

Icon.defaultProps = {
    className: "",
    colorType: "inherit",
};

Icon.propTypes = {
    className: PropTypes.string,
    colorType: IconColorType,
    iconType: IconType.isRequired,
};

export default Icon;
