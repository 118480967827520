import { gql, useApolloClient } from "@apollo/client";
import { fragments } from "../../managers/fragmentManager";
import { addEntry, deleteEntry } from "../../managers/cacheManager";
import { useDeleteAccountPasswordMutation, useAddAccountPasswordMutation, AccountPasswordFieldsFragmentDoc } from "../../graphql/generated";

export const useAccountPasswordAddMutation = () => {
    const [addAccountPassword] = useAddAccountPasswordMutation({
        update(
            cache,
            {
                data: {
                    accountPassword: { add: newPassword },
                },
            }
        ) {
            cache.modify({
                fields: {
                    accountPasswords(pasRefs) {
                        return addEntry(cache, pasRefs, AccountPasswordFieldsFragmentDoc, newPassword);
                    },
                },
                broadcast: false,
            });
        },
    });
    return { addAccountPassword };
};

// TODO refactor async lazy queries. THey are used in the crypto hook
export const useLazyAccountPasswordQueries = () => {
    const client = useApolloClient();

    const GET_ACCOUNT_PASSWORD = gql`
        query AccountPasswordQuery($searchTerm: String!, $tagIds: [Guid]!, $byArchived: Boolean = false) {
            accountPasswords(searchTerm: $searchTerm, tagIds: $tagIds, byArchived: $byArchived) {
                ...SelectAccountPassword
            }
        }
        ${fragments.query.SelectAccountPassword}
    `;

    const GET_ACCOUNT_PASSWORD_WITH_ENCRYPTED_STRING = gql`
        query AccountPasswordQuery($searchTerm: String!, $tagIds: [Guid]!, $byArchived: Boolean = false) {
            accountPasswords(searchTerm: $searchTerm, tagIds: $tagIds, byArchived: $byArchived) {
                encryptedString
                ...SelectAccountPassword
            }
        }
        ${fragments.query.SelectAccountPassword}
    `;

    const GET_ENCRYPTED_STRING_FOR_ACCOUNT_PASSWORD = gql`
        query EncryptedAccountPasswordString($passwordId: Guid!, $publicKeyId: Guid) {
            encryptedAccountPasswordString(passwordId: $passwordId, publicKeyId: $publicKeyId)
        }
    `;

    /**
     *
     * @param {string} searchTerm
     * @param {[Guid]} tagIds
     * @param {Boolean | null} byArchived
     * @param {Boolean} includeEncryptedPasswordStrings
     * @returns {Promise<[AccountPasswordType|null|undefined]>}
     */
    const useExecuteLoadAccountPasswordQuery = async (searchTerm, tagIds, byArchived = false, includeEncryptedPasswordStrings = false) => {
        const { data } = await client.query({
            query: includeEncryptedPasswordStrings ? GET_ACCOUNT_PASSWORD_WITH_ENCRYPTED_STRING : GET_ACCOUNT_PASSWORD,
            variables: { searchTerm: searchTerm, tagIds: tagIds, byArchived: byArchived },
            fetchPolicy: includeEncryptedPasswordStrings ? "no-cache" : "cache-first",
        });
        return data.accountPasswords;
    };

    /**
     *
     * @param {Boolean | null} byArchived
     * @param {Boolean} includeEncryptedPasswordStrings
     * @returns {Promise<(AccountPasswordType|null|undefined)[]>}
     */
    const useExecuteLoadAllAccountPasswordsQuery = async (byArchived = false, includeEncryptedPasswordStrings = false) =>
        await useExecuteLoadAccountPasswordQuery("", [], byArchived, includeEncryptedPasswordStrings);

    /**
     *
     * @param {Guid} passwordId
     * @param {Guid} publicKeyId
     * @returns {Promise<string>}
     */
    const useExecuteLoadEncryptedAccountPasswordStringQuery = async (passwordId, publicKeyId = null) => {
        const { data } = await client.query({
            query: GET_ENCRYPTED_STRING_FOR_ACCOUNT_PASSWORD,
            variables: { passwordId: passwordId, publicKeyId: publicKeyId },
            fetchPolicy: "network-only",
        });
        return data.encryptedAccountPasswordString;
    };

    return {
        useExecuteLoadAccountPasswordQuery,
        useExecuteLoadAllAccountPasswordsQuery,
        useExecuteLoadEncryptedAccountPasswordStringQuery,
    };
};

export const useAccountPasswordDeleteMutation = () => {
    const [deletePassword] = useDeleteAccountPasswordMutation({
        update(
            cache,
            {
                data: {
                    accountPassword: { delete: deletedPassword },
                },
            }
        ) {
            cache.modify({
                fields: {
                    accountPasswords(refs, { readField }) {
                        return deleteEntry(refs, readField, deletedPassword.id);
                    },
                },
            });
        },
    });

    return { deletePassword };
};
