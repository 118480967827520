import React from "react";
import { menu } from "../../managers/pathManager";
import ErrorInformation from "./ErrorInformation";
import VerticalContentPageContainer from "../common/layout/VerticalContentPageContainer";

const AdminAuthorizationNeededPage = () => {
    const heading = "Administratorberechtigung fehlt";
    const description =
        "Um den Inhalt dieser Seite anzuzeigen, benötigen Sie eine Administratorberechtigung, die Sie nicht haben. Bitte kontaktieren Sie Ihren Administrator, wenn Sie weitere Informationen benötigen";

    return (
        <VerticalContentPageContainer hideDrawer>
            <ErrorInformation
                heading={heading}
                description={description}
                actionButtonText="Zurück zum Dashboard"
                actionButtonHref={menu.root.path}
            />
        </VerticalContentPageContainer>
    );
};

export default AdminAuthorizationNeededPage;
