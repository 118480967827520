import { useGetPasswordHistoryQuery, useGetPasswordChangeLogsQuery, useGetKeyPairHistoryQuery } from "../../graphql/generated";

/**
 *
 * @param {Guid} entityId
 * @param {DateTime|null} from
 * @param {DateTime|null} to
 * @param {string|null} propertyName
 * @returns {{changeLogs: (ChangeLogType[]), loading: boolean, error: ApolloError}}
 */
export const useHistoryQuery = (entityId, from = null, to = null, propertyName = null) => {
    const { loading, error, data } = useGetPasswordHistoryQuery({
        variables: { entityId, from, to, propertyName },
        fetchPolicy: "no-cache",
    });

    let changeLogs = data?.changeLogs || [];
    return { changeLogs, loading, error };
};

/**
 *
 * @param passwordId
 * @returns {{passwordChangeLogs: (PasswordChangeLogType[]), loading: boolean, error: ApolloError}}
 */
export const usePasswordChangeLogsQuery = (passwordId) => {
    const { loading, error, data } = useGetPasswordChangeLogsQuery({
        variables: { passwordId },
        fetchPolicy: "no-cache",
    });

    let passwordChangeLogs = data?.passwordChangeLogs || [];
    return { passwordChangeLogs, loading, error };
};

/**
 *
 * @returns {{loading: boolean, error: ApolloError, keyPairChangeLogs: (KeyPairChangeLogType[])}}
 */
export const useKeyPairChangeLogsQuery = () => {
    const { loading, error, data } = useGetKeyPairHistoryQuery({
        fetchPolicy: "no-cache",
    });

    let keyPairChangeLogs = data?.keyPairChangeLogs || [];
    return { keyPairChangeLogs, loading, error };
};
