import PropTypes from "prop-types";
import { ListItemIcon, Menu, MenuItem, Typography } from "@mui/material";
import Icon from "../common/dataDisplay/Icon";
import makeStyles from "@mui/styles/makeStyles";
import { IconTypes } from "../../types/IconType";
import Divider from "@mui/material/Divider";
import { useMyselfRole } from "../../hooks/queries/userQueryHooks";
import useTranslation from "../common/translation/translation";

const useStyles = makeStyles((theme) => ({
    icon: {
        minWidth: "40px",
    },
    divider: {
        marginTop: "5px",
        marginBottom: "5px",
    },
}));

const GroupTableRowContextMenu = ({ anchorEl, onClose, onOpen, onEdit, onArchive, onMove, groupId }) => {
    const classes = useStyles();
    const { isViewer, isAdmin } = useMyselfRole(groupId);
    const { i18n } = useTranslation();

    return (
        <>
            <Menu keepMounted open={Boolean(anchorEl)} onClose={onClose} anchorReference="anchorPosition" anchorPosition={anchorEl}>
                <MenuItem
                    onClick={() => {
                        onOpen();
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <Icon className={classes.icon} iconType={IconTypes.bookOpen} />
                    </ListItemIcon>
                    <Typography variant="inherit">{i18n("general.actions.goTo")}</Typography>
                </MenuItem>

                <MenuItem
                    onClick={() => {
                        onEdit();
                        onClose();
                    }}
                >
                    <ListItemIcon>
                        <Icon className={classes.icon} iconType={isViewer ? IconTypes.visibleEye : IconTypes.editPasswordPen} />
                    </ListItemIcon>
                    <Typography variant="inherit">{isViewer ? i18n("general.actions.view") : i18n("general.actions.edit")}</Typography>
                </MenuItem>

                {isAdmin && (
                    <MenuItem
                        onClick={() => {
                            onClose();
                            onMove();
                        }}
                    >
                        <ListItemIcon>
                            <Icon className={classes.icon} iconType={IconTypes.expandArrows} />
                        </ListItemIcon>
                        <Typography variant="inherit">{i18n("general.actions.move")}</Typography>
                    </MenuItem>
                )}

                {isAdmin && <Divider className={classes.divider} />}

                {isAdmin && (
                    <MenuItem
                        onClick={() => {
                            onClose();
                            onArchive();
                        }}
                    >
                        <ListItemIcon>
                            <Icon className={classes.icon} iconType={IconTypes.deletePassword} />
                        </ListItemIcon>
                        <Typography variant="inherit">{i18n("general.actions.archive")}</Typography>
                    </MenuItem>
                )}
            </Menu>
        </>
    );
};

GroupTableRowContextMenu.propTypes = {
    onOpen: PropTypes.func.isRequired,
    onEdit: PropTypes.func.isRequired,
    anchorEl: PropTypes.object,
    onClose: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
    onMove: PropTypes.func.isRequired,
    groupId: PropTypes.string.isRequired,
};

export default GroupTableRowContextMenu;
