import React from "react";
import PropTypes from "prop-types";
import Icon from "../../dataDisplay/Icon";
import { IconTypes } from "../../../../types/IconType";
import { useGroupTreeQuery } from "../../../../hooks/queries/groupQueryHooks";
import { guid } from "../../../../managers/guidManager";
import { useGroupTreeStyles } from "./useGroupTreeStyles";
import GroupTreeBranch from "./GroupTreeBranch";
import TreeView from "@mui/lab/TreeView";
import { groupRoleType } from "../../../../managers/groupManager";

const GroupTree = ({ currentGroupId, onChange, withoutRoot, requiredRole }) => {
    const classes = useGroupTreeStyles();
    const { treeNodes } = useGroupTreeQuery();
    const ids = [guid.empty];

    return (
        <TreeView
            expanded={ids}
            defaultCollapseIcon={<Icon iconType={IconTypes.caretDown} />}
            defaultExpandIcon={<Icon iconType={IconTypes.users} className={classes.smallGroupIcon} />}
        >
            <GroupTreeBranch
                treeNodes={treeNodes}
                expanded={ids}
                onClick={onChange}
                currentGroupId={currentGroupId}
                withoutRoot={withoutRoot}
                requiredRole={requiredRole}
            />
        </TreeView>
    );
};

GroupTree.propTypes = {
    onChange: PropTypes.func.isRequired,
    currentGroupId: PropTypes.string,
    withoutRoot: PropTypes.bool,
    requiredRole: groupRoleType,
};

export default GroupTree;
