import React, { useEffect, useState } from "react";
import CustomModal from "../../common/feedback/modals/CustomModal";
import { FormControlLabel, Grid, Radio, RadioGroup, Typography } from "@mui/material";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { useMaterialUICheckedInput, useMaterialUIInput } from "../../../hooks/inputHook";
import { useGroupMutation, useGroupTreeByRoleQuery } from "../../../hooks/queries/groupQueryHooks";
import GroupFilter from "../../common/passwordComponents/toolbar/GroupFilter";
import { guid } from "../../../managers/guidManager";
import { groupRole } from "../../../managers/groupManager";
import { useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import { extractApolloErrorMessage } from "../../../managers/errorManager";
import useTranslation from "../../common/translation/translation";

const groupSaveLocation = {
    mainGroup: "mainGroup",
    subGroup: "subGroup",
};

const GroupAddModal = ({ onClose }) => {
    const { i18n } = useTranslation();
    const [errorMessage, setErrorMessage] = useState("");

    const { me, loading: loadingMe, error: errorMe } = useMyselfQuery();
    const { value: name, onChange: setName } = useMaterialUIInput("");
    const { value: saveInRootGroup, onChange: setSaveInRootGroup, setValue: setSaveInRootGroupValue } = useMaterialUICheckedInput(true);
    const [groups, setGroups] = useState([]);
    const parentGroupId = saveInRootGroup || !groups || groups.length === 0 ? null : groups[0];
    const { addGroup } = useGroupMutation(parentGroupId ? parentGroupId : guid.empty);
    const { treeNodes, loading: loadingTreeNodes, error: treeNodesError } = useGroupTreeByRoleQuery(groupRole.admin);

    useEffect(() => {
        // Set default value depending whether user is admin
        if (!loadingMe) setSaveInRootGroupValue(me.admin);
    }, [loadingMe]);

    const loading = loadingMe || loadingTreeNodes;
    const noGroupExists = !loading && treeNodes.length === 0;
    const validGroupSelection = saveInRootGroup || (!saveInRootGroup && groups.length === 1);

    const handleOk = async () => {
        try {
            await addGroup({ variables: { group: { name, parentGroupId } } });
            onClose();
        } catch (e) {
            console.error(e);
            setErrorMessage(extractApolloErrorMessage(e, i18n("groups.add.error")));
        }
    };

    const handleRootGroupChange = (value) => {
        setGroups(value.filter((v) => v !== groups[0]));
    };

    return (
        <CustomModal
            maxWidth="sm"
            onClose={onClose}
            okButtonEnabled={name !== "" && (noGroupExists || validGroupSelection)}
            onOk={handleOk}
            saveText={i18n("general.actions.add")}
            error={Boolean(errorMe) || Boolean(treeNodesError) || errorMessage !== ""}
            errorMessage={errorMessage}
            loading={loading}
            title={i18n("groups.add.title")}
        >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <TextField
                        margin="dense"
                        fullWidth
                        autoFocus
                        onChange={setName}
                        value={name}
                        label={i18n("groups.add.groupName")}
                        variant="outlined"
                    />
                </Grid>

                {!noGroupExists && (
                    <Grid item xs={12}>
                        {me.admin && (
                            <>
                                <Typography component="h2" style={{ fontSize: "1.5rem" }}>
                                    {i18n("groups.add.location")}
                                </Typography>
                                <RadioGroup
                                    aria-label="location"
                                    defaultValue={groupSaveLocation.mainGroup}
                                    name="radio-buttons-group"
                                    onChange={(e, v) => {
                                        setSaveInRootGroupValue(v === groupSaveLocation.mainGroup);
                                    }}
                                >
                                    <FormControlLabel value={groupSaveLocation.mainGroup} control={<Radio />} label={i18n("groups.add.mainGroup")} />
                                    <FormControlLabel value={groupSaveLocation.subGroup} control={<Radio />} label={i18n("groups.add.subGroup")} />
                                </RadioGroup>
                                {!saveInRootGroup && (
                                    <Grid item xs={12}>
                                        <Typography component="p">{i18n("groups.add.selectParent")}</Typography>
                                    </Grid>
                                )}
                                {!saveInRootGroup && (
                                    <GroupFilter setChecked={handleRootGroupChange} checked={groups} minimumGroupRole={groupRole.admin} />
                                )}
                            </>
                        )}

                        {!me.admin && (
                            <>
                                <Typography component="h2" style={{ fontSize: "1.5rem" }}>
                                    {i18n("groups.add.createSubGroup")}
                                </Typography>

                                {!saveInRootGroup && (
                                    <Grid item xs={12}>
                                        <Typography component="p">{i18n("groups.add.selectParent")}</Typography>
                                    </Grid>
                                )}
                                {!saveInRootGroup && (
                                    <GroupFilter setChecked={handleRootGroupChange} checked={groups} minimumGroupRole={groupRole.admin} />
                                )}
                            </>
                        )}
                    </Grid>
                )}
            </Grid>
        </CustomModal>
    );
};

GroupAddModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default GroupAddModal;
