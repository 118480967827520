import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import Page from "./Page";

const useStyles = makeStyles((theme) => ({
    alignmentContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    },
}));

const VerticalContentPageContainer = ({ children, ...props }) => {
    const classes = useStyles();

    return (
        <Page {...props}>
            <div className={classes.alignmentContainer}>{children}</div>
        </Page>
    );
};

VerticalContentPageContainer.defaultProps = {};

VerticalContentPageContainer.propTypes = {
    children: PropTypes.node.isRequired,
    hideDrawer: PropTypes.bool,
};

export default VerticalContentPageContainer;
