import React from "react";
import PropTypes from "prop-types";
import { useModalStatus } from "../../../hooks/modalHook";
import CustomSnackbar from "../feedback/CustomSnackbar";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({}));

/**
 *
 * @param {React.ReactNode} children
 * @param {string | function(): Promise<string>} toCopy
 * @param {string} snackbarTextSuccessfulOnCopy
 * @param {string} snackbarTextFailedOnCopy
 * @param {() => void} onAfterSuccessfulCopy
 * @param {() => void} onAfterFailedCopy
 * @param {boolean} disabled
 * @returns {JSX.Element}
 * @constructor
 */
const CopyToClipboardWithSnackbar = ({
    children,
    toCopy,
    snackbarTextSuccessfulOnCopy,
    snackbarTextFailedOnCopy,
    onAfterSuccessfulCopy,
    onAfterFailedCopy,
    disabled,
}) => {
    const classes = useStyles();

    const { modalState: successCopySnackbarState, open: openCopySuccessSnackbar, close: closeCopySuccessSnackbar } = useModalStatus();
    const { modalState: errorCopySnackbarState, open: openCopyErrorSnackbar, close: closeCopyErrorSnackbar } = useModalStatus();

    const handleCopy = async () => {
        if (disabled) return;
        try {
            const stringToCopy = typeof toCopy === typeof "" ? toCopy : await toCopy();
            await navigator.clipboard.writeText(stringToCopy);
            openCopySuccessSnackbar();
            onAfterSuccessfulCopy();
        } catch (e) {
            console.error(e);
            openCopyErrorSnackbar();
            onAfterFailedCopy();
        }
    };

    return (
        <>
            {successCopySnackbarState && (
                <CustomSnackbar
                    open
                    message={snackbarTextSuccessfulOnCopy}
                    onClose={closeCopySuccessSnackbar}
                    verticalPosition="top"
                    horizontalPosition="center"
                    variant="info"
                    withSlideTransition
                />
            )}
            {errorCopySnackbarState && (
                <CustomSnackbar
                    open
                    message={snackbarTextFailedOnCopy}
                    onClose={closeCopyErrorSnackbar}
                    verticalPosition="top"
                    horizontalPosition="center"
                    variant="error"
                    withSlideTransition
                />
            )}
            <div onClick={handleCopy}>{children}</div>
        </>
    );
};

CopyToClipboardWithSnackbar.defaultProps = {
    onAfterSuccessfulCopy: () => {},
    onAfterFailedCopy: () => {},
    disabled: false,
};

CopyToClipboardWithSnackbar.propTypes = {
    children: PropTypes.node.isRequired,
    toCopy: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
    snackbarTextSuccessfulOnCopy: PropTypes.string.isRequired,
    snackbarTextFailedOnCopy: PropTypes.string.isRequired,
    onAfterSuccessfulCopy: PropTypes.func,
    onAfterFailedCopy: PropTypes.func,
    disabled: PropTypes.bool,
};

export default CopyToClipboardWithSnackbar;
