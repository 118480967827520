import React from "react";
import PropTypes from "prop-types";
import CustomModal from "../feedback/modals/CustomModal";
import { Grid, Table, TableBody, TableCell, TableRow } from "@mui/material";
import { format } from "date-fns";
import { de } from "date-fns/esm/locale";
import { useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import useTranslation from "../translation/translation";

function AccessLogTableRow(accessLog) {
    const { me } = useMyselfQuery();

    return (
        <TableRow key={accessLog.updatedAt}>
            <TableCell>{format(new Date(accessLog.updatedAt + "Z"), "dd.MM.yyyy HH:mm", { locale: de })}</TableCell>
            {accessLog.user && <TableCell>{accessLog.user.firstname + " " + accessLog.user.lastname}</TableCell>}
            {!accessLog.user && <TableCell>{me.firstname + " " + me.lastname}</TableCell>}
        </TableRow>
    );
}

AccessLogTableRow.propTypes = {
    accessLog: PropTypes.object.isRequired,
};

function AccessLogModal({ onClose, logs }) {
    const { i18n } = useTranslation();

    return (
        <CustomModal
            onClose={onClose}
            maxWidth="sm"
            saveText="OK"
            withCancelButton={false}
            onOk={onClose}
            title={i18n("password.access.title")}
            loading={false}
            error={false}
            errorMessage=""
        >
            <Grid container>
                <Grid item xs={12}>
                    {logs.length !== 0 && (
                        <Table>
                            <TableBody>{logs.map((l) => AccessLogTableRow(l))}</TableBody>
                        </Table>
                    )}
                    {logs.length === 0 && <h2>{i18n("password.access.notAccessed")}</h2>}
                </Grid>
            </Grid>
        </CustomModal>
    );
}

AccessLogModal.propTypes = {
    logs: PropTypes.array.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default AccessLogModal;
