import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { AppBar, Button, Chip, Grid, Tab, Tabs } from "@mui/material";
import GroupModalTextField from "./GroupModalTextField";
import GroupModalPasswordTable from "./GroupModalPasswordTable";
import GroupModalUserTable from "./GroupModalUserTable";
import AddUserToGroupModal from "./AddUserToGroupModal";
import GroupModalTabPanel from "./GroupModalTabPanel";
import CustomModal from "../../common/feedback/modals/CustomModal";
import CustomSnackbar from "../../common/feedback/CustomSnackbar";
import AddPasswordModal from "../../common/passwordComponents/AddPasswordModal";
import ConfirmDeletePassword from "../../common/passwordComponents/ConfirmDeletePassword";
import InlineEditableText from "../../common/dataEntry/input/InlineEditableText";
import { KindOfPasswordTypes } from "../../../types/PasswordType";
import { getGroupRoleText } from "../../../managers/groupManager";
import { isGuidEmpty } from "../../../managers/guidManager";
import { useGroupLoading } from "./groupModalHooks";
import { useDelayedMaterialUIInput, useMaterialUIInput } from "../../../hooks/inputHook";
import { useMyselfQuery, useMyselfRole } from "../../../hooks/queries/userQueryHooks";
import { useModalStatus } from "../../../hooks/modalHook";
import { useGroupMutation, useLazyGroupTreeQuery, useRemoveUserFromGroupMutation } from "../../../hooks/queries/groupQueryHooks";
import { extractApolloErrorMessage } from "../../../managers/errorManager";
import { useSharedPasswordDeleteMutation } from "../../../hooks/queries/sharedPasswordHook";
import CircularProgress from "@mui/material/CircularProgress";
import makeStyles from "@mui/styles/makeStyles";
import useTranslation from "../../common/translation/translation";

const useStyles = makeStyles((theme) => ({
    appBar: {
        top: 80,
        backgroundColor: "white",
        color: "black",

        [theme.breakpoints.down("xs")]: {
            top: 50,
        },
    },
    addInputField: {
        margin: "5px 0px",
        marginTop: "8px",
    },
    addButton: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-end",
        paddingLeft: "1rem",
    },
}));

export const groupModalTabEnum = Object.freeze({ users: 0, passwords: 1 });
const GroupModal = ({ onClose, group, parentGroupId, onPassword, onRoleChange, onArchive }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();

    const [errorMessage, setErrorMessage] = useState("");
    const [currentPassword, setCurrentPassword] = useState();

    const { modalState: addPasswordModalState, open: openAddPasswordModal, close: closeAddPasswordModal } = useModalStatus();
    const { modalState: addUserToGroupModalState, open: openAddUserToGroupModal, close: closeAddUserToGroupModal } = useModalStatus();
    const { modalState: snackbarState, open: openSnackbar, close: closeSnackbar } = useModalStatus();

    const {
        modalState: confirmArchivePasswordModalState,
        open: openConfirmArchivePasswordModal,
        close: closeConfirmArchivePasswordModal,
    } = useModalStatus();

    const groupMutation = useGroupMutation(undefined, group.id);

    const { deletePassword } = useSharedPasswordDeleteMutation();
    const { removeUserFromGroup, loading: loadingRemoveUser } = useRemoveUserFromGroupMutation(parentGroupId, group.id);

    const { value: groupname, onChange: setGroupname } = useMaterialUIInput(group.name);
    const {
        value: userInGroupSearchTerm,
        localValue: localUserInGroupSearchTerm,
        onChange: setLocalUserInGroupSearchTerm,
    } = useDelayedMaterialUIInput("");
    const {
        value: passwordInGroupSearchTerm,
        localValue: localPasswordInGroupSearchTerm,
        onChange: setLocalPasswordInGroupSearchTerm,
    } = useDelayedMaterialUIInput("");

    const [tab, setTab] = useState(groupModalTabEnum.users);
    const { load: reloadGroupTree } = useLazyGroupTreeQuery();
    const {
        loadAll,
        usersInGroup,
        unfilteredUsersInGroup,
        passwords,
        unfilteredPasswords,
        hasError: groupLoadingError,
        loading: requestLoading,
    } = useGroupLoading({
        groupId: group.id,
        userInGroupSearchTerm,
        passwordInGroupSearchTerm,
    });

    const { me, error: myselfLoadingError, loading: myselfLoading } = useMyselfQuery();
    const { isViewer, isAdmin, isEditor } = useMyselfRole(group.id);
    const error = groupLoadingError || myselfLoadingError || errorMessage !== "";
    const loading = myselfLoading || loadingRemoveUser;

    useEffect(() => {
        if (!isGuidEmpty(group)) loadAll();
    }, [group, loadAll]);

    const onArchivePassword = (password) => {
        setCurrentPassword(password);
        openConfirmArchivePasswordModal();
    };

    const handleRemoveUserFromGroup = (userId) => {
        removeUserFromGroup({ variables: { groupId: group.id, userId } }).catch((e) => {
            console.error(e);
            setErrorMessage(i18n("groups.edit.removeUserError"));
        });
    };

    const handleTabChange = (e, newValue) => {
        setTab(newValue);
    };

    const handleEditGroup = () => {
        if (group.name === groupname) return; // Ignore when name did not change

        groupMutation
            .editGroup({ variables: { group: { name: groupname, id: group.id } } })
            .then(reloadGroupTree)
            .catch((e) => {
                console.error(e);
                setErrorMessage(i18n("groups.edit.editGroupNameError"));
            });
    };

    const userGroup = me.usergroups.find((u) => u.groupId === group.id);
    const myGroupRole = userGroup?.groupRole || "";

    const createEmptyTabContent = (description, buttonText, buttonOnClickHandler) => {
        return (
            <>
                <Grid item xs={12}>
                    <h2>{description}</h2>
                </Grid>
                {isAdmin && (
                    <Grid item xs={6} md={4}>
                        <Button className={classes.addInputField} onClick={buttonOnClickHandler} variant="contained" fullWidth color="primary">
                            {buttonText}
                        </Button>
                    </Grid>
                )}
            </>
        );
    };

    const title = (
        <Grid container>
            <Grid item>
                <InlineEditableText value={groupname} onChange={setGroupname} disabled={!isAdmin} onFinishEdit={handleEditGroup} />
            </Grid>
            <Grid item style={{ marginLeft: "15px" }}>
                <Chip label={getGroupRoleText(myGroupRole)} variant="outlined" />
            </Grid>
        </Grid>
    );

    return (
        <CustomModal
            onClose={onClose}
            saveText={i18n("general.actions.close")}
            withCancelButton={false}
            onOk={onClose}
            title={title}
            error={error}
            errorMessage={errorMessage}
            loading={loading}
        >
            <>
                <AppBar position="absolute" className={classes.appBar}>
                    <Tabs value={tab} onChange={handleTabChange}>
                        <Tab label={i18n("groups.edit.user")} />
                        <Tab label={i18n("general.keyWords.passwords")} />
                    </Tabs>
                </AppBar>
                <GroupModalTabPanel value={tab} index={groupModalTabEnum.users}>
                    {requestLoading && unfilteredUsersInGroup.length === 0 && (
                        <CircularProgress style={{ margin: "50px auto" }} className={classes.spinner} />
                    )}
                    {unfilteredUsersInGroup.length !== 0 && (
                        <>
                            <Grid item xs={isAdmin ? 10 : 12}>
                                <GroupModalTextField
                                    onChange={setLocalUserInGroupSearchTerm}
                                    value={localUserInGroupSearchTerm}
                                    label={i18n("groups.edit.searchUser")}
                                />
                            </Grid>
                            {isAdmin && (
                                <Grid item xs={2} className={classes.addButton}>
                                    <Button onClick={openAddUserToGroupModal} variant="contained" color="primary">
                                        {i18n("general.actions.add")}
                                    </Button>
                                </Grid>
                            )}

                            <Grid item xs={12}>
                                <GroupModalUserTable
                                    groupUsers={usersInGroup}
                                    groupId={group.id}
                                    onRemove={handleRemoveUserFromGroup}
                                    onRoleChange={onRoleChange}
                                />
                            </Grid>
                        </>
                    )}
                </GroupModalTabPanel>
                <GroupModalTabPanel value={tab} index={groupModalTabEnum.passwords}>
                    {unfilteredPasswords.length === 0 &&
                        localPasswordInGroupSearchTerm === "" &&
                        createEmptyTabContent(i18n("password.first.title"), i18n("general.actions.add"), openAddPasswordModal)}
                    {unfilteredPasswords.length > 0 && (
                        <>
                            <Grid item xs={12}>
                                <Grid container>
                                    <Grid item xs={!isViewer ? 10 : 12}>
                                        <GroupModalTextField
                                            onChange={setLocalPasswordInGroupSearchTerm}
                                            value={localPasswordInGroupSearchTerm}
                                            label={i18n("groups.edit.searchPassword")}
                                        />
                                    </Grid>
                                    {!isViewer && (
                                        <Grid item xs={2} className={classes.addButton}>
                                            <Button onClick={openAddPasswordModal} variant="contained" color="primary">
                                                {i18n("general.actions.add")}
                                            </Button>
                                        </Grid>
                                    )}
                                </Grid>
                            </Grid>
                            <Grid item xs={12}>
                                <GroupModalPasswordTable
                                    passwords={passwords}
                                    onPassword={onPassword}
                                    onArchivePassword={onArchivePassword}
                                    canArchive={isAdmin || isEditor}
                                />
                            </Grid>
                        </>
                    )}
                </GroupModalTabPanel>
                {confirmArchivePasswordModalState && (
                    <ConfirmDeletePassword
                        onOk={() => {
                            deletePassword({ variables: { id: currentPassword.id } })
                                .then(closeConfirmArchivePasswordModal)
                                .then(onArchive)
                                .catch((e) => {
                                    setErrorMessage(extractApolloErrorMessage(e));
                                });
                        }}
                        onClose={closeConfirmArchivePasswordModal}
                        passwords={[currentPassword]}
                    />
                )}
                {addPasswordModalState && (
                    <AddPasswordModal
                        initialPasswordType={KindOfPasswordTypes.groupPasswords}
                        isOpen={addPasswordModalState}
                        onClose={closeAddPasswordModal}
                        onOk={() => {
                            openSnackbar();
                            closeAddPasswordModal();
                        }}
                        groupId={group.id}
                    />
                )}
                {snackbarState && (
                    <CustomSnackbar onClose={closeSnackbar} message={i18n("groups.edit.savedPassword")} variant="success" withCloseButton />
                )}
                {addUserToGroupModalState && (
                    <AddUserToGroupModal usersInGroup={usersInGroup} group={group} onClose={closeAddUserToGroupModal} parentGroupId={parentGroupId} />
                )}
            </>
        </CustomModal>
    );
};

GroupModal.propTypes = {
    onClose: PropTypes.func.isRequired,
    onOk: PropTypes.func.isRequired,
    group: PropTypes.object.isRequired,
    parentGroupId: PropTypes.string,
    onPassword: PropTypes.func.isRequired,
    onRoleChange: PropTypes.func.isRequired,
    onArchive: PropTypes.func.isRequired,
};

export default GroupModal;
