import PropTypes from "prop-types";
import { useCustomerBillingPortalUrlQuery } from "../../hooks/queries/billingHook";
import ConfirmationDialog from "../common/feedback/modals/ConfirmationDialog";
import { ConfirmDialogVariantList } from "../../types/ConfirmDialogVariant";
import { useMyselfQuery } from "../../hooks/queries/userQueryHooks";

const LicenceErrorModal = ({ onClose }) => {
    const { url } = useCustomerBillingPortalUrlQuery();
    const { me } = useMyselfQuery();
    const hasIndividualPlan = me.mandator.customerinformation === null;

    const onOk = () => {
        window.open(url, "_blank");
        onClose();
    };

    return (
        <ConfirmationDialog
            open
            onOk={hasIndividualPlan ? onClose : onOk}
            onClose={onClose}
            variant={ConfirmDialogVariantList.error}
            title="Lizenzverwaltung"
            description="Sie haben zu wenig Lizenzen, um einen neuen Nutzer anzulegen."
            okText={hasIndividualPlan ? "Schließen" : "Lizenzen verwalten"}
        />
    );
};

LicenceErrorModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default LicenceErrorModal;
