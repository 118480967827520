import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            light: "#5ec4e6",
            main: "#008cc0",
            dark: "#02598b",
            contrastText: "#fff",
        },
        secondary: {
            light: "#B9D7C9",
            main: "#335c81",
            dark: "#251743",
            contrastText: "#fff",
        },
        background: {
            default: "#fff",
        },
    },
    colors: {
        pageBackground: "#F3F3F3",
        drawerTextWhite: "rgba(0, 0, 0, 0.80)",
        drawerTextBlack: "rgba(0, 0, 0, 0.80)",
        drawerBackgroundActiveHovered: "rgba(0, 0, 0, 0.20)",
        drawerDanger: "rgba(157, 0, 0, 0.54)",
        drawerDangerHovered: "rgba(107,0,0,0.54)",

        noLogoBackground: "#E3E5E7",

        iconDefault: "#E3E5E7",
        iconSelected: "#4391AE",

        filterBar: "#D2D2D2",

        textLight: "#727272",
        textNormal: "#000000",
        textDark: "#2D3035",
        textDarker: "#79818D",

        securityRating_verySecure: "#007E33",
        securityRating_secure: "#00C851",
        securityRating_weak: "#FFBB33",
        securityRating_dangerous: "#CC0000",

        changeLogOldValue: "#FFE7E7",
        changeLogNewValue: "#DDFADE",
    },
    sizes: {
        drawerWidth: "220px",
    },
    typography: {
        body1: {
            letterSpacing: "0.025em",
        },
        body2: {
            letterSpacing: "0.02em",
        },
        button: {
            letterSpacing: "0.08em",
            textTransform: "none",
        },
    },
});
// Object.freeze(theme);
export default theme;
