import PropTypes from "prop-types";
import { Button, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    paper: {
        margin: "0 auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        maxWidth: "30rem",
        padding: "30px",
        border: "1px solid #C7C6C1",
        borderRadius: "5px",
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
    heading: {
        color: "#1D1C3E",
        margin: theme.spacing(3, 0, 2),
    },
    paragraph: {
        margin: theme.spacing(1, 0, 1),
        width: "100%",
    },
}));

const ErrorInformation = ({ heading, description, errorMessage, actionButtonText, actionButtonHref }) => {
    const classes = useStyles();

    return (
        <div className={classes.paper}>
            <Typography className={classes.heading} component="h1" variant="h5">
                {heading}
            </Typography>

            <Typography className={classes.paragraph} align="justify" component="p" variant="body2">
                {description}
            </Typography>

            <Typography className={classes.paragraph} align="justify" component="p" variant="body2">
                {errorMessage}
            </Typography>

            {actionButtonText && (
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit} href={actionButtonHref}>
                    {actionButtonText}
                </Button>
            )}
        </div>
    );
};

ErrorInformation.propTypes = {
    heading: PropTypes.string.isRequired,
    description: PropTypes.node,
    errorMessage: PropTypes.string,
    actionButtonText: PropTypes.string,
    actionButtonHref: PropTypes.string,
};

export default ErrorInformation;
