import config from "../../../config";
import { ReactComponent as Logo } from "../../../images/Logo.svg";

const VaulteronLogoIcon = ({ ...props }) => {
    const version = config.version;

    return (
        <div title={`Vaulteron (version: ${version})`}>
            <Logo {...props} />
        </div>
    );
};

export default VaulteronLogoIcon;