import { MenuItem, Select } from "@mui/material";
import { KindOfPasswordTypes } from "../../../../types/PasswordType";
import React from "react";
import useTranslation from "../../translation/translation";

type PasswordType = "none" | "accountPasswords" | "companyPasswords" | "groupPasswords";

interface PasswordTypeSelectProps {
    value: PasswordType;
    onChange: (value: string) => void;
    isFilter: boolean;
}

export default function PasswordTypeSelect({ isFilter, onChange, value = "none" }: PasswordTypeSelectProps) {
    const { i18n } = useTranslation();

    return (
        <Select
            fullWidth
            value={value}
            onChange={(event) => {
                onChange(event.target.value);
            }}
            size="small"
            variant="standard"
        >
            {isFilter && <MenuItem value="none">{i18n("filter.type.noFilter")}</MenuItem>}
            <MenuItem value={KindOfPasswordTypes.companyPasswords}>{i18n("password.chips.company")}</MenuItem>
            <MenuItem value={KindOfPasswordTypes.groupPasswords}>{i18n("password.chips.group")} </MenuItem>
            <MenuItem value={KindOfPasswordTypes.accountPasswords}>{i18n("password.chips.private")}</MenuItem>
        </Select>
    );
}
