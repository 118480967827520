import React from "react";
import PropTypes from "prop-types";
import { useMediaQuery, useTheme } from "@mui/material";
import { useIsMobile } from "../../../hooks/mobileHook";

/**
 *
 * @param {React.ReactNode} children
 * @param {boolean} hideOnMobile
 * @param {boolean} hideOnTablet
 * @param {boolean} hideOnDesktop
 * @returns {JSX.Element}
 * @constructor
 */
const Visibility = ({ children, hideOnMobile, hideOnTablet, hideOnDesktop }) => {
    const theme = useTheme();
    const isMobile = useIsMobile();
    const isDesktop = useMediaQuery(theme.breakpoints.up("lg"));
    const isTablet = !isMobile && !isDesktop;

    if ((isMobile && hideOnMobile) || (isTablet && hideOnTablet) || (isDesktop && hideOnDesktop)) return <></>;
    else return <>{children}</>;
};

Visibility.defaultProps = {
    hideOnMobile: false,
    hideOnTablet: false,
    hideOnDesktop: false,
};

Visibility.propTypes = {
    hideOnMobile: PropTypes.bool,
    hideOnTablet: PropTypes.bool,
    hideOnDesktop: PropTypes.bool,
};

export default Visibility;
