import Page from "../common/layout/Page";
import { PageTypeList } from "../../types/PageType";
import {
    Button,
    Card,
    CardContent,
    Checkbox,
    FormControlLabel,
    Grid,
    Table,
    TableBody,
    TableRow,
    TableCell,
    TableHead,
    Stepper,
    Step,
    StepLabel,
    StepContent,
} from "@mui/material";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";
import React, { useState } from "react";
import useTranslation from "../common/translation/translation";
import { styled } from "@mui/system";
import { useMaterialUICheckedInput } from "../../hooks/inputHook";
import { useGetDeviceInformationQuery, useSendScamMailMutation } from "../../graphql/generated";
import { format } from "date-fns";
import { de } from "date-fns/esm/locale";
import { ReactMultiEmail } from "react-multi-email";
import "react-multi-email/dist/style.css";
import GitLab from "./gitlab.png";
import Facebook from "./facebook.png";

const Container = styled("div")({
    margin: "1em 2em",
});
export const Header = styled("p")({
    fontSize: "1.6em",
    fontWeight: "600",
    color: "#322D31",
});
export const StyledImg = styled("img")({
    width: "80%",
    boxShadow: "0px 0px 5px 1px rgba(0,0,0,0.50)",
    borderRadius: "17px 17px 17px 17px",
    marginBottom: "2em",
    marginTop: "2em",
    marginLeft: "10%",
});

export const StyledCard = styled(Card)({
    minHeight: "45em",
});
export const StyledReactMultiEmail = styled(ReactMultiEmail)({
    minHeight: "20em",
});

interface StyledStepContentProps {
    children?: React.ReactNode;
}
function StyledStepContent({ children }: StyledStepContentProps) {
    return (
        <StepContent>
            <Grid item md={12} xs={12}>
                <StyledCard>
                    <CardContent>{children}</CardContent>
                </StyledCard>
            </Grid>
        </StepContent>
    );
}

function TemplateContainer({ children }: StyledStepContentProps) {
    return (
        <Grid item md={3} sm={6} xs={12}>
            <StyledCard>
                <CardContent>{children}</CardContent>
            </StyledCard>
        </Grid>
    );
}

interface MailListSectionProps {
    mails: string[];
    setMails: (mails: string[]) => void;
}
function MailListSection({ mails, setMails }: MailListSectionProps) {
    const { i18n } = useTranslation();

    return (
        <>
            <Header>{i18n("deviceLogger.addEmail")}</Header>
            <StyledReactMultiEmail
                placeholder={i18n("deviceLogger.addEmail")}
                emails={mails}
                onChange={(_emails: string[]) => {
                    setMails(_emails);
                }}
                autoFocus={true}
                getLabel={(email: string, index: number, removeEmail: (index: number) => void) => {
                    return (
                        <div data-tag key={index}>
                            <div data-tag-item>{email}</div>
                            <span data-tag-handle onClick={() => removeEmail(index)}>
                                ×
                            </span>
                        </div>
                    );
                }}
            />
        </>
    );
}

export default function DeviceLoggerPage() {
    const { i18n } = useTranslation();
    const { value: accept, onChange: setAccept } = useMaterialUICheckedInput(false);

    const [mails, setMails] = useState<string[]>([]);
    const canSend = mails.length > 0 && accept;
    const [activeStep, setActiveStep] = useState(0);
    const [templateId, setTemplateId] = useState("");
    const [senderName, setSenderName] = useState("");
    const [redirectURL, setRedirectURL] = useState("");

    const [sendMail] = useSendScamMailMutation({});
    const { data } = useGetDeviceInformationQuery({
        fetchPolicy: "cache-and-network",
    });

    const deviceInformation = data?.deviceInformation || [];

    const onSendMail = () => {
        sendMail({ variables: { mail: { mails, templateId, senderName, redirectURL } } });
        setActiveStep(2);
    };

    return (
        <Page currentPageType={PageTypeList.adminConsole}>
            <Container>
                <PageHeader>{i18n("deviceLogger.title")}</PageHeader>
                <PageSubTitle>{i18n("deviceLogger.subTitle")}</PageSubTitle>

                <Stepper activeStep={activeStep} orientation="vertical">
                    <Step>
                        <StepLabel>{i18n("deviceLogger.chooseTemplate")}</StepLabel>
                        <StepContent>
                            <Grid container spacing={4}>
                                <TemplateContainer>
                                    <Header>GitLab - Change Password</Header>
                                    <StyledImg src={GitLab} alt="GitLab" />
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setTemplateId("d-230636a03a4e4411b623fd1a0a5d8468");
                                            setActiveStep(1);
                                            setSenderName("GitLab");
                                            setRedirectURL("gitlab.com");
                                        }}
                                    >
                                        {i18n("deviceLogger.chooseTemplate")}
                                    </Button>
                                </TemplateContainer>
                                <TemplateContainer>
                                    <Header>Facebook - Change Password</Header>
                                    <StyledImg src={Facebook} alt="Facebook" />
                                    <Button
                                        variant="contained"
                                        onClick={() => {
                                            setTemplateId("d-e760e867a6514ba49edc712a1850299b");
                                            setActiveStep(1);
                                            setSenderName("Facebook");
                                            setRedirectURL("facebook.com");
                                        }}
                                    >
                                        {i18n("deviceLogger.chooseTemplate")}{" "}
                                    </Button>
                                </TemplateContainer>
                            </Grid>
                        </StepContent>
                    </Step>
                    <Step>
                        <StepLabel>{i18n("deviceLogger.addEmail")}</StepLabel>
                        <StyledStepContent>
                            <MailListSection mails={mails} setMails={setMails} />
                            <FormControlLabel
                                disabled={mails.length === 0}
                                control={<Checkbox onChange={setAccept} checked={accept} />}
                                label={i18n("deviceLogger.accept")}
                            />
                            <br />
                            <Button onClick={onSendMail} disabled={!canSend} variant="contained">
                                {i18n("deviceLogger.send")}
                            </Button>
                        </StyledStepContent>
                    </Step>
                    <Step>
                        <StepLabel>{i18n("deviceLogger.hasSendMail")}</StepLabel>
                        <StyledStepContent>
                            <Header>{i18n("deviceLogger.hasSendMail")}</Header>
                            <Button
                                onClick={() => {
                                    setActiveStep(0);
                                }}
                                variant="contained"
                            >
                                {i18n("deviceLogger.startAgain")}
                            </Button>
                        </StyledStepContent>
                    </Step>
                </Stepper>

                <Grid container spacing={4}>
                    {deviceInformation.length === 0 ? null : (
                        <Grid item md={12} xs={12}>
                            <Card>
                                <CardContent>
                                    <Header>{i18n("deviceLogger.logHeader")}</Header>
                                    <Table>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Mail</TableCell>
                                                <TableCell align="right">Datum</TableCell>
                                                <TableCell align="right">IpAddress</TableCell>
                                                <TableCell align="right">Browser</TableCell>
                                                <TableCell align="right">BrowserVersion</TableCell>
                                                <TableCell align="right">Stadt</TableCell>
                                                <TableCell align="right">Land</TableCell>
                                                <TableCell align="right">Betriebssystem</TableCell>
                                                <TableCell align="right">Betriebssystem version</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {deviceInformation.map((device) => (
                                                <TableRow key={device?.id}>
                                                    <TableCell component="th" scope="row">
                                                        {device?.mail.replace("%40", "@")}
                                                    </TableCell>
                                                    <TableCell align="right">
                                                        {format(new Date(device?.createdAt + "Z"), "dd.MM.yyyy HH:mm:ss", { locale: de })}
                                                    </TableCell>
                                                    <TableCell align="right">{device?.ipAddress}</TableCell>
                                                    <TableCell align="right">{device?.browser}</TableCell>
                                                    <TableCell align="right">{device?.browserVersion}</TableCell>
                                                    <TableCell align="right">{device?.city}</TableCell>
                                                    <TableCell align="right">{device?.countryName}</TableCell>
                                                    <TableCell align="right">{device?.os}</TableCell>
                                                    <TableCell align="right">{device?.osVersion}</TableCell>
                                                </TableRow>
                                            ))}
                                        </TableBody>
                                    </Table>
                                </CardContent>
                            </Card>
                        </Grid>
                    )}
                </Grid>
            </Container>
        </Page>
    );
}
