import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";

import makeStyles from "@mui/styles/makeStyles";
import { TextField } from "@mui/material";
import { IconTypes } from "../../../../types/IconType";
import IconButton from "../../dataDisplay/IconButton";

const useStyles = makeStyles((theme) => ({
    editIcon: {
        padding: 0,
        alignSelf: "flex-start",

        "& .icon": {
            width: 15,
            height: 15,
            fontSize: 10,
        },
    },
    labelContainer: {
        display: "flex",
    },
}));

// Inspired by https://blog.logrocket.com/building-inline-editable-ui-in-react/
const InlineEditableText = ({ placeholder, value, onChange, onFinishEdit, disabled, className, ...props }) => {
    const classes = useStyles();

    const ref = useRef();
    const [isEditing, setEditing] = useState(false);

    useEffect(() => {
        if (ref && ref.current && isEditing === true) {
            ref.current.focus();
        }
    }, [isEditing, ref]);

    const handleKeyDown = (event) => {
        const { key } = event;
        const keys = ["Escape", "Tab"];
        const enterKey = "Enter";
        const allKeys = [...keys, enterKey];

        if (allKeys.indexOf(key) > -1) {
            setEditing(false);
            if (onFinishEdit) onFinishEdit();
        }
    };
    const handleFocusLost = () => {
        setEditing(false);
        if (onFinishEdit) onFinishEdit();
    };

    if (disabled) return value;

    return (
        <section {...props} className={className}>
            {isEditing ? (
                <div onBlur={handleFocusLost} onKeyDown={(e) => handleKeyDown(e)}>
                    <TextField inputRef={ref} value={value} onChange={onChange} placeholder={placeholder} />
                </div>
            ) : (
                <div onClick={() => setEditing(true)} className={classes.labelContainer}>
                    <label>{value || placeholder || ""}</label>
                    <IconButton iconType={IconTypes.editPasswordPen} className={classes.editIcon} />
                </div>
            )}
        </section>
    );
};

InlineEditableText.defaultProps = {
    className: "",
    value: "",
};

InlineEditableText.propTypes = {
    className: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onFinishEdit: PropTypes.func,
    disabled: PropTypes.bool,
};

export default InlineEditableText;
