import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as papa from "papaparse";
import { Checkbox, FormControl, FormControlLabel, Grid, Typography } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import CustomDataGrid from "../common/dataDisplay/CustomDataGrid";
import VaulteronColumnsPreview from "./helper/VaulteronColumnsPreview";
import ReorderableList from "./helper/ReorderableList";
import { Alert } from "@mui/lab";
import { ImportTypes, ImportTypesType } from "./helper/ImportTypes";
import { VaulteronPasswordColumns } from "./helper/VaulteronPasswordColumns";
import useTranslation from "../common/translation/translation";

const useStyles = makeStyles((theme) => ({
    container: {
        "& h2": {
            fontSize: "2rem",
        },
    },
}));

const StepAssignColumns = ({ onStepFinished, importType, uploadedFile, onInterpretedDataChanged }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();

    const [isParsingData, setIsParsingData] = useState(true);
    const [errorMessage, setErrorMessage] = useState("");
    const [firstLineIsHeading, setFirstLineIsHeading] = useState(true);
    const [columns, setColumns] = useState([]);

    const [rows, setRows] = useState([]);
    const [orderedColumns, setOrderedColumns] = useState([]);
    const [hasWebsiteColumn, setHasWebsiteColumn] = useState(true);
    const [hasAdditionalInformationColumn, setHasAdditionalInformationColumn] = useState(true);

    // See: https://stackoverflow.com/questions/61751728/asynchronous-calls-with-react-usememo
    useEffect(() => {
        let active = true;
        parseData();
        return () => {
            active = false;
        };

        async function parseData() {
            try {
                setColumns([]);
                setOrderedColumns([]);
                setRows([]);
                setErrorMessage("");
                setIsParsingData(true);
                const parsedData = await parseCSV();
                const { parsedColumns, parsedRows } = interpretData(parsedData);
                if (!active) {
                    return;
                }
                setColumns(parsedColumns);
                setRows(parsedRows);
                tryToOrderColumns(parsedColumns);
                onStepFinished(parsedRows.length > 0);
                setIsParsingData(false);
            } catch (e) {
                console.error(e);
                setErrorMessage(e.message);
            }
        }

        function tryToOrderColumns(columns) {
            const prepareTextForSearch = (text) =>
                text.replaceAll(" ", "").replaceAll("\n", "").replaceAll("\r", "").replaceAll("\t", "").toLowerCase();

            let notFoundPositionCount = 5;
            for (const column of columns) {
                const columnName = prepareTextForSearch(column.field);
                if ((columnName.includes("name") && !columnName.includes("username")) || columnName.includes("account")) column.position = 0;
                else if (columnName.includes("username") || columnName.includes("loginname")) column.position = 1;
                else if (columnName.includes("passwor")) column.position = 2;
                else if (columnName.includes("website") || columnName.includes("url")) column.position = 3;
                else if (columnName.includes("comments")) column.position = 4;
                else column.position = notFoundPositionCount++;
            }

            columns.sort((c1, c2) => c1.position - c2.position);

            setOrderedColumns(columns);
        }

        function interpretData(parsedData) {
            if (parsedData?.errors?.length > 0) {
                setErrorMessage("Beim Einlesen der Datei sind Fehler aufgetreten: " + parsedData.errors.map((e) => `${e.message}, `));
                return { parsedColumns: [], parsedRows: [] };
            }
            if (!parsedData || !parsedData.data || !parsedData.meta) return { parsedColumns: [], parsedRows: [] };

            if (parsedData.meta.fields) {
                const parsedRows = parsedData.data.map((row, index) => ({
                    id: index,
                    ...row,
                }));
                const parsedColumns = parsedData.meta.fields.map((field) => ({
                    field: field,
                    headerName: field,
                    flex: 1,
                }));
                return { parsedColumns, parsedRows };
            } else {
                // There are no title-fields --> generate generic columns
                if (parsedData.data.length === 0) return [];
                const numColumns = Object.values(parsedData.data[0]).length;
                let parsedRows = [];
                for (let i = 0; i < parsedData.data.length; i++) {
                    let currentRow = parsedData.data[i];
                    let newRow = { id: i };
                    for (let j = 0; j < numColumns; j++) {
                        newRow[`c${j}`] = currentRow[j];
                    }
                    parsedRows.push(newRow);
                }
                let parsedColumns = [...Array(numColumns).keys()].map((_, index) => ({
                    field: `c${index}`,
                    headerName: `Spalte ${index}`,
                    width: 150,
                }));
                return { parsedColumns, parsedRows };
            }
        }

        async function parseCSV() {
            return new Promise((resolve, reject) => {
                const parseConfig = {
                    header: firstLineIsHeading,
                    // delimiter: ",",
                    skipEmptyLines: "greedy",
                    // escapeChar: "\\",
                    complete: (results, file) => {
                        console.info(results);
                        resolve(results);
                    },
                    error: (error, file) => {
                        reject(error);
                    },
                };

                if (importType === ImportTypes.keepassCSV) {
                    parseConfig.escapeChar = "\\";
                }

                papa.parse(uploadedFile, parseConfig);
            });
        }
    }, [uploadedFile, firstLineIsHeading]);

    useEffect(() => {
        const interpretedData = [];

        for (let i = 0; i < rows.length; i++) {
            let dataRow = {};
            const currentRow = rows[i];
            dataRow[VaulteronPasswordColumns.passwortname] = currentRow[orderedColumns[0]?.field];
            dataRow[VaulteronPasswordColumns.username] = currentRow[orderedColumns[1]?.field];
            dataRow[VaulteronPasswordColumns.password] = currentRow[orderedColumns[2]?.field];
            if (hasWebsiteColumn) dataRow[VaulteronPasswordColumns.website] = currentRow[orderedColumns[3]?.field];
            if (hasAdditionalInformationColumn) {
                const index = hasWebsiteColumn ? 4 : 3;
                dataRow[VaulteronPasswordColumns.notes] = currentRow[orderedColumns[index]?.field];
            }
            interpretedData.push(dataRow);
        }

        onInterpretedDataChanged(interpretedData);
    }, [rows, orderedColumns, hasWebsiteColumn, hasAdditionalInformationColumn]);

    if (errorMessage) {
        return <Alert color="error">{errorMessage}</Alert>;
    }

    if (!isParsingData && columns.length === 0) {
        return (
            <div>
                <h2>Keine Daten gefunden</h2>
                <p>Diese Datei scheint leer zu sein oder sie ist nicht in einem CSV-Format.</p>
            </div>
        );
    }

    return (
        <Grid spacing={2} container direction="column" className={classes.container}>
            <Grid item xs={12}>
                <Typography component="h2">{i18n("import.columns.importedData")}</Typography>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox checked={firstLineIsHeading} onChange={() => setFirstLineIsHeading(!firstLineIsHeading)} />}
                        label={i18n("import.columns.isHeader")}
                    />
                </FormControl>
            </Grid>

            <Grid item xs={12}>
                <CustomDataGrid rows={rows} columns={columns} pageSize={10} />
            </Grid>

            <Grid item xs={12}>
                <Typography component="h2">{i18n("import.columns.assignColumns")}</Typography>
                <Typography component="p">{i18n("import.columns.assignColumnsDescription")}</Typography>
                <FormControl>
                    <FormControlLabel
                        control={<Checkbox checked={hasWebsiteColumn} onChange={() => setHasWebsiteColumn(!hasWebsiteColumn)} />}
                        label={i18n("import.columns.withWebsite")}
                    />
                </FormControl>
                <FormControl>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={hasAdditionalInformationColumn}
                                onChange={() => setHasAdditionalInformationColumn(!hasAdditionalInformationColumn)}
                            />
                        }
                        label={i18n("import.columns.withDescription")}
                    />
                </FormControl>
            </Grid>

            <Grid container item xs={8} wrap="nowrap">
                <ReorderableList columns={orderedColumns} onChange={setOrderedColumns} />

                <VaulteronColumnsPreview
                    columns={orderedColumns}
                    rows={rows}
                    hasWebsiteColumn={hasWebsiteColumn}
                    hasAdditionalInformationColumn={hasAdditionalInformationColumn}
                />
            </Grid>
        </Grid>
    );
};

StepAssignColumns.propTypes = {
    onStepFinished: PropTypes.func.isRequired,
    importType: ImportTypesType.isRequired,
    uploadedFile: PropTypes.object.isRequired,
    onInterpretedDataChanged: PropTypes.func.isRequired,
};

export default StepAssignColumns;
