import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Input from "@mui/material/Input";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import { DrawerContext } from "../../../contexts/DrawerContext";
import { Tooltip } from "@mui/material";
import { KindOfPasswordType, KindOfPasswordTypes } from "../../../types/PasswordType";
import { useIsMobile } from "../../../hooks/mobileHook";
import { useModalStatus } from "../../../hooks/modalHook";
import Icon from "../dataDisplay/Icon";
import { IconTypes } from "../../../types/IconType";
import classnames from "classnames";
import Visibility from "../dataDisplay/Visibility";
import { OfflineStatusContext } from "../../../contexts/OfflineStatusContext";
import { useMyselfQueryFromCache } from "../../../hooks/queries/userQueryHooks";
import useTranslation from "../translation/translation";

const useStyles = makeStyles(() => ({
    searchBarSpacer: {
        marginTop: 10,
    },
    container: {
        boxShadow: "0 0px 20px 0px #00000080",
        zIndex: 10,
    },
    searchBar: {
        backgroundColor: "white",
        zIndex: 1,
        flexWrap: (props) => (props.isMobile ? "nowrap" : "wrap"),
    },
    inputContainer: {
        flexGrow: 1,
        "& .MuiInputBase-root": {
            width: "100%",
        },
        "& .MuiInputBase-root input": {
            padding: "1rem 0.5rem 1rem 1.5rem",
            paddingLeft: "13px",
            fontSize: "1.3rem",
        },
        "& .MuiInput-underline:before": {
            display: "none",
            content: "unset",
        },
        "& .MuiInput-underline:after": {
            display: "none",
            content: "unset",
        },
    },
    buttonContainer: {
        marginRight: "13px",
    },
    addButton: {
        "& .MuiButton-startIcon": {
            marginRight: "2px",
        },
    },
    addButtonMobile: {
        padding: 5,
        minWidth: 0,
        borderRadius: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& .MuiButton-startIcon": {
            margin: 0,
        },
    },
}));

const SearchBar = ({ passwordType, placeholder, value, onChange, onAdd, subBar, addButtonDisabledMessage }) => {
    const isMobile = useIsMobile();
    const offlineStatusContext = OfflineStatusContext.useContainer();

    const drawerContext = DrawerContext.useContainer();
    const classes = useStyles({ drawerOpen: drawerContext.drawerOpen, isMobile: isMobile });
    const { i18n } = useTranslation();

    const { modalState: tooltipState, open: openTooltip, close: closeTooltip } = useModalStatus();
    const { me } = useMyselfQueryFromCache();
    const isBusinessCustomer = me.mandator.isBusinessCustomer;

    const isOffline = offlineStatusContext.isOffline;
    const localPasswordType = passwordType && isOffline && isBusinessCustomer ? KindOfPasswordTypes.offlinePasswords : passwordType;

    return (
        <>
            <div className={classes.container}>
                <Grid container className={classes.searchBar} alignItems="center">
                    <Grid className={classes.inputContainer}>
                        <Input type="text" placeholder={placeholder} value={value} onChange={onChange} />
                    </Grid>
                    {Boolean(onAdd) ? (
                        <Grid className={classes.buttonContainer}>
                            <Tooltip
                                title={addButtonDisabledMessage || ""}
                                arrow
                                open={tooltipState}
                                onClose={closeTooltip}
                                onOpen={() => {
                                    if (Boolean(addButtonDisabledMessage)) openTooltip();
                                }}
                            >
                                <span>
                                    <Button
                                        className={classnames(classes.addButton, { [classes.addButtonMobile]: isMobile })}
                                        onClick={onAdd}
                                        disabled={Boolean(addButtonDisabledMessage)}
                                        startIcon={isMobile ? <Icon iconType={IconTypes.plus} /> : undefined}
                                        color="primary"
                                        size="small"
                                        variant="contained"
                                        data-testid="btnAdd"
                                    >
                                        <Visibility hideOnMobile>
                                            {!Boolean(localPasswordType) && i18n("general.actions.add")}
                                            {Boolean(localPasswordType) &&
                                                (!isOffline || !isBusinessCustomer) &&
                                                i18n("password.actions.addPassword")}
                                            {Boolean(localPasswordType) &&
                                                isOffline &&
                                                isBusinessCustomer &&
                                                i18n("password.actions.addOfflinePassword")}
                                        </Visibility>
                                    </Button>
                                </span>
                            </Tooltip>
                        </Grid>
                    ) : undefined}
                </Grid>
                {subBar}
            </div>
            <div className={classes.searchBarSpacer} />
        </>
    );
};

SearchBar.defaultTypes = {
    onAdd: undefined, // Default: disable add button
    addButtonDisabledMessage: undefined,
};

SearchBar.propTypes = {
    passwordType: KindOfPasswordType,
    placeholder: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    onAdd: PropTypes.func,
    subBar: PropTypes.object,
    addButtonDisabledMessage: PropTypes.string,
};

export default SearchBar;
