// From: https://gist.github.com/deiu/2c3208c89fbc91d23226
// Secure Storage from: https://gist.github.com/saulshanabrook/b74984677bccd08b028b30d9968623f5

"used strict";

/* --------------------------------- errors --------------------------------- */

export class CryptoError extends Error {
    constructor(message = "Unknown crypto error") {
        super(message);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CryptoError);
        }
    }
}

class CryptoStorageError extends Error {
    constructor(message = "Unknown crypto storage error") {
        super(message);

        if (Error.captureStackTrace) {
            Error.captureStackTrace(this, CryptoStorageError);
        }
    }
}

/* --------------------------------- settings --------------------------------- */

const vaulteronGlobalSalt = "vaulteron-global-salt";
const ivLength = 10;
const saltLength = 16;
let signAlgorithm = {
    name: "RSA-OAEP",
    modulusLength: 4096,
    publicExponent: new Uint8Array([1, 0, 1]),
    hash: "SHA-256",
};

/* --------------------------------- helper --------------------------------- */

const textEncoder = new TextEncoder();
const textDecoder = new TextDecoder();

/**
 *
 * @param {CryptoKey} publicKey
 * @returns {Promise<string>}
 */
async function exportPublicKey(publicKey) {
    const spki = await window.crypto.subtle.exportKey("spki", publicKey);
    // Console.log("spki", spki);
    return convertBinaryToPem(spki, "RSA PUBLIC KEY");
}

/**
 *
 * @param {CryptoKey} privateKey
 * @returns {Promise<string>}
 */
async function exportPrivateKey(privateKey) {
    const pkcs8 = await window.crypto.subtle.exportKey("pkcs8", privateKey);
    // Console.log("pkcs8", pkcs8);
    return convertBinaryToPem(pkcs8, "RSA PRIVATE KEY");
}

/**
 *
 * @param {string} pemKey
 * @returns {Promise<CryptoKey>}
 */
async function importPublicKey(pemKey) {
    return await crypto.subtle.importKey("spki", convertPemToBinary(pemKey), signAlgorithm, false, ["encrypt"]);
}

/**
 *
 * @param {string} pemKey
 * @returns {Promise<CryptoKey>}
 */
async function importPrivateKey(pemKey) {
    return await crypto.subtle.importKey("pkcs8", convertPemToBinary(pemKey), signAlgorithm, false, ["decrypt"]);
}

/**
 *
 * @param {ArrayBufferLike} arrayBuffer
 * @returns {string}
 */
function arrayBufferToBase64String(arrayBuffer) {
    const byteArray = new Uint8Array(arrayBuffer);
    let byteString = "";
    for (let i = 0; i < byteArray.byteLength; i++) {
        byteString += String.fromCharCode(byteArray[i]);
    }
    return btoa(byteString);
}

/**
 *
 * @param {string} b64str
 * @returns {ArrayBufferLike}
 */
function base64StringToArrayBuffer(b64str) {
    const byteStr = atob(b64str);
    const bytes = new Uint8Array(byteStr.length);
    for (let i = 0; i < byteStr.length; i++) {
        bytes[i] = byteStr.charCodeAt(i);
    }
    return bytes.buffer;
}

/**
 *
 * @param {ArrayBufferLike} binaryData
 * @param {string} label
 * @returns {string}
 */
function convertBinaryToPem(binaryData, label) {
    const base64Cert = arrayBufferToBase64String(binaryData);
    let pemCert = "-----BEGIN " + label + "-----\r\n";
    let nextIndex = 0;
    while (nextIndex < base64Cert.length) {
        if (nextIndex + 64 <= base64Cert.length) {
            pemCert += base64Cert.substr(nextIndex, 64) + "\r\n";
        } else {
            pemCert += base64Cert.substr(nextIndex) + "\r\n";
        }
        nextIndex += 64;
    }
    pemCert += "-----END " + label + "-----\r\n";
    return pemCert;
}

/**
 *
 * @param {string} pem
 * @returns {ArrayBufferLike}
 */
function convertPemToBinary(pem) {
    const lines = pem.split("\n");
    let encoded = "";
    for (let i = 0; i < lines.length; i++) {
        if (
            lines[i].trim().length > 0 &&
            !lines[i].includes("-BEGIN RSA PRIVATE KEY-") &&
            !lines[i].includes("-BEGIN RSA PUBLIC KEY-") &&
            !lines[i].includes("-END RSA PRIVATE KEY-") &&
            !lines[i].includes("-END RSA PUBLIC KEY-")
        ) {
            encoded += lines[i].trim();
        }
    }
    return base64StringToArrayBuffer(encoded);
}

/* --------------------------------- hashing -------------------------------- */

/**
 * Creates an PBKDF2 hash to be used as a password
 *
 * @param {string} text
 * @returns {Promise<string>}
 */
const createPasswordHash = async (text) => {
    const salt = textEncoder.encode(vaulteronGlobalSalt);
    const iterations = 50000;

    const keyMaterial = await crypto.subtle.importKey("raw", textEncoder.encode(text), { name: "PBKDF2" }, false, ["deriveBits"]);
    const cryptoKeyBits = await crypto.subtle.deriveBits(
        {
            name: "PBKDF2",
            hash: "SHA-512",
            salt,
            iterations,
        },
        keyMaterial,
        512
    );
    return toHexString(new Uint8Array(cryptoKeyBits));
};

/* ------------------------------ async crypto ------------------------------ */

/**
 *
 * @returns {Promise<{privateKey: string, publicKey: string}>}
 */
const createAsymmetricalKeyPair = async () => {
    const keypair = await window.crypto.subtle.generateKey(
        {
            name: "RSA-OAEP",
            modulusLength: 4096,
            publicExponent: new Uint8Array([1, 0, 1]),
            hash: "SHA-256",
        },
        true,
        ["encrypt", "decrypt"]
    );
    // Console.log(keypair);
    const publicKey = await exportPublicKey(keypair.publicKey);
    const privateKey = await exportPrivateKey(keypair.privateKey);
    return { publicKey: publicKey, privateKey: privateKey };
};

/**
 *
 * @param {string} text
 * @param {string} publicKeyString
 * @returns {Promise<string>}
 */
const encryptWithPublicKeyStringToBase64 = async (text, publicKeyString) => {
    if (text.length === 0 || publicKeyString.length === 0) throw new CryptoError("One of the parameters is invalid");
    const stringToCheck = publicKeyString.replaceAll("\r", "").replaceAll("\n", "");
    if (!stringToCheck.startsWith("-----BEGIN RSA PUBLIC KEY-----") || !stringToCheck.endsWith("-----END RSA PUBLIC KEY-----"))
        throw new CryptoError("This is not a valid RSA key string");

    const encoded = textEncoder.encode(text);
    const publicKey = await importPublicKey(publicKeyString);
    const encryptedBuffer = await encryptWithPublicKey(encoded, publicKey);
    return arrayBufferToBase64String(encryptedBuffer);
};

/**
 *
 * @param {ArrayBufferLike} data
 * @param {CryptoKey} publicKey
 * @returns {Promise<any>}
 */
const encryptWithPublicKey = async (data, publicKey) => {
    try {
        return await window.crypto.subtle.encrypt(
            {
                name: "RSA-OAEP",
            },
            publicKey,
            data
        );
    } catch (error) {
        if (error instanceof DOMException) throw new CryptoError("Unable to encrypt: An unknown Crypto error occured");
        else throw new CryptoError("Unable to encrypt: Maybe your supplied arguments caused some problems?");
    }
};

/**
 *
 * @param {string} ciphertext
 * @param {string} privateKeyString
 * @returns {Promise<string>}
 */
const decryptWithPrivateKeyStringFromBase64 = async (ciphertext, privateKeyString) => {
    if (ciphertext.length === 0 || privateKeyString.length === 0) throw new CryptoError("One of the parameters is invalid");
    if (!privateKeyString.startsWith("-----BEGIN RSA PRIVATE KEY-----") && !privateKeyString.endsWith("-----END RSA PRIVATE KEY-----"))
        throw new CryptoError("This is not a valid RSA key string");

    const privateKey = await importPrivateKey(privateKeyString);
    const encryptedBuffer = base64StringToArrayBuffer(ciphertext);
    const decrypted = await decryptWithPrivateKey(encryptedBuffer, privateKey);
    return textDecoder.decode(decrypted);
};

/**
 *
 * @param {ArrayBufferLike} cipherData
 * @param {CryptoKey} privateKey
 * @returns {Promise<any>}
 */
const decryptWithPrivateKey = async (cipherData, privateKey) => {
    try {
        return await window.crypto.subtle.decrypt(
            {
                name: "RSA-OAEP",
            },
            privateKey,
            cipherData
        );
    } catch (error) {
        if (error instanceof DOMException) throw new CryptoError("Unable to decrypt: An unknown Crypto error occured");
        else throw new CryptoError("Unable to decrypt: Maybe your supplied arguments caused some problems?");
    }
};

/* --------------------------- synchronous crypto --------------------------- */

/**
 *
 * @param {string} passwordHash
 * @param {Uint8Array} salt
 * @returns {Promise<CryptoKey>}
 */
const getSymmetricalKeyFromUserCredentials = async (passwordHash, salt) => {
    const keyMaterial = await window.crypto.subtle.importKey("raw", textEncoder.encode(passwordHash), { name: "PBKDF2" }, false, [
        "deriveBits",
        "deriveKey",
    ]);
    return await window.crypto.subtle.deriveKey(
        {
            name: "PBKDF2",
            salt: salt,
            iterations: 100000,
            hash: "SHA-256",
        },
        keyMaterial,
        { name: "AES-GCM", length: 256 },
        true,
        ["encrypt", "decrypt"]
    );
};

/**
 *
 * @param {string} text
 * @param {Uint8Array} iv
 * @param {CryptoKey} key
 * @returns {Promise<any>}
 */
const encryptSymmetrically = async (text, iv, key) => {
    const encoded = textEncoder.encode(text);
    return await window.crypto.subtle.encrypt(
        {
            name: "AES-GCM",
            iv: iv,
        },
        key,
        encoded
    );
};

/**
 *
 * @param {ArrayBufferLike} ciphertext
 * @param {Uint8Array} iv
 * @param {CryptoKey} key
 * @returns {Promise<string>}
 */
const decryptSymmetrically = async (ciphertext, iv, key) => {
    let decrypted = await window.crypto.subtle.decrypt(
        {
            name: "AES-GCM",
            iv: iv,
        },
        key,
        ciphertext
    );
    return textDecoder.decode(decrypted);
};

/**
 *
 * @param {string} textToEncrypt
 * @param {string} passwordHash
 * @returns {Promise<string>}
 */
const encryptUsingUserCredentials = async (textToEncrypt, passwordHash) => {
    if (textToEncrypt.length === 0 || passwordHash.length === 0) throw new CryptoError("One of the parameters is invalid");

    // Salt
    const salt = new Uint8Array(saltLength);
    window.crypto.getRandomValues(salt);
    const saltString = toHexString(salt);

    // Construct IV
    // See https://developer.mozilla.org/en-US/docs/Web/API/AesGcmParams
    const iv = new Uint8Array(ivLength);
    window.crypto.getRandomValues(iv);
    const IVstring = toHexString(iv);

    try {
        const key = await getSymmetricalKeyFromUserCredentials(passwordHash, salt);
        const encryptedString = arrayBufferToBase64String(await encryptSymmetrically(textToEncrypt, iv, key));
        return saltString + IVstring + encryptedString;
    } catch (error) {
        if (error instanceof DOMException) throw new CryptoError("Unable to encrypt: An unknown Crypto error occured");
        else throw new CryptoError("Unable to encrypt: Maybe your supplied arguments caused some problems?");
    }
};

/**
 *
 * @param {string} textToDecrypt
 * @param {string} passwordHash
 * @returns {Promise<string>}
 */
const decryptUsingUserCredentials = async (textToDecrypt, passwordHash) => {
    if (textToDecrypt.length === 0 || passwordHash.length === 0) throw new CryptoError("One of the parameters is invalid");

    // Extract Salt
    const SaltString = textToDecrypt.slice(0, 2 * saltLength);
    const salt = fromHexString(SaltString);

    // Extract IV
    const IVstring = textToDecrypt.slice(2 * saltLength, 2 * saltLength + 2 * ivLength);
    const iv = fromHexString(IVstring);

    // Extract message
    const message = textToDecrypt.slice(2 * saltLength + 2 * ivLength);

    try {
        const key = await getSymmetricalKeyFromUserCredentials(passwordHash, salt);
        return await decryptSymmetrically(base64StringToArrayBuffer(message), iv, key);
    } catch (error) {
        if (error instanceof DOMException) throw new CryptoError("Unable to decrypt: An unknown Crypto error occured");
        else throw new CryptoError("Unable to decrypt: Maybe your supplied arguments caused some problems?");
    }
};

/* --------------------------------- helper --------------------------------- */

/**
 *
 * @param {string} encryptedPrivateKey
 * @param {string} userPasswordHash
 * @param {string} encryptedPassword
 * @returns {Promise<*>}
 */
const decryptEncryptedPassword = async (encryptedPrivateKey, userPasswordHash, encryptedPassword) => {
    let decryptedPrivateKey = await decryptUsingUserCredentials(encryptedPrivateKey, userPasswordHash);
    return await decryptWithPrivateKeyStringFromBase64(encryptedPassword, decryptedPrivateKey);
};

const fromHexString = (hexString) => new Uint8Array(hexString.match(/.{1,2}/g).map((byte) => parseInt(byte, 16)));

const toHexString = (bytes) => bytes.reduce((str, byte) => str + byte.toString(16).padStart(2, "0"), "");

/* ----------------------------- crypto storage ----------------------------- */

const databaseName = "Vaulteron_crypto_storage_db";
const databaseSchemaName = "crypt_db_schema";
const databaseKeyId = 1;

/**
 * @typedef StoredObject
 * @property {int} id
 * @property {CryptoKeyPair} keys
 * @property {string} encryptedDataBase64
 * @property {Uint8Array} encryptedSymmetricKey
 */

/**
 *
 * @param {Object} object
 * @returns {Promise<void | CryptoStorageError>}
 */
export async function saveSecureLocalData(object) {
    const jsonString = JSON.stringify(object);

    // Loads crypto keys that are only stored locally -> not extractable and secure
    const { localAsymmetricKeypair, encryptedSymmetricKey } = await loadOrCreateLocalAsymmetricalCryptoKeys();

    // Use the keys to decrypt the symmetrical key
    const decryptedSymmetricalKey = await decryptWithPrivateKey(encryptedSymmetricKey, localAsymmetricKeypair.privateKey);

    // Use the symmetrical key to encrypt/decrypt data
    const encryptedDataBase64 = await encryptUsingUserCredentials(jsonString, decryptedSymmetricalKey);

    await storeObject({
        id: databaseKeyId,
        keys: localAsymmetricKeypair,
        encryptedDataBase64: encryptedDataBase64,
        encryptedSymmetricKey: encryptedSymmetricKey,
    });
}

/**
 *
 * @returns {Promise<Object | CryptoStorageError>}
 */
export async function loadSecureLocalData() {
    const storedData = await loadStoredObject();
    if (!storedData) return null;

    // Use the keys to decrypt the symmetrical key
    const decryptedSymmetricalKey = await decryptWithPrivateKey(storedData.encryptedSymmetricKey, storedData.keys.privateKey);

    // Use the symmetrical key to encrypt/decrypt data
    const decryptedJson = await decryptUsingUserCredentials(storedData.encryptedDataBase64, decryptedSymmetricalKey);

    return JSON.parse(decryptedJson);
}

/**
 *
 * @returns {Promise<void | CryptoStorageError>}
 */
export async function clearSecureLocalData() {
    return new Promise((resolve, reject) => {
        const deleteDBRequest = indexedDB.deleteDatabase(databaseName);
        deleteDBRequest.onblocked = (event) => reject(new CryptoStorageError(event));
        deleteDBRequest.onerror = (event) => reject(new CryptoStorageError(event));
        deleteDBRequest.onsuccess = (event) => resolve(new CryptoStorageError(event));
    }).catch(() => {});
}

/* --------------------------- local helper --------------------------- */

/**
 *
 * @returns {Promise<{localAsymmetricKeypair: CryptoKeyPair, encryptedSymmetricKey: Uint8Array}>}
 */
async function loadOrCreateLocalAsymmetricalCryptoKeys() {
    const storedObject = await loadStoredObject();
    if (storedObject) {
        return {
            localAsymmetricKeypair: storedObject.keys,
            encryptedSymmetricKey: storedObject.encryptedSymmetricKey,
        };
    }

    const localAsymmetricKeypair = await createLocalAsymmetricalCryptoKeys();
    const symmetricKeyMaterial = generateSymmetricalKeyMaterial();
    const encryptedSymmetricKey = await encryptWithPublicKey(symmetricKeyMaterial, localAsymmetricKeypair.publicKey);
    return {
        localAsymmetricKeypair: localAsymmetricKeypair,
        encryptedSymmetricKey: encryptedSymmetricKey,
    };
}

/**
 *
 * @returns {Uint8Array}
 */
function generateSymmetricalKeyMaterial() {
    const array = new Uint32Array(10);
    crypto.getRandomValues(array);
    const material = `vaulteron-crypto-storage-symmetrical-key-material_${array.join("")}`;
    return textEncoder.encode(material);
}

/**
 *
 * @returns {Promise<StoredObject>}
 */
async function loadStoredObject() {
    return new Promise((resolve, reject) => {
        doDBOperation((store) => {
            const getDataRequest = store.get(databaseKeyId);
            getDataRequest.onsuccess = async () => {
                if (getDataRequest === undefined) resolve(undefined);
                else resolve(getDataRequest.result);
            };
        });
    });
}

/**
 *
 * @param {StoredObject} obj
 * @returns {Promise<void>}
 */
async function storeObject(obj) {
    doDBOperation((store) => {
        store.put(obj);
    });
}

function doDBOperation(callback) {
    // Open (or create) the database
    const openDBRequest = window.indexedDB.open(databaseName, 1);

    // Create the schema
    openDBRequest.onupgradeneeded = () => {
        const db = openDBRequest.result;
        db.createObjectStore(databaseSchemaName, { keyPath: "id" });
    };

    openDBRequest.onsuccess = () => {
        // Start a new transaction
        const db = openDBRequest.result;
        const tx = db.transaction(databaseSchemaName, "readwrite");
        const store = tx.objectStore(databaseSchemaName);

        callback(store);

        // Close the db when the transaction is done
        tx.oncomplete = () => db.close();
    };
}

/**
 *
 * @returns {Promise<CryptoKeyPair>}
 */
function createLocalAsymmetricalCryptoKeys() {
    return window.crypto.subtle.generateKey(
        {
            name: "RSA-OAEP",
            modulusLength: 2048, //can be 1024, 2048, or 4096
            publicExponent: new Uint8Array([0x01, 0x00, 0x01]),
            hash: { name: "SHA-256" }, //can be "SHA-1", "SHA-256", "SHA-384", or "SHA-512"
        },
        false, //whether the key is extractable (i.e. can be used in exportKey)
        ["encrypt", "decrypt"] //must be ["encrypt", "decrypt"] or ["wrapKey", "unwrapKey"]
    );
}

/* ------------------------------ export stuff ------------------------------ */
export const CryptoManager = {
    // Hashing
    createPasswordHash,
    // Async crypto
    createAsymmetricalKeyPair,
    encryptWithPublicKey: encryptWithPublicKeyStringToBase64,
    decryptWithPrivateKey: decryptWithPrivateKeyStringFromBase64,
    // Synchronous crypto
    encryptUsingUserCredentials,
    decryptUsingUserCredentials,
    // Helper
    decryptEncryptedPassword,
    // Secure crypto storage
    saveSecureLocalData,
    loadSecureLocalData,
    clearSecureLocalData,
};

// Debug: otherwise we would not be able to access the exported methods inside the console
window.globalCryptoManager = CryptoManager;
