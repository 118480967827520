import { Button, Typography } from "@mui/material";
import { KindOfPasswordTypes } from "../../../types/PasswordType";
import React from "react";
import { useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import useTranslation from "../translation/translation";
import { OfflineStatusContext } from "../../../contexts/OfflineStatusContext";

interface UserType {
    id: string;
    label: string;
}

interface PasswordSaveInformationProps {
    passwordType: KindOfPasswordTypes;
    user: UserType;
    showAction: boolean;
    currentGroupName?: string;
    onClick?: () => void;
}
export default function PasswordSaveInformation({ passwordType, user, currentGroupName, onClick, showAction }: PasswordSaveInformationProps) {
    const { i18n } = useTranslation();
    const { me } = useMyselfQuery();

    const offlineStatusContext = OfflineStatusContext.useContainer();

    if (offlineStatusContext.isOffline) return null;

    return (
        <Typography variant={"body2"}>
            {passwordType === KindOfPasswordTypes.companyPasswords &&
                user &&
                user.id !== me.id &&
                i18n("password.information.saveToUser", { key: "name", value: user.label })}
            {passwordType === KindOfPasswordTypes.companyPasswords &&
                (!user || user.id === me.id) &&
                i18n("password.information.saveToCompanyAccount")}
            {passwordType === KindOfPasswordTypes.accountPasswords && i18n("password.information.saveToPrivateAccount")}
            {passwordType === KindOfPasswordTypes.groupPasswords &&
                i18n("password.information.saveToGroup", { key: "name", value: currentGroupName })}{" "}
            {showAction ? (
                <Button onClick={onClick} color="primary" variant="text">
                    {i18n("general.actions.change")}
                </Button>
            ) : null}
        </Typography>
    );
}
