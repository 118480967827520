import React from "react";
import { gql, useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { addEntry, deleteEntry } from "../../managers/cacheManager";
import { fragments } from "../../managers/fragmentManager";
import { guid } from "../../managers/guidManager";

const GET_SHARED_TAGS = gql`
    query SharedTagQuery($searchTerm: String!, $groupId: Guid!) {
        sharedTags(searchTerm: $searchTerm, groupId: $groupId) {
            ...SelectSharedTag
        }
    }
    ${fragments.query.SelectSharedTag}
`;

export const useAccountTagMutation = () => {
    const ADD_ACCOUNT_TAG = gql`
        mutation AddAccountTag($accountTag: AddAccountTagInputType!) {
            accountTag {
                add(tag: $accountTag) {
                    ...SelectAccountTag
                }
            }
        }
        ${fragments.query.SelectAccountTag}
    `;

    const EDIT_ACCOUNT_TAG = gql`
        mutation EditAccountTag($accountTag: EditAccountTagInputType!) {
            accountTag {
                edit(tag: $accountTag) {
                    ...SelectAccountTag
                }
            }
        }
        ${fragments.query.SelectAccountTag}
    `;

    const DELETE_ACCOUNT_TAG = gql`
        mutation DeleteAccountTag($id: Guid!) {
            accountTag {
                delete(id: $id) {
                    ...SelectAccountTag
                }
            }
        }
        ${fragments.query.SelectAccountTag}
    `;

    const [addAccountTag] = useMutation(ADD_ACCOUNT_TAG, {
        update(
            cache,
            {
                data: {
                    accountTag: { add: newTag },
                },
            }
        ) {
            cache.modify({
                fields: {
                    accountTags(accountTagRef) {
                        return addEntry(cache, accountTagRef, fragments.query.SelectAccountTag, newTag);
                    },
                },
                broadcast: false,
            });
        },
    });

    const [updateAccountTag] = useMutation(EDIT_ACCOUNT_TAG);
    const [deleteAccountTag] = useMutation(DELETE_ACCOUNT_TAG, {
        update(
            cache,
            {
                data: {
                    accountTag: { delete: tagToDelete },
                },
            }
        ) {
            cache.modify({
                fields: {
                    accountTags(accountTagRef, { readField }) {
                        return deleteEntry(accountTagRef, readField, tagToDelete.id);
                    },
                },
                broadcast: false,
            });
        },
    });

    return { addAccountTag, updateAccountTag, deleteAccountTag };
};

export const useSharedTagMutation = () => {
    const ADD_SHARED_TAG = gql`
        mutation AddSharedTag($sharedTag: AddSharedTagInputType!) {
            sharedTag {
                add(tag: $sharedTag) {
                    ...SelectSharedTag
                }
            }
        }
        ${fragments.query.SelectSharedTag}
    `;

    const EDIT_SHARED_TAG = gql`
        mutation EditSharedTag($sharedTag: EditSharedTagInputType!) {
            sharedTag {
                edit(tag: $sharedTag) {
                    ...SelectSharedTag
                }
            }
        }
        ${fragments.query.SelectSharedTag}
    `;
    const [addSharedTag] = useMutation(ADD_SHARED_TAG, {
        update(
            cache,
            {
                data: {
                    sharedTag: { add: newTag },
                },
            }
        ) {
            cache.modify({
                fields: {
                    sharedTags(currentRefs) {
                        return addEntry(cache, currentRefs, fragments.query.SelectSharedTag, newTag);
                    },
                },
                broadcast: false,
            });
        },
    });

    const [updateSharedTag] = useMutation(EDIT_SHARED_TAG);

    const DELETE_Shared_Tag = gql`
        mutation DeleteSharedTag($id: Guid!) {
            sharedTag {
                delete(id: $id) {
                    ...SelectSharedTag
                }
            }
        }
        ${fragments.query.SelectSharedTag}
    `;
    const [deleteSharedTag] = useMutation(DELETE_Shared_Tag, {
        update(
            cache,
            {
                data: {
                    sharedTag: { delete: tag },
                },
            }
        ) {
            cache.modify({
                fields: {
                    sharedTags(sharedTagRef, { readField }) {
                        return deleteEntry(sharedTagRef, readField, tag.id);
                    },
                },
                broadcast: false,
            });
        },
    });

    return { addSharedTag, updateSharedTag, deleteSharedTag };
};

/**
 *
 * @param {string} searchTerm
 * @returns {{accountTags: (AccountTagType[]), loading: boolean, error: ApolloError}}
 */
export const useAccountTagQuery = (searchTerm = "") => {
    const GET_ACCOUNT_TAGS = gql`
        query AccountTagQuery($searchTerm: String!) {
            accountTags(searchTerm: $searchTerm) {
                ...SelectAccountTag
            }
        }
        ${fragments.query.SelectAccountTag}
    `;

    const { data, loading, error } = useQuery(GET_ACCOUNT_TAGS, {
        variables: { searchTerm },
        fetchPolicy: "cache-first",
    });

    const accountTags = !loading && !error ? data.accountTags : [];

    return { accountTags, loading, error };
};

/**
 *
 * @param {string} searchTerm
 * @param {Guid} groupId
 * @returns {{sharedTags: (SharedTagType[]), loading: boolean, error: ApolloError}}
 */
export const useSharedTagQuery = (searchTerm, groupId = guid.empty) => {
    const { data, loading, error } = useQuery(GET_SHARED_TAGS, {
        variables: { searchTerm, groupId },
        fetchPolicy: "cache-first",
    });

    const sharedTags = !loading && !error ? data.sharedTags : [];

    return { sharedTags, loading, error };
};

export const useLazySharedTagQuery = (searchTerm, groupId = guid.empty) => {
    const [load, { data, loading, error }] = useLazyQuery(GET_SHARED_TAGS, {
        variables: { searchTerm, groupId },
        fetchPolicy: "cache-first",
    });
    const sharedTags = !loading && !error && data ? data.sharedTags : [];
    return { load, sharedTags, loading, error };
};
