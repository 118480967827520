import React, { useState } from "react";
import { LoginContext } from "../../../contexts/LoginContext";
import makeStyles from "@mui/styles/makeStyles";
import { useModalStatus } from "../../../hooks/modalHook";
import CustomSnackbar from "./CustomSnackbar";
import { useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import { Alert } from "@mui/lab";
import { DrawerContext } from "../../../contexts/DrawerContext";
import useTranslation from "../translation/translation";

const useStyles = makeStyles((theme) => ({
    banner: {
        zIndex: 20,
        borderRadius: 0,
    },
    fakeBackgroundHeight: {
        height: 0,
    },
    link: {
        cursor: "pointer",
        fontWeight: "bold",
        textDecoration: "underline",
    },
}));

const EmailConfirmationBanner = () => {
    const drawerContext = DrawerContext.useContainer();
    const classes = useStyles({ drawerOpen: drawerContext.drawerOpen });

    const loginContext = LoginContext.useContainer();
    const { modalState: isErrorSnackbarOpen, open: openErrorSnackbar, close: closeErrorSnackbar } = useModalStatus();
    const { modalState: isSuccessSnackbarOpen, open: openSuccessSnackbar, close: closeSuccessSnackbar } = useModalStatus();
    const [activationMailSendSuccessfully, setActivationMailSendSuccessfully] = useState(false);
    const { i18n } = useTranslation();

    const { loading, error, me } = useMyselfQuery();

    const onResendActivationMail = async () => {
        try {
            await loginContext.resendActivationMail();
            setActivationMailSendSuccessfully(true);
            openSuccessSnackbar();
        } catch (e) {
            setActivationMailSendSuccessfully(false);
            openErrorSnackbar();
        }
    };

    return (
        <>
            {loginContext.loggedIn && !activationMailSendSuccessfully && !loading && !error && !me.emailConfirmed && (
                <>
                    <div className={classes.fakeBackgroundHeight} />
                    <Alert severity="error" className={classes.banner}>
                        <div style={{ marginTop: "3px" }}>
                            {i18n("banner.activationLink")}
                            <span onClick={onResendActivationMail} className={classes.link}>
                                {i18n("banner.newActivationLink")}
                            </span>
                        </div>
                    </Alert>
                </>
            )}
            {isErrorSnackbarOpen && <CustomSnackbar open variant="error" onClose={closeErrorSnackbar} message={i18n("banner.activationError")} />}
            {isSuccessSnackbarOpen && <CustomSnackbar open variant="success" onClose={closeSuccessSnackbar} message={i18n("banner.sendMail")} />}
        </>
    );
};

export default EmailConfirmationBanner;
