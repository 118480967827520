import React, { useState } from "react";
import CustomModal from "../feedback/modals/CustomModal";
import { Grid, TextField } from "@mui/material";
import TagSelect from "../dataEntry/select/TagSelect";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { useAutocompleteInput, useMaterialUIInput } from "../../../hooks/inputHook";
import { useMutation } from "@apollo/client";
import { EDIT_ACCOUNT_PASSWORD } from "../../../queries/passwordQuery";
import { CryptoManager } from "../../../managers/cryptoManager";
import PasswordInputField from "../dataEntry/input/PasswordInputField";
import { KindOfPasswordType, KindOfPasswordTypes, PasswordType } from "../../../types/PasswordType";
import { useLazySharedPasswordMutations } from "../../../hooks/queries/passwordQueryHooks";
import { useLazyUserQueries, useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import { encryptForUsersAndMyself } from "../../../managers/passwordManager";
import IconButton from "../dataDisplay/IconButton";
import { IconTypesList } from "../../../types/IconType";
import { IconColorTypeList } from "../../../types/IconColorType";
import { useRetrieveDecryptedPasswordString } from "../../../hooks/cryptoHook";
import { isWebsite, prepareWebsiteUrlToBeOpened } from "../../../managers/urlManager";
import config from "../../../config.json";
import CopyToClipboardWithSnackbar from "../dataDisplay/CopyToClipboardWithSnackbar";
import { LoginContext } from "../../../contexts/LoginContext";
import { OfflinePasswordsContext } from "../../../contexts/OfflinePasswordsContext";
import { OfflineStatusContext } from "../../../contexts/OfflineStatusContext";
import LoadingButton from "../dataDisplay/LoadingButton";
import useTranslation from "../translation/translation";
import { useModalStatus } from "../../../hooks/modalHook";
import { GeneratePassword } from "../../dashboard/GeneratePassword";

const useStyles = makeStyles((theme) => ({
    container: {
        margin: "auto",
        maxWidth: "600px",
    },
    text: {
        textAlign: "right",
        fontSize: "large",
        marginTop: "18px",
        paddingRight: "30px",
    },
    input: {
        width: "100%",
    },
    actionButtonContainer: {
        display: "flex",
        alignItems: "center",
        justifyContent: "center",

        "& .MuiButtonBase-root": {
            padding: "5px",
        },
    },
}));

/**
 *
 * @param {() => void} onOk
 * @param {() => void} onClose
 * @param {SharedPasswordType} password
 * @param {KindOfPasswordType} passwordType
 * @param {boolean} disableTags
 * @param {boolean} editable
 * @returns {JSX.Element}
 * @constructor
 */
const PasswordModal = ({ onOk, onClose, password, passwordType, disableTags, editable }) => {
    const classes = useStyles();
    const loginContext = LoginContext.useContainer();
    const offlineStatusContext = OfflineStatusContext.useContainer();
    const offlinePasswordsContext = OfflinePasswordsContext.useContainer();
    const { i18n } = useTranslation();

    const [showPassword, setShowPassword] = useState(false);
    const [successfullyDecrypted, setSuccessfullyDecrypted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [originalDecryptedPassword, setOriginalDecryptedPassword] = useState("");

    const { value: name, onChange: setName } = useMaterialUIInput(password.name);
    const { value: login, onChange: setLogin } = useMaterialUIInput(password.login);
    const { value: notes, onChange: setNotes } = useMaterialUIInput(password.notes || "");
    const { value: website, onChange: setWebsite } = useMaterialUIInput(password.websiteURL || password.websiteUrl || "");
    const { value: newPassword, onChange: setNewPassword, setValue: setNewPasswordValue } = useMaterialUIInput("");
    const { value: tags, onChange: setTags } = useAutocompleteInput(password.tags);

    const [editAccountPasswordMutation] = useMutation(EDIT_ACCOUNT_PASSWORD);
    const lazySharedPasswordMutations = useLazySharedPasswordMutations();
    const lazyOtherUsersQueries = useLazyUserQueries();
    const retrieveDecryptedPasswordStringHook = useRetrieveDecryptedPasswordString();

    const { modalState: passwordGeneratorState, open: openPasswordGeneratorModal, close: closePasswordGeneratorModal } = useModalStatus();
    const [generatedPassword, setGeneratedPassword] = useState("");

    const { loading: loadingQueryMyself, error: errorQueryMyself, me } = useMyselfQuery();

    const editAccountPassword = async (passwordToUpdate) => {
        try {
            if (successfullyDecrypted && newPassword !== originalDecryptedPassword) {
                passwordToUpdate.encryptedPassword = {
                    keyPairId: me.keyPair.id,
                    encryptedPasswordString: await CryptoManager.encryptWithPublicKey(newPassword, me.keyPair.publicKeyString),
                };
            }
            await editAccountPasswordMutation({ variables: { accountPassword: passwordToUpdate } });
            onOk();
        } catch (err) {
            console.error(err);
            setErrorMessage(i18n("password.actions.editError"));
        }
    };
    const editGroupPassword = async (passwordToUpdate) => {
        try {
            if (successfullyDecrypted && newPassword !== originalDecryptedPassword) {
                const admins = await lazyOtherUsersQueries.executeLoadAdmins();
                const usersWithAccess = await lazyOtherUsersQueries.executeGetUsersWithAccessToGroup(password.groupId);
                passwordToUpdate.encryptedPasswords = await encryptForUsersAndMyself(me, newPassword, [...admins, ...usersWithAccess]);
                await lazySharedPasswordMutations.useExecuteEditSharedPassword(passwordToUpdate);

                onOk();
            } else {
                await lazySharedPasswordMutations.useExecuteEditSharedPassword(passwordToUpdate);
                onOk();
            }
        } catch (err) {
            console.error(err);
            setErrorMessage(i18n("password.actions.editError"));
        }
    };

    const editCompanyPassword = async (passwordToUpdate) => {
        try {
            if (successfullyDecrypted && newPassword !== originalDecryptedPassword) {
                let users = await lazyOtherUsersQueries.executeLoadAdmins();
                if (password.createdBy.id !== me.id) {
                    const targetUser = await lazyOtherUsersQueries.executeLoadUserById(password.createdBy.id, true, null);
                    users = [...users, targetUser];
                }
                passwordToUpdate.encryptedPasswords = await encryptForUsersAndMyself(me, newPassword, users);
                await lazySharedPasswordMutations.useExecuteEditSharedPassword(passwordToUpdate);

                onOk();
            } else {
                await lazySharedPasswordMutations.useExecuteEditSharedPassword(passwordToUpdate);
                onOk();
            }
        } catch (err) {
            console.error(err);
            setErrorMessage(i18n("password.actions.editError"));
        }
    };

    const editOfflinePassword = async (passwordToUpdate) => {
        try {
            delete passwordToUpdate.id;
            passwordToUpdate.offlineGuid = password.offlineGuid;

            if (successfullyDecrypted && newPassword !== originalDecryptedPassword) {
                passwordToUpdate.encryptedPassword = await CryptoManager.encryptWithPublicKey(newPassword, me.keyPair.publicKeyString);
            } else {
                passwordToUpdate.encryptedPassword = password.encryptedPassword;
            }

            offlinePasswordsContext.updatePassword(passwordToUpdate);

            onOk();
        } catch (err) {
            console.error(err);
            setErrorMessage(i18n("password.actions.editError"));
        }
    };

    const handleSubmit = async () => {
        const passwordToUpdate = {
            id: password.id,
            name: name,
            login: login,
            websiteURL: website,
            notes: notes,
            tags: tags.map((t) => t.id),
            modifyLock: password.modifyLock,
        };

        switch (passwordType) {
            case KindOfPasswordTypes.accountPasswords:
                await editAccountPassword(passwordToUpdate);
                break;
            case KindOfPasswordTypes.groupPasswords:
                await editGroupPassword(passwordToUpdate);
                break;
            case KindOfPasswordTypes.companyPasswords:
                await editCompanyPassword(passwordToUpdate);
                break;
            case KindOfPasswordTypes.offlinePasswords:
                await editOfflinePassword(passwordToUpdate);
                break;
        }
    };

    const decryptPassword = async () => {
        try {
            let decryptedPassword = "";
            if (passwordType === KindOfPasswordTypes.offlinePasswords) {
                decryptedPassword = await CryptoManager.decryptEncryptedPassword(
                    me.keyPair.encryptedPrivateKey,
                    loginContext.userPasswordHash,
                    password.encryptedPassword
                );
            } else {
                decryptedPassword = await retrieveDecryptedPasswordStringHook(passwordType, password.id);
            }

            setSuccessfullyDecrypted(true);
            setNewPasswordValue(decryptedPassword);
            setOriginalDecryptedPassword(decryptedPassword);
            return decryptedPassword;
        } catch (err) {
            console.error(err);
            setErrorMessage(err.message);
        }
    };

    const openWebsite = () => {
        const url = prepareWebsiteUrlToBeOpened(website);
        if (!Boolean(url)) return;

        const anchorElement = document.createElement("a");
        anchorElement.target = "_blank";
        anchorElement.href = url;
        anchorElement.click();
    };

    const passwordInputCorrect = !successfullyDecrypted || (newPassword?.length > 0 && newPassword?.length <= config.maxPasswordLength);
    const okButtonEnabled = Boolean(name) && name !== "" && editable && passwordInputCorrect;
    const title = editable ? i18n("password.editTitle") : i18n("password.viewTitle");

    const localIsOfflineEditable = !offlineStatusContext.isOffline || passwordType === KindOfPasswordTypes.offlinePasswords;
    const localIsEditable = editable && localIsOfflineEditable;

    const isPasswordInput = successfullyDecrypted && showPassword;
    const loadingPassword = !successfullyDecrypted && showPassword;

    return (
        <>
            <CustomModal
                onClose={onClose}
                maxWidth="sm"
                saveText={i18n("password.actions.edit")}
                onOk={handleSubmit}
                okButtonEnabled={okButtonEnabled}
                title={title}
                loading={loadingQueryMyself && !me}
                error={Boolean(errorQueryMyself) || errorMessage !== ""}
                errorMessage={errorMessage}
            >
                <div className={classes.container}>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField
                                value={name}
                                onChange={setName}
                                required
                                size="small"
                                variant="outlined"
                                label={i18n("password.information.name")}
                                className={classes.input}
                                style={{ marginTop: "0.6em" }}
                                disabled={!localIsEditable}
                                data-testid="pw-name"
                            />
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={11}>
                                    <TextField
                                        value={website}
                                        onChange={setWebsite}
                                        label={i18n("password.information.website")}
                                        variant="outlined"
                                        size="small"
                                        className={classes.input}
                                        disabled={!localIsEditable}
                                        data-testid="pw-website"
                                    />
                                </Grid>
                                <Grid item xs={1} className={classes.actionButtonContainer}>
                                    <IconButton
                                        iconType={IconTypesList.externalLink}
                                        colorType={IconColorTypeList.secondary}
                                        disabled={!isWebsite(website)}
                                        onClick={openWebsite}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={11}>
                                    <TextField
                                        value={login}
                                        onChange={setLogin}
                                        variant="outlined"
                                        size="small"
                                        label={i18n("password.information.usernameEmail")}
                                        className={classes.input}
                                        disabled={!localIsEditable}
                                        data-testid="pw-login"
                                    />
                                </Grid>
                                <Grid item xs={1} className={classes.actionButtonContainer}>
                                    <CopyToClipboardWithSnackbar
                                        snackbarTextSuccessfulOnCopy={i18n("password.actions.copyUsername")}
                                        snackbarTextFailedOnCopy={i18n("password.actions.copyError")}
                                        toCopy={login}
                                        disabled={!Boolean(login)}
                                    >
                                        <IconButton
                                            iconType={IconTypesList.copy}
                                            colorType={IconColorTypeList.secondary}
                                            disabled={!Boolean(login)}
                                        />
                                    </CopyToClipboardWithSnackbar>
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={10}>
                                    {isPasswordInput && (
                                        <PasswordInputField
                                            required
                                            value={newPassword}
                                            label={i18n("password.information.password")}
                                            variant="outlined"
                                            onChange={setNewPassword}
                                            size="small"
                                            className={classes.input}
                                            showInitially={true}
                                            disabled={!localIsEditable}
                                            error={Boolean(newPassword.length > config.maxPasswordLength)}
                                            errorText={i18n("password.information.passwordMaxLength", {
                                                key: "count",
                                                value: config.maxPasswordLength,
                                            })}
                                        />
                                    )}
                                    {!isPasswordInput && (
                                        <LoadingButton
                                            loading={loadingPassword}
                                            variant="outlined"
                                            fullWidth
                                            id="btn-showpassword"
                                            onClick={() => {
                                                decryptPassword();
                                                setShowPassword(true);
                                            }}
                                            disabled={!localIsOfflineEditable}
                                        >
                                            {i18n("password.information.showPassword")}
                                        </LoadingButton>
                                    )}
                                </Grid>
                                <Grid item xs={1} className={classes.actionButtonContainer}>
                                    <IconButton
                                        iconType={IconTypesList.tools}
                                        colorType={IconColorTypeList.secondary}
                                        onClick={openPasswordGeneratorModal}
                                    />
                                </Grid>
                                <Grid item xs={1} className={classes.actionButtonContainer}>
                                    <CopyToClipboardWithSnackbar
                                        snackbarTextSuccessfulOnCopy={i18n("password.actions.copyPassword")}
                                        snackbarTextFailedOnCopy={i18n("password.actions.copyError")}
                                        toCopy={successfullyDecrypted ? newPassword : decryptPassword}
                                        disabled={!passwordInputCorrect || !localIsOfflineEditable}
                                    >
                                        <IconButton
                                            iconType={IconTypesList.copy}
                                            colorType={IconColorTypeList.secondary}
                                            disabled={!passwordInputCorrect || !localIsOfflineEditable}
                                        />
                                    </CopyToClipboardWithSnackbar>
                                </Grid>
                            </Grid>
                        </Grid>
                        {!disableTags && (
                            <Grid item xs={12}>
                                <TagSelect
                                    value={tags}
                                    groupId={password.groupId}
                                    onChange={setTags}
                                    className={classes.input}
                                    size="small"
                                    label="Tag hinzufügen"
                                    disabled={!localIsEditable}
                                    isAccountTags={passwordType === KindOfPasswordTypes.accountPasswords}
                                />
                            </Grid>
                        )}
                        <Grid item xs={12}>
                            <TextField
                                value={notes}
                                onChange={setNotes}
                                className={classes.input}
                                variant="outlined"
                                size="small"
                                label={i18n("password.information.description")}
                                minRows={4}
                                maxRows={15}
                                multiline
                                disabled={!localIsEditable}
                                data-testid="pw-description"
                            />
                        </Grid>
                    </Grid>
                </div>
            </CustomModal>
            {passwordGeneratorState && (
                <CustomModal
                    loading={false}
                    error={false}
                    onOk={() => {
                        setNewPasswordValue(generatedPassword);
                        closePasswordGeneratorModal();
                    }}
                    onClose={closePasswordGeneratorModal}
                    saveText={i18n("general.actions.ok")}
                    maxWidth="xs"
                    title={i18n("dashboard.support.generate.action")}
                >
                    <GeneratePassword
                        onChange={(newPW) => {
                            setGeneratedPassword(newPW);
                        }}
                    />
                </CustomModal>
            )}
        </>
    );
};

PasswordModal.propTypes = {
    onOk: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    password: PasswordType.isRequired,
    disableTags: PropTypes.bool.isRequired,
    passwordType: KindOfPasswordType.isRequired,
    editable: PropTypes.bool.isRequired,
};

export default PasswordModal;
