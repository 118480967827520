import React from "react";
import * as PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";
import { Grid } from "@mui/material";
import FilterButton from "../../dataDisplay/FilterButton";
import { IconTypes } from "../../../../types/IconType";

const useStyles = makeStyles((theme) => ({
    subBar: {
        padding: "0.3rem 1rem",
        backgroundColor: "white",
        justifyContent: "space-between",
        borderTop: "2px solid #a0a0a080",
    },
    filters: {
        display: "flex",
        alignItems: "center",
    },
    filterTitle: {
        fontSize: ".9rem",
        marginRight: "20px",
        color: theme.colors.textLight,
    },
}));

const PasswordFilterBar = ({ filterArchived, onChangeFilterArchived }) => {
    const classes = useStyles();

    const handleFilterArchived = () => onChangeFilterArchived(!filterArchived);

    return (
        <Grid container className={classes.subBar}>
            <Grid>
                <Grid container justifyContent="center">
                    <Grid className={classes.filters}>
                        <span className={classes.filterTitle}>Filter bei:</span>
                        <FilterButton onClick={handleFilterArchived} iconType={IconTypes.fileArchive} activated={filterArchived} text="Archiviert" />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

PasswordFilterBar.propTypes = {
    filterArchived: PropTypes.bool.isRequired,
    onChangeFilterArchived: PropTypes.func.isRequired,
};

export default PasswordFilterBar;
