import { useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import CustomModal from "../feedback/modals/CustomModal";
import { Grid, MenuItem, Select, TextField, Typography } from "@mui/material";
import { Alert, AlertTitle, Autocomplete } from "@mui/lab";
import makeStyles from "@mui/styles/makeStyles";
import GroupFilter from "../passwordComponents/toolbar/GroupFilter";
import { KindOfPasswordType, KindOfPasswordTypes, PasswordType } from "../../../types/PasswordType";
import { useMaterialUIInput } from "../../../hooks/inputHook";
import { guid, isGuidEmpty } from "../../../managers/guidManager";
import { useLazySharedPasswordsByGroupIdsQuery } from "../../../hooks/queries/sharedPasswordHook";
import { useLazyOfflinePasswordQuery } from "../../../hooks/queries/offlinePasswordHook";

import { useLazyUserQueries, useMyselfQueryFromCache } from "../../../hooks/queries/userQueryHooks";
import { groupRole } from "../../../managers/groupManager";
import { extractApolloErrorMessage } from "../../../managers/errorManager";
import { encryptForUsersAndMyself, reEncryptPasswordsForUsers } from "../../../managers/passwordManager";
import { useRetrieveDecryptedPasswordString } from "../../../hooks/cryptoHook";
import {
    useGetCompanyPasswordLazyQuery,
    useMoveCompanyPasswordToSharedPasswordMutation,
    useMoveSharedPasswordsToGroupMutation,
    useMoveSharedPasswordToCompanyPasswordMutation,
} from "../../../graphql/generated";
import useTranslation from "../translation/translation";

const useStyles = makeStyles((theme) => ({
    contentContainer: {
        minHeight: "50vh",
        flexWrap: "nowrap",
        flexDirection: "column",
    },
    title: {
        fontSize: "1.2rem",
        fontWeight: "bold",
    },
    rightTableCell: {
        fontSize: "1rem",
    },
    tableRow: {
        "& > *": {
            border: "none",
        },
    },
}));

const ConvertPasswordModal = ({ preselectedPasswords, onClose, fromType, fromGroupId }) => {
    const classes = useStyles();
    const { i18n } = useTranslation();
    const { me } = useMyselfQueryFromCache();
    const [errorMessage, setErrorMessage] = useState("");
    const [targetGroupId, setTargetGroupId] = useState(guid.empty);
    const [selectedPasswordsOptions, setSelectedPasswordsOptions] = useState(
        preselectedPasswords.map((password) => ({
            value: password.id,
            label: password.name,
        }))
    );
    const { value: targetKindOfPassword, onChange: setTargetKindOfPassword } = useMaterialUIInput(
        fromType === KindOfPasswordTypes.companyPasswords ? KindOfPasswordTypes.groupPasswords : KindOfPasswordTypes.companyPasswords
    );

    const retrieveDecryptedPasswordString = useRetrieveDecryptedPasswordString();
    const lazyOtherUsersQueries = useLazyUserQueries();

    const [moveCompanyPasswordsToSharedPasswords] = useMoveCompanyPasswordToSharedPasswordMutation();
    const [moveSharedPasswordsToCompanyPasswords] = useMoveSharedPasswordToCompanyPasswordMutation();
    const [moveSharedPasswordsToGroup] = useMoveSharedPasswordsToGroupMutation();

    const [getCompanyPasswords, { error: errorCompanyPasswords, data }] = useGetCompanyPasswordLazyQuery();
    const companyPasswords = data?.companyPasswords || [];

    const { getSharedPasswordsByGroupIds, sharedPasswords, error: errorSharedPasswordsByGroupIds } = useLazySharedPasswordsByGroupIdsQuery();
    const { getOfflinePasswords, offlinePasswords, error: errorOfflinePasswords } = useLazyOfflinePasswordQuery();
    const mapToPasswordSelectOptions = (passwords) => passwords.map((password) => ({ value: password.id, label: password.name }));
    const mappedSharedPasswords = useMemo(() => mapToPasswordSelectOptions(sharedPasswords), [sharedPasswords]);
    const mappedCompanyPasswords = useMemo(() => mapToPasswordSelectOptions(companyPasswords), [companyPasswords]);
    const mappedOfflinePasswords = useMemo(() => mapToPasswordSelectOptions(offlinePasswords), [offlinePasswords]);
    const passwordsAsSelectOptions = [...mappedCompanyPasswords, ...mappedSharedPasswords, ...mappedOfflinePasswords];
    useEffect(() => {
        switch (fromType) {
            case KindOfPasswordTypes.companyPasswords:
                getCompanyPasswords();
                break;
            case KindOfPasswordTypes.groupPasswords:
                getSharedPasswordsByGroupIds({ variables: { searchTerm: "", groupIds: [fromGroupId] } });
                break;
            case KindOfPasswordTypes.accountPasswords:
                break;
            case KindOfPasswordTypes.offlinePasswords:
                getOfflinePasswords();
                break;
        }
    }, [fromType, fromGroupId]);

    const onOk = () => {
        const passwordsToMoveIds = selectedPasswordsOptions.map((pw) => pw.value);

        if (targetKindOfPassword === KindOfPasswordTypes.groupPasswords) {
            handleMoveToSharedPassword(passwordsToMoveIds);
        }

        if (targetKindOfPassword === KindOfPasswordTypes.companyPasswords) {
            handleMoveToCompanyPassword(passwordsToMoveIds);
        }
    };

    const handleMoveToSharedPassword = async (passwordsToMoveIds) => {
        try {
            // Retrieve all decryptedPasswordsByIds(passwordsToMoveIds)
            let decryptedPasswords = [];
            for (let passwordsToMoveId of passwordsToMoveIds) {
                const localFromType = fromType === KindOfPasswordTypes.offlinePasswords ? KindOfPasswordTypes.companyPasswords : fromType;
                const decryptedPasswordString = await retrieveDecryptedPasswordString(localFromType, passwordsToMoveId);
                decryptedPasswords.push({
                    decryptedString: decryptedPasswordString,
                    id: passwordsToMoveId,
                });
            }

            let usersForEncryption = [];
            const usersWithAccessToTargetGroup = await lazyOtherUsersQueries.executeGetUsersWithAccessToGroup(targetGroupId);
            if (fromType === KindOfPasswordTypes.companyPasswords || fromType === KindOfPasswordTypes.offlinePasswords) {
                usersForEncryption = usersWithAccessToTargetGroup;
            } else {
                // reEncryptForUsers(accessToTargetGroup - admins - accessToCurrentGroup)
                const usersWithAccessToCurrentGroup = await lazyOtherUsersQueries.executeGetUsersWithAccessToGroup(fromGroupId);
                const usersWithAccessToCurrentGroupIds = usersWithAccessToCurrentGroup.map((u) => u.id);
                usersForEncryption = usersWithAccessToTargetGroup.filter((u) => !usersWithAccessToCurrentGroupIds.includes(u.id));
            }

            const encryptionsForUsersWithAccessToTargetGroup = await reEncryptPasswordsForUsers(decryptedPasswords, usersForEncryption);

            if (fromType === KindOfPasswordTypes.groupPasswords) {
                const variables = {
                    input: {
                        passwordIds: passwordsToMoveIds,
                        destinationGroupId: targetGroupId,
                        encryptedPasswords: encryptionsForUsersWithAccessToTargetGroup,
                    },
                };
                await moveSharedPasswordsToGroup({ variables });
            } else if (fromType === KindOfPasswordTypes.companyPasswords || fromType === KindOfPasswordTypes.offlinePasswords) {
                const variables = {
                    passwordsToMoveIds: passwordsToMoveIds,
                    destinationGroupId: targetGroupId,
                    encryptedPasswords: encryptionsForUsersWithAccessToTargetGroup,
                };
                await moveCompanyPasswordsToSharedPasswords({ variables });
            } else {
                setErrorMessage("Es ist ein Fehler aufgetreten: Passwörter können nicht von dieser Stelle nach Gruppenpasswörter kopiert werden");
            }
            onClose();
        } catch (e) {
            setErrorMessage(extractApolloErrorMessage(e));
        }
    };

    const handleMoveToCompanyPassword = async (passwordsToMoveIds) => {
        try {
            let allEncryptedPasswords = [];
            for (const passwordId of passwordsToMoveIds) {
                const localFromType = fromType === KindOfPasswordTypes.offlinePasswords ? KindOfPasswordTypes.companyPasswords : fromType;
                const passwordString = await retrieveDecryptedPasswordString(localFromType, passwordId);
                const admins = await lazyOtherUsersQueries.executeLoadAdmins();
                const currentEncryptedPasswords = await encryptForUsersAndMyself(me, passwordString, admins, passwordId);
                allEncryptedPasswords = [...allEncryptedPasswords, ...currentEncryptedPasswords];
            }
            const variables = {
                passwordsToMoveIds: passwordsToMoveIds,
                destinationUserId: me.id,
                encryptedPasswords: allEncryptedPasswords,
            };

            await moveSharedPasswordsToCompanyPasswords({ variables });
            onClose();
        } catch (e) {
            console.error(e.message);
            setErrorMessage(extractApolloErrorMessage(e));
        }
    };

    const okButtonEnabled = useMemo(
        () =>
            selectedPasswordsOptions.length > 0 &&
            (targetKindOfPassword !== KindOfPasswordTypes.groupPasswords || (!isGuidEmpty(targetGroupId) && fromGroupId !== targetGroupId)),
        [selectedPasswordsOptions, targetKindOfPassword, targetGroupId]
    );

    const showAccessLossMessage =
        okButtonEnabled &&
        fromType !== KindOfPasswordTypes.offlinePasswords &&
        fromType !== KindOfPasswordTypes.accountPasswords &&
        (targetKindOfPassword === KindOfPasswordTypes.accountPasswords || targetKindOfPassword === KindOfPasswordTypes.companyPasswords);

    const showAccessGrantedMessage = targetKindOfPassword === KindOfPasswordTypes.groupPasswords && okButtonEnabled;

    return (
        <CustomModal
            title={selectedPasswordsOptions.length === 1 ? i18n("password.convert.movePassword") : i18n("password.convert.movePasswords")}
            loading={false}
            onClose={onClose}
            saveText={i18n("general.actions.move")}
            error={Boolean(
                errorMessage || errorSharedPasswordsByGroupIds?.message || errorCompanyPasswords?.message || errorOfflinePasswords?.message
            )}
            errorMessage={errorMessage || errorSharedPasswordsByGroupIds || errorCompanyPasswords || errorOfflinePasswords}
            maxWidth="sm"
            okButtonEnabled={okButtonEnabled}
            onOk={onOk}
        >
            <Grid container spacing={2} className={classes.contentContainer}>
                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        {selectedPasswordsOptions.length === 1
                            ? i18n("password.convert.selectedPassword")
                            : i18n("password.convert.selectedPasswords")}
                    </Typography>
                </Grid>

                <Grid item xs={12}>
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        value={selectedPasswordsOptions}
                        onChange={(event, newValue) => {
                            setSelectedPasswordsOptions(newValue);
                        }}
                        noOptionsText={i18n("password.selects.noPasswordFound")}
                        options={passwordsAsSelectOptions}
                        getOptionLabel={(option) => option.label}
                        getOptionSelected={(option, value) => option.value === value.value}
                        renderInput={(params) => <TextField {...params} variant="outlined" placeholder={i18n("general.keyWords.passwords")} />}
                    />
                </Grid>

                <Grid item xs={12}>
                    <Typography className={classes.title}>
                        {fromType === KindOfPasswordTypes.companyPasswords && i18n("password.convert.moveToGroup")}
                        {fromType === KindOfPasswordTypes.groupPasswords && i18n("password.convert.moveToCompanyAccount")}
                        {fromType === KindOfPasswordTypes.offlinePasswords && i18n("password.convert.moveToSelect")}
                    </Typography>
                </Grid>

                {fromType === KindOfPasswordTypes.offlinePasswords && (
                    <Grid item xs={12}>
                        <Select onChange={setTargetKindOfPassword} value={targetKindOfPassword}>
                            {fromType !== KindOfPasswordTypes.companyPasswords && (
                                <MenuItem value={KindOfPasswordTypes.companyPasswords}>{i18n("password.convert.companyAccount")}</MenuItem>
                            )}
                            <MenuItem value={KindOfPasswordTypes.groupPasswords}>{i18n("password.convert.group")} </MenuItem>
                        </Select>
                    </Grid>
                )}
                <Grid item xs={12}>
                    {targetKindOfPassword === KindOfPasswordTypes.groupPasswords && (
                        <GroupFilter
                            minimumGroupRole={groupRole.editor}
                            showNoDataFoundMessage
                            checked={[targetGroupId]}
                            setChecked={(groupsIds) => {
                                setTargetGroupId(groupsIds.filter((id) => id !== targetGroupId)[0] || guid.empty);
                            }}
                        />
                    )}
                </Grid>
                <Grid item xs={12}>
                    {showAccessLossMessage && (
                        <Alert severity="warning">
                            <AlertTitle> {i18n("password.convert.accessTitle")}</AlertTitle>
                            {i18n("password.convert.accessLoss")}
                        </Alert>
                    )}
                    {showAccessGrantedMessage && (
                        <Alert severity="warning">
                            <AlertTitle> {i18n("password.convert.accessTitle")}</AlertTitle>
                            {i18n("password.convert.accessGranted")}
                        </Alert>
                    )}
                </Grid>
            </Grid>
        </CustomModal>
    );
};

ConvertPasswordModal.propTypes = {
    preselectedPasswords: PropTypes.arrayOf(PasswordType).isRequired,
    onClose: PropTypes.func.isRequired,
    fromType: KindOfPasswordType.isRequired,
    fromGroupId: PropTypes.string,
};

export default ConvertPasswordModal;
