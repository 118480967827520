import React, { useState } from "react";
import { OfflineStatusContext } from "../../contexts/OfflineStatusContext";
import { Table, TableBody } from "@mui/material";
import { KindOfPasswordTypes } from "../../types/PasswordType";
import TitleToolbar from "../common/layout/TitleToolbar";
import PasswordsContainer from "../common/passwordComponents/PasswordsContainer";
import PasswordTableRow from "../common/passwordComponents/PasswordTableRow";
import { ClientFilter, defaultPasswordSearchTermFields } from "../../managers/filterManager";
import { useContextMenu, useModalStatus } from "../../hooks/modalHook";
import PasswordTableRowContextMenu from "../common/passwordComponents/PasswordTableRowContextMenu";
import PasswordModal from "../common/passwordComponents/PasswordModal";
import PropTypes from "prop-types";
import ConfirmDeletePassword from "../common/passwordComponents/ConfirmDeletePassword";
import ConvertPasswordModal from "../common/convertPassword/ConvertPasswordModal";
import { useOfflinePasswordDeleteMutation } from "../../hooks/queries/offlinePasswordHook";
import { groupPasswordsByCreatorIdToMap } from "../../managers/passwordGroupingManager";
import { useSelectedPassword } from "../../hooks/passwordHook";

const SynchronizedOfflinePasswordTable = ({ searchTerm, syncedPassword }) => {
    const offlineStatusContext = OfflineStatusContext.useContainer();

    const { getSelectedPasswords, setSelectPasswords, selectAll, unselectAll } = useSelectedPassword();
    const [currentMultiSelectionKey, setCurrentMultiSelectionKey] = useState("");
    const [clickedPassword, setClickedPassword] = useState(/** @type SharedPasswordType */ {});
    const [isBulkOperation, setIsBulkOperation] = useState(false);

    const { anchorEl, open: openContextMenu, close: closeContextMenu } = useContextMenu();

    const { modalState: passwordModalState, open: openPasswordModal, close: closePasswordModal } = useModalStatus();
    const { modalState: convertModalState, open: openConvertModal, close: closeConvertModal } = useModalStatus();
    const {
        modalState: confirmDeletePasswordSyncedOfflineModalState,
        open: openConfirmDeleteSyncedOfflinePasswordModal,
        close: closeConfirmDeleteSyncedOfflinePasswordModal,
    } = useModalStatus();

    const { deleteOfflinePassword } = useOfflinePasswordDeleteMutation();

    const filteredSyncedPasswords = new ClientFilter(syncedPassword).bySearchTerm(defaultPasswordSearchTermFields, searchTerm).build();
    const groupedPasswordsMap = groupPasswordsByCreatorIdToMap(filteredSyncedPasswords);
    const groupedPasswordsArray = Array.from(groupedPasswordsMap.keys());

    const targetPasswords = isBulkOperation ? getSelectedPasswords(currentMultiSelectionKey) : [clickedPassword];

    return (
        <>
            {groupedPasswordsArray.map((userId) => {
                const passwords = groupedPasswordsMap.get(userId);
                const createdBy = passwords[0].createdBy;
                const fullname = createdBy["firstname"] + " " + createdBy["lastname"];

                return (
                    <div key={userId}>
                        <TitleToolbar
                            title={`Offlinepasswörter von ${fullname}`}
                            passwordCount={passwords.length}
                            selected={getSelectedPasswords(userId)}
                            setSelectAll={(_) => selectAll(userId, passwords)}
                            setUnselectAll={(_) => unselectAll(userId)}
                            onDelete={() => {
                                setIsBulkOperation(true);
                                setCurrentMultiSelectionKey(userId);
                                openConfirmDeleteSyncedOfflinePasswordModal();
                            }}
                            onConvert={() => {
                                setIsBulkOperation(true);
                                setCurrentMultiSelectionKey(userId);
                                openConvertModal();
                            }}
                        />
                        <PasswordsContainer>
                            <Table size="small">
                                <TableBody>
                                    {passwords.map((password, i) => (
                                        <PasswordTableRow
                                            key={i}
                                            showMenuOptions
                                            password={password}
                                            selectedPasswords={getSelectedPasswords(userId)}
                                            setSelectedPasswords={(passwords) => setSelectPasswords(userId, passwords)}
                                            onRowClick={(_) => {
                                                setClickedPassword(password);
                                                setIsBulkOperation(false);
                                                openPasswordModal();
                                            }}
                                            onContextMenu={
                                                offlineStatusContext.isOffline
                                                    ? null
                                                    : (e) => {
                                                          setClickedPassword(password);
                                                          setIsBulkOperation(false);
                                                          openContextMenu(e);
                                                      }
                                            }
                                        />
                                    ))}
                                </TableBody>
                            </Table>
                        </PasswordsContainer>
                    </div>
                );
            })}

            <PasswordTableRowContextMenu
                passwordType={KindOfPasswordTypes.companyPasswords}
                password={clickedPassword}
                anchorEl={anchorEl}
                onClose={closeContextMenu}
                onPasswordModal={openPasswordModal}
                onConfirmDeleteModal={openConfirmDeleteSyncedOfflinePasswordModal}
                onMovePassword={openConvertModal}
                removeOperationText="Löschen"
            />

            {confirmDeletePasswordSyncedOfflineModalState && (
                <ConfirmDeletePassword
                    onOk={() => {
                        for (let password of targetPasswords) {
                            deleteOfflinePassword({ variables: { id: password.id } });
                        }
                        unselectAll(currentMultiSelectionKey);
                        closeConfirmDeleteSyncedOfflinePasswordModal();
                    }}
                    onClose={closeConfirmDeleteSyncedOfflinePasswordModal}
                    passwords={targetPasswords}
                    isDelete
                />
            )}

            {passwordModalState && (
                <PasswordModal
                    passwordType={KindOfPasswordTypes.companyPasswords}
                    disableTags
                    editable
                    password={clickedPassword}
                    onClose={closePasswordModal}
                    onOk={closePasswordModal}
                />
            )}

            {convertModalState && (
                <ConvertPasswordModal
                    preselectedPasswords={targetPasswords}
                    onClose={(_) => {
                        closeConvertModal();
                        unselectAll(currentMultiSelectionKey);
                    }}
                    fromType={KindOfPasswordTypes.offlinePasswords}
                />
            )}
        </>
    );
};

SynchronizedOfflinePasswordTable.propTypes = {
    searchTerm: PropTypes.string.isRequired,
    syncedPassword: PropTypes.array.isRequired,
};

export default SynchronizedOfflinePasswordTable;
