import { encryptForUsersAndMyself, encryptPasswordForUsers } from "../../managers/passwordManager";
import { useLazyUserQueries } from "./userQueryHooks";
import { useSharedPasswordAddMutation } from "./sharedPasswordHook";
import { useOfflinePasswordAddMutation } from "./offlinePasswordHook";

import { CryptoManager } from "../../managers/cryptoManager";
import { useCachedAddCompanyPasswordMutation, useCompanyPasswordAddForUserMutation } from "./companyPasswordHook";
import { useAccountPasswordAddMutation } from "./accountPasswordHook";

export const createNewPasswordMutationObject = (name, login, website, description, keyPairId = null, tagIds = [], groupId = null) => {
    let newPassword = {
        name: name,
        login: login,
        websiteUrl: website || "",
        notes: description || "",
    };
    if (keyPairId) newPassword.keyPairId = keyPairId;
    if (tagIds.length > 0) newPassword.tags = tagIds;
    if (Boolean(groupId)) newPassword.groupId = groupId;
    return newPassword;
};

export const usePasswordMutation = () => {
    const lazyOtherUsersQueries = useLazyUserQueries();
    const { addSharedPassword } = useSharedPasswordAddMutation();
    const { addCompanyPasswordForUser } = useCompanyPasswordAddForUserMutation();
    const { addCompanyPassword } = useCachedAddCompanyPasswordMutation();
    const { addAccountPassword } = useAccountPasswordAddMutation();
    const { addOfflinePassword } = useOfflinePasswordAddMutation();

    const createAccountPassword = async (me, passwordString, passwordMutationObject) => {
        passwordMutationObject.encryptedPassword = await CryptoManager.encryptWithPublicKey(passwordString, me.keyPair.publicKeyString);
        await addAccountPassword({ variables: { accountPassword: passwordMutationObject } });
    };

    const createCompanyPassword = async (me, passwordString, passwordMutationObject) => {
        const admins = await lazyOtherUsersQueries.executeLoadAdmins(true);
        passwordMutationObject.encryptedPasswords = await encryptForUsersAndMyself(me, passwordString, admins);
        await addCompanyPassword({ variables: { sharedPassword: passwordMutationObject } });
    };

    const createOfflinePassword = async (me, passwordString, passwordMutationObject) => {
        const admins = await lazyOtherUsersQueries.executeLoadAdmins(true);
        passwordMutationObject.encryptedPasswords = await encryptForUsersAndMyself(me, passwordString, admins);
        await addOfflinePassword({ variables: { offlinePassword: passwordMutationObject } });
    };

    const createCompanyPasswordForOtherUser = async (me, passwordString, passwordMutationObject, targetUserId) => {
        const admins = await lazyOtherUsersQueries.executeLoadAdmins(true);
        const passwordOwner = await lazyOtherUsersQueries.executeLoadUserById(targetUserId, true);

        passwordMutationObject.encryptedPasswords = await encryptPasswordForUsers(passwordString, [...admins, passwordOwner]);
        await addCompanyPasswordForUser({ variables: { sharedPassword: passwordMutationObject, forUserId: targetUserId } });
    };

    const createGroupPassword = async (me, passwordString, passwordMutationObject, groupId) => {
        const usersWithAccess = await lazyOtherUsersQueries.executeGetUsersWithAccessToGroup(groupId);
        passwordMutationObject.encryptedPasswords = await encryptForUsersAndMyself(me, passwordString, usersWithAccess);
        passwordMutationObject.groupId = groupId;
        await addSharedPassword({ variables: { sharedPassword: passwordMutationObject } });
    };

    return { createAccountPassword, createCompanyPassword, createCompanyPasswordForOtherUser, createGroupPassword, createOfflinePassword };
};
