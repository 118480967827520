import PropTypes from "prop-types";
import CustomModal from "./CustomModal";
import LicenseInvalidAlert from "./LicenseInvalidAlert";
import { useNavigate } from "react-router-dom";
import { menu } from "../../../../managers/pathManager";
import useTranslation from "../../translation/translation";

const LicenseInvalidModal = ({ onClose }) => {
    const navigate = useNavigate();
    const { i18n } = useTranslation();

    const onOk = () => {
        navigate(menu.billing.path);
        onClose();
    };

    return (
        <CustomModal
            maxWidth="sm"
            title={i18n("general.error.licenseTitle")}
            loading={false}
            error={false}
            saveText={i18n("general.actions.ok")}
            onOk={onOk}
            onClose={onClose}
        >
            <LicenseInvalidAlert />
        </CustomModal>
    );
};

LicenseInvalidModal.propTypes = {
    onClose: PropTypes.func.isRequired,
};

export default LicenseInvalidModal;
