import React, { useEffect } from "react";
import SearchBar from "../common/dataEntry/SearchBar";
import PropTypes from "prop-types";
import { useDelayedMaterialUIInput } from "../../hooks/inputHook";
import { useIsMobile } from "../../hooks/mobileHook";
import UserFilterBar from "../common/passwordComponents/toolbar/UserFilterBar";

const UserSearchBar = ({ onSearchTerm, onAdd, filterArchived, onChangeFilterArchived, addButtonDisabledMessage }) => {
    const { value, localValue: localSearchTerm, onChange: setLocalSearchTerm } = useDelayedMaterialUIInput("");
    const isMobile = useIsMobile();

    useEffect(() => {
        onSearchTerm(value);
    }, [value]);

    const filterBar = <UserFilterBar filterArchived={filterArchived} onChangeFilterArchived={onChangeFilterArchived} />;

    return (
        <SearchBar
            placeholder={isMobile ? "Benutzer suchen" : "Nach welchem Benutzer suchen Sie?"}
            value={localSearchTerm}
            onChange={setLocalSearchTerm}
            onAdd={onAdd}
            subBar={filterBar}
            addButtonDisabledMessage={addButtonDisabledMessage}
        />
    );
};

UserSearchBar.propTypes = {
    onSearchTerm: PropTypes.func.isRequired,
    onAdd: PropTypes.func.isRequired,
    filterArchived: PropTypes.bool.isRequired,
    onChangeFilterArchived: PropTypes.func.isRequired,
    addButtonDisabledMessage: PropTypes.string,
};

export default UserSearchBar;
