import { Alert, AlertTitle } from "@mui/lab";
import useTranslation from "../../translation/translation";

const LicenseInvalidAlert = () => {
    const { i18n } = useTranslation();

    return (
        <Alert variant="outlined" severity="error">
            <AlertTitle>{i18n("general.error.licenseTitle")}</AlertTitle>
            {i18n("general.error.licenseDescription")}
        </Alert>
    );
};

export default LicenseInvalidAlert;
