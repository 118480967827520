import React, { useMemo } from "react";
import { gql, useApolloClient, useMutation } from "@apollo/client";
import { fragments } from "../../managers/fragmentManager";
import { addEntry, deleteEntry } from "../../managers/cacheManager";
import { GET_MYSELF, GET_USERS_IN_GROUP } from "./userQueryHooks";
import { groupRole } from "../../managers/groupManager";

import {
    GroupFieldsFragmentDoc,
    useGetAllGroupsQuery,
    useGetGroupTreeQuery,
    useGetGroupTreeByRoleQuery,
    useGetGroupTreeLazyQuery,
    useGetGroupsByParentQuery,
    useDeleteGroupMutation,
    useEditGroupMutation,
    useAddGroupMutation,
    useArchiveGroupMutation,
    useMoveGroupMutation,
    GetGroupTreeDocument,
    GetAllGroupsDocument,
} from "../../graphql/generated";

const flatten = (data) => {
    let result = [];
    result.push(...data);

    data.forEach((d) => {
        if (d.Children) flattenRecursive(result, d.Children);
    });

    return result;
};

const flattenRecursive = (result, children) => {
    result.push(...children);
    children.forEach((d) => {
        if (d.Children) flattenRecursive(result, d.Children);
    });
};

export const GET_TREE = gql`
    query GroupTree($byArchived: Boolean = false) {
        groupTree(byArchived: $byArchived)
    }
`;
export const GET_TREE_BY_ROLE = gql`
    query GroupTreeByRole($withRole: GroupRole, $byArchived: Boolean = false) {
        groupTree(withRole: $withRole, byArchived: $byArchived)
    }
`;

export const GET_GROUP_BY_PARENTID = gql`
    query GroupQuery($parentId: Guid, $byArchived: Boolean = false) {
        groups: groupsByParent(parentId: $parentId, byArchived: $byArchived) {
            ...SelectGroup
        }
    }
    ${fragments.query.SelectGroup}
`;

export const ADD_USER_TO_GROUP = gql`
    mutation addUserToGroup($user: AddUserGroupType!) {
        group {
            addUser(user: $user) {
                ...SelectUserSimple
            }
        }
    }
    ${fragments.query.SelectUserSimple}
`;

export const DELETE_USER_FROM_GROUP = gql`
    mutation DeleteUserFromGroup($groupId: Guid!, $userId: Guid!) {
        group {
            deleteUser(groupId: $groupId, userId: $userId) {
                ...SelectUserSimple
            }
        }
    }
    ${fragments.query.SelectUserSimple}
`;

/**
 *
 * @returns {{treeNodes: GroupTreeNode[], loading: boolean, error: ApolloError}}
 */
export const useGroupTreeQuery = (byArchived = false) => {
    let { loading, error, data } = useGetGroupTreeQuery({
        variables: { byArchived: byArchived },
        fetchPolicy: "cache-and-network",
    });
    const treeNodes = data && data.groupTree ? JSON.parse(data.groupTree) : [];
    const flattenedTreeNodes = useMemo(() => flatten(treeNodes), [treeNodes]);

    return { treeNodes, loading, error, flattenedTreeNodes };
};

/**
 *
 * @returns {{treeNodes: GroupTreeNode[], loading: boolean, error: ApolloError}}
 */
export const useLazyGroupTreeQuery = (byArchived = false) => {
    let [load, { loading, error, data }] = useGetGroupTreeLazyQuery({
        variables: { byArchived: byArchived },
        fetchPolicy: "cache-and-network",
    });
    const treeNodes = data && data.groupTree ? JSON.parse(data.groupTree) : [];
    const flattenedTreeNodes = useMemo(() => flatten(treeNodes), [treeNodes]);

    return { load, treeNodes, loading, error, flattenedTreeNodes };
};

/**
 *
 * @returns {{executeGroupTreeQuery: (function(byArchived: boolean|null = false): Promise<string>)}}
 */
export const useExecuteGroupTreeQuery = () => {
    const client = useApolloClient();

    const executeGroupTreeQuery = async (byArchived = false) => {
        const { data } = await client.query({
            query: GET_TREE,
            variables: { byArchived: byArchived },
            fetchPolicy: "network-only",
        });
        return JSON.parse(data.groupTree);
    };
    return { executeGroupTreeQuery };
};

export const useGroupTreeByRoleQuery = (role) => {
    let { loading, error, data } = useGetGroupTreeByRoleQuery({
        fetchPolicy: "network-only", // Disable cache
        variables: { withRole: role },
    });

    const treeNodes = data && data.groupTree ? JSON.parse(data.groupTree) : [];
    return { treeNodes, loading, error };
};

export const useAllGroupsQuery = () => {
    const { loading, error, data } = useGetAllGroupsQuery({
        fetchPolicy: "cache-and-network",
        variables: { byArchived: false },
    });

    const groups = data && data.groups && data.groups.length > 0 ? data.groups : [];
    return { groups, loading, error };
};

/**
 *
 * @returns {{executeAllUserWithAccessToGroupsQuery: (function(groupIds: string[]): Promise<[{id: string, usersWithAccess: [{id: string, admin: boolean, publicKey:{id:string, publicKeyString:string}}]}]>)}}
 */
export const useExecuteAllUserWithAccessToGroupsQuery = () => {
    const client = useApolloClient();

    const GET_ALL_USERS_WITH_ACCESS_TO_THIS_GROUP = gql`
        query GetAllUsersWithAccessToThisGroup($ids: [Guid], $byArchived: Boolean = false) {
            groups(ids: $ids, byArchived: $byArchived) {
                id
                usersWithAccess {
                    id
                    admin
                    publicKey {
                        id
                        publicKeyString
                    }
                }
            }
        }
    `;
    const executeAllUserWithAccessToGroupsQuery = async (groupIds, byArchived = false) => {
        const { data } = await client.query({
            query: GET_ALL_USERS_WITH_ACCESS_TO_THIS_GROUP,
            variables: { ids: groupIds, byArchived },
            fetchPolicy: "network-only",
        });
        return data.groups;
    };
    return { executeAllUserWithAccessToGroupsQuery };
};

export const useGroupByParentIdQuery = (currentGroupId, byArchived = false) => {
    const { loading, error, data } = useGetGroupsByParentQuery({
        variables: { parentId: currentGroupId, byArchived: byArchived },
        fetchPolicy: "cache-and-network",
        returnPartialData: true,
    });
    const groups = data && data.groups && data.groups.length > 0 ? data.groups : [];
    return { groups, loading, error };
};

export const useLazyGroupByParentIdQuery = () => {
    const client = useApolloClient();

    const executeGroupByParentIdQuery = async (parentId) => {
        const { data } = await client.query({
            query: GET_GROUP_BY_PARENTID,
            variables: { parentId: parentId, byArchived: false },
            fetchPolicy: "network-only",
        });
        return data.groups;
    };

    return { executeGroupByParentIdQuery };
};

export const useGroupMutation = (parentGroupId, currentGroupId) => {
    const [addGroup] = useAddGroupMutation({
        update(
            cache,
            {
                data: {
                    group: { add: newGroup },
                },
            }
        ) {
            cache.modify({
                fields: {
                    groups(refs) {
                        return addEntry(cache, refs, GroupFieldsFragmentDoc, newGroup);
                    },
                },
            });
        },
        refetchQueries: [
            { query: GET_TREE, variables: {} },
            { query: GET_GROUP_BY_PARENTID, variables: { parentId: parentGroupId, searchTerm: "", byArchived: false } },
            { query: GET_MYSELF, variables: {} },
        ],
        awaitRefetchQueries: true,
    });

    const [editGroup] = useEditGroupMutation();
    const [deleteGroup] = useDeleteGroupMutation({
        update(cache) {
            cache.modify({
                fields: {
                    groups(groupRef, { readField }) {
                        return deleteEntry(groupRef, readField, currentGroupId);
                    },
                },
                broadcast: false,
            });
        },
    });

    const [archiveGroup] = useArchiveGroupMutation({
        refetchQueries: [{ query: GetAllGroupsDocument }],
        awaitRefetchQueries: true,
        update(cache) {
            cache.modify({
                fields: {
                    groupsByParent(groupRef, { readField }) {
                        return deleteEntry(groupRef, readField, currentGroupId);
                    },
                    groups(groupRef, { readField }) {
                        return deleteEntry(groupRef, readField, currentGroupId);
                    },
                },

                broadcast: false,
            });
        },
    });

    return { addGroup, editGroup, deleteGroup, archiveGroup };
};

export const useGroupMoveMutation = () => {
    const [moveGroup] = useMoveGroupMutation({
        refetchQueries: [{ query: GetGroupTreeDocument }, { query: GetAllGroupsDocument }],
        awaitRefetchQueries: true,
    });

    return { moveGroup };
};

export const useAddUserToGroupMutation = (parentGroupId, currentGroupId) => {
    const [addUserToGroup, { loading }] = useMutation(ADD_USER_TO_GROUP, {
        refetchQueries: [
            { query: GET_USERS_IN_GROUP, variables: { groupIds: currentGroupId, byArchived: false, searchTerm: "" } },
            { query: GET_GROUP_BY_PARENTID, variables: { parentId: parentGroupId, byArchived: false, searchTerm: "" } },
        ],
        awaitRefetchQueries: true,
    });

    return { addUserToGroup, loading };
};

export const useRemoveUserFromGroupMutation = (parentGroupId, currentGroupId) => {
    const [removeUserFromGroup, { loading }] = useMutation(DELETE_USER_FROM_GROUP, {
        refetchQueries: [
            { query: GET_USERS_IN_GROUP, variables: { groupIds: currentGroupId, byArchived: false, searchTerm: "" } },
            { query: GET_GROUP_BY_PARENTID, variables: { parentId: parentGroupId, byArchived: false, searchTerm: "" } },
        ],
        awaitRefetchQueries: true,
    });

    return { removeUserFromGroup, loading };
};
