import React from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import List from "@mui/material/List";
import makeStyles from "@mui/styles/makeStyles";
import { useIsMobile } from "../../../hooks/mobileHook";

const useStyles = makeStyles((theme) => ({
    groupHeader: {
        fontSize: "1.3rem",
        width: "100%",
        borderBottom: "2px #0000003b solid",
    },
    cards: {
        display: "flex",
        flexWrap: "wrap",
    },
    cardContainer: {
        width: "100%",
    },
    addPassword: {
        float: "right",
    },
}));

const PasswordsContainer = ({ children }) => {
    const isMobile = useIsMobile();
    const classes = useStyles({ isMobile: isMobile });

    return (
        <Grid container>
            <Grid item className={classes.cardContainer}>
                <List className={classes.cards}>
                    <Grid container spacing={1}>
                        {children}
                    </Grid>
                </List>
            </Grid>
        </Grid>
    );
};

PasswordsContainer.propTypes = {
    children: PropTypes.node.isRequired,
};

export default PasswordsContainer;
