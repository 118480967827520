import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Box, Button, Grid, Snackbar, CircularProgress, Alert } from "@mui/material";
import { LoginContext } from "../../../contexts/LoginContext";
import PageDrawer from "./PageDrawer";
import EmailConfirmationBanner from "../feedback/EmailConfirmationBanner";
import makeStyles from "@mui/styles/makeStyles";
import EmptyPageContainer from "./EmptyPageContainer";
import { OfflineStatusContext } from "../../../contexts/OfflineStatusContext";
import { localStorageManager } from "../../../managers/localStorageManager";
import { PageType, PageTypeList } from "../../../types/PageType";
import { CryptoManager } from "../../../managers/cryptoManager";
import { OfflinePasswordsContext } from "../../../contexts/OfflinePasswordsContext";
import { useEnableTotpTwoFA, useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import { usePasswordMutation } from "../../../hooks/queries/passwordMutationHook";
import TrialBanner from "../feedback/TrialBanner";
import TwoFactorTotpModal from "../../profile/TwoFactorTotpModal";
import { DrawerContext } from "../../../contexts/DrawerContext";

const useStyles = makeStyles(() => ({
    container: {
        flexWrap: "nowrap",
    },
    content: {
        display: "flex",
        flexGrow: 1,
        flexDirection: "column",
        minHeight: "100vh",
        backgroundColor: "#F8F8F8",
    },
    spinner: {
        position: "fixed",
        left: "50%",
        top: "50%",
    },
}));

const Page = ({
    children,
    hideDrawer,
    empty,
    currentPageType,
    onNoContentAction,
    noContentActionText,
    emptyTitle,
    emptyInformation,
    inSearchOperation,
    loading,
}) => {
    const classes = useStyles();
    const loginContext = LoginContext.useContainer();
    const offlineStatusContext = OfflineStatusContext.useContainer();
    const drawerContext = DrawerContext.useContainer();

    const reloadPage = () => {
        offlineStatusContext.waitingNewWorker?.postMessage({ type: "SKIP_WAITING" });
        offlineStatusContext.setNewVersionAvailable(false);
        localStorageManager.setServiceWorkerUpdateAvailable(false);
        window.location.reload();
    };

    const offlinePasswordsContext = OfflinePasswordsContext.useContainer();
    const { me, isBusinessCustomer, loading: loadingMyself, error: errorLoadingMyself } = useMyselfQuery(true);
    const { enableTotp } = useEnableTotpTwoFA();

    const { createOfflinePassword } = usePasswordMutation();

    const twoFactorRequired = me.mandator.twoFactorRequired;
    const hasTwoFATypesEnabled = me.twoFATypesEnabled?.length > 0;
    const [hide, setHide] = useState(false);

    useEffect(() => {
        if (loginContext && isBusinessCustomer !== undefined) loginContext.setIsBusinessCustomer(isBusinessCustomer);
    }, [loginContext, isBusinessCustomer]);

    useEffect(() => {
        if (loadingMyself || errorLoadingMyself) return;
        if (offlineStatusContext.isOffline) return;
        if (offlinePasswordsContext.locallySavedPasswords.length === 0) return;
        if (offlinePasswordsContext.isSavingLocalPasswordsToServer) return;

        offlinePasswordsContext.setIsSavingLocalPasswordsToServer(true);

        const doSyncPasswords = async () => {
            for (const offlinePassword of offlinePasswordsContext.locallySavedPasswords) {
                const decryptedPasswordString = await CryptoManager.decryptEncryptedPassword(
                    me.keyPair.encryptedPrivateKey,
                    loginContext.userPasswordHash,
                    offlinePassword.encryptedPassword
                );
                const copyForApi = JSON.parse(JSON.stringify(offlinePassword));
                delete copyForApi.updatedAt;
                delete copyForApi.encryptedPassword;
                delete copyForApi.offlineGuid;
                copyForApi.websiteUrl = copyForApi.websiteUrl || copyForApi.websiteURL || "";
                delete copyForApi.websiteURL;
                delete copyForApi.tags;
                await createOfflinePassword(me, decryptedPasswordString, copyForApi);
                offlinePasswordsContext.removeOfflinePasswords(offlinePassword.offlineGuid);
            }
            offlinePasswordsContext.removeOfflinePasswords(offlinePasswordsContext.locallySavedPasswords.map((p) => p.offlineGuid));
            offlinePasswordsContext.setIsSavingLocalPasswordsToServer(false);
        };
        doSyncPasswords().catch(console.error);
    }, [
        createOfflinePassword,
        errorLoadingMyself,
        loadingMyself,
        loginContext.userPasswordHash,
        me,
        offlinePasswordsContext,
        offlineStatusContext.isOffline,
    ]);

    if (loginContext?.isBusinessCustomer === undefined && !hideDrawer) return <CircularProgress className={classes.spinner} />;

    const onEnableTwoFactorAuthentication = (codeInput) => {
        enableTotp({ variables: { totp: codeInput + "" } }).then(() => {
            setHide(true);
        });
    };

    if (!loadingMyself && twoFactorRequired && !hasTwoFATypesEnabled && !hide) {
        return <TwoFactorTotpModal hideCancel onClose={() => {}} onCodeEntered={onEnableTwoFactorAuthentication} />;
    }

    const maxWidth = hideDrawer ? "100%" : drawerContext.drawerOpen ? "calc(100% - 220px)" : "calc(100% - 57px)";

    return (
        <>
            <Grid container className={classes.container}>
                <Grid item>{!hideDrawer && <PageDrawer currentPageType={currentPageType} />}</Grid>
                <Grid item style={{ maxWidth }} className={classes.content}>
                    {!hideDrawer && loginContext.loggedIn && <EmailConfirmationBanner />}
                    {!hideDrawer && loginContext.loggedIn && <TrialBanner />}
                    <EmptyPageContainer
                        empty={empty}
                        loading={loading}
                        emptyTitle={emptyTitle}
                        emptyInformation={emptyInformation}
                        onNoContentAction={onNoContentAction}
                        inSearchOperation={inSearchOperation}
                        noContentActionText={noContentActionText}
                    >
                        {children}
                    </EmptyPageContainer>
                </Grid>
            </Grid>

            <Snackbar
                open={offlineStatusContext.newVersionAvailable}
                onClick={reloadPage}
                style={{ cursor: "pointer" }}
                anchorOrigin={{ vertical: "top", horizontal: "center" }}
            >
                <Box>
                    <Alert severity="success" style={{ width: "100%" }}>
                        <Box style={{ display: "flex", alignItems: "center" }}>
                            Eine neue Version ist verfügbar!
                            <Button color="primary" size="small" onClick={reloadPage} style={{ marginLeft: "1rem" }}>
                                Aktualisieren
                            </Button>
                        </Box>
                    </Alert>
                </Box>
            </Snackbar>
        </>
    );
};

Page.defaultProps = {
    currentPageType: PageTypeList.default,
    hideDrawer: false,
    empty: false,
    loading: false,
};

Page.propTypes = {
    children: PropTypes.node.isRequired,
    hideDrawer: PropTypes.bool,
    currentPageType: PageType,
    empty: PropTypes.bool,
    onNoContentAction: PropTypes.func,
    noContentActionText: PropTypes.string,
    emptyTitle: PropTypes.string,
    emptyInformation: PropTypes.string,
    inSearchOperation: PropTypes.bool,
    loading: PropTypes.bool,
};

export default Page;
