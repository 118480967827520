import { CryptoManager } from "./cryptoManager";

/**
 * @typedef SecureDataObject
 * @property {string} userPasswordHash
 */

/**
 *
 * @returns {Promise<SecureDataObject>}
 */
const loadSecureData = async () => await CryptoManager.loadSecureLocalData();

/**
 *
 * @param {SecureDataObject} data
 * @returns {Promise<void>}
 */
const saveSecureData = async (data) => await CryptoManager.saveSecureLocalData(data);

/* ############################### Functions ############################### */

const setUserPasswordHash = async (value) => {
    let secureData = await loadSecureData();
    if (secureData) secureData.userPasswordHash = value;
    else secureData = { userPasswordHash: value };
    await saveSecureData(secureData);
};

const getUserPasswordHash = async () => {
    const secureData = await loadSecureData();
    return secureData.userPasswordHash;
};

export const SecureStorageManager = {
    setUserPasswordHash,
    getUserPasswordHash,
    clearSecureLocalData: CryptoManager.clearSecureLocalData,
};