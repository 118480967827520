import { onError } from "@apollo/client/link/error";
import { RetryLink } from "apollo-link-retry";
import { CachePersistor, LocalStorageWrapper } from "apollo3-cache-persist";
import { ApolloClient, ApolloLink, HttpLink, InMemoryCache } from "@apollo/client";
import config from "./config.json";
import { menu } from "./managers/pathManager";
import { apiBaseUrl, checkIsDevelopmentEnvironment } from "./managers/apiManager";
import { Observable } from "@apollo/client/utilities";
import { localStorageManager } from "./managers/localStorageManager";

// Inspired by: https://github.com/helfer/apollo-link-queue
class CustomQueueLink extends ApolloLink {
    operationsQueue = [];
    isOpen = true;
    mutations = [];

    open() {
        this.isOpen = true;
        for (const { operation, forward, observer } of this.operationsQueue) {
            forward(operation).subscribe(observer);
        }
        this.operationsQueue = [];
    }

    close() {
        this.isOpen = false;
    }

    request(operation, forward) {
        if (this.isOpen) {
            return forward(operation);
        }
        if (operation.getContext().skipQueue) {
            return forward(operation);
        }
        return new Observable((observer) => {
            const operationEntry = { operation, forward, observer };
            this.enqueue(operationEntry);
            return () => this.cancelOperation(operationEntry);
        });
    }

    cancelOperation(entry) {
        this.operationsQueue = this.operationsQueue.filter((e) => e !== entry);
    }

    enqueue(entry) {
        const definitions = entry.operation?.query?.definitions;
        if (definitions.length > 0 && definitions[0]?.operation === "mutation") {
            // Mutations must not be possible
            const publicPagesPaths = Object.keys(menu)
                .filter((entry) => menu[entry].accessibleWithoutLogin === true)
                .map((entry) => menu[entry].path);
            if (!["/", ...publicPagesPaths].includes(window.location.pathname)) window.location.href = menu.error.path;
        } else {
            // Queries are pushed onto the queue and never stored away
            this.operationsQueue.push(entry);
        }
    }
}

const createErrorLink = (onNetworkError, handleUnauthorised) => {
    return onError(({ graphQLErrors, networkError, response, operation, forward }) => {
        console.warn(networkError);
        if (graphQLErrors) {
            graphQLErrors.forEach(({ extensions, message, locations, path }) => {
                if (extensions && extensions.code === "UNAUTHENTICATED") {
                    const publicPagesPaths = Object.keys(menu)
                        .filter((entry) => menu[entry].accessibleWithoutLogin === true)
                        .map((entry) => menu[entry].path);
                    if (!publicPagesPaths.includes(window.location.pathname)) handleUnauthorised();
                }
            });
        } else if (networkError) {
            if (networkError.statusCode === 401) {
                const publicPagesPaths = Object.keys(menu)
                    .filter((entry) => menu[entry].accessibleWithoutLogin === true)
                    .map((entry) => menu[entry].path);
                if (!publicPagesPaths.includes(window.location.pathname)) handleUnauthorised();
            } else if (networkError.statusCode === 400) {
                // ToDo: better handling of this
                console.error(
                    "The issued query just failed and returned with a 400 http error. This should be handled, however this is not implemented yet!"
                );
            } else {
                console.warn("network error (most likely offline):", networkError);
                onNetworkError(networkError);
            }
        }
    });
};

const createHttpLink = () => new HttpLink({ uri: `${apiBaseUrl}/api/graph`, credentials: "include" });

const createCustomCache = () => {
    /**
     * Merge existent cache with incoming
     */
    const customMergeFunction = (existing, incoming, args, readField) => {
        return incoming;

        /**
         * Custom merge feature is disabled due to issues with archivedAt.
         * The problem is that other users' cache still shows archived data.
         * Therefore, we should always update the cache.
         */

        const merged = existing ? existing.slice(0) : [];
        const existingIdSet = new Set(merged.map((task) => readField("id", task)));
        incoming = incoming.filter((task) => !existingIdSet.has(readField("id", task)));
        const afterIndex = merged.findIndex((task) => args?.afterId === readField("id", task));
        if (afterIndex >= 0) {
            merged.splice(afterIndex + 1, 0, ...incoming);
        } else {
            merged.push(...incoming);
        }
        return merged;
    };

    return new InMemoryCache({
        typePolicies: {
            Query: {
                fields: {
                    groupsByParent: {
                        keyArgs: ["parentId"],
                    },
                    groups: {
                        keyArgs: [],
                        merge(existing = [], incoming = [], { args, readField }) {
                            return customMergeFunction(existing, incoming, args, readField);
                        },
                    },
                    users: {
                        keyArgs: ["byArchived", "excludeUsersInGroupIds", "groupIds", "userIds"],
                        merge(existing = [], incoming = [], { args, readField }) {
                            return customMergeFunction(existing, incoming, args, readField);
                        },
                    },
                    companyPasswords: {
                        keyArgs: ["createdByIds"],
                        merge(existing = [], incoming = [], { args, readField }) {
                            return customMergeFunction(existing, incoming, args, readField);
                        },
                    },
                    sharedPasswords: {
                        keyArgs: [],
                        merge(existing = [], incoming = [], { args, readField }) {
                            return customMergeFunction(existing, incoming, args, readField);
                        },
                    },
                    sharedTags: {
                        keyArgs: [],
                        merge(existing = [], incoming = [], { args, readField }) {
                            return customMergeFunction(existing, incoming, args, readField);
                        },
                    },
                },
            },
        },
    });
};

/**
 *
 * @returns {Promise<{client: ApolloClient<NormalizedCacheObject>, persistor: CachePersistor<NormalizedCacheObject>, offlineLink: CustomQueueLink}>}
 */
export const createApolloClient = async (handleNetworkError, handleUnauthorised) => {
    const errorLink = createErrorLink(handleNetworkError, handleUnauthorised);
    const httpLink = createHttpLink();
    const retryLink = new RetryLink();

    const offlineLink = new CustomQueueLink();
    // Note: remove these listeners when your app is shut down to avoid leaking listeners.
    window.addEventListener("offline", () => offlineLink.close());
    window.addEventListener("online", () => offlineLink.open());

    const customCache = createCustomCache();
    const persistor = new CachePersistor({
        cache: customCache,
        storage: new LocalStorageWrapper(window.localStorage),
        debug: checkIsDevelopmentEnvironment(),
        trigger: "write",
    });

    // Cache-busting on version mismatch
    const currentVersion = localStorageManager.getApolloOfflineSchemaVersion();
    if (currentVersion === config.apolloOfflineSchemaVersion) {
        await persistor.restore();
    } else {
        console.warn(`apollo schema version is ${currentVersion} but should be ${config.apolloOfflineSchemaVersion} --> purging cache`);
        await persistor.purge();
        localStorageManager.setApolloOfflineSchemaVersion(config.apolloOfflineSchemaVersion);
    }

    const apolloClient = new ApolloClient({
        link: ApolloLink.from([
            // retryLink,
            offlineLink,
            errorLink,
            httpLink,
        ]),
        cache: customCache,
        credentials: "include",
    });

    return { client: apolloClient, persistor: persistor, offlineLink: offlineLink };
};
