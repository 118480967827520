import React from "react";
import PropTypes from "prop-types";
import Icon from "../../dataDisplay/Icon";
import { IconTypes } from "../../../../types/IconType";
import { ConfirmDialogVariant, ConfirmDialogVariantList } from "../../../../types/ConfirmDialogVariant";
import { IconColorTypeList } from "../../../../types/IconColorType";
import CustomModal from "./CustomModal";

const ConfirmationDialog = ({ open, title, description, variant, cancelText, okText, onOk, onClose }) => {

    let icon;
    switch (variant) {
        case ConfirmDialogVariantList.error:
            icon = <Icon iconType={IconTypes.circleError} colorType={IconColorTypeList.error} />;
            break;
        case ConfirmDialogVariantList.warning:
            icon = <Icon iconType={IconTypes.circleWarning} colorType={IconColorTypeList.primary} />;
            break;
        case ConfirmDialogVariantList.info:
        default:
            icon = <Icon iconType={IconTypes.circleInfo} colorType={IconColorTypeList.secondary} />;
            break;
    }

    return (
        <CustomModal
            open={open}
            loading={false}
            error={false}
            onClose={onClose}
            onOk={onOk}
            saveText={okText}
            cancelText={cancelText}
            withCancelButton={Boolean(cancelText)}
            title={title}
            closeableOnlyWithDialogActionButtons
            icon={icon}
            maxWidth="sm"
            variant="light"
        >
            {description}
        </CustomModal>
    );
};

ConfirmationDialog.defaultProps = {
    open: true,
    variant: ConfirmDialogVariantList.info,
    okText: "OK",
};

ConfirmationDialog.propTypes = {
    open: PropTypes.bool,
    title: PropTypes.string.isRequired,
    description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    variant: ConfirmDialogVariant,
    cancelText: PropTypes.string,
    okText: PropTypes.string.isRequired,
    onOk: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
};

export default ConfirmationDialog;
