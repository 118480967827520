import { arrayOf, number, oneOf, shape, string } from "prop-types";

/**
 * @typedef {string} KindOfPasswordType
 */

/**
 * KindOfPasswordTypes enum
 * @readonly
 * @enum {KindOfPasswordType}
 */
export const KindOfPasswordTypes = {
    accountPasswords: "accountPasswords",
    companyPasswords: "companyPasswords",
    groupPasswords: "groupPasswords",
    offlinePasswords: "offlinePasswords",
};

/**
 *
 * @param {KindOfPasswordType} passwordType
 * @returns {string}
 */
export const passwordTypeToString = (passwordType) => {
    switch (passwordType) {
        case KindOfPasswordTypes.accountPasswords:
            return "Passwort im Privataccount";
        case KindOfPasswordTypes.companyPasswords:
            return "Passwort";
        case KindOfPasswordTypes.groupPasswords:
            return "Gruppenpasswort";
        case KindOfPasswordTypes.offlinePasswords:
            return "Offlinepasswort";
        default:
            return "";
    }
};

export const KindOfPasswordType = oneOf(Object.values(KindOfPasswordTypes));

export const PasswordType = shape({
    id: string,
    name: string,
    login: string,
    tags: arrayOf(shape({ name: string, color: string })),
    updatedAt: string,
    securityRating: number,
});
