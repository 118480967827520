import { ReactNode } from "react";
import { styled } from "@mui/system";

interface LoginPageLayoutProps {
    children: ReactNode;
}

const StyledPage = styled("div")({
    display: "flex",
    flexGrow: 1,
    flexDirection: "column",
    minHeight: "100vh",
});

const VerticalContent = styled("div")({
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    marginTop: "15vh",
});

export default function LoginPageLayout({ children }: LoginPageLayoutProps) {
    return (
        <div className="loginPage">
            <StyledPage>
                <VerticalContent>{children}</VerticalContent>
            </StyledPage>
        </div>
    );
}
