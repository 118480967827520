import React from "react";
import ErrorInformation from "./ErrorInformation";
import { menu } from "../../managers/pathManager";
import VerticalContentPageContainer from "../common/layout/VerticalContentPageContainer";
import PropTypes from "prop-types";

// TODO: change to URL (see https://developer.mozilla.org/en-US/docs/Web/API/URL/searchParams)
const findGetParameter = (parameterName) => {
    let result = null,
        tmp = [];
    window.location.search
        .substr(1)
        .split("&")
        .forEach((item) => {
            tmp = item.split("=");
            if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
        });
    return result;
};

const EmailAlreadyVerified = () => (
    <VerticalContentPageContainer hideDrawer>
        <ErrorInformation
            heading="Ihre E-Mail-Adresse ist bestätigt."
            description="Vielen Dank, dass Sie Vaulteron verwenden. Hier sind Ihre Passwörter zu 100% sicher"
            errorMessage=""
            actionButtonText="Zurück zum Login"
            actionButtonHref={menu.login.path}
        />
    </VerticalContentPageContainer>
);

const NoUserWithThisID = () => (
    <VerticalContentPageContainer hideDrawer>
        <ErrorInformation
            heading="Leider wurde Ihr Benutzer nicht gefunden"
            description={
                <>
                    Bitte vergewissern Sie sich, dass Sie auf den richtigen Link geklickt haben und kontaktieren Sie unseren Support unter{" "}
                    <a href="mailto:office@vaulteron.com">office@vaulteron.com</a>
                </>
            }
            errorMessage=""
            actionButtonText="Zurück zum Login"
            actionButtonHref={menu.login.path}
        />
    </VerticalContentPageContainer>
);

const ErrorPage = ({ errorMessage }) => {
    const heading = "Etwas ist schief gelaufen";
    const description = (
        <>
            Ein Fehler ist aufgetreten und wir arbeiten daran ihn zu richten. Falls sie sofortige Hilfe benötigen schreiben Sie uns unter{" "}
            <a href="mailto:office@vaulteron.com">office@vaulteron.com</a>
        </>
    );

    const localErrorMessage = Boolean(errorMessage) ? errorMessage : findGetParameter("message");
    if (Boolean(localErrorMessage) && localErrorMessage.includes("EmailAlreadyVerified")) return <EmailAlreadyVerified />;
    if (Boolean(localErrorMessage) && localErrorMessage.includes("No user with this ID")) return <NoUserWithThisID />;

    return (
        <VerticalContentPageContainer hideDrawer>
            <ErrorInformation
                heading={heading}
                description={description}
                errorMessage={localErrorMessage}
                actionButtonText="Zurück zum Login"
                actionButtonHref={menu.login.path}
            />
        </VerticalContentPageContainer>
    );
};

ErrorPage.propTypes = {
    errorMessage: PropTypes.string,
};

export default ErrorPage;
