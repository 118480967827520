/**
 *
 * @param {string} websiteUrl
 * @returns {boolean}
 */
export const isWebsite = (websiteUrl) => {
    if (!Boolean(websiteUrl)) return false;

    return Boolean(websiteUrl.match(/^(http:\/\/|https:\/\/)?([a-z0-9][a-z0-9\-]*\.)+[a-z0-9][a-z0-9\-/]+$/i));
};

/**
 *
 * @param {string} websiteUrl
 * @returns {string}
 */
export const prepareWebsiteUrlToBeOpened = (websiteUrl) => {
    if (!isWebsite(websiteUrl)) return "";

    let targetUrl = websiteUrl;
    if (!targetUrl.startsWith("http") && !targetUrl.startsWith("https")) {
        targetUrl = "https://" + targetUrl;
    }
    return targetUrl;
};
