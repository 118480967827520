import React, { useRef, useState } from "react";
import Page from "../common/layout/Page";
import makeStyles from "@mui/styles/makeStyles";
import {
    Avatar,
    Button,
    Grid,
    IconButton,
    ListItemButton,
    List,
    ListItemAvatar,
    ListItemSecondaryAction,
    ListItemText,
    TextField,
    FormControlLabel,
    FormGroup,
} from "@mui/material";
import { useMaterialUIInput } from "../../hooks/inputHook";
import CustomSnackbar from "../common/feedback/CustomSnackbar";
import PasswordInputField from "../common/dataEntry/input/PasswordInputField";
import { useDisableTotpTwoFA, useEnableTotpTwoFA, useMyselfQuery, useUserMutation } from "../../hooks/queries/userQueryHooks";
import { useModalStatus } from "../../hooks/modalHook";
import TwoFactorTotpModal from "./TwoFactorTotpModal";
import RightIcon from "@mui/icons-material/ChevronRight";
import PhoneLinkLockIcon from "@mui/icons-material/PhonelinkLock";
import { format } from "date-fns";
import ChangeLoginPasswordModal from "./ChangeLoginPasswordModal";
import { extractApolloErrorMessage } from "../../managers/errorManager";
import ConfirmationDialog from "../common/feedback/modals/ConfirmationDialog";
import config from "../../config.json";
import { OfflineStatusContext } from "../../contexts/OfflineStatusContext";
import SetupPasswordResetModal from "./SetupPasswordResetModal";
import VaultCard from "../dashboard/VaultCard";
import useTranslation from "../common/translation/translation";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";
import { styled } from "@mui/system";
import MenuList from "@mui/material/MenuList";
import MenuItem from "@mui/material/MenuItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import PasswordOutlinedIcon from "@mui/icons-material/PasswordOutlined";
import PhoneAndroidOutlinedIcon from "@mui/icons-material/PhoneAndroidOutlined";
import HouseOutlinedIcon from "@mui/icons-material/HouseOutlined";
import Checkbox from "@mui/material/Checkbox";
import { useEditMandatorMutation } from "../../graphql/generated";
import WebAuthnSection from "./WebAuthnSection";

const useStyles = makeStyles(() => ({
    passwordInput: {
        width: "100%",
        margin: "0.5rem 0",
    },
    nested: {
        paddingLeft: 0,
    },
    versionNumber: {
        display: "inline-block",
        color: "rgba(201,201,201,0.74)",
        textAlign: "right",
        fontSize: "0.8rem",
        marginTop: "auto",
        marginRight: "18px",
    },
}));

const Container = styled("div")({
    margin: "1em 2em",
});

function ProfilePage({ currentPageType }) {
    const classes = useStyles();
    const { i18n } = useTranslation();

    const offlineStatusContext = OfflineStatusContext.useContainer();

    const { loading: loadingMyself, me } = useMyselfQuery();
    const [twoFATypesEnabled, setTwoFATypesEnabled] = useState(me.twoFATypesEnabled);

    const { modalState: offlineErrorDialogState, open: openOfflineErrorDialog, close: closeOfflineErrorDialog } = useModalStatus();
    const { modalState: setupPasswordResetModalState, open: openSetupPasswordResetModal, close: closeSetupPasswordResetModal } = useModalStatus();

    const { modalState: disableAuthenticatorModal, open: openDisableAuthenticatorModal, close: closeDisableAuthenticatorModal } = useModalStatus();
    const { modalState: changeLoginModal, open: openChangeLoginModal, close: closeChangeLoginModal } = useModalStatus();
    const [errorMessage, setErrorMessage] = useState("");
    const [successMessage, setSuccessMessage] = useState("");

    const { value: firstname, onChange: setFirstname } = useMaterialUIInput(me.firstname);
    const { value: lastname, onChange: setLastname } = useMaterialUIInput(me.lastname);
    const { value: email } = useMaterialUIInput(me.email);
    const { value: currentPassword, onChange: setCurrentPassword } = useMaterialUIInput("");
    const { value: newPassword, onChange: setNewPassword } = useMaterialUIInput("");
    const { value: newPasswordRepeated, onChange: setNewPasswordRepeated } = useMaterialUIInput("");

    const [twoFactorRequired, setTwoFactorRequired] = useState(me.mandator.twoFactorRequired);
    const [editMandator] = useEditMandatorMutation();

    const isTOTPEnabled = twoFATypesEnabled.includes("TOTP");

    const { modalState: twoFactorModalState, open: openTwoFactorTotpModal, close: closeTwoFactorModal } = useModalStatus();

    const { editMyself } = useUserMutation();

    const { disableTotp } = useDisableTotpTwoFA();
    const { enableTotp, loading: loadingEnableTwoFactorAuthentication } = useEnableTotpTwoFA();

    const loading = loadingMyself || loadingEnableTwoFactorAuthentication;

    const basicInfoRef = useRef(null);
    const resetPasswordRef = useRef(null);
    const changePasswordRef = useRef(null);
    const twoFactorRef = useRef(null);
    const companySettingRef = useRef(null);

    const onSubmit = async () => {
        setErrorMessage("");
        setSuccessMessage("");

        try {
            const user = {
                firstname,
                lastname,
                email,
            };
            await editMyself({ variables: { user } });
            setSuccessMessage("Ihre Benutzerdaten wurden erfolgreich geändert");
        } catch (e) {
            setErrorMessage(extractApolloErrorMessage(e, "Ein unerwarteter Fehler ist aufgetreten. Bitte versuche es erneut"));
        }
    };

    const onEnableTwoFactorAuthentication = (codeInput) => {
        enableTotp({ variables: { totp: codeInput + "" } })
            .then((e) => {
                setTwoFATypesEnabled(e.data.user.twofactor.enableTotp.twoFATypesEnabled);
            })
            .catch(() => {
                setErrorMessage("Dieser Code is falsch. Bitte überprüfen Sie den Code");
            });
    };
    const onDisableTwoFactorAuthentication = async (codeInput) => {
        try {
            const e = await disableTotp({ variables: { totp: codeInput + "" } });
            setTwoFATypesEnabled(e.data.user.twofactor.disableTotp.twoFATypesEnabled);
            closeDisableAuthenticatorModal();
        } catch (e) {
            setErrorMessage("Dieser Code is falsch. Bitte überprüfen Sie den Code");
        }
    };

    const onEditMandator = (twoFactorRequiredValue) => {
        editMandator({ variables: { twoFactorRequired: twoFactorRequiredValue } });
    };

    const lastPasswordChangeText = format(new Date(me.passwordChangedAt + "Z"), "dd.MM.yyyy");

    const samePasswords = Boolean(currentPassword) && currentPassword === newPassword;
    const smallerThanMinLength = Boolean(newPassword) && newPassword.length < config.minLoginPasswordLength;
    const differentNewPassword = Boolean(newPasswordRepeated) && newPassword !== newPasswordRepeated;
    const shouldValidatePassword = Boolean(currentPassword) && Boolean(newPassword) && Boolean(newPasswordRepeated);

    return (
        <Page loading={loading} currentPageType={currentPageType}>
            <Container>
                <PageHeader>{i18n("settings.title")}</PageHeader>
                <PageSubTitle>{i18n("settings.description")}</PageSubTitle>
                <Grid container spacing={2}>
                    <Grid item xl={5} md={4} sm={12}>
                        <VaultCard>
                            <MenuList style={{ width: "100%" }}>
                                <MenuItem onClick={() => basicInfoRef.current.scrollIntoView()} divider style={{ margin: "1em 0" }}>
                                    <ListItemIcon>
                                        <PersonOutlineOutlinedIcon fontSize="large" />
                                    </ListItemIcon>
                                    <ListItemText>{i18n("settings.navigation.general")}</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => resetPasswordRef.current.scrollIntoView()} divider style={{ margin: "1em 0" }}>
                                    <ListItemIcon>
                                        <LockResetOutlinedIcon fontSize="large" />
                                    </ListItemIcon>
                                    <ListItemText>{i18n("settings.navigation.resetPassword")}</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => changePasswordRef.current.scrollIntoView()} divider style={{ margin: "1em 0" }}>
                                    <ListItemIcon>
                                        <PasswordOutlinedIcon fontSize="large" />
                                    </ListItemIcon>
                                    <ListItemText>{i18n("settings.navigation.changePassword")}</ListItemText>
                                </MenuItem>
                                <MenuItem onClick={() => twoFactorRef.current.scrollIntoView()} divider style={{ margin: "1em 0" }}>
                                    <ListItemIcon>
                                        <PhoneAndroidOutlinedIcon fontSize="large" />
                                    </ListItemIcon>
                                    <ListItemText>{i18n("settings.navigation.twoFactor")}</ListItemText>
                                </MenuItem>
                                {me.admin && (
                                    <MenuItem onClick={() => companySettingRef.current.scrollIntoView()} style={{ margin: "1em 0" }}>
                                        <ListItemIcon>
                                            <HouseOutlinedIcon fontSize="large" />
                                        </ListItemIcon>
                                        <ListItemText>{i18n("settings.navigation.company")}</ListItemText>
                                    </MenuItem>
                                )}
                            </MenuList>
                        </VaultCard>
                    </Grid>
                    <Grid item xl={7} md={8} sm={12}>
                        <VaultCard title={`${me.firstname} ${me.lastname}`} innerRef={basicInfoRef}>
                            <Grid item xs={12}>
                                <TextField
                                    key="setting-firstname"
                                    value={firstname}
                                    onChange={setFirstname}
                                    variant="outlined"
                                    label={i18n("users.firstName")}
                                    fullWidth
                                    disabled={offlineStatusContext.isOffline}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField
                                    value={lastname}
                                    onChange={setLastname}
                                    variant="outlined"
                                    label={i18n("users.lastName")}
                                    fullWidth
                                    disabled={offlineStatusContext.isOffline}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField value={email} disabled variant="outlined" label={i18n("users.email")} fullWidth />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    style={{ marginBottom: "2em" }}
                                    onClick={onSubmit}
                                    color="primary"
                                    variant="contained"
                                    disabled={offlineStatusContext.isOffline || !firstname || !lastname}
                                >
                                    {i18n("general.actions.saveChange")}
                                </Button>
                            </Grid>
                        </VaultCard>
                        <VaultCard
                            title={i18n("settings.passwordReset.title")}
                            subTitle={i18n("settings.passwordReset.descriptionLong")}
                            innerRef={resetPasswordRef}
                        >
                            <Grid item xs={12}>
                                <List component="div" disablePadding>
                                    <ListItemButton
                                        className={classes.nested}
                                        onClick={() => {
                                            if (offlineStatusContext.isOffline) openOfflineErrorDialog();
                                            else openSetupPasswordResetModal();
                                        }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <PhoneLinkLockIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        {!me.passwordReset && (
                                            <ListItemText
                                                primary={i18n("settings.passwordReset.title")}
                                                secondary={i18n("settings.passwordReset.description")}
                                            />
                                        )}
                                        {me.passwordReset && (
                                            <ListItemText
                                                primary={i18n("settings.passwordReset.establishedTitle")}
                                                secondary={i18n("settings.passwordReset.overwriteInformation")}
                                            />
                                        )}
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                onClick={() => {
                                                    if (offlineStatusContext.isOffline) openOfflineErrorDialog();
                                                    else openSetupPasswordResetModal();
                                                }}
                                                edge="end"
                                                aria-label="openTwoFactorTotpModal"
                                            >
                                                <RightIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItemButton>
                                </List>
                            </Grid>
                        </VaultCard>
                        <VaultCard
                            title={i18n("settings.changePassword.title")}
                            subTitle={i18n("settings.changePassword.description")}
                            innerRef={changePasswordRef}
                        >
                            <Grid item xs={12}>
                                <PasswordInputField
                                    value={currentPassword}
                                    onChange={setCurrentPassword}
                                    className={classes.passwordInput}
                                    variant="outlined"
                                    label={i18n("settings.changePassword.currentPassword")}
                                    disabled={offlineStatusContext.isOffline}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordInputField
                                    value={newPassword}
                                    onChange={setNewPassword}
                                    className={classes.passwordInput}
                                    variant="outlined"
                                    label={i18n("settings.changePassword.newPassword")}
                                    disabled={offlineStatusContext.isOffline}
                                    error={smallerThanMinLength || samePasswords}
                                    errorText={
                                        samePasswords
                                            ? i18n("settings.changePassword.newPasswordDifferent")
                                            : smallerThanMinLength
                                            ? i18n("settings.changePassword.passwordMinLength", {
                                                  key: "count",
                                                  value: config.minLoginPasswordLength,
                                              })
                                            : ""
                                    }
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <PasswordInputField
                                    value={newPasswordRepeated}
                                    onChange={setNewPasswordRepeated}
                                    className={classes.passwordInput}
                                    variant="outlined"
                                    error={differentNewPassword}
                                    errorText={differentNewPassword ? i18n("settings.changePassword.passwordNoMatch") : ""}
                                    label={i18n("settings.changePassword.repeatPassword")}
                                    disabled={offlineStatusContext.isOffline}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Button
                                    onClick={openChangeLoginModal}
                                    disabled={
                                        !shouldValidatePassword ||
                                        smallerThanMinLength ||
                                        samePasswords ||
                                        differentNewPassword ||
                                        offlineStatusContext.isOffline
                                    }
                                    color="primary"
                                    variant="contained"
                                >
                                    {i18n("settings.changePassword.action")}
                                </Button>
                            </Grid>
                            <Grid item xs={12}>
                                {i18n("settings.changePassword.lastChange")} {lastPasswordChangeText}
                            </Grid>
                        </VaultCard>
                        <VaultCard title={i18n("settings.twoFactor.title")} subTitle={i18n("settings.twoFactor.description")} innerRef={twoFactorRef}>
                            <Grid item xs={12}>
                                <List component="div" disablePadding>
                                    <ListItemButton
                                        className={classes.nested}
                                        onClick={() => {
                                            if (offlineStatusContext.isOffline) {
                                                openOfflineErrorDialog();
                                            } else {
                                                if (isTOTPEnabled) openDisableAuthenticatorModal();
                                                else openTwoFactorTotpModal();
                                            }
                                        }}
                                    >
                                        <ListItemAvatar>
                                            <Avatar>
                                                <PhoneLinkLockIcon />
                                            </Avatar>
                                        </ListItemAvatar>
                                        <ListItemText
                                            primary={
                                                isTOTPEnabled
                                                    ? i18n("settings.twoFactor.totp.deactivateTitle")
                                                    : i18n("settings.twoFactor.totp.activateTitle")
                                            }
                                            secondary={i18n("settings.twoFactor.totp.activateDescription")}
                                        />
                                        <ListItemSecondaryAction>
                                            <IconButton
                                                onClick={() => {
                                                    if (offlineStatusContext.isOffline) {
                                                        openOfflineErrorDialog();
                                                    } else {
                                                        if (isTOTPEnabled) openDisableAuthenticatorModal();
                                                        else openTwoFactorTotpModal();
                                                    }
                                                }}
                                                edge="end"
                                                aria-label="openTwoFactorTotpModal"
                                            >
                                                <RightIcon />
                                            </IconButton>
                                        </ListItemSecondaryAction>
                                    </ListItemButton>

                                    <WebAuthnSection />
                                </List>
                            </Grid>
                        </VaultCard>
                        {me.admin && (
                            <VaultCard
                                innerRef={companySettingRef}
                                title={i18n("settings.company.title")}
                                subTitle={i18n("settings.company.description")}
                            >
                                <Grid item xs={12}>
                                    <FormGroup>
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={twoFactorRequired}
                                                    onChange={(e) => {
                                                        onEditMandator(e.target.checked);
                                                        setTwoFactorRequired(e.target.checked);
                                                    }}
                                                />
                                            }
                                            label={i18n("settings.company.checkbox")}
                                        />
                                    </FormGroup>
                                </Grid>
                            </VaultCard>
                        )}
                    </Grid>
                </Grid>

                {(errorMessage !== "" || successMessage !== "") && (
                    <CustomSnackbar
                        open
                        variant={errorMessage === "" ? "success" : "error"}
                        onClose={(_) => {
                            setErrorMessage("");
                            setSuccessMessage("");
                        }}
                        message={errorMessage === "" ? successMessage : errorMessage}
                    />
                )}
                {changeLoginModal && (
                    <ChangeLoginPasswordModal onClose={closeChangeLoginModal} currentPassword={currentPassword} newPassword={newPassword} />
                )}
                {twoFactorModalState && <TwoFactorTotpModal onClose={closeTwoFactorModal} onCodeEntered={onEnableTwoFactorAuthentication} />}

                {disableAuthenticatorModal && (
                    <ConfirmationDialog
                        onOk={onDisableTwoFactorAuthentication}
                        onClose={closeDisableAuthenticatorModal}
                        open
                        okText={i18n("general.actions.deactivate")}
                        cancelText={i18n("general.actions.cancel")}
                        title={i18n("settings.twoFactor.totp.deactivateTitle")}
                        description={i18n("settings.twoFactor.totp.deactivateDescription")}
                    />
                )}

                {setupPasswordResetModalState && <SetupPasswordResetModal onClose={closeSetupPasswordResetModal} />}

                {offlineErrorDialogState && (
                    <ConfirmationDialog
                        open
                        onOk={closeOfflineErrorDialog}
                        onClose={closeOfflineErrorDialog}
                        title="Einstellungen nicht verfügbar"
                        description="Solange sie offline sind können keine Sicherheitseinstellungen verändert werden."
                    />
                )}

                <div className={classes.versionNumber}>version: {config.version}</div>
            </Container>
        </Page>
    );
}

export default ProfilePage;
