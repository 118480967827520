import React, { useEffect } from "react";
import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";
import { menu } from "./managers/pathManager";
import { useLazyOfflinePasswordQuery } from "./hooks/queries/offlinePasswordHook";
import { OfflinePasswordsContext } from "./contexts/OfflinePasswordsContext";
import { LoginContext } from "./contexts/LoginContext";
import LoadingContainer from "./components/common/layout/LoadingContainer";
import LoginPage from "./components/login";
import PageNotFound from "./components/systemPages/PageNotFound";
import UnauthorizedPage from "./components/systemPages/UnauthorizedPage";
import { CircularProgress } from "@mui/material";

import "./styles/global.css";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import Page from "./components/common/layout/Page";

export function isElectron() {
    // Renderer process
    if (typeof window !== "undefined" && typeof window.process === "object" && window.process.type === "renderer") {
        return true;
    }

    // Main process
    if (typeof process !== "undefined" && typeof process.versions === "object" && !!process.versions.electron) {
        return true;
    }

    // Detect the user agent when the `nodeIntegration` option is set to true
    return typeof navigator === "object" && typeof navigator.userAgent === "string" && navigator.userAgent.indexOf("Electron") >= 0;
}

function App({ unauthorised }) {
    const loginContext = LoginContext.useContainer();
    const { isBusinessCustomer, loggedIn } = loginContext;

    const offlinePasswordsContext = OfflinePasswordsContext.useContainer();

    // Load once, when the app is loaded to get the initial value to enable/disable the menu in the PageDrawer
    const { getOfflinePasswords, offlinePasswords } = useLazyOfflinePasswordQuery();

    useEffect(() => {
        if (!loggedIn) return;

        getOfflinePasswords().then(() => {
            offlinePasswordsContext.setHasSyncedPasswords(offlinePasswords.length > 0);
        });
    }, [loggedIn, getOfflinePasswords, offlinePasswordsContext, offlinePasswords.length]);

    const createRoute = (menuEntry) => {
        let { path, page, accessibleWithoutLogin } = menuEntry;

        if (unauthorised === true && loggedIn === true) return getRoute(path, <UnauthorizedPage />);
        if (loggedIn === false && !accessibleWithoutLogin) return getRoute(path, <LoginPage />);
        if (accessibleWithoutLogin === true) return getRoute(path, page);

        if (isBusinessCustomer === undefined)
            return getRoute(
                path,
                <Page>
                    <CircularProgress />
                </Page>
            );
        if (!page) return;

        return getRoute(path, <LoadingContainer needsAdmin={menuEntry.needsAdmin}>{page}</LoadingContainer>);
    };

    const getRoute = (path, Page) => {
        return <Route key={path} exact path={path} element={Page} />;
    };

    let routes = [];
    Object.entries(menu).forEach(([_, value]) => {
        const route = createRoute(value);
        if (route) routes.push(route);
    });

    if (isElectron()) {
        return (
            <HashRouter>
                <Routes>
                    {routes.map((m) => m)}
                    <Route path="*" element={<PageNotFound />} />
                </Routes>
            </HashRouter>
        );
    }
    return (
        <BrowserRouter>
            <Routes>
                {routes.map((m) => m)}
                <Route path="*" element={<PageNotFound />} />
            </Routes>
        </BrowserRouter>
    );
}

export default App;
