import { useGetMandatorsQuery, useDeleteMandatorMutation } from "../../graphql/generated";

/**
 *
 * @returns {{loading: boolean, error: ApolloError, mandators: MandatorType[]}}
 */
export const useMandatorsQuery = () => {
    let { loading, error, data } = useGetMandatorsQuery({
        fetchPolicy: "network-only",
    });

    const mandators = data ? data.mandators : [];
    return { mandators, loading, error };
};

export const useDeleteMandator = () => {
    const [deleteMandator] = useDeleteMandatorMutation();
    return { deleteMandator };
};