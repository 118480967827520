import React from "react";
import PropTypes from "prop-types";
import TextField from "@mui/material/TextField";
import { HtmlAutoCompleteType } from "../../types/HtmlAutoCompleteType";

function LoginInput(props) {
    const { onChange, required } = props;

    return <TextField variant="outlined" margin="normal" fullWidth {...props} onChange={(e) => onChange(e.target.value)} />;
}

LoginInput.defaultProps = {
    type: "text",
    required: true,
};

LoginInput.propTypes = {
    value: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    id: PropTypes.string,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    autoComplete: HtmlAutoCompleteType,
    type: PropTypes.oneOf(["text", "password"]),
    autoFocus: PropTypes.bool,
    disabled: PropTypes.bool,
    error: PropTypes.bool,
    required: PropTypes.bool,
};

export default LoginInput;
