import { buildCurrentRow, getPropertyName, sortChangeLogs } from "./historyUtils";
import { useHistoryQuery, useKeyPairChangeLogsQuery, usePasswordChangeLogsQuery } from "../../../../hooks/queries/historyQueryHook";
import { useEffect, useState } from "react";

/**
 *
 * @typedef {[{id: string, name: string}]} SimpleTagArrayType
 */

async function generateRows(me, loginContext, changeLogs, passwordChangeLogs, keyPairChangeLogs, password) {
    const sortedChangeLogs = await sortChangeLogs(
        me.keyPair.encryptedPrivateKey,
        loginContext.userPasswordHash,
        changeLogs,
        passwordChangeLogs,
        keyPairChangeLogs
    );

    let rows = [];
    let lastRow = null;
    let currentPasswordState = {
        name: password.name,
        websiteURL: password.websiteURL,
        login: password.login,
        notes: password.notes,
        formattedTags: "",
    };
    /**
     *
     * @type SimpleTagArrayType
     */
    let unchangedTags = password.tags.map((t) => ({ id: t.id, name: t.name }));
    /**
     *
     * @type SimpleTagArrayType
     */
    let newTags = [];
    /**
     *
     * @type SimpleTagArrayType
     */
    let removedTags = [];
    for (let i = 0; i < sortedChangeLogs.length; i++) {
        /**
         *
         * @type {ChangeLogType}
         */
        const changeLog = sortedChangeLogs[i];
        if (changeLog.propertyName === "EncryptedPasswordUpdated" || changeLog.propertyName === "GroupId") continue;

        let currentRow = lastRow;
        let isNewRow = currentRow === null || currentRow.createdAt !== changeLog.createdAt;

        if (isNewRow) {
            // Start a new row
            currentRow = buildCurrentRow(currentPasswordState, changeLog);
            newTags = [];
            removedTags = [];
        }

        // Update current password state with changes
        const propertyName = getPropertyName(changeLog);
        if (propertyName === "sharedPasswordTags") {
            if (changeLog.oldValue === null) {
                // New
                unchangedTags = unchangedTags.filter((t) => t.id !== changeLog.newValue.entityId);
                newTags.push({ id: changeLog.newValue.entityId, name: changeLog.newValue.name });
            }
            if (changeLog.newValue === null) {
                // Deleted
                removedTags.push({ id: changeLog.oldValue.entityId, name: changeLog.oldValue.name });
            }
        } else {
            const oldValue = changeLog?.oldValue?.name || "";
            const newValue = changeLog?.newValue?.name || "";
            currentRow[propertyName] = {
                unchangedValue: null,
                oldValue: oldValue,
                newValue: newValue,
            };
            currentPasswordState[propertyName] = oldValue;
        }

        let isNextChangeLogNewRow = i === sortedChangeLogs.length - 1 || currentRow?.createdAt !== sortedChangeLogs[i + 1].createdAt;
        if (isNextChangeLogNewRow) {
            unchangedTags.sort();
            currentRow.formattedTags = {
                unchangedValue: unchangedTags.map((t) => t.name).join(", "),
                oldValue: removedTags.map((t) => t.name).join(", "),
                newValue: newTags.map((t) => t.name).join(", "),
            };
            unchangedTags = [...unchangedTags, ...removedTags];
        }

        if (isNewRow) rows.push(currentRow);
        lastRow = currentRow;
    }
    return rows;
}

export const useGenerateHistoryRow = (password, me, loginContext) => {
    const { passwordChangeLogs, loading: passwordChangeLogLoading, error: passwordChangeLogsError } = usePasswordChangeLogsQuery(password.id);
    const { keyPairChangeLogs, loading: keyPairChangeLogsLoading, error: keyPairChangeLogsError } = useKeyPairChangeLogsQuery();
    const { changeLogs, loading: changeLogsLoading, error: changeLogsError } = useHistoryQuery(password.id);
    const loading = passwordChangeLogLoading || keyPairChangeLogsLoading || changeLogsLoading;
    const error = passwordChangeLogsError || keyPairChangeLogsError || changeLogsError;
    const [data, setData] = useState([]);
    const [finishGeneratingRows, setFinishGeneratingRows] = useState(false);

    useEffect(() => {
        if (loading === false && finishGeneratingRows === false) {
            generateRows(me, loginContext, changeLogs, passwordChangeLogs, keyPairChangeLogs, password).then((e) => {
                setData(e);
                setFinishGeneratingRows(true);
            });
        }
    }, [changeLogs, finishGeneratingRows, keyPairChangeLogs, loading, loginContext, me, password, passwordChangeLogs]);

    return { data, changeLogs, loading: !finishGeneratingRows, error };
};
