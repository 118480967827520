import React from "react";
import { gql, useApolloClient, useMutation, useQuery } from "@apollo/client";
import { fragments } from "../../managers/fragmentManager";
import { addEntry } from "../../managers/cacheManager";
import { groupRole } from "../../managers/groupManager";
import {
    useDisableTwoFaMutation,
    useEnableTotpMutation,
    useGetMyselfQuery,
    useGetTotpAuthenticatorKeyMutation,
    useGetWebAuthnChallengeMutation,
    useEditUserMutation,
    useGrantAdminPermissionsMutation,
    useRevokeAdminPermissionsMutation,
    useArchiveUserMutation,
    useAddUserMutation,
    useResendAccountActivationMailMutation,
    useChangeOwnLoginPasswordMutation,
} from "../../graphql/generated";

export const GET_MYSELF = gql`
    query GetMyself {
        me {
            ...SelectOwnUserSimple
        }
    }
    ${fragments.query.SelectOwnUserSimple}
`;

/**
 *
 * @param {boolean} forceNetworkFetch
 * @returns {{me: (OwnUserType|*), loading: boolean, error: ApolloError, isLicenseValid: boolean, isSystemAdmin: boolean}}
 */
export const useMyselfQuery = (forceNetworkFetch = false) => {
    let { loading, error, data } = useGetMyselfQuery({
        fetchPolicy: forceNetworkFetch ? "network-only" : "cache-first",
    });

    const me = !loading && !error && data ? data.me : { mandator: {}, usergroups: [] };
    const isLicenseValid = me.mandator.subscriptionStatus !== "NONE";
    const isTrial = me.mandator.subscriptionStatus === "TRIAL";
    const isSystemAdmin = me.admin;
    const isBusinessCustomer = me.mandator.isBusinessCustomer;

    const createdAt = new Date(me.mandator.createdAt);
    const trialFinishedAt = new Date(createdAt.setMonth(createdAt.getMonth() + 1));
    const trialFinishedLocalDate = trialFinishedAt.toLocaleDateString("de-DE", {
        year: "numeric",
        month: "2-digit",
        day: "2-digit",
    });

    return { me, loading, error, isLicenseValid, isTrial, isSystemAdmin, isBusinessCustomer, trialFinishedLocalDate };
};

/**
 *
 * @param {Guid} groupId
 * @returns {{isAdmin: boolean, isEditor: boolean, isViewer: boolean}}
 */
export const useMyselfRole = (groupId) => {
    const { me, isLicenseValid } = useMyselfQuery();

    const userGroup = me.usergroups.find((u) => u.groupId === groupId) || {};
    const myGroupRole = userGroup.groupRole;
    const isAdmin = myGroupRole === (groupRole.admin || me.admin) && isLicenseValid;
    const isEditor = myGroupRole === groupRole.editor && isLicenseValid;
    const isViewer = myGroupRole === groupRole.viewer || !isLicenseValid;

    return { isAdmin, isEditor, isViewer };
};

const GET_USER_BY_ID = gql`
    query GetUserById($userIds: [Guid]!, $byArchived: Boolean = false) {
        users(userIds: $userIds, byArchived: $byArchived) {
            publicKey {
                id
                publicKeyString
            }
            ...SelectUserSimple
        }
    }
    ${fragments.query.SelectUserSimple}
`;
/**
 *
 * @param {Guid} userId
 * @param {boolean|null} byArchived
 * @returns {{loading: boolean, error: ApolloError, user: UserType}}
 */
export const useLoadUserById = (userId, byArchived = false) => {
    let { loading, error, data } = useQuery(GET_USER_BY_ID, { variables: { userIds: [userId], byArchived: byArchived } });
    const user = !loading && !error && data ? data.users[0] : {};

    return { user, loading, error };
};

export const useLazyUserQueries = () => {
    const client = useApolloClient();

    /**
     *
     * @param {Guid} targetUserId
     * @param {boolean} forceNetworkFetch
     * @param {boolean | null} byArchived
     * @returns {Promise<UserType>}
     */
    const executeLoadOtherUserById = async (targetUserId, forceNetworkFetch = false, byArchived = false) => {
        const { data } = await client.query({
            query: GET_USER_BY_ID,
            variables: { userIds: [targetUserId], byArchived: byArchived },
            fetchPolicy: forceNetworkFetch ? "no-cache" : "cache-first",
        });
        return data.users[0];
    };

    const GET_GROUPS_OF_USERS_BY_ID = gql`
        query GetGroupsOfUsers($userIds: [Guid]!, $byArchived: Boolean = false) {
            users(userIds: $userIds, byArchived: $byArchived) {
                publicKey {
                    id
                    publicKeyString
                }
                groups {
                    ...SelectGroup
                }
                ...SelectUserSimple
            }
        }
        ${fragments.query.SelectUserSimple}
        ${fragments.query.SelectGroup}
    `;
    /**
     *
     * @param {[Guid]} targetUserIds
     * @param {boolean} forceNetworkFetch
     * @param {boolean | null} byArchived
     * @returns {Promise<GroupType>}
     */
    const executeLoadGroupsOfUsers = async (targetUserIds, forceNetworkFetch = false, byArchived = false) => {
        const { data } = await client.query({
            query: GET_GROUPS_OF_USERS_BY_ID,
            variables: { userIds: targetUserIds, byArchived: byArchived },
            fetchPolicy: forceNetworkFetch ? "network-only" : "cache-first",
        });
        return data?.users[0]?.groups ?? [];
    };

    const GET_ADMINS = gql`
        query GetAdmins {
            admins {
                publicKey {
                    id
                    publicKeyString
                }
                ...SelectUserSimple
            }
        }
        ${fragments.query.SelectUserSimple}
    `;
    /**
     *
     * @param {boolean} forceNetworkFetch
     * @returns {Promise<Array<UserType|null|undefined>>}
     */
    const executeLoadAdmins = async (forceNetworkFetch = false) => {
        const { data } = await client.query({
            query: GET_ADMINS,
            fetchPolicy: forceNetworkFetch ? "no-cache" : "cache-first",
        });
        return data.admins;
    };

    const GET_ALL_USERS_WITH_ACCESS_TO_THIS_GROUP = gql`
        query GetAllUsersWithAccessToThisGroup($ids: [Guid], $byArchived: Boolean = false) {
            groups(ids: $ids, byArchived: $byArchived) {
                id
                usersWithAccess {
                    id
                    publicKey {
                        id
                        publicKeyString
                    }
                }
            }
        }
    `;

    /**
     *
     * @param {Guid} groupId
     * @returns {Promise<[UserType]>}
     */
    const executeGetUsersWithAccessToGroup = async (groupId) => {
        const { data } = await client.query({
            query: GET_ALL_USERS_WITH_ACCESS_TO_THIS_GROUP,
            variables: { ids: groupId },
            fetchPolicy: "no-cache",
        });
        return data && data.groups && data.groups.length > 0 ? data.groups[0].usersWithAccess : [];
    };

    return {
        executeLoadUserById: executeLoadOtherUserById,
        executeLoadAdmins,
        executeLoadGroupsOfUsers,
        executeGetUsersWithAccessToGroup,
    };
};

/**
 *
 * @param forceNetworkFetch
 * @returns {function(): Promise<OwnUserType>}
 */
export const useMyselfQueryAsync = (forceNetworkFetch = false) => {
    const client = useApolloClient();
    const GET_MYSELF = gql`
        query GetMyself {
            me {
                ...SelectOwnUserSimple
            }
        }
        ${fragments.query.SelectOwnUserSimple}
    `;
    return async () => {
        const { data } = await client.query({
            query: GET_MYSELF,
            variables: {},
            fetchPolicy: forceNetworkFetch ? "network-only" : "cache-first",
        });
        return data.me;
    };
};

/**
 *
 * @returns {{me: OwnUserType}|{}}
 */
export const useMyselfQueryFromCache = () => {
    const client = useApolloClient();
    const res = client.cache.readQuery({ query: GET_MYSELF });
    if (!res) return {};
    const me = res.me;
    return me ? { me } : {};
};

export const GET_USERS = gql`
    query UsersQuery($searchTerm: String!, $withoutUserIds: [Guid]!, $excludeUsersInGroupIds: [Guid]!, $byArchived: Boolean = false) {
        users(searchTerm: $searchTerm, withoutUserIds: $withoutUserIds, excludeUsersInGroupIds: $excludeUsersInGroupIds, byArchived: $byArchived) {
            ...SelectUserSimple
        }
    }
    ${fragments.query.SelectUserSimple}
`;

/**
 * @typedef {Object} UsersQueryFilterParams
 * @property {string} [searchTerm]
 * @property {string[]} [withoutUserIds]
 * @property {string[]} [excludeUsersInGroupIds]
 * @property {?boolean} [byArchived]
 */

/**
 * @typedef {Object} UsersTypeResult
 * @property {boolean} loading
 * @property {ApolloError} error
 * @property {UserType[]} users
 */

/**
 * @param {UsersQueryFilterParams} filterParam
 * @returns UsersTypeResult
 */
export const useFilteredUsersQuery = (filterParam) => {
    return useUsersQuery(filterParam.searchTerm, filterParam.withoutUserIds, filterParam.excludeUsersInGroupIds, filterParam.byArchived);
};

/**
 *
 * @param searchTerm
 * @param withoutUserIds
 * @param excludeUsersInGroupIds
 * @param byArchived
 * @returns UsersTypeResult
 */
export const useUsersQuery = (searchTerm, withoutUserIds = [], excludeUsersInGroupIds = [], byArchived = null) => {
    const { loading, error, data } = useQuery(GET_USERS, {
        variables: { searchTerm, withoutUserIds, excludeUsersInGroupIds, byArchived },
        fetchPolicy: "cache-and-network",
    });

    const users = data?.users || [];

    return { users, loading, error };
};

export const GET_USERS_IN_GROUP = gql`
    query UsersQueryInGroup($searchTerm: String!, $groupIds: [Guid]!, $byArchived: Boolean = false) {
        users(searchTerm: $searchTerm, groupIds: $groupIds, byArchived: $byArchived) {
            id
            lastname
            firstname
            email
            usergroups {
                group {
                    id
                    name
                }
                groupRole
            }
        }
    }
`;

export const useUserMutation = () => {
    const [editUser, { loading: editUserLoading, error: editUserError }] = useEditUserMutation();
    const [editMyself, { loading: editMyselfLoading, error: editMyselfError }] = useEditUserMutation({
        refetchQueries: [{ query: GET_MYSELF }],
        awaitRefetchQueries: true,
    });

    const [grantAdminPermission, { loading: grantAdminPermissionLoading, error: grantAdminPermissionError }] = useGrantAdminPermissionsMutation();
    const [revokeAdminPermission, { loading: revokeAdminPermissionLoading, error: revokeAdminPermissionError }] = useRevokeAdminPermissionsMutation();
    const [archiveOrReactivateUser, { loading: archiveOrReactivateUserLoading, error: archiveOrReactivateUserError }] = useArchiveUserMutation();
    const [addUser, { error: addUserError, loading: addUserLoading }] = useAddUserMutation({
        update(
            cache,
            {
                data: {
                    user: { add: newUser },
                },
            }
        ) {
            cache.modify({
                fields: {
                    users(refs) {
                        return addEntry(cache, refs, fragments.query.SelectUserSimple, newUser);
                    },
                },
                broadcast: false,
            });
        },
    });

    const [resendAccountActivationEmail, { error: resendAccountActivationEmailError, loading: resendAccountActivationEmailLoading }] =
        useResendAccountActivationMailMutation();
    const [changeUserPassword, { loading: changeUserPasswordLoading, error: changeUserPasswordError }] = useChangeOwnLoginPasswordMutation();

    const loading =
        addUserLoading ||
        editUserLoading ||
        editMyselfLoading ||
        changeUserPasswordLoading ||
        grantAdminPermissionLoading ||
        revokeAdminPermissionLoading ||
        archiveOrReactivateUserLoading ||
        resendAccountActivationEmailLoading;

    const error =
        addUserError ||
        editUserError ||
        editMyselfError ||
        changeUserPasswordError ||
        grantAdminPermissionError ||
        revokeAdminPermissionError ||
        archiveOrReactivateUserError ||
        resendAccountActivationEmailError;

    return {
        addUser,
        editUser,
        editMyself,
        changeUserPassword,
        grantAdminPermission,
        revokeAdminPermission,
        archiveOrReactivateUser,
        resendAccountActivationEmail,
        loading,
        error,
    };
};

export const useEditUserInGroupAwaitRefetchMutation = (groupId) => {
    const EDIT_USER_IN_GROUP = gql`
        mutation EditUserInGroup($user: EditUserGroupType!) {
            group {
                editUser(user: $user) {
                    ...SelectUserSimple
                }
            }
        }
        ${fragments.query.SelectUserSimple}
    `;

    const [editUserInGroup, { loading, error }] = useMutation(EDIT_USER_IN_GROUP, {
        refetchQueries: [
            {
                query: GET_USERS_IN_GROUP,
                variables: { groupIds: groupId, searchTerm: "" },
            },
        ],
        awaitRefetchQueries: true,
    });

    return { editUserInGroup, loading, error };
};

export const useEnableTotpTwoFA = () => {
    const [getTotpAuthenticatorKey, { loading: generateLoading, error: generateError }] = useGetTotpAuthenticatorKeyMutation();
    const [enableTotp, { loading: enableLoading, error: enableError }] = useEnableTotpMutation();

    const loading = generateLoading || enableLoading;
    const error = generateError || enableError;

    return { getTotpAuthenticatorKey, enableTotp, loading, error };
};

export const useDisableTotpTwoFA = () => {
    const [disableTotp, { loading, error }] = useDisableTwoFaMutation();
    return { disableTotp, loading, error };
};
