import PropTypes from "prop-types";
import { TableCell, TableRow } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import { IconTypes } from "../../types/IconType";
import IconButton from "../common/dataDisplay/IconButton";
import { useIsMobile } from "../../hooks/mobileHook";
import Visibility from "../common/dataDisplay/Visibility";

const useStyles = makeStyles((theme) => ({
    tableRow: {
        cursor: "pointer",
    },
}));

/**
 *
 * @param {UserType} user
 * @param {(user: UserType) => void} onRowClick
 * @param {(e: MouseEvent, user: UserType) => void} onContextMenu
 * @returns {JSX.Element}
 * @constructor
 */
const UserTableRow = ({ user, onRowClick, onContextMenu }) => {
    const { id, firstname, lastname, email } = user;

    const classes = useStyles();
    const isMobile = useIsMobile();

    const handleRowClick = () => onRowClick(user);
    const handleContextMenuClick = (e) => onContextMenu(e, user);

    return (
        <TableRow key={id} hover onContextMenu={handleContextMenuClick}>
            <TableCell onClick={handleRowClick} className={classes.tableRow} component="th" scope="row">
                {firstname + " " + lastname}
            </TableCell>

            <Visibility hideOnMobile>
                <TableCell onClick={handleRowClick} className={classes.tableRow}>
                    {email.toLowerCase()}
                </TableCell>
            </Visibility>

            <TableCell width={5} align="right">
                <IconButton iconType={IconTypes.moreOptions} onClick={handleContextMenuClick} />
            </TableCell>
        </TableRow>
    );
};

UserTableRow.propTypes = {
    user: PropTypes.object.isRequired,
    onRowClick: PropTypes.func.isRequired,
    onContextMenu: PropTypes.func.isRequired,
};

export default UserTableRow;
