import { format } from "date-fns";
import { de } from "date-fns/esm/locale";
import { CryptoManager } from "../../../../managers/cryptoManager";

/**
 *
 * @param {AccountPasswordType|SharedPasswordType} password
 * @param {ChangeLogType} changeLog
 * @returns {{createdAt: (DateTime), encryptedPasswordString: *, notes: (string), websiteURL: (string), changedBy: string, name, formattedCreatedAt: string, id, login: (string)}}
 */
export const buildCurrentRow = (password, changeLog) => {
    return {
        id: changeLog.id,
        name: password.name,
        encryptedPasswordString: changeLog?.encryptedPasswordString,
        websiteURL: password.websiteURL,
        login: password.login,
        notes: password.notes,
        formattedCreatedAt: format(new Date(changeLog.createdAt + "Z"), "dd.MM.yyyy HH:mm:ss", { locale: de }),
        createdAt: changeLog.createdAt,
        changedBy: changeLog.createdBy ? `${changeLog.createdBy.firstname} ${changeLog.createdBy.lastname}` : "System",
    };
};

/**
 *
 * @param {string} encryptedPrivateKey
 * @param {string} userPasswordHash
 * @param {ChangeLogType[]} changeLogs
 * @param {PasswordChangeLogType[]} passwordChangeLogs
 * @param {KeyPairChangeLogType[]} keyPairChangeLogs
 * @returns {Promise<ChangeLogType[]>}
 */
export const sortChangeLogs = async (encryptedPrivateKey, userPasswordHash, changeLogs, passwordChangeLogs, keyPairChangeLogs) => {
    if (!encryptedPrivateKey || !userPasswordHash || !keyPairChangeLogs?.length) return [];

    const deepCopyChangeLogs = JSON.parse(JSON.stringify(changeLogs));
    const deepCopyPasswordChangeLogs = JSON.parse(JSON.stringify(passwordChangeLogs));
    const deepCopyKeyPairChangeLogs = JSON.parse(JSON.stringify(keyPairChangeLogs));
    const privateKey = await CryptoManager.decryptUsingUserCredentials(encryptedPrivateKey, userPasswordHash);
    const decryptKeyPairLogs = await decryptKeyPairsChangeLogs(deepCopyKeyPairChangeLogs, privateKey);

    const mappedPasswordChangeLog = await Promise.all(
        deepCopyPasswordChangeLogs.map(async (changeLog) => {
            const { current, previous } = getKeyPairByPasswordChangeLog(decryptKeyPairLogs, changeLog);
            changeLog.newValue = {};
            changeLog.newValue.name = await CryptoManager.decryptEncryptedPassword(
                previous?.encryptedPrivateKey || encryptedPrivateKey,
                current?.previousPasswordHash || userPasswordHash,
                changeLog.encryptedPasswordString
            );
            changeLog.propertyName = "encryptedPasswordString";
            return changeLog;
        })
    );

    mappedPasswordChangeLog.forEach((passwordChangeLog, i) => {
        passwordChangeLog.oldValue = {};
        passwordChangeLog.oldValue.name = mappedPasswordChangeLog[i + 1]?.newValue.name;
    });

    const logs = [...deepCopyChangeLogs, ...mappedPasswordChangeLog];
    return logs.sort((b, a) => a.createdAt.localeCompare(b.createdAt));
};

/**
 *
 * @param {KeyPairChangeLogType[]} keyPairChangeLogs
 * @param {string} decryptedPrivateKey
 * @returns {Promise<KeyPairChangeLogType[]>}
 */
const decryptKeyPairsChangeLogs = async (keyPairChangeLogs, decryptedPrivateKey) => {
    for (let i = 0; i < keyPairChangeLogs.length; i++) {
        const keyPairChange = keyPairChangeLogs[i];
        const previousPasswordHashEncryptedWithPublicKey = keyPairChange.previousPasswordHashEncryptedWithPublicKey;
        if (previousPasswordHashEncryptedWithPublicKey) {
            let previousPasswordHash;
            if (i === 0) {
                previousPasswordHash = await CryptoManager.decryptWithPrivateKey(previousPasswordHashEncryptedWithPublicKey, decryptedPrivateKey);
            } else {
                previousPasswordHash = await CryptoManager.decryptEncryptedPassword(
                    keyPairChange.encryptedPrivateKey,
                    keyPairChangeLogs[i - 1].previousPasswordHash,
                    previousPasswordHashEncryptedWithPublicKey
                );
            }

            keyPairChange.previousPasswordHash = previousPasswordHash;
        }
    }
    return keyPairChangeLogs;
};

/**
 *
 * @param {KeyPairChangeLogType[]} keyPairChangeLogs
 * @param {ChangeLogType} changeLog
 * @returns {{current: KeyPairChangeLogType, previous: KeyPairChangeLogType|null}}
 */
const getKeyPairByPasswordChangeLog = (keyPairChangeLogs, changeLog) => {
    if (keyPairChangeLogs.length === 1) return { current: keyPairChangeLogs[0], previous: null };
    for (let i = keyPairChangeLogs.length - 1; i >= 0; i--) {
        if (Date.parse(keyPairChangeLogs[i].createdAt) > Date.parse(changeLog.createdAt)) {
            return { current: keyPairChangeLogs[i], previous: keyPairChangeLogs[i + 1] };
        }
    }
    return { current: keyPairChangeLogs[keyPairChangeLogs.length - 1], previous: null };
};

/**
 *
 * @param {ChangeLogType} changeLog
 * @returns {string}
 */
export const getPropertyName = (changeLog) => {
    if (!changeLog.propertyName) return undefined;
    return changeLog.propertyName.charAt(0).toLowerCase() + changeLog.propertyName.slice(1);
};
