import { CryptoManager } from "./cryptoManager";

/**
 *
 * @param {string} passwordString
 * @param {[{Object}]} users
 * @param {string|null} passwordId
 * @returns {Promise<[{sharedPasswordId: string, keyPairId: string, encryptedPasswordString: string}]>}
 */
export const encryptPasswordForUsers = async (passwordString, users, passwordId = null) => {
    let encryptedPasswords = [];
    for (const user of users) {
        const keyPairObject = user.keyPair || user.publicKey;
        if (keyPairObject === null) continue; // Ignore users without a public key
        const publicKeyString = keyPairObject.publicKeyString;
        const keyPairId = keyPairObject.id;

        if (encryptedPasswords.map((ep) => ep.keyPairId).includes(keyPairId)) continue; // We already added this one.

        const encryptedOtherUserPW = await CryptoManager.encryptWithPublicKey(passwordString, publicKeyString);
        encryptedPasswords.push({
            sharedPasswordId: passwordId,
            keyPairId: keyPairId,
            encryptedPasswordString: encryptedOtherUserPW,
        });
    }
    return encryptedPasswords;
};

export const encryptForUsersAndMyself = async (me, passwordString, users, passwordId = null) => {
    return await encryptPasswordForUsers(passwordString, [me, ...users], passwordId);
};

/**
 *
 * @param {[{decryptedString: string, id: string, publicKeyId: string}]} passwords
 * @param {string} targetUserPublicKeyString
 * @param {string|null} keyPairId
 * @param {boolean} isAccountPassword
 * @returns {Promise<[{publicKeyId: string, sharedPasswordId: string, encryptedPasswordString: string}]>}
 */
export const encryptPasswordsForUser = async (passwords, targetUserPublicKeyString, keyPairId = null, isAccountPassword = false) => {
    let encryptedPasswords = [];
    for (const password of passwords) {
        const encryptedPasswordString = await CryptoManager.encryptWithPublicKey(password.decryptedString, targetUserPublicKeyString);
        let pw = {
            encryptedPasswordString: encryptedPasswordString,
        };

        if (isAccountPassword) pw.accountPasswordId = password.id;
        else pw.sharedPasswordId = password.id;

        if (Boolean(keyPairId)) pw.keyPairId = keyPairId;

        encryptedPasswords.push(pw);
    }
    return encryptedPasswords;
};

/**
 * @param {[{publicKeyId: string, decryptedString: string, id: string}]} passwords
 * @param {[UserType]} users
 * @returns {Promise<[{publicKeyId: string, sharedPasswordId: string, encryptedPasswordString: string}]>}
 */
export const reEncryptPasswordsForUsers = async (passwords, users) => {
    const decryptionPromises = users.map((userWithAccess) =>
        encryptPasswordsForUser(passwords, userWithAccess.publicKey.publicKeyString, userWithAccess.publicKey.id)
    );
    const list2D = await Promise.all(decryptionPromises);
    return list2D.flat();
};
