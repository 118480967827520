import { KindOfPasswordTypes } from "../types/PasswordType";
import { CryptoManager } from "../managers/cryptoManager";
import { useMyselfQueryAsync } from "./queries/userQueryHooks";
import { useLazyAccountPasswordQueries } from "./queries/accountPasswordHook";
import { useLazySharedPasswordQueries } from "./queries/passwordQueryHooks";
import { LoginContext } from "../contexts/LoginContext";
import { extractApolloErrorMessage } from "../managers/errorManager";
import { useExecuteGroupTreeQuery } from "./queries/groupQueryHooks";
import { getGroupsFromParentGroups } from "../managers/groupManager";
import { useLazyOfflinePasswordQueries } from "./queries/offlinePasswordHook";

/**
 *
 * @param {[{id: string, encryptedString: string}]} encryptedPasswords
 * @param {string} encryptedPrivateKey
 * @param {string} userPasswordHash
 * @returns {Promise<[{id: string, decryptedString: string}]>}
 */
async function decryptPasswords(encryptedPasswords, encryptedPrivateKey, userPasswordHash) {
    let decryptedPasswords = [];
    for (const encryptedPassword of encryptedPasswords) {
        const decryptedPasswordString = await CryptoManager.decryptEncryptedPassword(
            encryptedPrivateKey,
            userPasswordHash,
            encryptedPassword.encryptedString
        );
        decryptedPasswords.push({
            id: encryptedPassword.id,
            decryptedString: decryptedPasswordString,
        });
    }
    return decryptedPasswords;
}

/**
 *
 * @returns {(function(byArchived: bool|null = null, targetEncryptedPrivateKey: string|null = null): Promise<[{decryptedString: string, id: string}]>)}
 */
export const useRetrieveDecryptedAccountPasswords = () => {
    const getMyself = useMyselfQueryAsync();
    const loginContext = LoginContext.useContainer();
    const accountPasswordQueries = useLazyAccountPasswordQueries();

    return async (byArchived = null, targetEncryptedPrivateKey = null) => {
        let privateKey = targetEncryptedPrivateKey;
        if (targetEncryptedPrivateKey === null) {
            const me = await getMyself();
            privateKey = me.keyPair.encryptedPrivateKey;
        }

        let encryptedPasswords;
        try {
            encryptedPasswords = await accountPasswordQueries.useExecuteLoadAllAccountPasswordsQuery(byArchived, true);
        } catch (e) {
            console.error(e);
            throw new Error(extractApolloErrorMessage(e, "Es ist leider ein Fehler aufgetreten."));
        }

        try {
            return await decryptPasswords(encryptedPasswords, privateKey, loginContext.userPasswordHash);
        } catch (err) {
            console.error(err);
            throw new Error("Beim Entschlüsseln des Passwortes ist ein Fehler aufgetreten. " + err.message);
        }
    };
};

/**
 *
 * @returns {(function(byArchived: bool|null = null, targetEncryptedPrivateKey: string|null = null): Promise<[{decryptedString: string, id: string}]>)}
 */
export const useRetrieveAllDecryptedAccountPasswords = () => {
    const getMyself = useMyselfQueryAsync();
    const loginContext = LoginContext.useContainer();
    const accountPasswordQueries = useLazyAccountPasswordQueries();

    return async (byArchived = null, targetEncryptedPrivateKey = null) => {
        let privateKey = targetEncryptedPrivateKey;
        if (targetEncryptedPrivateKey === null) {
            const me = await getMyself();
            privateKey = me.keyPair.encryptedPrivateKey;
        }

        let encryptedPasswords;
        try {
            encryptedPasswords = await accountPasswordQueries.useExecuteLoadAllAccountPasswordsQuery(byArchived, true);
        } catch (e) {
            console.error(e);
            throw new Error(extractApolloErrorMessage(e, "Es ist leider ein Fehler aufgetreten."));
        }

        try {
            let decryptedPasswords = [];
            for (const encryptedPassword of encryptedPasswords) {
                const decryptedPasswordString = await CryptoManager.decryptEncryptedPassword(
                    privateKey,
                    loginContext.userPasswordHash,
                    encryptedPassword.encryptedString
                );
                decryptedPasswords.push({
                    id: encryptedPassword.id,
                    decryptedString: decryptedPasswordString,
                });
            }

            return decryptedPasswords;
        } catch (err) {
            console.error(err);
            throw new Error("Beim Entschlüsseln des Passwortes ist ein Fehler aufgetreten. " + err.message);
        }
    };
};

/**
 *
 * @returns {(function(byArchived: bool|null = null, targetEncryptedPrivateKey: string|null = null): Promise<[{decryptedString: string, id: string}]>)}
 */
export const useRetrieveAllDecryptedSharedAndCompanyAndOfflinePasswords = () => {
    const getMyself = useMyselfQueryAsync();
    const loginContext = LoginContext.useContainer();
    const sharedPasswordQueries = useLazySharedPasswordQueries();
    const offlinePasswordQueries = useLazyOfflinePasswordQueries();

    return async (byArchived = null, targetEncryptedPrivateKey = null) => {
        let privateKey = targetEncryptedPrivateKey;
        if (targetEncryptedPrivateKey === null) {
            const me = await getMyself();
            privateKey = me.keyPair.encryptedPrivateKey;
        }

        let encryptedPasswords;
        try {
            const encryptedSharedPasswords = await sharedPasswordQueries.executeLoadAllSharedPasswordsQuery(byArchived, true);
            const encryptedCompanyPasswords = await sharedPasswordQueries.executeLoadAllCompanyPasswordsQuery(byArchived, true);
            const encryptedOfflinePasswords = await offlinePasswordQueries.executeLoadAllOfflinePasswordsQuery(true);
            encryptedPasswords = [...encryptedSharedPasswords, ...encryptedCompanyPasswords, ...encryptedOfflinePasswords];
        } catch (e) {
            console.error(e);
            throw new Error(extractApolloErrorMessage(e, "Es ist leider ein Fehler aufgetreten."));
        }

        try {
            return await decryptPasswords(encryptedPasswords, privateKey, loginContext.userPasswordHash);
        } catch (err) {
            console.error(err);
            throw new Error("Beim Entschlüsseln des Passwortes ist ein Fehler aufgetreten. " + err.message);
        }
    };
};

/**
 *
 * @returns {(function(createdByIds: [Guid], byArchived: boolean|null = null): Promise<[{decryptedString: string, id: string}]>)}
 */
export const useRetrieveDecryptedCompanyPasswordsByCreatorIds = () => {
    const getMyself = useMyselfQueryAsync();
    const loginContext = LoginContext.useContainer();
    const sharedPasswordQueries = useLazySharedPasswordQueries();

    return async (createdByIds, byArchived = null) => {
        const me = await getMyself();
        let privateKey = me.keyPair.encryptedPrivateKey;

        let encryptedPasswords;
        try {
            encryptedPasswords = await sharedPasswordQueries.executeLoadCompanyPasswordQuery("", createdByIds, byArchived, true);
        } catch (e) {
            console.error(e);
            throw new Error(extractApolloErrorMessage(e, "Es ist leider ein Fehler aufgetreten."));
        }

        try {
            return await decryptPasswords(encryptedPasswords, privateKey, loginContext.userPasswordHash);
        } catch (err) {
            console.error(err);
            throw new Error("Beim Entschlüsseln der Passwörter ist ein Fehler aufgetreten. " + err.message);
        }
    };
};

/**
 *
 * @returns {(function(createdByIds: [Guid]): Promise<[{decryptedString: string, id: string}]>)}
 */
export const useRetrieveDecryptedOfflinePasswordsByCreatorIds = () => {
    const getMyself = useMyselfQueryAsync();
    const loginContext = LoginContext.useContainer();
    const offlinePasswordQueries = useLazyOfflinePasswordQueries();

    return async (createdByIds) => {
        const me = await getMyself();
        let privateKey = me.keyPair.encryptedPrivateKey;

        let encryptedPasswords;
        try {
            encryptedPasswords = await offlinePasswordQueries.executeLoadOfflinePasswordQuery("", createdByIds, true);
        } catch (e) {
            console.error(e);
            throw new Error(extractApolloErrorMessage(e, "Es ist leider ein Fehler aufgetreten."));
        }

        try {
            return await decryptPasswords(encryptedPasswords, privateKey, loginContext.userPasswordHash);
        } catch (err) {
            console.error(err);
            throw new Error("Beim Entschlüsseln der Passwörter ist ein Fehler aufgetreten. " + err.message);
        }
    };
};

/**
 *
 * @returns {(function(groupIds: [Guid], byArchived: boolean|null = null): Promise<[{decryptedString: string, id: Guid}]>)}
 */
export const useRetrieveDecryptedPasswordsInGroups = () => {
    const getMyself = useMyselfQueryAsync();
    const loginContext = LoginContext.useContainer();
    const sharedPasswordQueries = useLazySharedPasswordQueries();

    return async (groupIds, byArchived = null) => {
        const me = await getMyself();

        let encryptedPasswords;
        try {
            encryptedPasswords = await sharedPasswordQueries.executeLoadSharedPasswordsQuery("", groupIds, byArchived, true);
        } catch (e) {
            console.error(e);
            throw new Error(extractApolloErrorMessage(e, "Es ist leider ein Fehler aufgetreten."));
        }

        try {
            return await decryptPasswords(encryptedPasswords, me.keyPair.encryptedPrivateKey, loginContext.userPasswordHash);
        } catch (err) {
            console.error(err);
            throw new Error("Beim Entschlüsseln des Passwortes ist ein Fehler aufgetreten. " + err.message);
        }
    };
};

/**
 *
 * @returns {function(parentGroupId: [Guid], byArchived: boolean|null = null): Promise<[{decryptedString: string, id: Guid}]>}
 */
export const useRetrieveDecryptedPasswordsInGroupsWithSubGroups = () => {
    const retrieveDecryptedPasswordsInGroups = useRetrieveDecryptedPasswordsInGroups();
    const { executeGroupTreeQuery } = useExecuteGroupTreeQuery();

    return async (parentGroupIds, byArchived = null) => {
        const groupTreeNodes = await executeGroupTreeQuery(byArchived);
        const groupIds = getGroupsFromParentGroups(groupTreeNodes, parentGroupIds);

        return await retrieveDecryptedPasswordsInGroups(groupIds, byArchived);
    };
};

export const useRetrieveDecryptedPasswordString = () => {
    const getMyself = useMyselfQueryAsync();
    const loginContext = LoginContext.useContainer();
    const accountPasswordQueries = useLazyAccountPasswordQueries();
    const sharedPasswordQueries = useLazySharedPasswordQueries();

    return async (passwordType, passwordId) => {
        const me = await getMyself();

        let passwordString = "";
        try {
            switch (passwordType) {
                case KindOfPasswordTypes.accountPasswords:
                    passwordString = await accountPasswordQueries.useExecuteLoadEncryptedAccountPasswordStringQuery(passwordId);
                    break;
                case KindOfPasswordTypes.groupPasswords:
                case KindOfPasswordTypes.companyPasswords:
                    passwordString = await sharedPasswordQueries.executeLoadEncryptedSharedPasswordStringQuery(passwordId);
                    break;
            }
        } catch (e) {
            console.error(e);
            throw new Error("Es ist leider ein Fehler aufgetreten.");
        }

        try {
            let decryptedPassword;
            decryptedPassword = await CryptoManager.decryptEncryptedPassword(
                me.keyPair.encryptedPrivateKey,
                loginContext.userPasswordHash,
                passwordString
            );
            return decryptedPassword;
        } catch (err) {
            console.error(err);
            throw new Error("Beim Entschlüsseln des Passwortes ist ein Fehler aufgetreten. " + err.message);
        }
    };
};
