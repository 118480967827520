import React from "react";
import PropTypes from "prop-types";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    changeLogColumnContainer: {
        "& label": {
            padding: 3,
            marginRight: 5,
        },
    },
    oldValue: {
        backgroundColor: theme.colors.changeLogOldValue,
        textDecoration: "line-through",
    },
    newValue: {
        backgroundColor: theme.colors.changeLogNewValue,
    },
}));

export const LoggedColumnRenderCell = ({ cellParams }) => {
    const classes = useStyles();

    if (!cellParams.formattedValue) return <label />;

    if (typeof cellParams.formattedValue === "string") {
        return <label>{cellParams.formattedValue}</label>;
    }
    return (
        <div className={classes.changeLogColumnContainer}>
            {cellParams.formattedValue.unchangedValue && <label>{cellParams.formattedValue.unchangedValue}</label>}
            {cellParams.formattedValue.oldValue && <label className={classes.oldValue}>{cellParams.formattedValue.oldValue}</label>}
            {cellParams.formattedValue.newValue && <label className={classes.newValue}>{cellParams.formattedValue.newValue}</label>}
        </div>
    );
};
LoggedColumnRenderCell.propTypes = {
    cellParams: PropTypes.object.isRequired,
};
