import * as React from "react";
import PropTypes from "prop-types";
import { Link, useLocation } from "react-router-dom";
import { IconType, IconTypes } from "../../../types/IconType";
import { useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import { PageType, PageTypes } from "../../../types/PageType";
import PageDrawerListEntryBase from "./PageDrawerListEntryBase";
import { menu } from "../../../managers/pathManager";
import { OfflineStatusContext } from "../../../contexts/OfflineStatusContext";
import { OfflinePasswordsContext } from "../../../contexts/OfflinePasswordsContext";
import useTranslation from "../translation/translation";
import { ExtensionInfosContext } from "../../../contexts/ExtensionContext";
import { useDeviceInfos } from "../../../hooks/deviceInfosHook";

const defaultPageProps = {
    currentPageType: PageType.isRequired,
};

/**
 *
 * @param {string} currentPageType
 */
function isDefaultBusinessPage(currentPageType) {
    return currentPageType === PageTypes.default;
}

/**
 *
 * @param {string} currentPageType
 */
function isAdminBusinessPage(currentPageType) {
    return currentPageType === PageTypes.adminConsole;
}

/**
 *
 * @param {string} currentPageType
 */
function isPrivatePage(currentPageType) {
    return currentPageType === PageTypes.private;
}

export function DashboardEntry({ currentPageType }) {
    const { i18n } = useTranslation();
    if (!isDefaultBusinessPage(currentPageType)) return;
    return <PageDrawerListEntry menuItem={menu.root} iconType={IconTypes.dashboard} text={i18n("navigation.dashboard")} />;
}
DashboardEntry.propTypes = defaultPageProps;

export function AllPasswordsEntry({ currentPageType }) {
    const { i18n } = useTranslation();
    if (!isDefaultBusinessPage(currentPageType)) return;
    return <PageDrawerListEntry id="btnAllPasswords" menuItem={menu.all} iconType={IconTypes.allPasswords} text={i18n("navigation.allPasswords")} />;
}
AllPasswordsEntry.propTypes = defaultPageProps;

export function EmployeePasswordsEntry({ currentPageType }) {
    const { me } = useMyselfQuery();
    const { i18n } = useTranslation();
    if (!me.mandator.isBusinessCustomer) return;

    if (!isDefaultBusinessPage(currentPageType) || !me.admin) return;
    return (
        <PageDrawerListEntry
            id="btnEmployeePasswords"
            menuItem={menu.sharedPasswords}
            iconType={IconTypes.lock}
            text={i18n("navigation.employeePasswords")}
        />
    );
}
EmployeePasswordsEntry.propTypes = defaultPageProps;

export function GroupPasswordsEntry({ currentPageType }) {
    const { i18n } = useTranslation();
    if (!isDefaultBusinessPage(currentPageType)) return;
    return (
        <PageDrawerListEntry
            id="btnGroupPasswords"
            menuItem={menu.groupPasswords}
            iconType={IconTypes.sharedPassword}
            text={i18n("navigation.groupPasswords")}
        />
    );
}
GroupPasswordsEntry.propTypes = defaultPageProps;

export function GroupManagementEntry({ currentPageType }) {
    const { i18n } = useTranslation();
    if (!isDefaultBusinessPage(currentPageType)) return;
    return <PageDrawerListEntry menuItem={menu.groups} iconType={IconTypes.groups} text={i18n("navigation.groupManagement")} />;
}
GroupManagementEntry.propTypes = defaultPageProps;

export function UserManagementEntry({ currentPageType }) {
    const { i18n } = useTranslation();
    if (!isAdminBusinessPage(currentPageType)) return;
    return <PageDrawerListEntry menuItem={menu.users} iconType={IconTypes.userEdit} text={i18n("navigation.userManagement")} />;
}
UserManagementEntry.propTypes = defaultPageProps;

export function ImportEntry({ currentPageType }) {
    const { i18n } = useTranslation();
    if (!isAdminBusinessPage(currentPageType) && !isPrivatePage(currentPageType)) return;
    return <PageDrawerListEntry menuItem={menu.aImport} iconType={IconTypes.import} text={i18n("navigation.import")} />;
}
ImportEntry.propTypes = defaultPageProps;

export function ExportEntry({ currentPageType }) {
    const { i18n } = useTranslation();
    if (!isAdminBusinessPage(currentPageType) && !isPrivatePage(currentPageType)) return;

    return <PageDrawerListEntry menuItem={menu.aExport} iconType={IconTypes.export} text={i18n("navigation.export")} />;
}
ExportEntry.propTypes = defaultPageProps;

export function BillingEntry({ currentPageType }) {
    const { i18n } = useTranslation();
    if (!isAdminBusinessPage(currentPageType)) return;
    return <PageDrawerListEntry menuItem={menu.billing} iconType={IconTypes.money} text={i18n("navigation.payments")} />;
}
BillingEntry.propTypes = defaultPageProps;

export function DeviceLogger({ currentPageType }) {
    const { i18n } = useTranslation();
    const { isBusinessCustomer } = useMyselfQuery();

    if (!isAdminBusinessPage(currentPageType)) return;
    if (!isBusinessCustomer) return;

    return <PageDrawerListEntry menuItem={menu.deviceLogger} iconType={IconTypes.visibleEye} text={i18n("navigation.deviceLogger")} />;
}
BillingEntry.propTypes = defaultPageProps;

export function GotoAdminConsoleEntry({ currentPageType }) {
    const { me } = useMyselfQuery();
    const { i18n } = useTranslation();
    if (!isDefaultBusinessPage(currentPageType) || !me.admin) return;
    return <PageDrawerListEntry menuItem={menu.users} iconType={IconTypes.userSettings} text={i18n("navigation.adminConsole")} />;
}
GotoAdminConsoleEntry.propTypes = defaultPageProps;

export function CloseEntry({ currentPageType }) {
    const { i18n } = useTranslation();
    if (!isAdminBusinessPage(currentPageType) && !isPrivatePage(currentPageType) && currentPageType !== PageTypes.superAdminConsole) return;
    return <PageDrawerListEntry menuItem={menu.root} iconType={IconTypes.signOut} text={i18n("navigation.leave")} />;
}
CloseEntry.propTypes = defaultPageProps;

export function PwnedEntry({ currentPageType }) {
    const { i18n } = useTranslation();
    if (!isDefaultBusinessPage(currentPageType)) return;

    return <PageDrawerListEntry menuItem={menu.pwned} iconType={IconTypes.circleInfo} text={i18n("navigation.pwned")} />;
}
SettingEntry.propTypes = defaultPageProps;

export function SettingEntry({ currentPageType }) {
    const { i18n } = useTranslation();
    if (!isDefaultBusinessPage(currentPageType)) return;

    return <PageDrawerListEntry menuItem={menu.profile} iconType={IconTypes.cog} text={i18n("navigation.settings")} />;
}
SettingEntry.propTypes = defaultPageProps;

export function OfflinePasswordEntry({ currentPageType }) {
    const offlineStatusContext = OfflineStatusContext.useContainer();
    const offlinePasswordsContext = OfflinePasswordsContext.useContainer();
    const { i18n } = useTranslation();
    if (!isDefaultBusinessPage(currentPageType)) return;
    const showOfflineDataEntry =
        offlineStatusContext.isOffline || offlinePasswordsContext.hasLocallySavedPasswords || offlinePasswordsContext.hasSyncedPasswords;

    if (!showOfflineDataEntry) return;
    return (
        <PageDrawerListEntry
            highlighted
            menuItem={menu.offlineData}
            iconType={IconTypes.circleWarning}
            text={offlineStatusContext.isOffline ? i18n("navigation.offlineStatus") : i18n("navigation.offlinePasswords")}
        />
    );
}
OfflinePasswordEntry.propTypes = defaultPageProps;

export function MandatorEntry({ currentPageType }) {
    const { me } = useMyselfQuery();
    if (!me.superAdmin || currentPageType !== PageTypes.superAdminConsole) return;

    return <PageDrawerListEntry menuItem={menu.manageMandators} iconType={IconTypes.userEdit} text="Mandatorverwaltung" />;
}

MandatorEntry.propTypes = defaultPageProps;

export function GotoSuperAdminEntry({ currentPageType }) {
    const { me } = useMyselfQuery();
    if (!me.superAdmin || !isDefaultBusinessPage(currentPageType)) return;

    return <PageDrawerListEntry menuItem={menu.manageMandators} iconType={IconTypes.support} text="Support" />;
}

GotoSuperAdminEntry.propTypes = defaultPageProps;

export function InstallAppEntry({ currentPageType }) {
    const offlineStatusContext = OfflineStatusContext.useContainer();
    const { isMobileDevice } = useDeviceInfos();
    const { i18n } = useTranslation();
    if (!(currentPageType === PageTypes.default && offlineStatusContext.isAppInstallerReady && isMobileDevice)) return;

    return (
        <PageDrawerListEntryBase
            onClick={offlineStatusContext.showInstallPwaPrompt}
            iconType={IconTypes.muiPhoneInstall}
            text={i18n("navigation.installApp")}
            currentPageType={currentPageType}
            show
        />
    );
}

InstallAppEntry.propTypes = defaultPageProps;

export function InstallExtensionEntry({ currentPageType }) {
    const { isMobileDevice, isChromium } = useDeviceInfos();
    const extensionContext = ExtensionInfosContext.useContainer();
    const { i18n } = useTranslation();
    if (currentPageType !== PageTypes.default || extensionContext.isExtensionAvailable || !isChromium || isMobileDevice) return;

    return (
        <PageDrawerListEntryBase
            onClick={extensionContext.openInstallTab}
            iconType={IconTypes.muiExtensionInstall}
            text={i18n("navigation.installExtension")}
            currentPageType={currentPageType}
            show
        />
    );
}

InstallExtensionEntry.propTypes = defaultPageProps;

function PageDrawerListEntry({ menuItem, iconType, text, onClick, highlighted, id }) {
    const { path } = menuItem;
    const location = useLocation();

    return (
        <Link data-testid={id} to={path} onClick={onClick} style={{ textDecoration: "none" }} key={path}>
            <PageDrawerListEntryBase show text={text} iconType={iconType} active={location.pathname === path} highlighted={highlighted} />
        </Link>
    );
}

PageDrawerListEntry.defaultProps = {
    highlighted: false,
};

PageDrawerListEntry.propTypes = {
    menuItem: PropTypes.object.isRequired,
    iconType: IconType.isRequired,
    text: PropTypes.string.isRequired,
    highlighted: PropTypes.bool,
    onClick: PropTypes.func,
};
