const addEntry = (cache, refsInCache, fragment, data) => {
    const newRef = cache.writeFragment({
        data: data,
        fragment: fragment,
    });

    return Object.values({ ...refsInCache, newRef });
};

const deleteEntry = (refsInCache, readField, id) => {
    return refsInCache.filter((commentRef) => id !== readField("id", commentRef));
};

const deleteEntries = (refsInCache, readField, ids) => {
    return refsInCache.filter((commentRef) => !ids.includes(readField("id", commentRef)));
};

export { addEntry, deleteEntry, deleteEntries };
