import { useGetCompanyPasswordQuery } from "../../graphql/generated";
import CompanyPasswordsPage from "./CompanyPasswordsPage";
import { useMyselfQuery } from "../../hooks/queries/userQueryHooks";
import useTranslation from "../common/translation/translation";

export function SharedPasswords() {
    const { me } = useMyselfQuery();
    const { i18n } = useTranslation();
    const { data, loading } = useGetCompanyPasswordQuery({
        fetchPolicy: "cache-and-network",
    });

    const companyPasswords = data?.companyPasswords.filter((pw) => pw.createdBy.id !== me.id) || [];
    return (
        <CompanyPasswordsPage
            title={i18n("navigation.employeePasswords")}
            subTitle={i18n("dashboard.vaults.employeePasswordsDescription")}
            loading={loading}
            rowPasswords={companyPasswords}
        />
    );
}
