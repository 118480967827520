import React from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import { IconButton as MuiIconButton } from "@mui/material";
import Icon from "./Icon";
import { IconType } from "../../../types/IconType";
import { IconColorType, IconColorTypeList } from "../../../types/IconColorType";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles((theme) => ({
    button: {
        "& .MuiIcon-root": {
            width: "auto",
        },
    },
}));

const IconButton = React.forwardRef(({ iconType, colorType, size, onClick, className, disabled, ...otherProps }, ref) => {
    const classes = useStyles();

    return (
        <MuiIconButton size={size} onClick={onClick} className={classnames(className, classes.button)} {...otherProps} disabled={disabled} ref={ref}>
            <Icon iconType={iconType} colorType={disabled ? IconColorTypeList.disabled : colorType} />
        </MuiIconButton>
    );
});

IconButton.defaultProps = {
    colorType: IconColorTypeList.inherit,
    size: "medium",
    className: "",
    disabled: false,
};

IconButton.propTypes = {
    iconType: IconType.isRequired,
    colorType: IconColorType,
    size: PropTypes.oneOf(["small", "medium"]),
    onClick: PropTypes.func,
    className: PropTypes.string,
    disabled: PropTypes.bool,
};

export default IconButton;
