import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import Icon from "../dataDisplay/Icon";
import ListItemText from "@mui/material/ListItemText";
import makeStyles from "@mui/styles/makeStyles";
import { IconType } from "../../../types/IconType";
import { Box, Tooltip } from "@mui/material";
import { useModalStatus } from "../../../hooks/modalHook";
import { useIsMobile } from "../../../hooks/mobileHook";
import { DrawerContext } from "../../../contexts/DrawerContext";

const useStyles = makeStyles((theme) => ({
    menuLink: {
        textDecoration: "none",
        color: theme.colors.drawerTextWhite,
        "& .MuiListItem-root:hover": {
            backgroundColor: theme.colors.drawerBackgroundActiveHovered,
            color: theme.colors.drawerTextBlack,
        },
        "& .MuiListItem-root:hover .icon": {
            color: theme.colors.drawerTextBlack,
        },
        "& .MuiListItemIcon-root": {
            minWidth: "unset",
        },
        "& .icon": {
            textDecoration: "none",
            color: theme.colors.drawerTextWhite,
            minWidth: "22px",
            fontSize: "1.2rem",
            display: "flex",
            justifyContent: "center",
        },
    },
    activeLink: {
        color: theme.colors.drawerTextBlack,
        "& .MuiListItem-root": {
            backgroundColor: theme.colors.drawerBackgroundActiveHovered,
        },
        "& .icon": {
            color: theme.colors.drawerTextBlack,
        },
    },
    highlighted: {
        color: theme.colors.drawerTextBlack,
        "& .MuiListItem-root": {
            backgroundColor: theme.colors.drawerDanger,
        },
        "& .MuiListItem-root:hover": {
            backgroundColor: theme.colors.drawerDangerHovered,
            color: theme.colors.drawerTextBlack,
        },
        "& .icon": {
            color: theme.colors.drawerTextBlack,
        },
    },
}));

const PageDrawerListEntryBase = ({ iconType, text, show, active, highlighted, onClick }) => {
    const classes = useStyles();
    const isMobile = useIsMobile();

    const { modalState: tooltipState, open: openTooltip, close: closeTooltip } = useModalStatus();
    const drawerContext = DrawerContext.useContainer();

    if (!show) return <></>;

    return (
        <Box onClick={onClick} className={classNames(classes.menuLink, { [classes.activeLink]: active }, { [classes.highlighted]: highlighted })}>
            <Tooltip title={text} arrow open={tooltipState && isMobile} onClose={closeTooltip} onOpen={openTooltip}>
                <ListItem
                    button
                    sx={{
                        minHeight: 48,
                        justifyContent: "center",
                        px: 2.5,
                    }}
                >
                    <ListItemIcon
                        sx={{
                            minWidth: 0,
                            mr: "center",
                            justifyContent: "center",
                        }}
                    >
                        <Icon iconType={iconType} />
                    </ListItemIcon>
                    {drawerContext.drawerOpen && <ListItemText style={{ marginLeft: "20px" }} primary={text} />}
                </ListItem>
            </Tooltip>
        </Box>
    );
};

PageDrawerListEntryBase.defaultProps = {
    highlighted: false,
};

PageDrawerListEntryBase.propTypes = {
    show: PropTypes.bool.isRequired,
    iconType: IconType.isRequired,
    text: PropTypes.string.isRequired,
    highlighted: PropTypes.bool,
    onClick: PropTypes.func,
    key: PropTypes.string,
};

export default PageDrawerListEntryBase;
