import Page from "../common/layout/Page";
import { PageTypeList } from "../../types/PageType";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";
import { Grid, TextField, Table, TableBody, TableHead, TableRow, TableCell, List, ListItem, ListItemText, Chip } from "@mui/material";
import { styled } from "@mui/system";
import useTranslation from "../common/translation/translation";
import Button from "@mui/material/Button";
import { useState } from "react";
import { useGetAllPasswordQuery, useGetPwnedMailLazyQuery } from "../../graphql/generated";

const Container = styled("div")({
    margin: "1em 2em",
});

export const Header = styled("p")({
    fontSize: "1.6em",
    fontWeight: "600",
    color: "#322D31",
});

const StyledTextField = styled(TextField)({
    backgroundColor: "white",
});

const StyledTableCell = styled(TableCell)({
    fontSize: "1.1em",
    fontWeight: "400",
});

const StyledChips = styled(Chip)({
    margin: ".3em",
});

interface PwnedProps {
    Name: string;
    Title: string;
    Domain: string;
    BreachDate: string;
    AddedDate: string;
    ModifiedDate: string;
    PwnCount: number;
    Description: string;
    LogoPath: string;
    DataClasses: string[];
    IsVerified: boolean;
    IsFabricated: boolean;
    IsSensitive: boolean;
    IsRetired: boolean;
    IsSpamList: boolean;
    IsMalware: boolean;
}

function validateEmail(email: string) {
    const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
}

export default function PwnedPage() {
    const { i18n } = useTranslation();

    const [mail, setMail] = useState<string>("");
    const [currentSearch, setCurrentSearch] = useState<string>("");

    let [load, { loading, data }] = useGetPwnedMailLazyQuery({
        fetchPolicy: "cache-first",
    });

    const { data: allPasswordsData } = useGetAllPasswordQuery({
        fetchPolicy: "cache-and-network",
    });

    const companyPasswords = allPasswordsData?.companyPasswords || [];
    const sharedPasswords = allPasswordsData?.sharedPasswords || [];
    const accountPasswords = allPasswordsData?.accountPasswords || [];
    const allPasswords = [...companyPasswords, ...accountPasswords, ...sharedPasswords];
    const pwnedResult = data?.pwned ? JSON.parse(data.pwned) : [];

    const loginList = Array.from(new Set(allPasswords.map((p) => p?.login).filter((p) => (p ? validateEmail(p) : false)) as string[]));

    return (
        <Page currentPageType={PageTypeList.default}>
            <Container>
                <PageHeader>{i18n("pwned.title")}</PageHeader>
                <PageSubTitle>{i18n("pwned.subTitle")}</PageSubTitle>

                <Grid container spacing={4}>
                    <Grid item xs={10}>
                        <StyledTextField value={mail} onChange={(e) => setMail(e.target.value)} size="small" fullWidth />
                    </Grid>

                    <Grid item xs={2}>
                        <Button
                            disabled={loading || !validateEmail(mail)}
                            onClick={() => {
                                load({ variables: { mail } });
                                setCurrentSearch(mail);
                            }}
                            variant="contained"
                        >
                            {i18n("pwned.action")}
                        </Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={5}>
                        <List>
                            {loginList.map((login: string) => {
                                return (
                                    <ListItem
                                        secondaryAction={
                                            <Button
                                                variant="contained"
                                                onClick={() => {
                                                    setCurrentSearch(login);
                                                    load({ variables: { mail: login } });
                                                }}
                                            >
                                                {i18n("pwned.action")}
                                            </Button>
                                        }
                                    >
                                        <ListItemText primary={login} />
                                    </ListItem>
                                );
                            })}
                        </List>
                    </Grid>

                    <Grid item xs={12}>
                        <Header>{currentSearch}</Header>
                    </Grid>
                    <Grid item xs={12}>
                        {!loading && pwnedResult.statusCode === 429 ? <Header>{i18n("pwned.limit")}</Header> : undefined}

                        {!loading && pwnedResult.length === 0 && currentSearch ? <Header>{i18n("pwned.notShown")}</Header> : undefined}

                        {!loading && pwnedResult.length > 0 ? (
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <StyledTableCell>
                                            <strong>{i18n("pwned.name")}</strong>
                                        </StyledTableCell>
                                        <StyledTableCell>{i18n("pwned.stolen")}</StyledTableCell>
                                        <StyledTableCell>{i18n("pwned.description")}</StyledTableCell>
                                        <StyledTableCell style={{ minWidth: "120px" }}>{i18n("pwned.date")}</StyledTableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {pwnedResult.map((result: PwnedProps, i: number) => {
                                        const { Name, Description, BreachDate, DataClasses } = result;
                                        return (
                                            <TableRow key={"pwd" + i}>
                                                <StyledTableCell>
                                                    <strong>{Name}</strong>
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    {DataClasses.map((dataClass, i: number) => (
                                                        <StyledChips key={"dc" + i} label={dataClass} />
                                                    ))}
                                                </StyledTableCell>
                                                <StyledTableCell>
                                                    <div dangerouslySetInnerHTML={{ __html: Description }} />
                                                </StyledTableCell>
                                                <StyledTableCell style={{ minWidth: "120px" }}>{BreachDate}</StyledTableCell>
                                            </TableRow>
                                        );
                                    })}
                                </TableBody>
                            </Table>
                        ) : undefined}
                    </Grid>
                </Grid>
            </Container>
        </Page>
    );
}
