import { useEffect, useState } from "react";

export const useMaterialUIInput = (initValue) => {
    const [value, setValue] = useState(initValue);

    const onChange = (event) => {
        setValue(event.target.value);
    };

    const clear = () => {
        setValue("");
    };

    return { value, onChange, setValue, clear };
};

/**
 *
 * @param {*} initValue
 * @returns {{onChange: (event: React.ChangeEvent<HTMLInputElement>, value: {id: string} | null) => void, value: *}}
 */
export const useAutocompleteInput = (initValue) => {
    const [value, setValue] = useState(initValue);

    const onChange = (event, value) => {
        setValue(value);
    };

    return { value, onChange };
};

/**
 *
 * @param {*} initValue
 * @returns {{onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, setValue: (value: *) => void, value: *}}
 */
export const useMaterialUICheckedInput = (initValue) => {
    const [value, setValue] = useState(initValue);

    const onChange = (event) => {
        setValue(event.target.checked);
    };

    return { value, onChange, setValue };
};
/**
 *
 * @param {*} initValue
 * @returns {{onChange: (event: React.ChangeEvent<HTMLInputElement>) => void, localValue: *, value: *}}
 */
export const useDelayedMaterialUIInput = (initValue) => {
    const [value, setValue] = useState(initValue);
    const { value: localValue, onChange: setLocalValue } = useMaterialUIInput(initValue);

    const [timer, setTimer] = useState(null);

    useEffect(() => {
        setTimer(
            setTimeout(() => {
                setTimer(null);
                setValue(localValue);
            }, 500)
        );

        return () => clearTimeout(timer);
    }, [localValue]);

    const onChange = (e) => {
        if (timer) clearTimeout(timer);
        setLocalValue(e);
    };

    return { value, localValue, onChange };
};
