import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Grid, TextField } from "@mui/material";
import CustomModal from "../feedback/modals/CustomModal";
import { useAddPassword } from "../../../hooks/passwordHook";
import PasswordInputField from "../dataEntry/input/PasswordInputField";
import { useMyselfQuery } from "../../../hooks/queries/userQueryHooks";
import { KindOfPasswordType, KindOfPasswordTypes } from "../../../types/PasswordType";
import { createNewPasswordMutationObject, usePasswordMutation } from "../../../hooks/queries/passwordMutationHook";
import { extractApolloErrorMessage } from "../../../managers/errorManager";
import config from "../../../config.json";
import { OfflineStatusContext } from "../../../contexts/OfflineStatusContext";
import { OfflinePasswordsContext } from "../../../contexts/OfflinePasswordsContext";
import { CryptoManager } from "../../../managers/cryptoManager";
import { useContextMenu, useModalStatus } from "../../../hooks/modalHook";
import SavePasswordToContextMenu from "./SavePasswordToContextMenu";
import { useAllGroupsQuery } from "../../../hooks/queries/groupQueryHooks";
import useTranslation from "../translation/translation";
import IconButton from "../dataDisplay/IconButton";
import { IconTypesList } from "../../../types/IconType";
import { IconColorTypeList } from "../../../types/IconColorType";
import { GeneratePassword } from "../../dashboard/GeneratePassword";
import { styled } from "@mui/system";
import ConfirmNotSavePasswordDialog from "./ConfirmNotSavePasswordDialog";
import PasswordSaveInformation from "./PasswordSaveInformation";

const Container = styled("div")({
    margin: "auto",
    maxWidth: "600px",
});
const StyledTextField = styled(TextField)({
    width: "100%",
});
const StyledPasswordInputField = styled(PasswordInputField)({
    width: "100%",
});

function uuidv4() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
        (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    );
}

const AddPasswordModal = ({ onClose, onOk, groupId, initialPasswordType }) => {
    const offlineStatusContext = OfflineStatusContext.useContainer();
    const offlinePasswordsContext = OfflinePasswordsContext.useContainer();
    const { createAccountPassword, createCompanyPassword, createCompanyPasswordForOtherUser, createGroupPassword } = usePasswordMutation();

    const [errorWhenAddingPasswords, setErrorWhenAddingPasswords] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const { i18n } = useTranslation();

    const { modalState: passwordGeneratorState, open: openPasswordGeneratorModal, close: closePasswordGeneratorModal } = useModalStatus();
    const { modalState: confirmNotSaveState, open: openConfirmNotSave, close: closeConfirmNotSave } = useModalStatus();
    const [generatedPassword, setGeneratedPassword] = useState("");

    const {
        user,
        setUser,
        name,
        setName,
        website,
        setWebsite,
        login,
        setLogin,
        password: passwordString,
        setPassword: setPasswordString,
        setPasswordValue,
        tags,
        description,
        setDescription,
    } = useAddPassword();
    const [groups, setGroups] = useState([]);

    useEffect(() => {
        if (groupId) setGroups([groupId]);
    }, [groupId]);
    const { loading: loadingQueryMyself, error: errorQueryMyself, me } = useMyselfQuery();

    const [passwordType, setPasswordType] = useState(initialPasswordType);
    const localPasswordType = offlineStatusContext.isOffline ? KindOfPasswordTypes.offlinePasswords : passwordType;
    const { anchorEl, open: openContextMenu, close: closeContextMenu } = useContextMenu();
    const { groups: allGroups } = useAllGroupsQuery();
    const currentGroupName = groups.length > 0 ? allGroups.filter((group) => group.id === groups[0])[0].name : "";

    useEffect(() => {
        if (errorQueryMyself) setErrorMessage("test");
    }, [errorQueryMyself]);

    const handleSubmitCleanup = () => {
        onOk();
        setErrorMessage("");
    };

    const handleAddingAccountPassword = async () => {
        const newPassword = createNewPasswordMutationObject(name, login, website, description, me.keyPair.id);
        await createAccountPassword(me, passwordString, newPassword);

        handleSubmitCleanup();
    };

    const handleAddingGroupPassword = async () => {
        try {
            const tagIds = tags.map((t) => t.id);
            const newPassword = createNewPasswordMutationObject(name, login, website, description, null, tagIds, groups[0]);
            await createGroupPassword(me, passwordString, newPassword, groups[0]);

            handleSubmitCleanup();
        } catch (e) {
            console.error(e);
            setErrorWhenAddingPasswords(true);
            setErrorMessage(extractApolloErrorMessage(e));
        }
    };

    const handleAddingCompanyPassword = async () => {
        try {
            const newPassword = createNewPasswordMutationObject(name, login, website, description);
            await createCompanyPassword(me, passwordString, newPassword);

            handleSubmitCleanup();
        } catch (e) {
            setErrorWhenAddingPasswords(true);
            setErrorMessage(extractApolloErrorMessage(e));
        }
    };

    const handleAddingCompanyPasswordForOtherUser = async () => {
        try {
            const newPassword = createNewPasswordMutationObject(name, login, website, description);
            await createCompanyPasswordForOtherUser(me, passwordString, newPassword, user?.id);

            handleSubmitCleanup();
        } catch (e) {
            setErrorWhenAddingPasswords(true);
            setErrorMessage(extractApolloErrorMessage(e));
        }
    };

    const handleAddOfflinePassword = async () => {
        const newPassword = createNewPasswordMutationObject(name, login, website, description);
        newPassword.encryptedPassword = await CryptoManager.encryptWithPublicKey(passwordString, me.keyPair.publicKeyString);
        newPassword.updatedAt = new Date().toISOString();
        newPassword.tags = [];
        newPassword.offlineGuid = uuidv4();
        offlinePasswordsContext.addOfflinePassword(newPassword);

        handleSubmitCleanup();
    };

    const handleSubmit = async () => {
        try {
            switch (localPasswordType) {
                case KindOfPasswordTypes.accountPasswords:
                    await handleAddingAccountPassword();
                    break;
                case KindOfPasswordTypes.groupPasswords:
                    await handleAddingGroupPassword();
                    break;
                case KindOfPasswordTypes.companyPasswords:
                    if (user) await handleAddingCompanyPasswordForOtherUser();
                    else await handleAddingCompanyPassword();
                    break;
                case KindOfPasswordTypes.offlinePasswords:
                    await handleAddOfflinePassword();
                    break;
            }
        } catch (err) {
            setErrorWhenAddingPasswords(true);
            setErrorMessage(i18n("password.actions.addPasswordError"));
        }
    };

    const handleGroupChange = (value) => {
        if (value.length > 0) setGroups(value.filter((v) => v !== groups[0]));
    };

    return (
        <>
            <CustomModal
                onClose={openConfirmNotSave}
                maxWidth="sm"
                saveText={offlineStatusContext.isOffline ? i18n("password.actions.addOfflinePassword") : i18n("password.actions.addPassword")}
                onOk={handleSubmit}
                okButtonEnabled={Boolean(name) && Boolean(passwordString) && Boolean(passwordString.length <= config.maxPasswordLength)}
                title={i18n("password.actions.addPassword")}
                loading={loadingQueryMyself && !me}
                error={Boolean(errorQueryMyself) || errorWhenAddingPasswords}
                errorMessage={errorMessage}
            >
                <Container>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <PasswordSaveInformation
                                passwordType={passwordType}
                                user={user}
                                showAction={true}
                                currentGroupName={currentGroupName}
                                onClick={openContextMenu}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                value={name}
                                onChange={setName}
                                required
                                autoFocus
                                variant="outlined"
                                size="small"
                                label={i18n("password.information.name")}
                                data-testid="pw-name"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                value={login}
                                onChange={setLogin}
                                variant="outlined"
                                size="small"
                                label={i18n("password.information.usernameEmail")}
                                data-testid="pw-login"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container>
                                <Grid item xs={11}>
                                    <StyledPasswordInputField
                                        required
                                        showInitially
                                        value={passwordString}
                                        onChange={setPasswordString}
                                        label={i18n("password.information.password")}
                                        variant="outlined"
                                        size="small"
                                        error={Boolean(passwordString && passwordString.length > config.maxPasswordLength)}
                                        errorText={i18n("password.information.passwordMaxLength", { key: "count", value: config.maxPasswordLength })}
                                    />
                                </Grid>
                                <Grid item xs={1}>
                                    <IconButton
                                        iconType={IconTypesList.tools}
                                        colorType={IconColorTypeList.secondary}
                                        onClick={openPasswordGeneratorModal}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                value={website}
                                onChange={setWebsite}
                                label={i18n("password.information.website")}
                                variant="outlined"
                                size="small"
                                data-testid="pw-website"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <StyledTextField
                                value={description}
                                onChange={setDescription}
                                variant="outlined"
                                size="small"
                                label={i18n("password.information.description")}
                                minRows={4}
                                maxRows={15}
                                multiline
                                data-testid="pw-description"
                            />
                        </Grid>
                    </Grid>
                </Container>
            </CustomModal>
            <SavePasswordToContextMenu
                setPasswordType={setPasswordType}
                anchorEl={anchorEl}
                onClose={closeContextMenu}
                onEdit={closeContextMenu}
                setGroups={handleGroupChange}
                groups={groups}
                setUser={setUser}
                user={user}
            />
            {passwordGeneratorState && (
                <CustomModal
                    loading={false}
                    error={false}
                    onOk={() => {
                        setPasswordValue(generatedPassword);
                        closePasswordGeneratorModal();
                    }}
                    onClose={closePasswordGeneratorModal}
                    saveText={i18n("general.actions.ok")}
                    maxWidth="xs"
                    title={i18n("dashboard.support.generate.action")}
                >
                    <GeneratePassword
                        onChange={(newPW) => {
                            setGeneratedPassword(newPW);
                        }}
                    />
                </CustomModal>
            )}
            <ConfirmNotSavePasswordDialog
                onCancel={closeConfirmNotSave}
                onClose={() => {
                    closeConfirmNotSave();
                    onClose();
                }}
                open={confirmNotSaveState}
            />
        </>
    );
};

AddPasswordModal.defaultProps = {
    groupId: undefined,
    initialPasswordType: KindOfPasswordTypes.accountPasswords,
};

AddPasswordModal.propTypes = {
    onOk: PropTypes.func.isRequired,
    onClose: PropTypes.func.isRequired,
    groupId: PropTypes.string,
    initialPasswordType: KindOfPasswordType.isRequired,
};

export default AddPasswordModal;
