const registerFidoKey = async (input) => {
    const publicKey = JSON.parse(input);

    // Parse Properties
    publicKey.challenge = coerceToArrayBuffer(publicKey.challenge);
    publicKey.user.id = coerceToArrayBuffer(publicKey.user.id);

    // Create Credentials
    const newCredential = await navigator.credentials.create({ publicKey });

    // Parse Properties
    let attestationObject = new Uint8Array(newCredential.response.attestationObject);
    let clientDataJSON = new Uint8Array(newCredential.response.clientDataJSON);
    let rawId = new Uint8Array(newCredential.rawId);

    const data = {
        id: newCredential.id,
        rawId: coerceToBase64Url(rawId),
        type: newCredential.type,
        extensions: newCredential.getClientExtensionResults(),
        response: {
            AttestationObject: coerceToBase64Url(attestationObject),
            clientDataJson: coerceToBase64Url(clientDataJSON),
        },
    };

    return JSON.stringify(data);
};

const verifyFidoKey = async (challengeInfo) => {
    // Parse Properties
    challengeInfo.challenge = coerceToArrayBuffer(challengeInfo.challenge);
    for (const cred of challengeInfo.allowCredentials) {
        cred.id = coerceToArrayBuffer(cred.id);
    }

    // Get Credentials
    const assertedCredential = await navigator.credentials.get({ publicKey: challengeInfo });

    // Parse Properties
    let authData = new Uint8Array(assertedCredential.response.authenticatorData);
    let clientDataJSON = new Uint8Array(assertedCredential.response.clientDataJSON);
    let rawId = new Uint8Array(assertedCredential.rawId);
    let sig = new Uint8Array(assertedCredential.response.signature);

    const data = {
        id: assertedCredential.id,
        rawId: coerceToBase64Url(rawId),
        type: assertedCredential.type,
        extensions: assertedCredential.getClientExtensionResults(),
        response: {
            authenticatorData: coerceToBase64Url(authData),
            clientDataJson: coerceToBase64Url(clientDataJSON),
            signature: coerceToBase64Url(sig),
        },
    };
    return data;
};

/* --------------------------------- helper --------------------------------- */
const coerceToArrayBuffer = function (thing, name) {
    if (typeof thing === "string") {
        // Base64url to base64
        thing = thing.replace(/-/g, "+").replace(/_/g, "/");

        // Base64 to Uint8Array
        const str = window.atob(thing);
        const bytes = new Uint8Array(str.length);
        for (let i = 0; i < str.length; i++) {
            bytes[i] = str.charCodeAt(i);
        }
        thing = bytes;
    }

    // Array to Uint8Array
    if (Array.isArray(thing)) {
        thing = new Uint8Array(thing);
    }

    // Uint8Array to ArrayBuffer
    if (thing instanceof Uint8Array) {
        thing = thing.buffer;
    }

    // Error if none of the above worked
    if (!(thing instanceof ArrayBuffer)) {
        throw new TypeError("could not coerce '" + name + "' to ArrayBuffer");
    }

    return thing;
};

const coerceToBase64Url = function (thing) {
    // Array or ArrayBuffer to Uint8Array
    if (Array.isArray(thing)) {
        thing = Uint8Array.from(thing);
    }

    if (thing instanceof ArrayBuffer) {
        thing = new Uint8Array(thing);
    }

    // Uint8Array to base64
    if (thing instanceof Uint8Array) {
        let str = "";
        const len = thing.byteLength;

        for (let i = 0; i < len; i++) {
            str += String.fromCharCode(thing[i]);
        }
        thing = window.btoa(str);
    }

    if (typeof thing !== "string") {
        throw new Error("could not coerce to string");
    }

    // Base64 to base64url
    // NOTE: "=" at the end of challenge is optional, strip it off here
    thing = thing.replace(/\+/g, "-").replace(/\//g, "_").replace(/=*$/g, "");

    return thing;
};

/* ------------------------------ export stuff ------------------------------ */
export const TwoFAManager = {
    registerFidoKey,
    verifyFidoKey,
};
// Debug: otherwise we would not be able to access the exported methods inside the console
window.globalTwoFAManager = TwoFAManager;
