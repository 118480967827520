import CustomModal from "../feedback/modals/CustomModal";
import React, { useEffect, useState } from "react";
import useTranslation from "../translation/translation";
import { Grid, Table, TableHead, TableRow, TableBody, TableCell } from "@mui/material";
import { useRetrieveDecryptedPasswordString } from "../../../hooks/cryptoHook";
import { styled } from "@mui/system";
import { AccountPasswordType, SharedPasswordType } from "../../../graphql/generated";

export const Header = styled("p")({
    fontSize: "1.6em",
    fontWeight: "600",
    color: "#322D31",
});

interface NatoAlphabetPasswordModalProps {
    onClose: () => void;
    passwordType: string;
    password: AccountPasswordType | SharedPasswordType;
}

function convertCharToCodeWord(symbol: string) {
    const upperCaseSymbol = symbol.toUpperCase();

    switch (upperCaseSymbol) {
        case "A":
            return "Alfa";
        case "B":
            return "Bravo";
        case "C":
            return "Charlie";
        case "D":
            return "Delta";
        case "E":
            return "Echo";
        case "F":
            return "Foxtrot";
        case "G":
            return "Golf";
        case "H":
            return "Hotel";
        case "I":
            return "India";
        case "J":
            return "Juliett";
        case "K":
            return "Kilo";
        case "L":
            return "Lima";
        case "M":
            return "Mike";
        case "N":
            return "November";
        case "O":
            return "Oscar";
        case "P":
            return "Papa";
        case "Q":
            return "Quebec";
        case "R":
            return "Romeo";
        case "S":
            return "Sierra";
        case "T":
            return "Tango";
        case "U":
            return "Uniform";
        case "V":
            return "Victor";
        case "W":
            return "Whiskey";
        case "X":
            return "Xray";
        case "Y":
            return "Yankee";
        case "Z":
            return "Zulu";
        case "1":
            return "One";
        case "2":
            return "Two";
        case "3":
            return "Three";
        case "4":
            return "Four";
        case "5":
            return "Five";
        case "6":
            return "Six";
        case "7":
            return "Seven";
        case "8":
            return "Eight";
        case "9":
            return "Nine";
        case "0":
            return "Zero";
    }
    return undefined;
}

export default function NatoAlphabetPasswordModal(props: NatoAlphabetPasswordModalProps) {
    const { onClose, passwordType, password } = props;
    const { i18n } = useTranslation();
    const [passwordString, setPasswordString] = useState("");
    const retrieveDecryptedPasswordString = useRetrieveDecryptedPasswordString();

    useEffect(() => {
        const fetchData = async () => {
            const data = await retrieveDecryptedPasswordString(passwordType, password.id);
            setPasswordString(data);
        };

        fetchData().catch(console.error);
    }, [password.id, passwordType, retrieveDecryptedPasswordString]);

    const getCaseText = (symbol: string) => {
        if (symbol === symbol.toLowerCase()) {
            return i18n("password.show.small");
        } else {
            return i18n("password.show.large");
        }
    };

    const getCodeWord = (symbol: string) => {
        const codeWord = convertCharToCodeWord(symbol);
        if (codeWord) return codeWord + " [" + getCaseText(symbol) + "]";

        return "Symbol";
    };

    return (
        <CustomModal
            loading={false}
            error={false}
            onOk={onClose}
            onClose={onClose}
            saveText={i18n("general.actions.ok")}
            maxWidth="xs"
            withCancelButton={false}
            title={password.name}
        >
            <Grid container spacing={2} style={{ maxHeight: "60vh" }}>
                <Grid item xs={12}>
                    <Header>{Array.from(passwordString).map((passwordChar) => passwordChar)}</Header>
                </Grid>
                <Grid item xs={12}>
                    <Table size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell>{i18n("password.show.position")}</TableCell>
                                <TableCell>{i18n("password.show.character")}</TableCell>
                                <TableCell>NATO phonetic alphabet</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {Array.from(passwordString).map((passwordChar, i) => {
                                return (
                                    <TableRow>
                                        <TableCell>{i + 1}</TableCell>
                                        <TableCell>{passwordChar}</TableCell>
                                        <TableCell>{getCodeWord(passwordChar)}</TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </CustomModal>
    );
}
