import React, { useEffect, useState } from "react";
import { OfflineStatusContext } from "../../contexts/OfflineStatusContext";
import Page from "../common/layout/Page";
import ContentContainer from "../common/layout/ContentContainer";
import PasswordSearchBar from "../common/dataEntry/PasswordSearchBar";
import { KindOfPasswordTypes } from "../../types/PasswordType";
import { ClientFilter, defaultPasswordSearchTermFields } from "../../managers/filterManager";
import { useModalStatus } from "../../hooks/modalHook";
import { OfflinePasswordsContext } from "../../contexts/OfflinePasswordsContext";
import LocalOfflinePasswordTable from "./LocalOfflinePasswordTable";
import CustomSnackbar from "../common/feedback/CustomSnackbar";
import AddPasswordModal from "../common/passwordComponents/AddPasswordModal";
import SynchronizedOfflinePasswordTable from "./SynchronizedOfflinePasswordTable";
import { menu } from "../../managers/pathManager";
import { useNavigate } from "react-router-dom";
import { useOfflinePasswordQuery } from "../../hooks/queries/offlinePasswordHook";
import { PageHeader, PageSubTitle } from "../common/typography/Headers";

const OfflinePasswordsPage = () => {
    const offlineStatusContext = OfflineStatusContext.useContainer();
    const offlinePasswordsContext = OfflinePasswordsContext.useContainer();
    const navigate = useNavigate();

    const [searchTerm, setSearchTerm] = useState("");
    const { loading: loadingSyncedPasswords, offlinePasswords: offlineSyncedPasswords } = useOfflinePasswordQuery();

    const { open: openSnackbar, close: closeSnackbar, modalOpen: isSnackbarOpen } = useModalStatus();
    const { modalState: addOfflinePasswordModalState, open: openAddOfflinePasswordModal, close: closeAddOfflinePasswordModal } = useModalStatus();

    const filteredSyncedPasswords = new ClientFilter(offlineSyncedPasswords).bySearchTerm(defaultPasswordSearchTermFields, searchTerm).build();

    useEffect(() => {
        if (!offlineStatusContext.isOffline && !offlinePasswordsContext.hasLocallySavedPasswords && offlineSyncedPasswords.length === 0) {
            navigate(menu.all.path);
        }
    }, [navigate, offlinePasswordsContext.hasLocallySavedPasswords, offlineStatusContext.isOffline, offlineSyncedPasswords.length]);

    useEffect(() => {
        console.log(offlineSyncedPasswords.length);

        offlinePasswordsContext.setHasSyncedPasswords(offlineSyncedPasswords.length > 0);
    }, [offlinePasswordsContext, offlineSyncedPasswords.length]);

    const emptyTitle = "Es befinden sich derzeit keine Offlinepasswörter auf diesem Gerät.";
    const empty = Boolean(
        !offlineStatusContext.isOffline &&
            !loadingSyncedPasswords &&
            filteredSyncedPasswords.length === 0 &&
            !offlinePasswordsContext.locallySavedPasswords.length
    );
    const isInSearch = Boolean(searchTerm !== "");

    return (
        <Page
            loading={loadingSyncedPasswords}
            empty={empty}
            emptyTitle={emptyTitle}
            emptyInformation={""}
            inSearchOperation={isInSearch}
            onNoContentAction={openAddOfflinePasswordModal}
        >
            <PasswordSearchBar
                passwordType={KindOfPasswordTypes.companyPasswords}
                onAddPassword={offlineStatusContext.isOffline ? openAddOfflinePasswordModal : null}
                onSearchTerm={setSearchTerm}
            />
            <ContentContainer>
                <PageHeader>Offline Passwörter</PageHeader>
                <PageSubTitle>Es wurden Passwörter hinzugefügt, als Sie kein Internet hatten.</PageSubTitle>
                <LocalOfflinePasswordTable searchTerm={searchTerm} />
                <SynchronizedOfflinePasswordTable searchTerm={searchTerm} syncedPassword={offlineSyncedPasswords} />
            </ContentContainer>
            {addOfflinePasswordModalState && (
                <AddPasswordModal
                    initialPasswordType={KindOfPasswordTypes.offlinePasswords}
                    onClose={closeAddOfflinePasswordModal}
                    onOk={() => {
                        openSnackbar();
                        closeAddOfflinePasswordModal();
                    }}
                />
            )}
            {isSnackbarOpen && (
                <CustomSnackbar onClose={closeSnackbar} message="Passwort erfolgreich gespeichert" variant="success" withCloseButton />
            )}
        </Page>
    );
};

OfflinePasswordsPage.propTypes = {};

export default OfflinePasswordsPage;
