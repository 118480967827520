import { fragments } from "../managers/fragmentManager";
import { gql } from "@apollo/client";

export const GET_ACCOUNT_PASSWORD = gql`
    query AccountPasswordQuery($searchTerm: String!, $tagIds: [Guid]!, $byArchived: Boolean = false) {
        accountPasswords(searchTerm: $searchTerm, tagIds: $tagIds, byArchived: $byArchived) {
            ...SelectAccountPassword
        }
    }
    ${fragments.query.SelectAccountPassword}
`;

export const GET_SHARED_PASSWORD = gql`
    query SharedPasswordQuery($searchTerm: String!, $groupIds: [Guid]!, $byArchived: Boolean = false) {
        sharedPasswords(searchTerm: $searchTerm, groupIds: $groupIds, byArchived: $byArchived) {
            ...SelectSharedPassword
        }
    }
    ${fragments.query.SelectSharedPassword}
`;

export const EDIT_ACCOUNT_PASSWORD = gql`
    mutation EditAccountPassword($accountPassword: EditAccountPasswordInputType!) {
        accountPassword {
            edit(password: $accountPassword) {
                ...SelectAccountPassword
            }
        }
    }
    ${fragments.query.SelectAccountPassword}
`;

export const EDIT_SHARED_PASSWORD = gql`
    mutation EditSharedPassword($sharedPassword: EditSharedPasswordInputType!) {
        sharedPassword {
            edit(password: $sharedPassword) {
                ...SelectSharedPassword
            }
        }
    }
    ${fragments.query.SelectSharedPassword}
`;