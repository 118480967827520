import { gql } from "@apollo/client";

export const fragments = Object.freeze({
    query: {
        SelectSharedPassword: gql`
            fragment SelectSharedPassword on SharedPasswordType {
                id
                name
                login
                securityRating
                websiteURL
                notes
                modifyLock
                isSavedAsOfflinePassword
                tags {
                    id
                    name
                    color
                }
                updatedAt
                archivedAt
                groupId
                accessLog {
                    id
                    updatedAt
                    user {
                        firstname
                        lastname
                    }
                }
            }
        `,
        SelectAccountPassword: gql`
            fragment SelectAccountPassword on AccountPasswordType {
                id
                name
                login
                securityRating
                websiteURL
                notes
                tags {
                    id
                    name
                    color
                }
                updatedAt
                accessLog {
                    id
                    updatedAt
                }
            }
        `,
        SelectUserSimple: gql`
            fragment SelectUserSimple on UserType {
                id
                lastname
                firstname
                email
                sex
                admin
                emailConfirmed
                archivedAt
            }
        `,
        SelectOwnUserSimple: gql`
            fragment SelectOwnUserSimple on OwnUserType {
                id
                lastname
                firstname
                email
                sex
                admin
                superAdmin
                emailConfirmed
                twoFATypesEnabled
                passwordChangedAt
                keyPair {
                    id
                    encryptedPrivateKey
                    publicKeyString
                }
                usergroups {
                    groupId
                    userId
                    groupRole
                }
                mandator {
                    id
                    subscriptionUserLimit
                    subscriptionStatus
                    isBusinessCustomer
                    customerinformation {
                        id
                        name
                        defaultPaymentMethodId
                        email
                    }
                }
            }
        `,
        SelectAccountTag: gql`
            fragment SelectAccountTag on AccountTagType {
                id
                name
                color
            }
        `,
        SelectSharedTag: gql`
            fragment SelectSharedTag on SharedTagType {
                id
                name
                color
                group {
                    id
                    name
                }
            }
        `,
        SelectGroup: gql`
            fragment SelectGroup on GroupType {
                id
                name
                archivedAt
                countPasswords
                countSubGroups
                countUsers
                countTags
            }
        `,
    },
});
