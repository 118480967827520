import { makeStyles } from "@mui/styles";

export const useGroupTreeStyles = makeStyles((theme) => ({
    groupItem: {
        fontSize: 14,
    },
    smallGroupIcon: {
        fontSize: 17,
        width: "1.7rem",
    },
}));
