import { CardContent, Grid, Link } from "@mui/material";
import Icon from "../common/dataDisplay/Icon";
import { Header } from "./DashboardPage";
import useTranslation from "../common/translation/translation";

export default function SupportCard({ header, iconType, href, linkText, showTimeInformation }) {
    const { i18n } = useTranslation();

    return (
        <CardContent>
            <Header>{header}</Header>
            <Grid container>
                <Grid item xs={2}>
                    <div style={{ marginRight: "20px", float: "right" }}>
                        <Icon colorType="action" iconType={iconType} />
                    </div>
                </Grid>
                <Grid item xs={10}>
                    <Link style={{ fontSize: "1.2em" }} color="inherit" variant="body2" href={href}>
                        {linkText}
                    </Link>
                </Grid>
                {showTimeInformation && (
                    <Grid style={{ fontSize: "1.2em", marginTop: "0.8em" }} item xs={12}>
                        {i18n("dashboard.support.opening")}
                    </Grid>
                )}
            </Grid>
        </CardContent>
    );
}
