import React from "react";
import PropTypes from "prop-types";
import Button from "@mui/material/Button";
import makeStyles from "@mui/styles/makeStyles";
import Icon from "./Icon";
import { IconType } from "../../../types/IconType";

const useStyles = makeStyles((theme) => ({
    button: {
        fontSize: ".8rem",
        padding: "8px",
        color: (props) => (props.activated ? "white" : theme.colors.textLight),

        "& .icon": {
            width: "1.5rem",
            fontSize: "1.1rem",
            color: theme.colors.filterBar,
        },
        "& .MuiButton-label": {
            textTransform: "none",
        },
        "& .MuiButton-startIcon": {
            marginRight: "4px",
        },
    },
}));

const FilterButton = ({ iconType, text, onClick, activated }) => {
    const classes = useStyles({ activated: activated });

    return (
        <Button
            onClick={onClick}
            startIcon={<Icon iconType={iconType} />}
            className={classes.button}
            variant={activated ? "contained" : "text"}
            color={activated ? "primary" : "secondary"}
        >
            {text}
        </Button>
    );
};

FilterButton.propTypes = {
    iconType: IconType.isRequired,
    onClick: PropTypes.func,
    text: PropTypes.any,
    activated: PropTypes.bool.isRequired,
};

export default FilterButton;
